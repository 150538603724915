import React, { useContext, useEffect, useState } from 'react';
import instance from '../../Assets/Axios/AxiosInstance';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import useUserCan, { useIsUser } from '../../Assets/Functions/UserPermissions';
import Layout from '../../HOC/Layout/Layout';
import { withRouter } from 'react-router-dom';
import { StoreContext } from '../../Store/StoreContext';
import PopupModal from '../../Components/UI/PopUps/PopupModal';
import MaintenanceBanner from './MaintenanceBanner/MaintenanceBanner';
import ClosingGrantSection from './ClosingGrantSection/ClosingGrantSection';
import CategoriesCarousel from '../../Components/AppicationComponents/CategoriesCarousel/CategoriesCarousel';
import { UpgradeCallToAction, BookConsultationCallToAction } from '../../Components/UI/CallToAction/CallToAction';
import { useHistory } from 'react-router-dom';
import { GRANT_PAGE_URL } from '../AvailableGrantsView/AvailableGrantsView';
import ProactiveDisclosureBanner from './ProactiveDisclosureBanner/ProactiveDisclosureBanner';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import ViolatedTermsOfServiceModal from '../../Components/UI/PopUps/ViolatedTermsOfServiceModal/ViolatedTermsOfServiceModal';
import { GROUPS, isInGroup } from '../../utils/groups';

import classes from './HomePage.module.css';

/**
 * URL for the home page.
 */
export const HOME_PAGE_URL = '/home';

const HomePage = () => {
  const { state, actions } = useContext(StoreContext);
  const history = useHistory();
  const canSeeExplore = useUserCan.SeeExploreFunding();
  const isPaying = useIsUser.Paying();
  const [totalGrantMatches, setTotalGrantMatches] = useState('some');
  const [showViolatedTermsOfServiceModal, setShowViolatedTermsOfServiceModal] = useState(false);

  // handling callbacks
  // the UpgradeToBasicPopUp is stored in HOC/Layout/Layout.js rather than here
  // because the Navigation component need to be able to display it even on non-HomePage components
  const openGrantsWithThisCategory = (title, isEmpty) => {
    Mixpanel.track('Category Clicked', {
      category: title,
    });
    history.push(isEmpty ? GRANT_PAGE_URL : `${GRANT_PAGE_URL}?subcategory=${encodeURIComponent(title)}`);
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    instance
      .get('/get-user-attributes/')
      .then(res => {
        if (isInGroup(GROUPS.termsOfServiceViolators, res.data.groups)) {
          setShowViolatedTermsOfServiceModal(true);
          Mixpanel.track('Terms of Service Violator Attempted Login');
        }
      })
      .catch(() => {
        console.log('Error Retrieving User Attributes');
        Mixpanel.track('Error Retrieving User Attributes');
      });

    instance.get(`/api/matches/`, { cancelToken: source.token }).then(res => {
      setTotalGrantMatches(res.data.count);
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className={classes.container}>
        <p className={`${classes.section} ${classes.totalGrantCount}`}>
          Your business has <span className={classes.grantCountNumber}>{totalGrantMatches}</span> matches!
        </p>

        <ClosingGrantSection isLocked={!canSeeExplore} />

        <ProactiveDisclosureBanner />

        <div className={classes.section}>
          <h2 className={classes.headerAlignment}>EXPLORE MY GRANTS</h2>
          <p className={classes.headerAlignment}>Pick a category to start exploring</p>
          <div className={'d-sm-flex d-none'}>
            <CategoriesCarousel
              slidesPerView={3}
              slidesPerGroup={1}
              autoPlayDelay={3000}
              extraClasses={classes.carousel}
              extraCardClasses={classes.categoryCard}
              cardOnClick={openGrantsWithThisCategory}
              centeredSlides
            />
          </div>
          <div className={'d-sm-none'}>
            <CategoriesCarousel
              slidesPerView={2}
              slidesPerGroup={1}
              autoPlayDelay={3000}
              extraClasses={classes.carousel}
              extraCardClasses={classes.categoryCard}
              cardOnClick={openGrantsWithThisCategory}
              centeredSlides
            />
          </div>
        </div>

        {isPaying ? <BookConsultationCallToAction extraClasses={classes.callToAction} /> : <UpgradeCallToAction extraClasses={classes.callToAction} />}
      </div>
      {process.env.REACT_APP_MAINTENANCE_WARNING === 'true' && <MaintenanceBanner />}
      {state.alphaPopup.flag && (
        <PopupModal
          type="alphaUsPopup"
          backgroundClicked={() => actions.setAlphaPopup({ flag: false })}
        />
      )}
      <ViolatedTermsOfServiceModal show={showViolatedTermsOfServiceModal} />
    </Layout>
  );
};

export default withRouter(HomePage);
