import React, { useRef, useState, useContext } from 'react';
import { StoreContext } from '../../../../Store/StoreContext';
import YearMonthToggle from '../../../../Components/YearMonthToggle/YearMonthToggle';
import PlanSection from '../../../../Components/PlanSection/PlanSection';
import classes from './Plans.module.css';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import { createStripeCheckout, portalSubmit } from '../../../../Assets/Functions/Stripe/Stripe';
import { useIsUser } from '../../../../Assets/Functions/UserPermissions';
import DiscountCode from '../../../../Components/UI/DiscountCode/DiscountCode';

const Plans = ({ referralCode, discountInfo, urlPlan }) => {
  const [isMonthly, setIsMonthly] = useState(false);
  const cardsRef = useRef();
  const isUserPaying = useIsUser.Paying();

  const { actions } = useContext(StoreContext);
  const checkoutSubmit = (e, plan, isMonthly) => {
    Mixpanel.track('Clicked on Choose Plan', { plan: plan });
    e.preventDefault();
    actions.setShowLoadingPopUp(true);
    // stripe checkout //
    return createStripeCheckout(plan, isMonthly)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        actions.setShowLoadingPopUp(false);
        console.log(err);
      });
  };

  const toggleHandler = () => {
    setIsMonthly(!isMonthly);
  };

  const scrollToTop = () => {
    cardsRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };
  return (
    <div className={classes.plansContainer}>
      <h1 className={classes.header}>We’ve got a plan for your business.</h1>
      <div className={classes.toggle}>
        <YearMonthToggle
          isMonthly={isMonthly}
          toggleHandler={toggleHandler}
        />
      </div>
      {isMonthly && (
        <div className={classes.monthlyInfo}>
          {/* <img
              src={infoWhite}
              alt="info"
            /> */}
          Our monthly plans have a 12-month minimum term, giving you cash flow flexibility and enough runway to see ROI on your grant investment.
          <br />
          Grants are a long game - stay in it to win it!
        </div>
      )}

      <PlanSection
        checkoutSubmit={checkoutSubmit}
        portalSubmit={portalSubmit}
        isMonthly={isMonthly}
        scrollToTop={scrollToTop}
        urlPlan={urlPlan}
      />
      <br />
      <a
        href="https://hellopocketed.io/plans/"
        target="_blank"
        rel="noreferrer"
        style={{ margin: '1em' }}
      >
        See full plan details
      </a>
      <br />
      {/* check if the code should be shown and if there is information being returned from the `discount-code` api */}
      {referralCode && !isUserPaying && discountInfo.discountPlan ? (
        <div style={{ marginBottom: '1em', marginTop: '1em', width: '50%' }}>
          <DiscountCode
            discountCode={referralCode}
            discountInfo={discountInfo}
            isReferral={true}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Plans;
