import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useScreenWidth, DEVICE_WIDTH } from '@hellopocketed/react-ui.js_utils.core';
import PropTypes from 'prop-types';
import { StoreContext } from '../../../../Store/StoreContext';
import GrantTitle from '../../../../Components/UI/GrantDetails/GrantTitle/GrantTitle';
import GrantAmount from '../../../../Components/UI/GrantDetails/GrantAmount/GrantAmount';
import GrantClosingDate from '../../../../Components/UI/GrantDetails/GrantClosingDate/GrantClosingDate';
import { GRANT_PAGE_URL } from '../../../AvailableGrantsView/AvailableGrantsView';
import BaseOverlay from '../../../../Components/UI/Overlays/BaseOverlay/BaseOverlay';

import classes from './ClosingGrant.module.css';

const ClosingGrant = ({ currentMatch, isLocked }) => {
  /**
   * The actual grant data (name, amount, etc.) that are saved in the backend.
   */
  const { grant } = currentMatch;
  const { actions } = useContext(StoreContext);

  const history = useHistory();

  const openGrant = () => {
    actions.setCurrentMatch(currentMatch);
    actions.setSearchValue(currentMatch.grant.name);
    history.push(GRANT_PAGE_URL);
  };

  // default cutOffLength in the grant title
  let cutOffLength = undefined;
  let screenWidth = useScreenWidth();
  if (screenWidth < DEVICE_WIDTH.xLarge) {
    cutOffLength = 40;
  }

  let title = (
    <GrantTitle
      title={grant.name}
      cutOffLength={cutOffLength}
    />
  );

  if (isLocked) {
    title = <BaseOverlay coverClasses={classes.overlayCover}>{title}</BaseOverlay>;
  }

  return (
    <div
      className={`${classes.container} pocketed-rounded-card`}
      onClick={openGrant}
    >
      {title}
      <GrantAmount
        amount={grant.amount}
        extraClasses={classes.grantAmount}
      />
      <GrantClosingDate expiroryDate={grant.deadlines} />
    </div>
  );
};

ClosingGrant.propTypes = {
  /**
   * All the grant data as seen in Assets/Types/TypeExamples.js.
   */
  currentMatch: PropTypes.object.isRequired,

  /**
   * Whether this component is locked from user interaction.
   */
  isLocked: PropTypes.bool,
};

export default ClosingGrant;
