import React, { createContext, useEffect, useReducer } from 'react';
// import { useActions } from './Actions';
// import { Reducer } from './Reducer';
// import initialState from './InitialState';
import { initialStates, reducers, useActions } from './Slices';

const StoreContext = createContext(initialStates);

const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, initialStates);
  const actions = useActions(dispatch);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      if (state.currentUser.user !== '') {
      }
    });

    return () => {
      if (state.currentUser.user !== '') {
      }
    };
  }, [state.currentUser.user]);

  return <StoreContext.Provider value={{ state, actions, dispatch }}>{children}</StoreContext.Provider>;
};

export { StoreContext, StoreProvider };
