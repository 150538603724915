import React from 'react';
import Layout from '../../../HOC/Layout/Layout';
import PropTypes from 'prop-types';

import classes from './GrantView.module.css';

/**
 * Intended to be used for the any GrantView component (AvailableGrantsView and SingleGrantView).
 * @param {*} param0
 * @returns
 */
const GrantView = ({ headerLeft, headerRight, bodyLeft, bodyRight, footer }) => {
  return (
    <Layout>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.headerLeft}>{headerLeft}</div>
          <div className={classes.headerRight}>{headerRight}</div>
        </div>

        <div className={classes.body}>
          <div className={classes.bodyLeft}>{bodyLeft}</div>
          <div className={classes.bodyRight}>{bodyRight}</div>
        </div>

        <div className={classes.footer}>{footer}</div>
      </div>
    </Layout>
  );
};

GrantView.propTypes = {
  /**
   * The left side of the header on top of the main body.
   */
  headerLeft: PropTypes.node,

  /**
   * The right side of the header on top of the main body.
   */
  headerRight: PropTypes.node,

  /**
   * The left side of the main body.
   */
  bodyLeft: PropTypes.node,

  /**
   * The right side of the main body.
   */
  bodyRight: PropTypes.node,

  /**
   * The area underneath the main body.
   */
  footer: PropTypes.node,
};

export default GrantView;
