import React, { useContext } from 'react';
import { StoreContext } from '../../../../Store/StoreContext';
import classes from './EditProfilePopup.module.css';
import { ReactComponent as Warning } from '../../../../Assets/Images/MyProfile/warning.svg';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';

const EditProfilePopup = () => {
  const { actions } = useContext(StoreContext);
  return (
    <div
      className={classes.container}
      onClick={e => e.stopPropagation()}
    >
      <h2 className={classes.title}> Warning </h2>
      <Warning className={classes.icon} />
      <p className={classes.message}>
        By editing your Grant Profile, your grant matches will be re-generated and could cause you to <b>lose your saved grants</b>.
      </p>
      <HoverableButton
        presetBtnStyle="pink"
        onClick={() => actions.setEditProfilePopup({ flag: false })}
      >
        I understand
      </HoverableButton>
    </div>
  );
};

export default EditProfilePopup;
