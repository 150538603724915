import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import classes from './IPFundingPopup.module.css';
import IPFundingImage from '../../../../Assets/Images/UI/IPFunding.png';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import { StoreContext } from '../../../../Store/StoreContext';

const IPFundingPopup = () => {
  const { state, actions } = useContext(StoreContext);

  return (
    <Modal
      show={state.IPFundingPopup}
      onHide={() => actions.setIPFundingPopup(false)}
      dialogClassName={classes.modalDialog}
      contentClassName={classes.modalContent}
      centered
    >
      <div
        className={classes.image}
        onClick={() => {
          Mixpanel.track('Click on IPFunding Popup', {});
          window.open('https://meetings.hubspot.com/linda-jolly/round-robin-campaign-booking');
        }}
      >
        <img
          src={IPFundingImage}
          alt="your business may be interested in IP Funding"
          className={classes.img}
        ></img>
      </div>
    </Modal>
  );
};

export default IPFundingPopup;
