import React, { useContext } from 'react';
import { OutlineHoverableButton } from '@hellopocketed/react-ui.widgets.buttons.outline_hoverable_button';
import PropTypes from 'prop-types';
import { StoreContext } from '../../../../Store/StoreContext';

import classes from './ClearFiltersButton.module.css';

/**
 * Clear all the filters in the filter selection.
 * @returns
 */
const ClearFiltersButton = ({ onClick }) => {
  const { actions } = useContext(StoreContext);
  const masterOnClick = () => {
    actions.resetFilters();
    if (onClick) onClick();
  };

  return (
    <OutlineHoverableButton
      presetBtnStyle="dark-purple"
      presetSize="small"
      onClick={masterOnClick}
      className={classes.button}
    >
      Clear All
    </OutlineHoverableButton>
  );
};

ClearFiltersButton.propTypes = {
  /**
   * Extra onclick handler for when the button is clicked.
   */
  onClick: PropTypes.func,
};

export default ClearFiltersButton;
