import BaseSlice from './BaseSlice';

const initialState = {
  /**
   * Track the notificationData for the user. See Assets/Types/NotificationExample.js to see what it should look like.
   */
  notificationData: null,
};

const reducerObj = {
  setNotificationData: (state, action) => {
    return {
      ...state,
      notificationData: action.payload,
    };
  },
};

const NotificationSlice = new BaseSlice(initialState, reducerObj);
export default NotificationSlice;
