import React from 'react';
import classes from './Maintenance.module.css';
import { PocketedColoredLogoColoredText } from '@hellopocketed/react-ui.images.pocketed_logos';

const Maintanance = () => {
  return (
    <div className={classes.container}>
      <div className={classes.notice}>
        <div className={classes.content}>
          <img
            alt="Pocketed"
            src={PocketedColoredLogoColoredText}
          />
          <h2>🔧 &nbsp; Under Maintenance &nbsp; 🔧</h2>
          <p>We are currently upgrading our hosting to provide better performance. We should be back online later today! </p>
          <p>Thank you for your patience!</p>
        </div>
      </div>
    </div>
  );
};

export default Maintanance;
