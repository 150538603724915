/**
 * An object mapping the REACT_APP_HOST (backend URL) with the appropriate
 * CSRF token name to use.
 */
const CSRF_TOKENS_OBJ = {
  'http://localhost:8000': 'csrf_local',
  'https://backend.hellopocketed.io': 'csrf_staging',
  'https://api.hellopocketed.io': 'csrf_prod',
};

/**
 * The CSRF_TOKEN name we should use when querying the backend.
 */
const CSRF_TOKEN_NAME = CSRF_TOKENS_OBJ[process.env.REACT_APP_HOST];

export default CSRF_TOKEN_NAME;
