import React, { useState, useRef } from 'react';
import ReasonPopup from './ReasonPopup';
import DealPopup from './DealPopup';

// import classes from './CancellationPopup.module.css';

const CancellationPopup = ({ closePopup, portalSubmit }) => {
  const [showDeal, setShowDeal] = useState(false);
  const [buttonMessage, setButtonMessage] = useState(false);

  // Reasons a user wants to cancel, passed to <ReasonPopup>, <DealPopup>
  const cancelReasons = [
    {
      title: 'price',
      text: 'Too pricey',
      ref: useRef(),
      dealMessage: (
        <>
          <p>We’re Brianna and Aria, Pocketed's co-founders.</p>

          <p>We hear you loud and clear - it’s too expensive - and we get it.</p>

          <p>We also know (from experience!) that there is money out there for every business spending money AND how crucial this investment could be to kick-start your growth.</p>

          <p>We understand how hard it is to build a business. That’s why we strive to be founder-friendly while balancing our own overhead and building more and more value for you.</p>

          <p>What can we do to make this feasible for your business? Let’s meet where you’re at - what is Pocketed worth to you? Make us a deal and let’s get you started on your grant funding journey!</p>
        </>
      ),
    },
    {
      title: 'usage',
      text: "I didn't find what I need",
      ref: useRef(),
      dealMessage: (
        <>
          <p>We’re Brianna and Aria, Pocketed's co-founders.</p>

          <p>We hear you loud and clear - you’re not sure how Pocketed can help - and we get it.</p>

          <p>We also know (from experience!) that there is money available for every business spending money AND how crucial this investment could be to kick-start your growth.</p>

          <p>One thing we know (also from experience!) is that grants are a long game. Giving yourself time to succeed with it is the hardest step.</p>

          <p>That’s why we strive to be founder-friendly while balancing our own overhead and building more and more value for you.</p>

          <p>What can we do to make this feasible for your business? Let’s meet where you’re at - make us a deal and let’s get you started on your grant funding journey!</p>
        </>
      ),
    },
    {
      title: 'help',
      text: 'I need more help finding grants',
      ref: useRef(),
      buttonMessage: <>Contact us</>,
      dealMessage: (
        <>
          <p>We're Brianna and Aria, Pocketed's co-founders.</p>

          <p>We hear you loud and clear - grants are time-consuming. We get it. </p>

          <p>We also know (from experience!) that there is money out there for every business AND how crucial this capital is to accelerating your growth.</p>

          <p>Did you know we offer a Concierge solution, exclusively available to highly eligible companies?</p>

          <p>Through Pocketed Concierge, our grant specialists build your custom Grant Funding Roadmap, share ongoing recommendations of exciting funding opportunities, and can even do the grant writing for you? Basically, we take care of grants so you can do what you do best: build your business.</p>

          <p>Funding, guaranteed. We guarantee we will secure funding for your business, or your money back.</p>

          <p>Interested? Contact us to learn more.</p>
        </>
      ),
    },
    /**
     * "OTHER" FIELD SHOULD REMAIN IN THIS LIST
     * OBJECTS WILL RENDER IN THE ORDER OF THIS LIST
     * PLEASE ADD REASONS NEEDING ONLY RADIO BUTTONS ABOVE ^^
     */
    {
      title: 'other',
      text: 'Other',
      ref: useRef(),
      dealMessage: (
        <>
          <p>We’re Brianna and Aria, Pocketed's co-founders.</p>

          <p>We hear you loud and clear - grants are time-consuming - and we get it.</p>

          <p>We also know (from experience!) that there is money out there for every business spending money AND how crucial this investment could be to kick-start your growth.</p>

          <p>Contact us to chat more about your business needs so we can get you the funding you are eligible for! With our team in your back pocket, you’ll never miss another penny.</p>

          <p>Or, make us a deal and let’s get you started on your grant funding journey!</p>
        </>
      ),
    },
  ];

  return (
    <>
      {showDeal ? (
        <DealPopup
          dealMessage={showDeal}
          buttonMessage={buttonMessage}
          closePopup={closePopup}
          portalSubmit={portalSubmit}
        />
      ) : (
        <ReasonPopup
          setShowDeal={setShowDeal}
          setButtonMessage={setButtonMessage}
          closePopup={closePopup}
          cancelReasons={cancelReasons}
        />
      )}
    </>
  );
};

export default CancellationPopup;
