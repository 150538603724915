import instance from '../../Axios/AxiosInstance';
import debounce from '../debounce';
import { getTranslateableNodes, getIframes } from './getTextForTranslate';
import { engToFreStore, freToEngStore } from './translateGlobal';

/**
 * Translate everything that's in the site at the current moment.
 * @param {Node} rootNode - the root node to start translating. Since this is a React app -> translate the root.
 * @param {boolean} toFrench - whether we are translating from eng to french. If false, it means we are translating from
 * french to eng.
 */
export async function translateSite(rootNode, toFrench) {
  let [textNodes, inputNodes, title] = getTranslateableNodes(rootNode, toFrench, true);
  await translateNodes(textNodes, inputNodes, title, toFrench);
}

/**
 * Translate the nodes passed in.
 * @param {Array} textNodes - an array of nodes that has texts to be translated.
 * @param {Array} inputNodes - an array of nodes that are input nodes that needs to be translated.
 * @param {Array} title - the page's title.
 * @param {boolean} toFrench - whether we are translating from eng to french. If false, it means we are translating from
 * french to eng.
 * @returns
 */
async function translateNodes(textNodes, inputNodes, title, toFrench) {
  // transStore represent the translation direction. If toFrench is true, this is eng -> fre
  let transStore = engToFreStore;

  // reverseTransStore represent the reverse of the above. If toFrench is true, this is fre -> eng
  let reverseTransStore = freToEngStore;

  if (toFrench) {
    // check whether the nodes already have its translations cached so we don't have to requery
    let notYetTranslatedTextNodes = textNodes.filter(node => !transStore[node.textContent]);
    let textNodesContent = notYetTranslatedTextNodes.map(node => node.textContent);

    let inputNodesContent = inputNodes.map(node => node.value).filter(txt => !transStore[txt]);

    let notYetTranslated = textNodesContent.concat(inputNodesContent);
    if (title !== '' && !transStore[title]) {
      notYetTranslated.push(title);
    }

    if (notYetTranslated.length !== 0) {
      let translatedText = await translateViaAPI(notYetTranslated);

      // store the translation
      let domParser = new DOMParser();
      let i;
      // store the text first
      for (i = 0; i < textNodesContent.length; i++) {
        let body = domParser.parseFromString(translatedText[i], 'text/html').body;
        if (notYetTranslatedTextNodes[i].complexNode) {
          // complex nodes need to have their children stored in node form
          // so we can put them back later
          transStore[textNodesContent[i]] = body.childNodes;
          reverseTransStore[body.innerHTML] = freezeNodeListContent(notYetTranslatedTextNodes[i].complexNode.childNodes);
        } else {
          transStore[textNodesContent[i]] = body.textContent;
          reverseTransStore[body.textContent] = textNodesContent[i];
        }
      }

      // store the translated input if any
      for (let j = 0; j < inputNodesContent.length; j++) {
        // continue where we left off before with i since we use the same array
        let body = domParser.parseFromString(translatedText[i++], 'text/html').body;
        // whether the text is complex aka contains more than just simple text nodes
        transStore[inputNodesContent[j]] = body.textContent;
        reverseTransStore[body.textContent] = inputNodesContent[j];
      }

      // still has title
      if (i !== notYetTranslated.length) {
        let body = domParser.parseFromString(translatedText[i++], 'text/html').body;
        transStore[title] = body.textContent;
        reverseTransStore[body.textContent] = title;
      }
    }
  } else {
    // !toFre means French to English
    transStore = freToEngStore;
    reverseTransStore = engToFreStore;
  }

  // replace old text with translated text
  for (let node of textNodes) {
    if (node.complexNode) {
      // complex node takes more work to replace
      let ogNode = node.complexNode;
      let translationNode = transStore[node.textContent];
      replaceComplexNodeText(ogNode, translationNode, node.documentGlobal);
    } else {
      let translatedContent = transStore[node.textContent];

      // for the grant profile edit page, the multi-select component's
      // text during edit and display have the same text => both
      // points to the NodeList representing the translated text.
      // thus, we gotta handle case where a simple text is mapped to a nodelist
      if (translatedContent?.constructor?.name === 'NodeList') {
        translatedContent = translatedContent[0].textContent;
      }
      node.textContent = translatedContent;
    }
  }

  for (let node of inputNodes) {
    node.value = transStore[node.value];
  }

  if (title) document.title = transStore[title];
}

/**
 * Translate the text using the API.
 * @param {Array} textArr
 * @returns
 */
export function translateViaAPI(textArr) {
  return instance
    .post('/translate/', { data: textArr })
    .then(res => res.data.translated)
    .catch(err => {
      console.error('Translation Failed');
      console.error(err);
      throw new Error('Translation Failed');
    });
}

/**
 * Freeze the node list content. We only get the attributes
 * that we need for `replaceComplexNodeText`, which are
 * `nodeName` and `textContent`.
 * @param {NodeList} nodeList
 */
function freezeNodeListContent(nodeList) {
  let freezeList = [];
  for (let node of nodeList) {
    freezeList.push({
      nodeName: node.nodeName,
      textContent: node.textContent,
    });
  }
  return freezeList;
}

/**
 * Replace a complex node with its french translation.
 * @param {Node} ogNode - the original node in the DOM
 * @param {NodeList} transNodeList - the translation node list that we store in the store
 * @param {Document} documentGlobal - the document object that the
 * element belongs in.
 */
function replaceComplexNodeText(ogNode, transNodeList, documentGlobal) {
  let transIdx = 0;
  let ogNodeIdx = 0;
  while (true) {
    let ogChild = ogNode.childNodes[ogNodeIdx];
    let transChild = transNodeList[transIdx];

    if (transChild.nodeName === ogChild.nodeName) {
      // works for #text, <br>, <a> etc.
      if (ogChild.nodeName !== 'svg') {
        ogChild.textContent = transChild.textContent;
      }
      transIdx++;
      ogNodeIdx++;
    } else {
      // mismatched type -> we only care about text nodes
      if (ogChild.nodeName === '#text') {
        ogChild.textContent = ''; // clear the og child's text content BUT don't remove it from DOM
        ogNodeIdx++;
      } else if (transChild.nodeName === '#text') {
        ogNode.insertBefore(documentGlobal.createTextNode(transChild.textContent), ogChild);
        transIdx++;
        ogNodeIdx++; // increase og as well since we just added a new text node
      }
    }

    // check whether both ends at the same time
    if (ogNodeIdx === ogNode.childNodes.length && transIdx === transNodeList.length) {
      break;
    }
    // check whether og ends before trans
    else if (transIdx < transNodeList.length && ogNodeIdx === ogNode.childNodes.length) {
      // all spans and as should be filled in. Rest should be text nodes.
      for (let i = transIdx; i < transNodeList.length; i++) {
        ogNode.appendChild(document.createTextNode(transNodeList[i].textContent));
      }
      break;
    }
    // check whether trans ends before og
    else if (ogNodeIdx < ogNode.childNodes.length && transIdx === transNodeList.length) {
      //  spans and as should be filled in. Rest should be text nodes.
      // These nodes don't need to show anything anymore. Will replace them with empty str ""
      for (let i = ogNodeIdx; i < ogNode.childNodes.length; i++) {
        ogNode.childNodes[i].textContent = '';
      }
      break;
    }
  }
}

/**
 * An observer specifically for iframes. Usually used for Hubspot forms.
 */
const iframeObserver = new MutationObserver(() => translateSite(document.querySelector('#root'), true));

/**
 * Observe any iframes that could show up in the page.
 */
export function observeIframe() {
  let iframes = getIframes();
  if (iframes.length === 0) {
    iframeObserver.disconnect();
  } else {
    /**
     * Note: we only use 1 hubspot form per page => if we ever need more, we'll modify this code.
     */
    let iframeBody = iframes[0][1];
    iframeObserver.observe(iframeBody, {
      childList: true,
      subtree: true,
      characterData: true,
    });
  }
}

/**
 * Disconnect the `iframeObserver`.
 */
export function disconnectIframeObserver() {
  iframeObserver.disconnect();
}

export const startTranslateMessage = 'start_translate';
export const endTranslateMessage = 'end_translate';

/**
 * Translate the site on change with a debounce wrapper around it.
 * @param rootNode - the node where we will begin translating.
 */
let [debouncedChangeWatcher, cancelDebouncedChangeWatcher] = debounce(rootNode => {
  try {
    translateSite(rootNode, true).then(() => {
      window.postMessage(endTranslateMessage);
    });
    observeIframe();
  } catch (e) {
    alert("Sorry! We can't translate our site at the moment. Please refresh the site.");
  }
}, 500);

/**
 * Watch for changes in the DOM and translate texts that way.
 * @param rootNode - the node where we will begin translating.
 */
export function translateOnDOMChange(rootNode) {
  window.postMessage(startTranslateMessage);
  debouncedChangeWatcher(rootNode);
}

export function cancelTranslateOnDOMChange() {
  cancelDebouncedChangeWatcher();
  window.postMessage(endTranslateMessage);
}
