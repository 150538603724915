import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { MultiSelect } from 'react-multi-select-component';
import PropTypes from 'prop-types';
import classes from './CustomInput.module.css';
import { formatAmount } from '@hellopocketed/react-ui.js_utils.core';

const CustomInput = props => {
  let theInput;
  if (props.edit) {
    theInput = <EditInput {...props} />;
  } else {
    theInput = <DisplayInput {...props} />;
  }

  return theInput;
};

/**
 * Helper wrapper class for display input.
 * @param {} param0
 * @returns
 */
const DisplayInput = ({ type, label, horizontal, inputName, value, isGrantProfileCreationInput, elementConfig }) => {
  let displayValue;
  switch (type) {
    case 'text':
    case 'date':
    case 'email':
    case 'phone':
    case 'number':
    case 'textarea': // above cases are all evaluated the same as case below
      // takes cares of null, undefined, and empty str
      if (!value) value = 'N/A';
      else if (inputName === 'monthlySpending') {
        value = formatAmount(value);
      }
      displayValue = value;
      break;
    case 'dropdown':
      displayValue = value;
      if (typeof value == 'boolean') {
        displayValue = value ? 'Incorporated' : 'Unincorporated';
      } else if (typeof value == 'object') {
        displayValue = value.label;
      }
      break;
    case 'dropdown-multiple':
      if (value !== undefined && value.length > 0) {
        displayValue = value.map(valueItem => (typeof valueItem === 'string' ? valueItem : valueItem.value)).join(', ');
      } else {
        displayValue = 'None Selected';
      }
      break;
    case 'date-picker':
      displayValue = value.toLocaleString('en-US', { year: 'numeric', month: 'long' });
      break;
    default:
      throw new Error(`Invalid CustomInput type: '${type}'`);
  }

  let containerClassName = `${classes.section} ${classes.display} ${horizontal ? classes.horizontal : classes.vertical} `;
  if (isGrantProfileCreationInput) containerClassName += classes.gpInput;

  return (
    <div className={containerClassName}>
      <Form.Label
        className={`pocketed-input-label`}
        htmlFor={inputName}
      >
        {label}
      </Form.Label>
      <p {...elementConfig}>{displayValue}</p>
    </div>
  );
};

/**
 * Helper class for edit input.
 * @param {} param0
 * @returns
 */
const EditInput = ({ type, label, horizontal, inputName, value, elementConfig, dropdownOptionConfig, onChange, options, isGrantProfileCreationInput, belowInputContent, belowLabelText, error }) => {
  const onChangeWrapper = event => {
    let value = event;
    if (event?.target?.value !== undefined) value = event.target.value;
    else if (value === undefined) value = '';

    if (onChange) onChange(inputName, value);
  };

  // -------------------- NOTE --------------------
  // If the input is retrieving values from our DB and the value returned is null, an error pops up in chrome devtools:
  // "Warning: 'value' prop on 'input' should not be null. Consider using the empty string to clear the component or 'undefined' for uncontrolled components."

  // And when editing, the following error occurs:
  // "Warning: A component is changing an uncontrolled input of type number to be controlled. Input elements should not switch from uncontrolled to controlled (or vice versa)."

  // The check below makes sure that null values are cleared before rendering them into the input fields, thus handling the errors.
  if (value === null) value = '';
  // ----------------------------------------------

  let input;
  switch (type) {
    case 'text': // fall through since they share similar elements
    case 'date':
    case 'email':
    case 'password':
    case 'number':
      input = (
        <Form.Control
          className={`pocketed-bordered-input `}
          id={inputName}
          type={type}
          value={value}
          {...elementConfig}
          onChange={onChangeWrapper}
        />
      );
      if (inputName === 'monthlySpending') {
        input = (
          <InputGroup>
            <InputGroup.Text className={classes.currencyContainer}>$</InputGroup.Text>
            {input}
          </InputGroup>
        );
      }
      break;
    case 'dropdown':
      input = (
        <Form.Control
          className={`pocketed-bordered-input ${classes.clickable_input} `}
          onChange={onChangeWrapper}
          title={value.toString()}
          value={value}
          id={inputName}
          {...elementConfig}
          as="select"
        >
          {/* if the dropdown value is currently empty, assign the selected prop to an empty option */}
          {/* set the selected option via the `value` prop of <CustomInput> above or else React complains*/}
          {(value === '' || value?.length === 0) && (
            <option
              value=""
              hidden
              {...dropdownOptionConfig}
            ></option>
          )}

          {typeof options != 'undefined' &&
            options.map((option, index) => {
              let value = option;
              let label = option;
              if (typeof option != 'string') {
                value = option.value.toString();
                label = option.label;
              }

              return (
                <option
                  key={index}
                  value={value}
                  {...dropdownOptionConfig}
                >
                  {label}
                </option>
              );
            })}
        </Form.Control>
      );
      break;
    case 'dropdown-multiple':
      let arrayValue = value.map(i => {
        if (typeof i === 'string') {
          return { value: i, label: i };
        } else {
          return i;
        }
      });

      input = (
        <div>
          <MultiSelect
            options={options}
            value={arrayValue}
            onChange={onChangeWrapper}
            labelledBy={'Select'}
            hasSelectAll={false}
            disableSearch
            className={classes.multiselect}
          />
        </div>
      );
      break;
    case 'textarea':
      input = (
        <Form.Control
          className={`pocketed-bordered-input }`}
          id={inputName}
          onChange={onChangeWrapper}
          value={value}
          {...elementConfig}
          as="textarea"
        />
      );
      break;
    case 'checkbox':
      input = (
        <div>
          <Form.Check
            type="checkbox"
            label={label}
            onChange={onChangeWrapper}
            {...elementConfig}
          />
        </div>
      );
      break;
    case 'date-picker':
      input = (
        <DatePicker
          value={value}
          onChange={onChangeWrapper}
          {...elementConfig}
          selected={value}
          showMonthYearPicker
          dateFormat="MMMM yyyy"
          className={`pocketed-bordered-input `}
        />
      );
      break;
    case 'phone':
      input = (
        <PhoneInput
          required
          placeholder=""
          value={value}
          onChange={onChangeWrapper}
          defaultCountry="CA"
        />
      );
      break;
    case 'radio':
      input = (
        <>
          {options.map((option, index) => {
            let checked = value.toString() === option.value.toString();
            let label = option.label;
            let id = option.label;
            return (
              <Form.Check
                className={classes.gpRadio}
                required
                key={index}
                type="radio"
                label={label}
                value={option.value}
                name={inputName}
                onChange={onChangeWrapper}
                checked={checked}
                id={id}
              />
            );
          })}
        </>
      );
      break;
    default:
      input = <></>;
  }

  let containerClassName = `${classes.section} ${horizontal ? classes.horizontal : classes.vertical} `;
  if (isGrantProfileCreationInput) containerClassName += classes.gpInput;
  return (
    <>
      <div className={containerClassName}>
        <Form.Label
          className={`pocketed-input-label `}
          htmlFor={inputName}
        >
          {label}
          {belowLabelText && <p className={classes.belowLabelText}>{belowLabelText}</p>}
        </Form.Label>
        {input}
      </div>
      {belowInputContent && belowInputContent}
      {error && error}
    </>
  );
};

CustomInput.propTypes = {
  /**
   * One of the acceptable custom input type as seen above.
   */
  type: PropTypes.string.isRequired,

  /**
   * Whether the input is editable by the user.
   */
  edit: PropTypes.bool,

  /**
   * Content for label element.
   */
  label: PropTypes.string.isRequired,

  /**
   * Name of the input config.
   */
  inputName: PropTypes.string,

  /**
   * Whether to display in horizontal style.
   */
  horizontal: PropTypes.bool,

  /**
   * Extra configs for the inputs.
   */
  elementConfig: PropTypes.object,

  /**
   * Extra configs for the <option> of a dropdown input.
   */
  dropdownOptionConfig: PropTypes.object,

  /**
   * Value of the input.
   */
  value: PropTypes.any,

  /**
   * Callback for when the input changed. Takes a function
   * that is the format (key, value) => null. `key` is the name of
   * the config object, `value` is the
   * input's value after a change is made.
   */
  onChange: PropTypes.func,

  /**
   * Extra text below the label. Useful to explain details to the user.
   */
  belowLabelText: PropTypes.node,

  /**
   * Extra content below the input. Useful to explain details to the user. Accept a node or a string.
   */
  belowInputContent: PropTypes.node,

  /**
   * Extra error text below the input.
   */
  error: PropTypes.string,

  /**
   * Possible options for input types that has multiple choices.
   */
  options: PropTypes.any,

  /**
   * Inputor grant profile creation form input only.
   * NOTE: this will replace the default class name.
   */
  isGrantProfileCreationInput: PropTypes.bool,

  /**
   * Whether the input is empty. Currently, only `dropdown-multiple`
   * inputs use it but any inputs can use this too.
   */
  isEmpty: PropTypes.bool,
};

export default CustomInput;
