import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PopupModal from '../../../../UI/PopUps/PopupModal';
import { encrypt } from '../../../../../Assets/Functions/Encrypter';
import withTooltip from '../../../../../HOC/Tooltip/Tooltip';
import { ReactComponent as ShareIconSvg } from '../../../../../Assets/Images/AvailableGrants/share.svg';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';

/**
 * A button for sharing the grant with other users. Also handle
 * displaying the share URL through a popup.
 */
const ShareIcon = ({ currentMatch }) => {
  const [shareClicked, setShareClicked] = useState(false);

  /**
   * grant link is hard coded in to hellopocketed.io,
   * this is so clearbank users who share our grants are sending users to our platform and not clearbanks
   */
  const shareLink = `https://app.hellopocketed.io${createSingleGrantLink(currentMatch)}`;

  const IconWithToolTip = withTooltip(ShareIconSvg, 'Share this grant');

  const clickedShare = () => {
    Mixpanel.track('Share Grant Clicked', { grantName: currentMatch.grant.name });
    setShareClicked(true);
  };

  return (
    <>
      <IconWithToolTip
        className={`pocketed-hoverable pocketed-small-icon`}
        fill="var(--dark-purple)"
        alt="share"
        onClick={clickedShare}
      />
      {shareClicked && (
        <PopupModal
          backgroundClicked={() => setShareClicked(false)}
          type="share"
          grantLink={shareLink}
        />
      )}
    </>
  );
};

function createSingleGrantLink(currentMatch) {
  // depending on whether the grant shown is from a grant link or from a search, the country
  // value is different
  let hashedId = encodeURIComponent(encrypt(currentMatch.grant.id));
  let country = currentMatch.usa_grant || currentMatch.country.name === 'US' ? 'US' : 'canada';
  return `/grant?id=${hashedId}&country=${country}`;
}

ShareIcon.propTypes = {
  currentMatch: PropTypes.object.isRequired,
};

export default ShareIcon;
