import React from 'react';

import classes from './PocketedPerksPage.module.css';
import Layout from '../../../HOC/Layout/Layout';
import { useIsUser } from '../../../Assets/Functions/UserPermissions';
import zakBiggs from '../../../Assets/Images/zak_biggs_goodlawyer.png';
import { ReactComponent as HeartSvg } from '../../../Assets/Images/UI/heart.svg';
import { ReactComponent as EditSvg } from '../../../Assets/Images/edit.svg';
import { ReactComponent as HandshakeSvg } from '../../../Assets/Images/handshake.svg';
import { ReactComponent as PhoneSvg } from '../../../Assets/Images/phone.svg';
import { Download } from 'react-bootstrap-icons';
import PocketedPerks from './PocketedPerks/PocketedPerks';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
export const POCKETED_PERKS_PAGE_URL = '/pocketed-perks';

const PocketedPerksPage = () => {
  const isPPlusOrConcierge = useIsUser.PPlusOrConcierge();
  /**
   * `img` are mini HOC => we just want to define the image source/svg and let
   * `PocketedPerksCard` decide the stylings.
   */
  const pocketedPlusPerks = [
    {
      title: 'STUDENT HIRING GRANTS',
      body: ['Grant writing exclusively for subscribers.'],
      link: 'https://welcome.hellopocketed.io/hiring-funding-summer-2023',
      hasButton: true,
      basicStandardAccess: true, // extra check to allow access to basic standard users
      img: props => (
        <HoverableButton
          presetBtnStyle="pink"
          presetSize="chunky"
        >
          Tell Me More!
        </HoverableButton>
      ),
    },
    {
      title: 'DOWNLOAD',
      body: ['Pocketed Application Wizard - Beta (Chrome Only)'],
      link: 'https://chrome.google.com/webstore/detail/pocketed-application-wiza/cbijhbkgjcpbhpibemiaegefkppofjeb?hl=en&authuser=0',
      img: props => <Download {...props} />,
    },
    {
      title: 'INTELLIGENCE',
      body: ['See Insights, Keywords, and Resources to help you apply'],
      link: '/available-grants',
      img: props => <EditSvg {...props} />,
    },
    {
      title: 'CONSULTATIONS',
      body: ['Book now and receive 25% off!'],
      link: '/book-a-consultation',
      img: props => <PhoneSvg {...props} />,
    },
    {
      title: 'EMAIL ALERTS',
      body: ['Get automatic email alerts every time your saved program opens or closes.'],
      link: '/saved-grants',
      img: props => <HeartSvg {...props} />,
    },
    {
      title: 'PARTNER DISCOUNTS',
      body: ['Click to view!'],
      link: '/partner-perks',
      img: props => <HandshakeSvg {...props} />,
    },
    // {
    //   title: 'COMING SOON',
    //   body: ['More Pocketed+ perks coming soon!'],
    //   img: props => (
    //     <img
    //       src={PocketedColoredLogo}
    //       {...props}
    //       alt="Pocketed+ Logo"
    //     />
    //   ),
    // },
  ];

  return (
    <Layout isPocketedPlus={!isPPlusOrConcierge ? true : false}>
      <div className={classes.container}>
        <div className={classes.left}>
          <PocketedPerks
            sectionTitle={'Access your Pocketed+ Perks!'}
            data={pocketedPlusPerks}
            disabled={!isPPlusOrConcierge}
          />
        </div>
        <div className={classes.right}>
          <div className={classes.testimonialContainer}>
            <img
              src={zakBiggs}
              alt="testimonial"
            />
            <p>
              <b>"</b>In the absence of Pocketed, I would never have been able to secure a $7,500 grant. Just weeks after learning about the program, we had an intern in our office making valuable contributions to our team!<b>"</b>{' '}
            </p>
            <div className={classes.testimonialFooter}>
              <p>
                <b>Zak Biggs, Director of Public Affairs</b>
              </p>
              <a
                href="https://www.goodlawyer.ca/"
                rel="noreferrer"
                target="_blank"
                translate="no"
              >
                Goodlawyer
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PocketedPerksPage;
