/**
 * Contains info related to user authentication/basic info.
 * Check the ./index.js for rules regarding these objects.
 */
export const UserAuthInputElemConfigs = {
  email: {
    type: 'email',
    label: 'Email',
    elementConfig: {
      required: true,
    },
    id: 'email',
    edit: true,
    value: '',
    entryValue: 'email',
  },
  firstName: {
    type: 'text',
    label: 'First Name',
    elementConfig: {
      required: true,
    },
    id: 'fname',
    edit: true,
    value: '',
    entryValue: 'first_name',
  },
  lastName: {
    type: 'text',
    label: 'Last Name',
    elementConfig: {
      required: true,
    },
    id: 'lname',
    edit: true,
    value: '',
    entryValue: 'last_name',
  },
  password: {
    type: 'password',
    label: 'Password',
    elementConfig: {
      required: true,
    },
    id: 'password',
    edit: true,
    value: '',
    entryValue: 'password1',
  },
};
