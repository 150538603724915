import React from 'react';
import RegisterNavigation from '../../../Components/Navigation/RegisterNavigation/RegisterNavigation.js';
import classes from './RegisterLayout.module.css';

const RegisterLayout = ({ children }) => {
  return (
    <div className={classes.container + ' flex-column flex-sm-row'}>
      <RegisterNavigation />
      {children}
    </div>
  );
};

export default RegisterLayout;
