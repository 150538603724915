import React, { useContext, useState } from 'react';
import instance from '../../../Assets/Axios/AxiosInstance';
import { StoreContext } from '../../../Store/StoreContext';
import classes from './PasswordReset.module.css';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import { UserAuthInputElemConfigs } from '../../../Assets/Functions/GrantProfileCreationFormElements/FormElementConfigs';
import InputArrayMapper from '../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import PocketedLogo from '../../../Components/UI/PocketedLogo/PocketedLogo';

const PasswordReset = () => {
  const { actions } = useContext(StoreContext);
  const [profileState, setProfileState] = useState({
    form: {
      email: { ...UserAuthInputElemConfigs.email },
    },
    loading: true,
    editForm: false,
    formComplete: false,
  });

  const formChange = (key, value) => {
    setProfileState(old => {
      let temp = { ...old };
      temp.form[key].value = value;
      return temp;
    });
  };

  const formSubmit = e => {
    e.preventDefault();
    let email = document.getElementById('email').value;

    let formdata = new FormData();
    formdata.append('email', email);

    instance
      .post('/accounts/password_reset/', formdata)
      .then(res => {
        Mixpanel.track('Password Reset', { status: 'Success' });
        console.log(res.response);
        setProfileState(old => {
          let res = { ...old, formComplete: true };
          return res;
        });
      })
      .catch(err => {
        Mixpanel.track('Password Reset', { status: 'Failed', error: err });
        // console.log(err.response.status, err.response.data.error)
        if (err.response.status === 422) {
          actions.setPopup({ flag: true, message: 'error' });
        } else {
          setProfileState(old => {
            let temp = { ...old };
            temp.form['email'].belowInputContent = <p className={classes.error}>Error: your email is not registered</p>;
            return temp;
          });
        }
      });
  };

  return (
    <form
      className={classes.form}
      onSubmit={e => formSubmit(e)}
    >
      <PocketedLogo extraClasses={classes.img} />
      <div className={classes.header}>
        <h2>Password Reset</h2>
      </div>
      {profileState.formComplete ? (
        <div className={classes.formCompleteMsg}>
          <p>Please check your email to reset your password!</p>
        </div>
      ) : (
        <>
          <InputArrayMapper
            customInputProps={profileState.form}
            containerClassName={classes.inputContainer}
            inputElementsClassName={classes.inputElements}
            formChange={formChange}
          />
          <div className={classes.formButtons}>
            <HoverableButton
              presetBtnStyle="dark-purple"
              type="submit"
              id="reset-password-submit-btn"
            >
              Reset Password
            </HoverableButton>
          </div>
        </>
      )}
    </form>
  );
};

export default PasswordReset;
