import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classes from './PocketedPerks.module.css';
import PocketedPerksCard from '../PocketedPerksCard/PocketedPerksCard';
import LockedOverlay from '../../../../Components/UI/Overlays/LockedOverlay/LockedOverlay';
import goToLink from '../../../../Assets/Functions/ForwardLink';
import { useHistory } from 'react-router-dom';
import { useIsUser } from '../../../../Assets/Functions/UserPermissions';

const PocketedPerks = ({ sectionTitle, data, disabled }) => {
  const userIsPaying = useIsUser.Paying(); // need to add special check for "student hiring grants" perk - Basic Standard users have access as well

  const history = useHistory();
  return (
    <div className={classes.container}>
      <h2 className={classes.sectionTitle}>{sectionTitle}</h2>
      <div className={classes.cardGrid}>
        {data.map(perk => (
          <Fragment key={perk.title}>
            {!disabled | (userIsPaying & perk.basicStandardAccess) ? ( // pplus/premium OR (paying [basic standard] and special access set for the card)
              <PocketedPerksCard
                key={perk.title}
                data={perk}
              />
            ) : (
              // Locked for Free, Basic Legacy, and Basic Standard (unless basicStandardAccess=true for that card)
              <LockedOverlay
                key={perk.title}
                onClick={() => {
                  goToLink(history, '/account/subscription');
                }}
                tooltipMessage={'Upgrade Now'}
                coverClasses={classes.perksLockCover}
              >
                <PocketedPerksCard
                  key={perk.title}
                  data={perk}
                />
              </LockedOverlay>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

PocketedPerks.propTypes = {
  /**
   * The title of the perks component if any.
   */
  sectionTitle: PropTypes.string,

  /**
   * The data that we will use to render the perks card.
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.arrayOf(PropTypes.string),
      link: PropTypes.string,
      img: PropTypes.func,
    })
  ),
};

export default PocketedPerks;
