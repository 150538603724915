import React, { useContext, useEffect, useState } from 'react';
import withTooltip from '../../../HOC/Tooltip/Tooltip';
import instance from '../../../Assets/Axios/AxiosInstance';
import signOut from '../../../Assets/Functions/SignOut';
import { withRouter } from 'react-router-dom';
import useUserCan from '../../../Assets/Functions/UserPermissions';
import { ReactComponent as ConsultIcon } from '../../../Assets/Images/Icons/consultation.svg';
import { StoreContext } from '../../../Store/StoreContext';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import GrantNotificationsBell from './GrantNotificationsBell/GrantNotificationsBell';
import goToLink from '../../../Assets/Functions/ForwardLink';
import { LogoutWidget } from '@hellopocketed/react-ui.widgets.logout_widget';
import { useIsUser } from '../../../Assets/Functions/UserPermissions';
import { ReactComponent as GearIcon } from '../../../Assets/Images/UI/gear_icon.svg';

import classes from './LayoutHeader.module.css';
const LayoutHeader = props => {
  const { history } = props;
  const { actions, state } = useContext(StoreContext);
  const [firstName, setFirstName] = useState(null);
  const canGetAlerts = useUserCan.DoAll();
  const isICPCandidate = useIsUser.ICPCandidate();

  useEffect(() => {
    instance.get('/api/users/').then(res => {
      // if no user, we'll still have null
      if (res.data.results.length !== 0) {
        // if user doesn't have a first name, we'll get a ""
        setFirstName(res.data.results[0]['first_name']);
      }
    });
  }, []);

  const SettingsWithTooltip = withTooltip(GearIcon, 'Account Settings');

  return (
    <>
      <div className={`d-sm-flex d-none ` + classes.container}>
        <div className={classes.welcomeHeader}>{firstName === null ? <h3>Hello there!</h3> : <h3>Welcome back{firstName !== '' ? ', ' + firstName : ''}</h3>}</div>

        <div className={classes.iconSection}>
          <div className={classes.headerButtonSection}>
            {isICPCandidate ? (
              <HoverableButton
                href="https://meetings.hubspot.com/jamie-proctor-boyce/welcome-to-pocketed"
                presetBtnStyle="pink"
                presetSize="chunky"
              >
                Book a call
              </HoverableButton>
            ) : (
              !props.isGrantWriter &&
              !props.isPocketedPlus && (
                <HoverableButton
                  href="/hire-a-grant-writer"
                  presetBtnStyle="pink"
                  presetSize="chunky"
                >
                  Hire a Grant writer
                </HoverableButton>
              )
            )}
          </div>
          {canGetAlerts && <GrantNotificationsBell />}
          <SettingsWithTooltip
            alt="Account Settings"
            onClick={() => goToLink(history, '/account')}
            className={`pocketed-big-icon ${classes.settingIcon}`}
          />
          <LogoutWidget logoutCallback={() => signOut(state, actions, history)} />
        </div>
      </div>
      <div className={`d-sm-none ` + classes.container}>
        {/* for mobbile screen */}
        <div className={classes.welcomeHeader}>{firstName === null ? <h3>Hello there!</h3> : <h3>Welcome back{firstName !== '' ? ', ' + firstName : ''}</h3>}</div>

        <div className={classes.iconSection}>
          <div className={classes.headerButtonSection}>
            {!props.isConsultation && !props.isPocketedPlus && (
              <HoverableButton
                href="/book-a-consultation"
                presetBtnStyle="pink"
                presetSize="chunky"
                style={{ padding: 2 }}
              >
                <ConsultIcon className={classes.bookConsultIcon} />
              </HoverableButton>
            )}
          </div>
          {<GrantNotificationsBell />}
          <SettingsWithTooltip
            alt="Account Settings"
            onClick={() => goToLink(history, '/account')}
            className={`pocketed-big-icon ${classes.settingIcon}`}
          />
          <LogoutWidget logoutCallback={() => signOut(state, actions, history)} />
        </div>
      </div>
    </>
  );
};

export default withRouter(LayoutHeader);
