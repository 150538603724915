import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import withTooltip from '../../../../../HOC/Tooltip/Tooltip';
import { StoreContext } from '../../../../../Store/StoreContext';
import instance from '../../../../../Assets/Axios/AxiosInstance';
import isGrantHidden, { LikeEnum } from '../../../../../Assets/Functions/IsGrantHidden';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import * as Icon from 'react-bootstrap-icons';
import classes from './HideIcon.module.css';

/**
 * A button for hiding a grant from the user. This means the
 * grant is put to the bottom of the grant list.
 */
const HideIcon = ({ id, liked, name }) => {
  const { actions } = useContext(StoreContext);

  // allows us to update the isHidden state as needed
  const [isHidden, setIsHidden] = useState(isGrantHidden(liked));
  const IconWithToolTip = withTooltip(isHidden ? Icon.EyeSlashFill : Icon.Eye, `${isHidden ? 'Unhide' : 'Hide'} this grant`);

  // update the state when the props changes
  useEffect(() => {
    setIsHidden(isGrantHidden(liked));
  }, [liked]);

  /**
   * Hide/unhide a grant depending on the current state depending on the current state.
   * This uses the old `like`/`dislike` system rather than refactoring it.
   */
  const toggleHideGrant = () => {
    const oldIsHidden = isHidden;
    setIsHidden(!isHidden); // update the UI right away since 99% of the time, the request succeeds.
    Mixpanel.track('User Clicked on Hide', { grantName: name });
    instance
      .patch(`/api/matches/${id}/`, { liked: isHidden ? LikeEnum.NEUTRAL : LikeEnum.DISLIKED })
      .then(res => {
        actions.replaceCurrentMatch(res.data); // upgrade the state so our source of truth is synced
      })
      .catch(err => {
        console.log("Found an error changing a grant's liked state. See below: ");
        console.error(err);
        setIsHidden(oldIsHidden); // flip back since we got an error
      });
  };

  return (
    <IconWithToolTip
      className={`pocketed-big-icon pocketed-hoverable ${classes.icon} ${isHidden && classes.active}`}
      onClick={toggleHideGrant}
    />
  );
};

HideIcon.propTypes = {
  /**
   * The id of the `currentMatch` object, NOT the the id of the `grant` object (see Assets/Types/TypeExamples).
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  /**
   * The liked value of the grant.
   */
  liked: PropTypes.number.isRequired,
};

export default HideIcon;
