import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { PocketedColoredLogoWhiteText } from '@hellopocketed/react-ui.images.pocketed_logos';
import classes from './OfficeHoursPopup.module.css';
import clock from '../../../../Assets/Images/clock-icon-pink.png';
import { StoreContext } from '../../../../Store/StoreContext';
import { XCircle } from 'react-bootstrap-icons';
const OOPopup = () => {
  const { actions } = useContext(StoreContext);
  let history = useHistory();
  const goTo = location => {
    actions.setOfficeHoursPopup({ flag: false });
    history.push(location);
  };
  return (
    <div
      className={classes.container}
      onClick={e => e.stopPropagation()}
    >
      <div className={classes.heading}>
        <img
          src={PocketedColoredLogoWhiteText}
          alt="plus"
        />
        <div
          className={classes.icon}
          onClick={() => {
            actions.setOfficeHoursPopup({ flag: false });
          }}
        >
          <XCircle
            size={30}
            color={'white'}
          />
        </div>
      </div>

      <div className={classes.content}>
        <h2 className={classes.title}> Pocketed Virtual Office Hours</h2>
        <img
          src={clock}
          alt="Timer"
        />
        <p className={classes.message}>Have questions about Pocketed? Come join us for Pocketed Office Hours!</p>
        <button
          className={classes.btn}
          onClick={() => goTo('/office-hours')}
        >
          Register Here!
        </button>
      </div>
    </div>
  );
};

export default OOPopup;
