import React from 'react';
import classes from './PlanCard.module.css';

const PlanCard = ({ data, isMonthly, urlPlan }) => {
  return (
    <div className={`${classes.card} ${classes[data.color]} ${urlPlan === data.plan && classes.selected} pocketed-rounded-card`}>
      <h2 className={classes.title}>{data.plan}</h2>

      {data.price !== undefined ? (
        <h1 className={`${classes.price} ${classes[data.color]}`}>
          ${data.price}
          {data.superscript && <sup>*</sup>}
          <sub className={classes.sub}>USD</sub>
        </h1>
      ) : (
        <data.Image />
      )}

      <div className={classes.subtitle}>
        <span>{data.subtitle}</span>
        <p className={classes.commitment}>{isMonthly ? '12-month commitment' : `billed annually at $${data.price * 12}`}</p>
      </div>

      <div>{data.button}</div>
      <div className={classes.details}>{data.details}</div>
    </div>
  );
};

export default PlanCard;
