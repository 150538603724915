/**
 * Contains variables that are 'global' regarding translations. These are not
 * actually `global` variables (i.e. `window.engToFreStore` doesn't exist).
 */

/**
 * Store eng-to-fre text so we don't have to requery the API if we already have the texts.
 */
export let engToFreStore = {};

/**
 * Store fre-to-eng text so we can translate the text back.
 */
export let freToEngStore = {};

/**
 * All the languages that we support currently.
 */
export const allLangs = ['English', 'Français'];
