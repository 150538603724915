import BaseSlice from './BaseSlice';
import FilterSlice from './FilterSlice';
import GrantSlice from './GrantSlice';
import UserInfoSlice from './UserInfoSlice';
import UISlice from './UISlice';
import NotificationSlice from './NotificationSlice';

/**
 * The combined actions of the store.
 */
const [allActionTypes, initialStates, reducers] = combineSlices(FilterSlice, GrantSlice, UserInfoSlice, UISlice, NotificationSlice);

/**
 * Combine different slices together
 * @param  {...BaseSlice} slices
 */
function combineSlices(...slices) {
  let actions = [];
  let initialStates = {};
  let reducers = [];

  for (const slice of slices) {
    actions = [...actions, ...slice.actions];

    initialStates = {
      ...initialStates,
      ...slice.initialState,
    };

    reducers.push(slice.reducers);
  }

  // add our custom reset state function type
  actions.push(BaseSlice.RESET_STATE_TO_INITIAL_ACTION);

  /**
   * The master reducer function that calls each smaller reducer function.
   * @param {*} state
   * @param {*} action
   * @returns
   */
  const reducerFunction = (state, action) => {
    for (const reducer of reducers) {
      let result = reducer(state, action);
      if (result !== BaseSlice.NO_MATCHING_REDUCER) {
        return result;
      }
    }

    if (action.type === BaseSlice.RESET_STATE_TO_INITIAL_ACTION) return initialStates;
    return state; // do nothing
  };

  return [actions, initialStates, reducerFunction];
}

/**
 * Return an object containing the actions that this store can do.
 * @param {*} dispatch - the `dispatch` variable that `useReducer`
 * provides.
 * @returns
 */
function useActions(dispatch) {
  const actions = {};
  for (const action of allActionTypes) {
    actions[action] = payload => dispatch({ type: action, payload });
  }

  return actions;
}

export { initialStates, reducers, useActions };
