/**
 * Debounce a function if it's called repeatedly within
 * the `time` specified. See https://www.freecodecamp.org/news/javascript-debounce-example/.
 * Also includes a cancel function.
 * @param {Function} func
 * @param {number} time - in milisecs
 * @returns an array containing 2 functions: first index is a function that
 * will passes all its parameters to the `func` passed in. This function returns
 * a promise containing the result of the passed in function. Second element
 * is a cancel function to cancel the first function as needed. The cancel
 * function only cancel the upcoming invocation of the debounced function
 */
export default function debounce(func, time) {
  let timer = null;
  let isCanceled = false;
  return [
    (...args) => {
      // reset isCanceled when invoked to prevent left overs
      isCanceled = false;
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }

      return new Promise(resolve => {
        timer = setTimeout(() => {
          if (!isCanceled) resolve(func(...args));
        }, time);
      });
    },
    () => (isCanceled = true),
  ];
}
