import React, { useContext } from 'react';
import { StoreContext } from '../../../../../Store/StoreContext';
import PropTypes from 'prop-types';

import classes from './GrantUsageTextbox.module.css';

const GrantUsageTextbox = ({ Icon, title, subtitle, grantType }) => {
  const { actions } = useContext(StoreContext);
  const setGrantType = grantType => {
    actions.setSelectedFilter({ grantTypes: [grantType] });
  };
  return (
    <div
      className={classes.textBox}
      onClick={() => setGrantType(grantType)}
    >
      <Icon className={classes.textBoxImg} />
      <div className={classes.textBoxTextContent}>
        <h2 className={classes.title}>{title}</h2>
        <p className={classes.text}>{subtitle}</p>
      </div>
    </div>
  );
};

GrantUsageTextbox.propTypes = {
  /**
   * The icon we want to use. The result of a `import {ReactComponent as Icon} from "./source.svg".
   */
  Icon: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default GrantUsageTextbox;
