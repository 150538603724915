import React from 'react';
import PropTypes from 'prop-types';

import classes from './CircularImage.module.css';

/**
 * A circular image that is extendable with classes.
 * @param {} props
 * @returns
 */
const CircularImage = ({ imgSrc, extraClasses, shiftXValue = 0, shiftYValue = 0, alt = 'A circular image' }) => {
  if (shiftXValue < -100 || shiftXValue > 100) shiftXValue = 0;
  if (shiftYValue < -100 || shiftYValue > 100) shiftYValue = 0;

  let style = {
    right: `${shiftXValue}%`,
    top: `${shiftYValue}%`,
  };

  return (
    <div className={`${classes.imgWrapper} ${extraClasses}`}>
      <img
        src={imgSrc}
        alt={alt}
        className={classes.img}
        style={style}
      />
    </div>
  );
};

CircularImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  extraClasses: PropTypes.string,
  /* A shift percentage value to shift the image along the x-axis. Must be between -100 to 100 inclusive.*/
  shiftXValue: PropTypes.number,
  /* A shift percentage value to shift the image along the y-axis. Must be between -100 to 100 inclusive.*/
  shiftYValue: PropTypes.number,

  /**
   * An <img> 'alt' attribute.
   */
  alt: PropTypes.string,
};

export default CircularImage;
