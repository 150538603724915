import React, { useState } from 'react';
import classes from './UpgradeToBasicPopUp.module.css';
import UpgradeImg from '../../../../Assets/Images/UI/free_trial_over.png';
import Loading from '../../Loading/Loading';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import withTooltip from '../../../../HOC/Tooltip/Tooltip';

const UpgradeToBasicPopUp = () => {
  let popup = <LoadingPopUp />;

  // KEEP in case we return to having two different popups based on revenue
  // Note: revenue has been refactored in the backend. If we want to bring this back, query /api/grantprofile
  // to get the most up to date revenue ranges (also give us min and max value of the range)
  // const grantProfile = useGrantProfile();
  // if (UNDER_50K_REVENUES.includes(grantProfile.annual_revenue)) {
  //   popup = <Under50KPopUp />;
  // } else {
  //   popup = <Over50KPopUp />;
  // }

  popup = <SimplifiedPopUp />;

  return popup;
};

const LoadingPopUp = () => {
  return (
    <div
      className={classes.container}
      onClick={e => e.stopPropagation()}
    >
      <Loading />
    </div>
  );
};

const SimplifiedPopUp = () => {
  let buttonClick = () => {
    Mixpanel.track('Subscribe Now Clicked On PopUP');
  };

  return (
    <div
      className={classes.container}
      onClick={e => e.stopPropagation()}
    >
      <a href="/account/subscription">
        <img
          src={UpgradeImg}
          alt="Upgrade to view matches"
          className={classes.img}
          onClick={() => buttonClick()}
        />
      </a>
    </div>
  );
};

// KEEP in case we return to having two different popups based on revenue
// const Under50KPopUp = () => {
//   return (
//     <div
//       className={classes.container}
//       onClick={e => e.stopPropagation()}
//     >
//       <h2 className={classes.title}> Free Money Is Waiting </h2>
//       <p className="p">Save hundreds of hours Googling for grants and get all the funding programs in one place!</p>
//       <p className="p">Don't miss a dollar! Grants have never been this easy.</p>
//       <HoverableButton
//         presetBtnStyle="pink"
//         href="/pocketed-plus"
//       >
//         Subscribe Now!
//       </HoverableButton>
//       <p className={classes.smallMsg}>Subscribe now for your personalized grant matches.</p>
//       <CopyableDiscountCode />
//       <img
//         src={Upgradegif}
//         alt="Pplus"
//         className={classes.gif}
//       />
//     </div>
//   );
// };

// KEEP in case we return to having two different popups based on revenue
// const Over50KPopUp = () => {
//   return (
//     <div
//       className={classes.container}
//       onClick={e => e.stopPropagation()}
//     >
//       <h2 className={classes.title}> Stay By Your Phone!</h2>
//       <p className="p">A Pocketed Specialist will be in touch within the next 24 hours.</p>
//       <p className="p">Don't miss a dollar! Grants have never been this easy.</p>

//       <CopyableDiscountCode />
//       <img
//         src={Upgradegif}
//         alt="Pplus"
//         className={classes.gif}
//       />

//       <div>
//         <p className={`m-0 ${classes.smallMsg}`}>Ready right away?</p>
//         <p className={classes.smallMsg}>Subscribe now for your personalized grant matches.</p>
//       </div>
//       <HoverableButton
//         presetBtnStyle="pink"
//         href="/pocketed-plus"
//       >
//         Subscribe Now!
//       </HoverableButton>
//     </div>
//   );
// };

// KEEP in case we return to having a discount code
const CopyableDiscountCode = () => {
  const [copied, setCopied] = useState(false);
  let Tooltip = withTooltip('span', copied ? 'Copied!' : 'Copy to clipboard');
  let discountCode = 'WELCOME25';

  let onClick = () => {
    setCopied(true);
    navigator.clipboard.writeText(discountCode);
  };

  return (
    <div className={classes.discountText}>
      Subscribe to <b>Basic</b> or <b>Plus</b> now for 25% off your first year with code{' '}
      <Tooltip
        className={classes.discountCode}
        onClick={onClick}
      >
        {discountCode}
      </Tooltip>
    </div>
  );
};

export default UpgradeToBasicPopUp;
