import { GrantProfileInputElemConfigs, configAddOns } from '../../../../../Assets/Functions/GrantProfileCreationFormElements/FormElementConfigs';

export const formContent = {
  companySize: configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.companySize, '*'),
  annualRevenue: configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.annualRevenue, '*'),
  monthlySpending: configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.monthlySpending, '*'),
  industries: configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.industries, '*'),
  founderType: {
    ...configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.founderType, ' (Optional)'),
    belowLabelText: 'Some grants offer priority access to founders within these demographics',
  },
  raisedCapitol: { ...GrantProfileInputElemConfigs.raisedCapitol },
  accessedFunding: { ...GrantProfileInputElemConfigs.accessedFunding },
  engageRD: {
    ...configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.engageRD, ' (Optional)'),
    hasReliants: true,
    reliantInputs: [{ match: true, display: 'sredSubmitted' }],
    optional: 'You don’t need to answer this question, but it may help us improve your matches!',
  },
  sredSubmitted: {
    ...configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.sredSubmitted, ' (Optional)'),
    optional: 'You don’t need to answer this question, but it may help us improve your matches!',
    show: false,
  },
  preferredLang: { ...GrantProfileInputElemConfigs.preferredLang },
  payrollProvider: {
    ...GrantProfileInputElemConfigs.payrollProvider,
    belowInputContent: <p>We are working on new features to automate more of the funding process, making your experience seamless.</p>,
  },
};
