import React from 'react';
import PropTypes from 'prop-types';

import classes from './GrantTitle.module.css';

const TITLE_CHAR_LIMIT = 55;

/**
 * The title of a grant.
 * @param {Object} props
 * @returns
 */
const GrantTitle = ({ title, cutOffLength = TITLE_CHAR_LIMIT }) => {
  return <h3 className={classes.grantTitle}>{title.length > cutOffLength ? `${title.slice(0, cutOffLength)}...` : title}</h3>;
};

GrantTitle.propTypes = {
  title: PropTypes.string,
  /**
   * How many characters are shown before we cut it off and format it with a `...`.
   * If title.length is less than `cutOffLength`, nothing happens.
   */
  cutOffLength: PropTypes.number,
};

export default GrantTitle;
