import React from 'react';
import PropTypes from 'prop-types';

import classes from './GrantNotificationItem.module.css';

const GrantNotificationItem = ({ notification, index, onClick }) => {
  const seen = notification.status ? classes.unread : '';
  const onClickHandler = () => onClick(notification, index);

  return (
    <div
      className={classes.container}
      onClick={onClickHandler}
    >
      <div className={classes.body}>
        <h3>{notification.notification_type}</h3>
        <p className={classes.info}>{notification.grant.name}</p>
      </div>
      <div className={classes.notificationContainer}>
        <div className={seen} />
      </div>
    </div>
  );
};

GrantNotificationItem.propTypes = {
  /**
   * Notification data that we are showing to the user.
   */
  notification: PropTypes.object.isRequired,

  /**
   * Index of the `notification` object in its parent array. Used
   * to supply for the `onClick`.
   */
  index: PropTypes.number.isRequired,

  /**
   * Onclick handler for when this item is clicked.
   * It should take an argument for `notification`
   * and `index`.
   */
  onClick: PropTypes.func.isRequired,
};

export default GrantNotificationItem;
