import React from 'react';
import PropTypes from 'prop-types';
import SaveGrantIcon from '../../../UI/SaveGrantIcon/SaveGrantIcon';
import { OutlineHoverableButton } from '@hellopocketed/react-ui.widgets.buttons.outline_hoverable_button';
import { formatAmount } from '@hellopocketed/react-ui.js_utils.core';
import ShareIcon from './ShareIcon/ShareIcon';
import HideIcon from './HideIcon/HideIcon';
import DropdownGrantTextSection from '../GrantTextInformation/DropdownGrantTextSection/DropdownGrantTextSection';
import withTooltip from '../../../../HOC/Tooltip/Tooltip';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import getCategoryIcon from '../../../../Assets/categoryIcons';
import { HIRE_GRANT_WRITER_URL } from '../../../../Containers/GetHelp/HireGrantWriter/HireGrantWriter';
import { DEFAULT_CATEGORY_NAME } from '../../../../Assets/Functions/CategoryUtil';

import classes from './GrantDetailsHeader.module.css';
import grantDetailsClasses from '../GrantDetailsView.module.css';

/**
 * The header of the grant details which display a quick summary of the grant.
 * Also has a `locked` mode that can be turned on by specifying the `isLockedCallback`.
 * @param {} props
 * @returns
 */
const GrantDetailsHeader = ({ currentMatch, isLockedCallback }) => {
  /**
   * The actual grant data (name, amount, etc.) that are saved in the backend.
   */
  let { grant } = currentMatch;

  // set up mixpanel tracking
  // useEffect(() => {
  // Mixpanel.trackLinks('#apply', 'Clicked on ‘Apply for Grant’ ', { grant_name: grant.name });
  // Mixpanel.trackLinks('#finance', 'Clicked on ‘Finance this Grant’ ', () => {
  //     return {
  //       grant_name: grant.name,
  //       type: grant.grant_type.type,
  //       amount: grant.amount,
  //       region: grant.region,
  //     }
  // });
  // }, []);

  const openPage = page => window.open(page);
  const buttonOnClick = (page_url, trackMsg, grantName) => {
    Mixpanel.track(trackMsg, { grant_name: grantName });
    if (isLockedCallback) {
      isLockedCallback();
    } else {
      openPage(page_url);
    }
  };

  let CategoryIcon = getCategoryIcon(DEFAULT_CATEGORY_NAME);
  if (grant.subcategories[0]) {
    CategoryIcon = getCategoryIcon(grant.subcategories[0].name);
  }

  // format the grant name and funder name
  let grantNameClassName = `${grantDetailsClasses.sectionHeader} ${classes.title}`;
  let funderClassName = ``;
  // simple pass through HOC
  let GrantNameElem = props => <h2 {...props}>{props.children}</h2>;
  let FunderNameElem = props => <span {...props}>{props.children}</span>;
  if (isLockedCallback) {
    const isLockedClass = isLockedCallback ? 'blurred-text' : '';
    grantNameClassName += ` ${isLockedClass} ${classes.lockedText}`; // space in front is mandatory
    funderClassName += ` ${isLockedClass} ${classes.lockedText}`; // space in front is mandatory
    GrantNameElem = withTooltip('h2', 'Sign in to see grant name');
    FunderNameElem = withTooltip('span', 'Sign in to see funding organization');
  }

  return (
    <div className={`${grantDetailsClasses.section} ${classes.container} pocketed-rounded-card`}>
      <CategoryIcon className={classes.icon} />
      <div className={classes.textArea}>
        <GrantNameElem
          className={grantNameClassName}
          onClick={isLockedCallback}
        >
          {grant.name}
        </GrantNameElem>
        <p className={classes.grantAmount}>{formatAmount(grant.amount)}</p>
        <p className={classes.fundedBy}>
          Funded by&nbsp;
          <FunderNameElem
            className={funderClassName}
            onClick={isLockedCallback}
          >
            {grant.issuer}
          </FunderNameElem>
        </p>
        <span className={classes.buttonArea}>
          <OutlineHoverableButton
            presetBtnStyle="dark-purple"
            presetSize="skinny"
            onClick={() => buttonOnClick(grant.app_link, 'User clicked CTA  Apply now Button', grant.name)}
          >
            Apply Now
          </OutlineHoverableButton>

          <OutlineHoverableButton
            presetBtnStyle="pink"
            presetSize="skinny"
            onClick={() => buttonOnClick(`${HIRE_GRANT_WRITER_URL}?grant_name=${grant.name}`, 'User clicked CTA Hire a Grant Writer Button', grant.name)}
          >
            Hire a Grant Writer
          </OutlineHoverableButton>
        </span>

        <DropdownGrantTextSection
          title="Description"
          content={grant.description}
          overrideCallback={isLockedCallback}
          blurText={isLockedCallback !== undefined}
        />
      </div>
      <div className={classes.iconArea}>
        <HideIcon
          id={currentMatch.id}
          liked={currentMatch.liked}
        />
        <ShareIcon currentMatch={currentMatch} />
        <SaveGrantIcon
          id={currentMatch.id}
          saved={currentMatch.saved}
          overrideCallback={isLockedCallback}
        />
      </div>
    </div>
  );
};

GrantDetailsHeader.propTypes = {
  /**
   * All the grant data as seen in Assets/Types/TypeExamples.js.
   */
  currentMatch: PropTypes.object.isRequired,

  /**
   * A callback used when the `GrantDetailsHeader` is in locked mode. Default is
   * `undefined` for not in locked mode. Supplying this prop means we want to lock
   * the component.
   */
  isLockedCallback: PropTypes.func,
};

export default GrantDetailsHeader;
