import React from 'react';
import PropTypes from 'prop-types';
import GrantTextSection from '../GrantTextInformation/GrantTextSection/GrantTextSection';
import DropdownGrantTextSection from '../GrantTextInformation/DropdownGrantTextSection/DropdownGrantTextSection';
import ApplicationCard from './ApplicationCard/ApplicationCard';
import { reqAttributesToTitle } from '@hellopocketed/react-ui.js_utils.core';

import classes from './GrantRequirements.module.css';
import grantDetailsClasses from '../GrantDetailsView.module.css';
import scheduleIcon from '../../../../Assets/Images/AvailableGrants/schedule.png';

const attributesToShow = {
  comp_req: 'Company Requirements',
  value: 'Award Amounts',
  ...reqAttributesToTitle,
};

/**
 * Show the requirements of the grants. These are info that decide whether someone
 * can apply/qualify for a grant. These doesn't include general info like description
 * or length.
 * @param {} props
 * @returns
 */
const GrantRequirements = ({ grant, isLockedCallback }) => {
  return (
    <div className={`${grantDetailsClasses.section} ${classes.container} pocketed-rounded-card`}>
      <div className={classes.column}>
        {Object.entries(attributesToShow)
          .filter(([key, _]) => grant[key]) // must have a truthy value (non-empty string, not undefined)
          .map(([key, title]) => {
            if (isLockedCallback) {
              return (
                <DropdownGrantTextSection
                  key={key}
                  title={title}
                  content={grant[key]}
                  overrideCallback={isLockedCallback}
                  blurText={isLockedCallback !== undefined}
                />
              );
            }
            return (
              <GrantTextSection
                key={key}
                title={title}
                content={grant[key]}
                link={key === 'comp_req' ? grant.program_site : ''}
              />
            );
          })}
      </div>

      <div className={`${classes.column} ${classes.rightColumn}`}>
        <ApplicationCard
          iconSrc={scheduleIcon}
          title="Deadline"
          subtitle={grant.deadlines}
        />
      </div>
    </div>
  );
};

GrantRequirements.propTypes = {
  /**
   * The actual grant data (name, amount, etc.) that are saved in the backend.
   */
  grant: PropTypes.object.isRequired,

  /**
   * A callback used when the `GrantRequirements` is in locked mode. Default is
   * `undefined` for not in locked mode. Supplying this prop means we want to lock
   * the component.
   */
  isLockedCallback: PropTypes.func,
};

export default GrantRequirements;
