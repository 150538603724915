import { LikeEnum } from '../../Assets/Functions/IsGrantHidden';
import BaseSlice from './BaseSlice';

/**
 * Replace an item in the `oldArray` based on whether its id
 * is the same as the `newItem`'s id.
 * @param {Array} oldArray - an array containing objects with the field
 * `id`. Usually use for the `grantList`.
 * @param {*} newItem
 * @returns
 */
const findAndReplaceListItem = (oldArray, newItem) => {
  let index = oldArray.findIndex(i => i.id === newItem.id);
  oldArray[index] = newItem;
  return oldArray;
};

/**
 * Holds states that are related to the showing/displaying of grant information.
 */
const initialState = {
  /**
   * Information on the match the user just clicked on.
   * See the `currentMatchExample` in `Assets/Types/TypeExamples.js`.
   */
  currentMatch: null,

  /**
   * Hold all the grants that show up in the GrantList UI in <AvailableGrants>.
   * Think of it as a page of all the grants that the user can see.
   * Basically an array of `currentMatchExample`.
   */
  grantList: [],

  // for whether the grant list is loading or not
  grantListLoading: false,

  // For the total amount of all grants the user matched with under the current filtering
  totalGrantCount: 0,
};

const reducerObj = {
  // set the currentMatch
  setCurrentMatch: (state, action) => {
    return {
      ...state,
      currentMatch: {
        ...state.currentMatch,
        ...action.payload,
      },
    };
  },

  /**
   * Clear the grant details. Use this rather than
   * calling `actions.setCurrentMatch(null)`.
   * @param {*} state
   * @param {*} action
   * @returns
   */
  clearCurrentMatch: (state, action) => {
    return {
      ...state,
      currentMatch: null,
    };
  },

  /**
   * Replace a currentMatch object in both `state.grantList` and
   * `state.currentMatch` if one exists.
   * @param {} state
   * @param {*} action
   * @returns
   */
  replaceCurrentMatch: (state, action) => {
    if (!action.payload) {
      return {
        ...state,
      };
    } else {
      let oldState = { ...state };
      let newList = [...findAndReplaceListItem(oldState.grantList, action.payload)];

      // also update the state.currentMatch if there's one
      if (state.currentMatch?.id === action.payload.id) {
        oldState.currentMatch = action.payload;
      }
      return {
        ...oldState,
        grantList: newList,
      };
    }
  },

  setGrantList: (state, action) => {
    return {
      ...state,
      grantList: action.payload.results,
      totalGrantCount: action.payload.count,
    };
  },

  setGrantListLoading: (state, action) => {
    return {
      ...state,
      grantListLoading: action.payload,
    };
  },

  /**
   * Set the grant inside the `currentMatch` to `action.payload`.
   * This will create a dummy `currentMatch` object so that it won't
   * affect whatever object is actually in `currentMatch`.
   * @param {*} state
   * @param {*} action - contains a `payload` attribute which
   * is an object with two attributes: a `grant` attribute (mandatory) which
   * is the grant itself and a `country` attribute (optional) which is a
   * string representing a country name (e.g. 'canada' or 'US').
   *
   * @example setIndividualGrant({grant: {}, country: 'canada'})
   * @returns
   */
  setIndividualGrant: (state, action) => {
    return {
      ...state,
      currentMatch: {
        ...state.currentMatch,
        grant: action.payload.grant,
        country: { name: action.payload.country || 'canada' }, // repackage it so it follows the same format as the API return
        id: -1, // non-existent match
        new: false,
        saved: false,
        liked: LikeEnum.NEUTRAL,
      },
    };
  },
};

const GrantSlice = new BaseSlice(initialState, reducerObj);
export default GrantSlice;
