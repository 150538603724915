import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import img from '../../../../Assets/Images/UI/concierge_upgrade_popup.png';
import { useHistory } from 'react-router-dom';
import { StoreContext } from '../../../../Store/StoreContext';

import classes from './ConciergeModal.module.css';

const ConciergeModal = () => {
  const { state, actions } = useContext(StoreContext);
  const history = useHistory();

  return (
    <Modal
      show={state.conciergePopup}
      onHide={() => {
        actions.setConciergePopup(false);
      }}
      className={classes.modal}
      dialogClassName={classes.modalDialog}
      contentClassName={classes.modalContent}
      centered
    >
      <Modal.Body className={classes.modalBody}>
        <h2 style={{ marginTop: '1em' }}>Welcome to Pocketed</h2>
        <div className={classes.image}>
          <img
            src={img}
            alt="Ready for funding, guaranteed? Upgrade to Pocketed Concierge today."
            className={classes.img}
            onClick={() => {
              actions.setConciergePopup(false);
              history.push('/account/subscription?wp_plan=concierge');
            }}
          />
        </div>
        <p
          className={classes.freeTrialLink}
          onClick={() => actions.setConciergePopup(false)}
        >
          Not ready to subscribe yet? Start your BASIC 14-day trial for <i>free</i> now!
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default ConciergeModal;
