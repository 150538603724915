import React from 'react';
import PropTypes from 'prop-types';
import BaseResourcesSection from './BaseResourcesSection/BaseResourcesSection';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';

/**
 * Resources that all grants should have.
 */
const defaultResources = [
  {
    title: 'Getting started with grants',
    url: 'https://hellopocketed.io/2021/02/05/pocketed-grants-101/',
  },
  {
    title: 'How grants work',
    url: 'https://hellopocketed.io/2021/05/14/pocketed-grant-basics/',
  },
  {
    title: 'Your first application',
    url: 'https://hellopocketed.io/2021/05/20/the-grant-application-process/',
  },
  {
    title: 'Recruiting for hiring grants',
    url: 'https://hellopocketed.io/2021/08/30/recruiting-for-hiring-grants/',
  },
  {
    title: 'What is grant-based financing?',
    url: 'https://hellopocketed.io/2021/07/16/what-is-grant-based-financing/',
  },
];

const ResourcesSection = ({ grant }) => {
  return (
    <BaseResourcesSection>
      <ul>
        {[...grant.resources, ...defaultResources].map(resource => {
          return (
            <li key={resource.title}>
              <a
                href={resource.url}
                target="_blank"
                rel="noreferrer"
                onClick={() => Mixpanel.track('User clicked on Resources', { resourceTitle: resource.title, resourceLink: resource.url })}
              >
                {resource.title}
              </a>
            </li>
          );
        })}
      </ul>
    </BaseResourcesSection>
  );
};

ResourcesSection.propTypes = {
  /**
   * The actual grant data (name, amount, etc.) that are saved in the backend.
   */
  grant: PropTypes.object.isRequired,
};

export default ResourcesSection;
