import React, { useEffect } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';

const SemiPrivateRoute = ({ component: Component, authLevel: AuthLevel, history, ...rest }) => {
  // const script = document.createElement('script');

  // script.src = 'https://static.zdassets.com/ekr/snippet.js?key=fcdb48d5-87ab-4d46-912b-1556ed5130e6';
  // script.id = 'ze-snippet';

  // useEffect(() => {
  //     document.body.appendChild(script);

  //     return () => {
  //         // console.log('hellpo', script)
  //         document.body.removeChild(script);
  //     }
  // })

  return (
    <Route
      {...rest}
      render={props => <Component {...props} />}
    />
  );
};

export default withRouter(SemiPrivateRoute);
