import React from 'react';
import PropTypes from 'prop-types';

import classes from './ApplicationCard.module.css';

/**
 * A card displaying information about the application in the
 * requirements section.
 */
const ApplicationCard = ({ iconSrc, title, subtitle }) => {
  return (
    <div className={classes.card}>
      <img
        src={iconSrc}
        alt="card icon"
        className={classes.icon}
      />
      <p className={classes.text}>
        <b>{title}</b>
      </p>
      <p className={classes.text}>{subtitle}</p>
    </div>
  );
};

ApplicationCard.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ApplicationCard;
