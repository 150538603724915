import React, { useState } from 'react';
import classes from './FAQItem.module.css';
import PropTypes from 'prop-types';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';

const FAQItem = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);
  const clickFAQ = () => {
    setIsActive(!isActive);
    Mixpanel.track('Click on FAQ', { title: title });
  };
  return (
    <div className={classes.faq__item}>
      <div
        className={classes.faq__heading}
        onClick={clickFAQ}
      >
        <div className={classes.faq__title}>{title}</div>
        <div className={classes.faq__dropdown}>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && (
        <div className={classes.faq__content}>
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

FAQItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]),
  content: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]),
};

export default FAQItem;
