import instance from '../Axios/AxiosInstance';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import BaseSlice from '../../Store/Slices/BaseSlice';

/**
 * Helper function to sign a user out and handle various
 * clean up.
 * @param {*} state - a Context state object.
 * @param {*} actions - a Context actions object that allows
 * us to manipulate the state.
 * @param {*} history - a History object from react-router-dom.
 */
export default function signOut(state, actions, history) {
  const cleanUp = () => {
    Mixpanel.track('Session Ended');
    history.push('/auth/signin');

    // reset everything EXCEPT the curLang
    let curLang = state.translate.curLang;
    actions[BaseSlice.RESET_STATE_TO_INITIAL_ACTION]();
    actions.setCurLang(curLang);
  };

  instance
    .post('/accounts/logout/')
    .then(cleanUp)
    .catch(err => {
      console.error(err);
      cleanUp();
    });
}
