import React from 'react';
import PropTypes from 'prop-types';
import IntelCard from '../IntelCard/IntelCard';

import { ReactComponent as NewSuperScriptImg } from '../../../../../Assets/Images/AvailableGrants/new_icon.svg';
import classes from './BasePocketedIntelSection.module.css';

/**
 * Height of the text part of intel card by default (not-extended).
 * Make sure this value is compatible with `lockedInsights` and `lockedKeywords`.
 */
export const intelCardTextHeight = '10em';

/**
 * Show the intel that Pocketed has on a grant. So far, this includes
 * Insights, and keywords.
 * @param {} props
 * @returns
 */
const BasePocketedIntelSection = ({ insightCardContent, keywordCardContent }) => {
  let insightCard = (
    <IntelCard
      title="Insights about this grant"
      textHeight={intelCardTextHeight}
    >
      {insightCardContent}
    </IntelCard>
  );

  let keywordCard = (
    <IntelCard
      title="Keywords for your application"
      textHeight={intelCardTextHeight}
      extraClasses={classes.keywordCard}
      presetStyle="faded-pink"
    >
      {keywordCardContent}

    </IntelCard>
  );

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>
        POCKETED GRANT INTELLIGENCE
        <NewSuperScriptImg className={classes.superscript} />
      </h2>
      <p>Proprietary tools and information to submit your application</p>
      <div className={classes.cardsContainer}>
        {insightCard}
        {keywordCard}
      </div>
    </div>
  );
};

BasePocketedIntelSection.propTypes = {
  /**
   * The content of the insight card.
   */
  insightCardContent: PropTypes.node.isRequired,

  /**
   * The content of the keyword card.
   */
  keywordCardContent: PropTypes.node.isRequired,
};

export default BasePocketedIntelSection;
