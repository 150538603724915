import React, { useEffect, useState, useContext } from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

const OnboardingProgress = ({ percentage }) => {
  return (
    <ProgressBar
      percent={percentage}
      filledBackground="linear-gradient(to right, #231e5f, #692e82)"
    >
      <Step transition="scale">
        {({ accomplished }) => (
          <img
            style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
            width="40"
            src="https://upload.wikimedia.org/wikipedia/commons/9/91/Eo_circle_purple_white_number-1.svg"
            alt="1"
          />
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished }) => (
          <img
            style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
            width="40"
            src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Eo_circle_purple_white_number-2.svg"
            alt="2"
          />
        )}
      </Step>
    </ProgressBar>
  );
};

export default OnboardingProgress;
