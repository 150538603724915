import React, { useContext, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import CSRF_TOKEN_NAME from '../../../Assets/CSRF_TOKEN_NAME';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import classes from './LoginForm.module.css';
import axios from '../../../Assets/Axios/AxiosInstance';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import CustomInput from '../../../Components/UI/CustomInputs/CustomInput';
import Loading from '../../../Components/UI/Loading/Loading';
import { StoreContext } from '../../../Store/StoreContext';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import instance from '../../../Assets/Axios/AxiosInstance';
import { GRANT_PAGE_URL } from '../../AvailableGrantsView/AvailableGrantsView';
import { UserAuthInputElemConfigs } from '../../../Assets/Functions/GrantProfileCreationFormElements/FormElementConfigs';
import useUserCan from '../../../Assets/Functions/UserPermissions';
import PocketedLogo from '../../../Components/UI/PocketedLogo/PocketedLogo';

const LoginForm = props => {
  const { actions } = useContext(StoreContext);
  const canSeeExplore = useUserCan.SeeExploreFunding();
  const [forwardingUrl, setForwardingUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  const [profileState, setProfileState] = useState({
    form: {
      email: { ...UserAuthInputElemConfigs.email },
      password: { ...UserAuthInputElemConfigs.password },
    },
    editForm: false,
    signinFailed: {
      flag: false,
      message: '',
    },
  });

  const onInputChange = (key, value) => {
    setProfileState(old => {
      let temp = { ...old };
      temp.form[key].value = value;
      return temp;
    });
  };

  /**
   * Handle user info gotten from a query to `/user/` or `/get-user-attributes/'
   * This involves saving data as appropriate into the
   * state.
   * @param {*} data, a
   */
  const handleUserInfoFromBackend = (data, forwardTo) => {
    Mixpanel.track('Login', { status: 'Success' });
    Mixpanel.track('Session Started');
    Mixpanel.people.set({ activated: 'true' });

    // store standard user details
    actions.setBasicUserInfo(data);

    // condition checking and set popup flags
    if (data.showCampaign) actions.setPopup({ referralPopup: true });
    if (data.show_alpha_popup) actions.setAlphaPopup({ flag: true });
    if (data.clearAngel) actions.setCurrentUser({ clearAngel: true });
    if (data.show_mm_popup) actions.setMMPopup({ flag: true });
    if (data.is_us_user) actions.setCurrentUser({ is_us_user: true });
    if (props.callback) props.callback();

    if (props.noRedirect) {
      return;
    }

    // choose where to redirect user
    if (!data.grantprofile) {
      props.history.push('/grant-profile-creation');
    } else if (forwardTo) {
      props.history.push(forwardTo);
    } else {
      props.history.push(GRANT_PAGE_URL);
    }
  };

  const errorHandling = err => {
    Mixpanel.track('Login', { status: 'Failed', error: err });
    if (!err.response) {
      setProfileState(old => {
        let res = {
          ...old,
          signinFailed: {
            flag: true,
            message: 'Sign in failed, please contact the developers.',
          },
        };
        return res;
      });
      setLoading(false);
    } else if (err.response.status === 500) {
      setProfileState(old => {
        let res = {
          ...old,
          signinFailed: {
            flag: true,
            message: <>Whoops something went wrong, please try signing in again!</>,
          },
        };
        return res;
      });
      setLoading(false);
    } else if ('grantprofile' in err.response.data && !err.response.data.grantprofile) {
      props.history.push('/grant-profile-creation');
    } else if ('activated' in err.response.data && !err.response.data.activated) {
      Mixpanel.people.set({ activated: 'false' });
      setProfileState(old => {
        let res = {
          ...old,
          signinFailed: {
            flag: true,
            message: (
              <>
                Your account has not been activated, click{' '}
                <a
                  className={classes.redirect}
                  onClick={() => props.history.push('/auth/resend-activation/')}
                >
                  here
                </a>{' '}
                to resend your activation email.
              </>
            ),
          },
        };
        return res;
      });
      setLoading(false);
    } else if ('beta' in err.response.data && err.response.data.beta) {
      props.history.push('/checkback-soon');
    } else if ('waitlist' in err.response.data && err.response.data.waitlist) {
      props.history.push('/checkback-soon');
    } else {
      setProfileState(old => {
        let res = {
          ...old,
          signinFailed: {
            flag: true,
            message: 'Sign in failed, your email or password is incorrect',
          },
        };
        return res;
      });
      setLoading(false);
    }
    console.error('Unsuccessful login.', err);
  };

  /**
   * Error handling, extracting URL params,
   * and checking whether to log the user in automatically
   */
  useEffect(() => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const gpError = params.get('gpError');
    if (gpError) {
      setProfileState(old => {
        let res = {
          ...old,
          signinFailed: {
            flag: true,
            message: (
              <>
                Whoops, something went wrong when creating your account! We apologise for any inconvenience caused. <br /> Please sign in and try again.
              </>
            ),
          },
        };
        return res;
      });
      setLoading(false);
      return;
    }

    const forwardToUrl = params.get('forwardTo');
    // forward the user unless they are a FREE user trying to access EXPLORE FUNDING
    if (forwardToUrl && !(forwardToUrl === GRANT_PAGE_URL && !canSeeExplore)) {
      setForwardingUrl(forwardToUrl);
    }

    instance
      .get('/user/')
      .then(res => {
        // if user is logged in
        if (res.data.username) {
          instance
            .get('/get-user-attributes/')
            .then(res => {
              handleUserInfoFromBackend(res.data, forwardToUrl);
            })
            .catch(errorHandling);
        } else {
          // user needs to log in => displays the inputs
          setLoading(false);
        }
      })
      .catch(errorHandling);
  }, []);

  /**
   * useEffect above seems to handle situations where there is information in the store (i.e if user is logged in and then logs out)
   * loginClick below seems to handle situations where there is no user information store (i.e. incognito with no user info)
   * Therefore, when forwardingUrl, we need to check for both situations
   * Also it appears that users cannot be forwarded to '/available-grants'
   */
  const loginClick = e => {
    e.preventDefault();
    setLoading(true);
    let data = {
      csrfmiddlewaretoken: Cookies.get(CSRF_TOKEN_NAME),
      username: profileState.form.email.value,
      password: profileState.form.password.value,
    };
    // const post_acc = axios.post('/accounts/login/', qs.stringify(data))
    // const get_clear = axios.get('/api/clearangel')

    axios
      .post('/accounts/login/', qs.stringify(data))
      .then(res => handleUserInfoFromBackend(res.data, forwardingUrl))
      .catch(errorHandling);
  };

  return (
    <>
      {loading ? (
        <div className={classes.loading}>
          <Loading />
          <p>Please Wait...</p>
        </div>
      ) : (
        <Form className={classes.container}>
          <PocketedLogo extraClasses={classes.img} />
          <h2>Sign In</h2>
          <input
            type="hidden"
            name="csrfmiddlewaretoken"
            value={document.cookie}
          />
          <div className={classes.content}>
            {Object.keys(profileState.form).map((key, index) => {
              let theForm = { ...profileState.form };
              return (
                <div
                  key={index}
                  className={classes.inputElements}
                >
                  <CustomInput
                    inputName={key}
                    {...theForm[key]}
                    onChange={onInputChange}
                  />
                </div>
              );
            })}
            <NavLink
              to="/auth/reset-password"
              className={classes.passwordResetLink}
            >
              I forgot my password
            </NavLink>
            {profileState.signinFailed.flag ? <p className={classes.failedSignin}>{profileState.signinFailed.message}</p> : <></>}
          </div>
          <div className={classes.formButtons}>
            <HoverableButton
              presetBtnStyle="pink"
              onClick={loginClick}
              type="submit"
            >
              Sign in
            </HoverableButton>
            <p>
              Don’t have an account? <NavLink to="/create-account">Sign up!</NavLink>
            </p>
          </div>
        </Form>
      )}
    </>
  );
};

export default withRouter(LoginForm);
