import React from 'react';
import classes from './PocketedPerksCard.module.css';
import { useHistory } from 'react-router-dom';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';

// TODO: add proptypes + determine the best way to forward data
// { title, body, link, img, isDiscount, isTool }
const PocketedPerksCard = ({ data, cardStyle }) => {
  const { img: Image } = data;
  const history = useHistory();
  const goTo = (link, title) => {
    Mixpanel.track('Pocketed perks card clicked', {
      cardName: data.title,
    });
    if (!link) {
      return;
    } else if (link.startsWith('https://') || link.startsWith('mailto:')) {
      window.open(link);
    } else {
      history.push(link);
    }
  };

  return (
    <div
      className={`${classes.container} + ${!data.link && classes.disabled} pocketed-rounded-card`}
      style={cardStyle}
      onClick={() => {
        goTo(data.link);
      }}
    >
      <p className={`m-0 ${classes.title}`}>{data.title}</p>
      <p className={`m-0 ${classes.body}`}>{data.body}</p>
      {!data.hasButton ? (
        <Image className={classes.image} />
      ) : (
        <div className={classes.buttonDiv}>
          <Image />
        </div>
      )}
    </div>
  );
};

export default PocketedPerksCard;
