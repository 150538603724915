import React, { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, withRouter } from 'react-router-dom';
import { StoreContext } from '../../../Store/StoreContext';
import classes from './RegisterNavigation.module.css';
import signOut from '../../../Assets/Functions/SignOut';
import { PocketedColoredLogoWithMargin } from '@hellopocketed/react-ui.images.pocketed_logos';
import signoutIcon from '../../../Assets/Images/icon-Logout.png';
import LangSwitch from '../../LangSwitch/LangSwitch';
import { useFeedbackUI } from '@hellopocketed/react-ui.widgets.feedback_ui';

const RegisterNavigation = props => {
  const { actions, state } = useContext(StoreContext);
  const { showConfirmationModal } = useFeedbackUI();

  const signOutClicked = () => {
    showConfirmationModal({
      title: 'Are you sure you want to log out?',
      message: 'Your progress will be lost.',
    }).then(confirm => {
      if (confirm) {
        signOut(state, actions, props.history);
      }
    });
  };

  return (
    <>
      <Nav className={`flex-column d-sm-flex d-none ` + classes.navbar}>
        <NavLink
          to="/"
          exact={true}
          className={classes.navHeader}
          id="home"
          onClick={e => {
            e.preventDefault();
          }}
        >
          <img
            src={PocketedColoredLogoWithMargin}
            className={classes.icon}
            alt="Pocketed Logo"
          />
        </NavLink>
        <button
          className={classes.signoutBtn}
          onClick={signOutClicked}
        >
          <img
            src={signoutIcon}
            alt="Log Out"
          />
          Sign Out
        </button>

        <LangSwitch className={classes.langSwitch} />
      </Nav>
    </>
  );
};

export default withRouter(RegisterNavigation);
