import React, { useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import MMOptIn from './MMOptIn/MMOptIn';
import instance from '../../Assets/Axios/AxiosInstance';
import classes from './ManagedSolution.module.css';
import { Helmet } from 'react-helmet';

import Layout from '../../HOC/Layout/Layout';

const ManagedSolution = props => {
  const [msUser, setMSUser] = useState(false);
  useEffect(() => {
    fetchGP();
  }, []);
  const fetchGP = () => {
    instance
      .get('/api/grantprofiles/')
      .then(res => {
        const data = res.data.results[0];

        if (data.contract_signed) {
          // Updated terms for Pocketed Grant Writing
          setMSUser(true);
        }
        // if (data.opt_in_contract) {
        //     setMSUser(true);
        // }
      })
      .catch(err => {
        console.log(err.data);
      });
  };

  return (
    <Layout>
      <Helmet>
        <title>Grant Writing</title>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.content}>
          <Switch>
            <Route
              exact
              path="/opt-in"
              component={() => (
                <MMOptIn
                  existingUser={true}
                  msUser={msUser}
                  setMSUser={setMSUser}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(ManagedSolution);
