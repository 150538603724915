import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import instance from '../../Assets/Axios/AxiosInstance';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import { StoreContext } from '../../Store/StoreContext';
import { GROUPS, isInGroup } from '../../utils/groups';

const PrivateRoute = ({ component: Component, authLevel: AuthLevel, history, ...rest }) => {
  const [auth, setAuth] = useState(true);
  const { state, actions } = useContext(StoreContext);

  // Checks if user is logged in, redirects to login page if not. This is where user information is parsed.
  useEffect(() => {
    instance
      .get('/user/')
      .then(res => {
        if (isInGroup(GROUPS.termsOfServiceViolators, res.data.groups)) {
          history.push('/home');
          return;
        }

        if ('isAuthenticated' in res.data && !res.data.isAuthenticated) {
          setAuth(false);
          return;
        }

        // store standard user details
        actions.setBasicUserInfo(res.data);
        // Initiaites tracking of user interactions on mixpanel
        Mixpanel.identify(res.data.username);
        Mixpanel.people.set_once({ $email: res.data.username });
        Mixpanel.people.set({ 'User Type': res.data.user_type, 'User Subtype': res.data.user_subtype });
        if (!res.data.grantprofile) {
          history.push('/grant-profile-creation');
        }
        if (res.data.clearAngel) actions.setCurrentUser({ clearAngel: true });
        // document.body.appendChild(script);
        if (state.currentUser.user !== res.data.username) {
          actions.setCurrentUser({ user: res.data.username, loggedIn: true, is_us_user: res.data.is_us_user });
          Mixpanel.timeEvent('User Session started');
        }
      })
      .catch(err => {
        console.error(err);
        setAuth(false);
      });
  }, []);

  return (
    <Route
      {...rest}
      render={props => (auth ? <Component {...props} /> : <Redirect to="/auth/signin" />)}
    />
  );
};

export default withRouter(PrivateRoute);
