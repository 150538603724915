import React, { useContext, useEffect } from 'react';
import { StoreContext } from '../../../Store/StoreContext';
import Layout from '../../../HOC/Layout/Layout';
import StaticPage from '../../../HOC/StaticPage/StaticPage';
import { HubSpotForm } from '@hellopocketed/react-ui.hubspot_form';
import { getConsultationLink } from '../../../Assets/Functions/ConsultationLink';
import image from '../../../Assets/Images/Hire a grantwriter.jpg';
import { Helmet } from 'react-helmet';

import classes from './HireGrantWriter.module.css';

/**
 * The URL to access this component.
 */
export const HIRE_GRANT_WRITER_URL = '/hire-a-grant-writer';

const HireGrantWriter = () => {
  const { state } = useContext(StoreContext);
  const currentUser = state.currentUser;
  const consultationLink = getConsultationLink(currentUser.paidTier);
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const grant_name = params.get('grant_name');
  const populatedFields = {
    // name of the field on the Hubspot form that we wish to populate
    what_is_the_name_of_the_grant_you_would_like_help_writing__you_may_list_multiple_grants_: { value: '', type: 'textarea' },
  };

  useEffect(() => {
    if (grant_name) {
      populatedFields['what_is_the_name_of_the_grant_you_would_like_help_writing__you_may_list_multiple_grants_'].value = grant_name;
    }
  }, [grant_name]);

  const FAQData = [
    {
      title: 'When should I hire a grant writer?',
      content: (
        <>
          Hire a grant writer when you are ready to apply for a grant and win funding! To get the most out of your session, it helps to know the program you want to apply for. Every grant program is unique and our grant writers will need to confirm
          specific business requirements in order to move your application forward. Not sure which program you want to apply for? Start with a consultation{' '}
          <a
            href={consultationLink}
            target="blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    {
      title: 'How much does it cost to hire a grant writer?',
      content:
        'Pocketed grant writers work on either an hourly, flat-rate, or success-based fee depending on the program. Pricing is also contingent on the competitiveness of the program and the suitability of your application for the program. When you’re ready to hire a grant writer, they will give you an accurate estimate of the cost before the application process moves forward. Fill out the form on this page to get started.',
    },
    {
      title: 'Do I get to choose my grant writer?',
      content: 'Pocketed will match you with the best grant writer for your program. You can rest easy knowing we have done the vetting for you.',
    },
    {
      title: 'Will the grant writer have expertise in my program?',
      content: 'Pocketed works with grant writers that have experience in a wide range of programs. Once you know your program, we will match you to a grant writer who has the right expertise and knowledge to help you win that funding!',
    },
    {
      title: 'What if I don’t know my program yet?',
      content: (
        <>
          <a href="/available-grants">Click here</a> to explore your list! Need further help?{' '}
          <a
            href={consultationLink}
            target="blank"
          >
            Book a 1:1 consultation
          </a>{' '}
          with our grant specialists to get assistance exploring your grants and planning your applications.
        </>
      ),
    },
  ];

  return (
    <Layout isGrantWriter={true}>
      <Helmet>
        <title>Hire a Grant Writer</title>
      </Helmet>
      <StaticPage
        title="Hire a Grant Writer"
        imgSrc={image}
        FAQ={FAQData}
        form={
          <HubSpotForm
            formId="42f88b97-c61d-4fb3-bba2-b029832c30a9"
            populatedFields={populatedFields}
          />
        }
      >
        <p>
          <i>All conversations are confidential.</i>
        </p>
        <p className={classes.helpApplyingText}>
          <b>Need help applying?</b>
        </p>
        <p className={classes.helpApplyingText}>
          Once you have identified the funding programs you want to apply for, share a few more details and we'll match you with the right specialist.
          <br /> <br />
          Not sure what you want to apply for yet? Start with a one-on-one{' '}
          <a
            href={consultationLink}
            target="blank"
          >
            consultation
          </a>
          .
        </p>
      </StaticPage>
    </Layout>
  );
};

export default HireGrantWriter;
