import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import instance from '../../../Assets/Axios/AxiosInstance';
import axios from 'axios';
import Loading from '../../../Components/UI/Loading/Loading';
import { PriceCounter } from '@hellopocketed/react-ui.widgets.price_counter';
import { getGrantListValue } from '@hellopocketed/react-ui.js_utils.core';
import { deformatDate } from '@hellopocketed/react-ui.js_utils.core';
import ClosingGrant from './ClosingGrant/ClosingGrant';
import { useHistory } from 'react-router-dom';
import { GRANT_PAGE_URL } from '../../AvailableGrantsView/AvailableGrantsView';
import { ReactComponent as DollarBillImg } from '../../../Assets/Images/AvailableGrants/dollar_bill.svg';
import { TitleCard } from '@hellopocketed/react-ui.cards.title_card';

import classes from './ClosingGrantSection.module.css';

/**
 * The maximum amount of closing grants we are displaying on the screen.
 */
const MAX_CLOSING_GRANTS_AMOUNT = 3;

const ClosingGrantSection = ({ isLocked }) => {
  const [sortedClosingGrants, setSortedClosingGrants] = useState([]);
  const history = useHistory();

  // fetch all the closing grants and sort them from nearest to farthest
  // in terms of deadlin
  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    instance
      .get(`/api/matches/?closingGrants=true`, { cancelToken: source.token })
      .then(res => {
        let grants = res.data.results;
        setSortedClosingGrants(
          grants.sort((a, b) => {
            let dateA = deformatDate(a.grant.deadlines);
            let dateB = deformatDate(b.grant.deadlines);

            // the backend won't return any non-date proper values.
            if (dateA > dateB) return 1;
            else if (dateA < dateB) return -1;
            return 0;
          })
        );
      })
      .catch(err => console.error(err));
  }, []);

  const openClosingGrants = () => {
    history.push(`${GRANT_PAGE_URL}?closingGrants=true`);
  };

  let closingGrantsTotalValue = getGrantListValue(sortedClosingGrants);
  let dollarAmountElem = <Loading />;
  if (closingGrantsTotalValue > 0) {
    dollarAmountElem = <PriceCounter end={closingGrantsTotalValue} />;
  } else {
    // dollarAmountElem = <p className={classes.totalAmount}>{sortedClosingGrants.length === 0 ? '...' : 'Amount Varies'}</p>;
    if (sortedClosingGrants.length === 0) {
      dollarAmountElem = (
        <DollarBillImg
          className={classes.dollarBillImg}
          alt="test"
        />
      );
    } else {
      dollarAmountElem = <p className={classes.totalAmount}>Amount Varies</p>;
    }
  }

  let amountOfGrantsElem = '';
  if (sortedClosingGrants) {
    if (sortedClosingGrants.length === 0) amountOfGrantsElem = 'NO';
    else amountOfGrantsElem = sortedClosingGrants.length;
  }

  return (
    <div className={classes.container}>
      <TitleCard
        title={`${amountOfGrantsElem} GRANTS DUE IN THE NEXT 30 DAYS`}
        cardClassName={`${classes.card} ${classes.leftCard}`}
        bigTitle
      >
        <div className={classes.closingGrantsContainer}>
          {sortedClosingGrants ? (
            sortedClosingGrants.slice(0, MAX_CLOSING_GRANTS_AMOUNT).map(closingGrant => {
              return (
                <ClosingGrant
                  currentMatch={closingGrant}
                  key={closingGrant.grant.id}
                  isLocked={isLocked}
                />
              );
            })
          ) : (
            <Loading />
          )}

          {sortedClosingGrants.length > 0 ? (
            <p
              className={`link ${classes.closingGrantLink} ${sortedClosingGrants.length < MAX_CLOSING_GRANTS_AMOUNT ? classes.forceAlignBottom : ''}`}
              onClick={isLocked ? () => {} : openClosingGrants}
            >
              See all
            </p>
          ) : (
            <div className={classes.emptyGrantTextContainer}>
              <p className={`m-0 ${classes.emptyGrantText}`}>Grants are cyclical. It's a great time to plan your next project!</p>
              <a
                className={`link ${classes.emptyGrantText}`}
                href={GRANT_PAGE_URL}
              >
                Click here to explore grants.
              </a>
            </div>
          )}
        </div>
      </TitleCard>

      <TitleCard
        title="VALUE OF GRANTS DUE IN THE NEXT 30 DAYS"
        cardClassName={classes.card}
        bigTitle
      >
        <div className={classes.totalValueContainer}>{dollarAmountElem}</div>
      </TitleCard>
    </div>
  );
};

ClosingGrantSection.propTypes = {
  /**
   * Whether this component is locked from user interaction.
   */
  isLocked: PropTypes.bool,
};

export default ClosingGrantSection;
