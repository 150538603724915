import React, { useContext } from 'react';
import { StoreContext } from '../../../../Store/StoreContext';
import { DEFAULT_CATEGORY_NAME } from '../../../../Assets/Functions/CategoryUtil';
import { CategoryDropdownOption, SubcategoryDropdownOption, LoadingDropdownOption } from '../DropdownOption/DropdownOption';
import useFilterTypes from '../../../../Assets/Functions/StateSingletonHelper/useFilterTypesGetter';
import BaseFilterBarDropdown from '../BaseFilterBarDropdown/BaseFilterBarDropdown';
import PropTypes from 'prop-types';

/**
 * Name of the category group we are displaying.
 */
const POCKETED_PICKS_GROUP_NAME = 'Pocketed Picks';

const ShowMeDropdown = ({ isShowing, setIsShowing, signInCallback }) => {
  const { state, actions } = useContext(StoreContext);
  const filterTypes = useFilterTypes(typeof signInCallback === 'function');

  /**
   *
   * An object containing a category tree. See the `categoryResponse` object
   * in Assets/Types/CategoryResponseExample.js
   */
  let { categories_groups, display_categories } = filterTypes;

  let optionsElem = [];
  if (!display_categories) {
    // if not loaded yet
    optionsElem.push(<LoadingDropdownOption key="loading" />);
  } else {
    // find the Project Category group
    let catGroup = categories_groups.find(group => group.name === POCKETED_PICKS_GROUP_NAME);

    // cool thing about this group is each category has exactly one subcategory
    // with the same name => don't need to filter the subcategory
    let validCats = catGroup.categories.filter(category => display_categories.includes(category.name));

    // the currently active subcategory
    let activeSubcategory = state.filterSelection.selected.subcategory;
    // since we have multiple similar named subcats, we will match the first one
    // that we found.
    let foundSubcat = false;

    // even though this is a category, we want to treat it as a subcategory
    // so we reuse SubcategoryDropdownOption
    for (let cat of validCats) {
      const { name } = cat;

      // set the subcat when clicked
      // if it already selected, toggle to unselect it.
      const onOptionClick = selected => {
        actions.setSelectedFilter({
          subcategory: selected ? '' : name,
        });
        setIsShowing(false);
      };

      // select the first found subcategory
      let selected = false;
      if (!foundSubcat) {
        selected = activeSubcategory === name;
        // if true, we found our subcategory
        if (selected) foundSubcat = true;
      }

      optionsElem.push(
        <SubcategoryDropdownOption
          name={name}
          key={name}
          onClick={onOptionClick}
          selected={selected}
        />
      );
    }
  }

  // this shouldn't happen but safety check
  if (optionsElem.length === 0) {
    optionsElem.push(
      <CategoryDropdownOption
        name={DEFAULT_CATEGORY_NAME}
        key={DEFAULT_CATEGORY_NAME}
      />
    );
  }

  return (
    <BaseFilterBarDropdown
      signInCallback={signInCallback}
      title="Show me"
      isShowing={isShowing}
      setIsShowing={setIsShowing}
    >
      {optionsElem}
    </BaseFilterBarDropdown>
  );
};

ShowMeDropdown.propTypes = {
  /**
   * Whether to show the content.
   */
  isShowing: PropTypes.bool.isRequired,

  /**
   * A function that can set the `setIsShowing` value.
   */
  setIsShowing: PropTypes.func.isRequired,

  /**
   * On click handler for asking user to sign in. Also functions
   * as a switch to let the component knows the user is NOT signed in yet.
   */
  signInCallback: PropTypes.func,
};

export default ShowMeDropdown;
