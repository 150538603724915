import React, { useEffect, useState, useContext } from 'react';
import instance from '../../../Assets/Axios/AxiosInstance';
import InputArrayMapper from '../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper';
import { UserAuthInputElemConfigs, configAddOns } from '../../../Assets/Functions/GrantProfileCreationFormElements/FormElementConfigs';
import classes from './UserProfile.module.css';
import sharedStyles from '../sharedStyles.module.css';
import Loading from '../../../Components/UI/Loading/Loading';
import { StoreContext } from '../../../Store/StoreContext';
import { Link } from 'react-router-dom';
import ClearAngelIcon from '../../../Assets/Images/ClearAngel/CLEARCO_BLACK-WORDMARK-OLD.png';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import { Helmet } from 'react-helmet';

const UserProfile = () => {
  const { state } = useContext(StoreContext);
  const [profileState, setProfileState] = useState({
    form: {
      email: configAddOns.makeElemNonEditable(UserAuthInputElemConfigs.email),
      firstName: configAddOns.makeElemNonEditable(UserAuthInputElemConfigs.firstName),
      lastName: configAddOns.makeElemNonEditable(UserAuthInputElemConfigs.lastName),
    },
    loading: true,
    clearAngel: false, // when true, will display certain elements
  });

  useEffect(() => {
    // GET user data to display basic account details
    instance
      .get('/api/users/')
      .then(res => {
        let data = { ...res.data.results[0] };
        setProfileState(old => {
          let oldstate = { ...old };
          oldstate.form.email.value = data.email;
          oldstate.form.firstName.value = data.first_name;
          oldstate.form.lastName.value = data.last_name;
          oldstate.loading = false;
          return oldstate;
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <form className={sharedStyles.container}>
      <Helmet>
        <title>My Account</title>
      </Helmet>
      {profileState.loading ? (
        <Loading className={sharedStyles.loading}>Please Wait...</Loading>
      ) : (
        <>
          <h2>My Account</h2>
          {state.currentUser.clearAngel && (
            <img
              src={ClearAngelIcon}
              className={classes.clearAngelIcon}
              alt="ClearAngel icon"
            />
          )}
          <InputArrayMapper
            customInputProps={profileState.form}
            horizontal
            containerClassName={sharedStyles.inputArrayContainer}
          />
          <div className={sharedStyles.formButtons}>
            <Link to="/account/change-password">
              <HoverableButton presetBtnStyle="dark-purple">Change Password</HoverableButton>
            </Link>
          </div>
        </>
      )}
    </form>
  );
};

export default UserProfile;
