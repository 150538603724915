import React from 'react';
import PropTypes from 'prop-types';
import getCategoryIcon from '../../../../Assets/categoryIcons';

import classes from './CategoryCard.module.css';

const CategoryCard = ({ title, isEmpty, onClick, extraClasses = '' }) => {
  let Icon = getCategoryIcon(title);

  return (
    <div
      className={`${classes.container} pocketed-rounded-card ${extraClasses}`}
      onClick={() => onClick(title, isEmpty)}
      title="View all grants of this type"
    >
      <Icon className={classes.icon} />
      <p className={classes.title}>{title}</p>
    </div>
  );
};

CategoryCard.propTypes = {
  title: PropTypes.string,
  imgUrl: PropTypes.string,
  /** For when the card is just a placeholder for empty categories.  */
  isEmpty: PropTypes.bool,

  /**
   * An event listener for the category card. Function signature will be
   * (title, isEmpty) => {}. `title` is the title of the card and `isEmpty`
   * is whether the card is a place holder for empty categories.
   */
  onClick: PropTypes.func.isRequired,

  /**
   * Extra styling classes if needed.
   */
  extraClasses: PropTypes.string,
};

export default CategoryCard;
