import BaseSlice from './BaseSlice';
import Cookies from 'js-cookie';

/**
 * Hold states relevant to the filters.
 */
const initialState = {
  // For determining whether to show the grant list search bar
  showSearchBar: false,

  // For determining whether the 'grant saved' notification should show
  grantSavedPopUp: false,

  // For determining when to show a pop up, and what content is in the popup
  popup: {
    flag: false,
    message: '',
    type: '',
    referralPopup: false,
  },
  alphaPopup: {
    flag: false,
  },
  // holidaysPopup: {
  //   flag: true,
  // },
  editProfilePopup: {
    flag: false,
  },

  /**
   * The popup shown at the home page asking user to upgrade to basic.
   */
  showUpgradeToBasicPopup: false,
  showLoadingPopup: false,
  showNonICPPopup: !Cookies.get('shownNonICPPopup'), // if cookie exists
  MMPopup: {
    flag: false,
  },
  officeHoursPopup: {
    flag: true,
  },
  subscribedPopup: {
    flag: false,
  },

  // Used to display popup on Account Creation forms when the total matched grants to the user are low.
  shortGrantList: false,

  talkToMePopup: true,
  
  IPFundingPopup: true,

  conciergePopup: true,

  trialModal: true,
};

const reducerObj = {
  showSearchBar: (state, action) => {
    let tempState = { ...state };
    let result = {
      ...tempState,
      showSearchBar: action.payload,
    };
    return result;
  },

  setPopup: (state, action) => {
    let tempStatePopup = { ...state.popup };
    return {
      ...state,
      popup: {
        ...tempStatePopup,
        ...action.payload,
      },
    };
  },

  setAlphaPopup: (state, action) => {
    let tempAlphaPopup = { ...state.alphaPopup };
    return {
      ...state,
      alphaPopup: {
        ...tempAlphaPopup,
        ...action.payload,
      },
    };
  },

  setHolidaysPopup: (state, action) => {
    let tempHolidaysPopup = { ...state.holidaysPopup };
    return {
      ...state,
      holidaysPopup: {
        ...tempHolidaysPopup,
        ...action.payload,
      },
    };
  },

  setEditProfilePopup: (state, action) => {
    let tempEditProfilePopup = { ...state.editProfilePopup };
    return {
      ...state,
      editProfilePopup: {
        ...tempEditProfilePopup,
        ...action.payload,
      },
    };
  },

  setShowUpgradeToBasicPopUp: (state, action) => {
    return {
      ...state,
      showUpgradeToBasicPopUp: action.payload,
    };
  },
  setShowLoadingPopUp: (state, action) => {
    return {
      ...state,
      showLoadingPopup: action.payload,
    };
  },
  setShowNonICPPopup: (state, action) => {
    Cookies.set('shownNonICPPopup', 'true');
    return {
      ...state,
      showNonICPPopup: action.payload,
    };
  },

  setMMPopup: (state, action) => {
    let tempMMPopup = { ...state.MMPopup };
    return {
      ...state,
      MMPopup: {
        ...tempMMPopup,
        ...action.payload,
      },
    };
  },

  setOfficeHoursPopup: (state, action) => {
    let tempOOPopup = { ...state.officeHoursPopup };
    return {
      ...state,
      officeHoursPopup: {
        ...tempOOPopup,
        ...action.payload,
      },
    };
  },

  setSubscribedPopup: (state, action) => {
    let tempStatePopup = { ...state.subscribedPopup };
    return {
      ...state,
      subscribedPopup: {
        ...tempStatePopup,
        ...action.payload,
      },
    };
  },

  setShortGrantList: (state, action) => {
    return {
      ...state,
      shortGrantList: action.payload,
    };
  },

  setTalkToMePopup: (state, action) => {
    return {
      ...state,
      talkToMePopup: action.payload,
    };
  },

  setIPFundingPopup: (state, action) => {
    return {
      ...state,
      IPFundingPopup: action.payload,
    };
  },

  setConciergePopup: (state, action) => {
    return {
      ...state,
      conciergePopup: action.payload,
    };
  },

  setTrialModal: (state, action) => {
    return {
      ...state,
      trialModal: action.payload,
    };
  },
};

const UISlice = new BaseSlice(initialState, reducerObj);
export default UISlice;
