import React, { useEffect, useState, useContext } from 'react';
import { formContent } from './FormContent';
import getBackendDropdownOptions from '../../../../../Assets/Functions/GrantProfileOptionsGetter';
import instance from '../../../../../Assets/Axios/AxiosInstance';
import GrantProfileCreationBuilder from '../GrantProfileCreationBuilder';
import { withRouter } from 'react-router';
import OnboardingProgress from '../../../../../Components/UI/ProgressBar/ProgressBar';
import { StoreContext } from '../../../../../Store/StoreContext';
import Loading from '../../../../../Components/UI/Loading/Loading';
import classes from '../FormStyles.module.css';
import pageClasses from './GrantInformation.module.css';
import { formatGrantProfileValue } from '../GrantProfileUtils';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import { OutlineHoverableButton } from '@hellopocketed/react-ui.widgets.buttons.outline_hoverable_button';

const percentage = 100;
const GrantInformation = ({ history }) => {
  const { state, actions } = useContext(StoreContext);
  const [formContents, setFormContents] = useState({
    ...formContent,
    preferredLang: {
      ...formContent.preferredLang,
      value: state.translate.curLang,
      onChange: (_, value) => actions.setCurLang(value),
    },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [missingIndustries, setMissingIndustries] = useState(false);
  const [otherSelected, setOtherSelected] = useState('');

  useEffect(() => {
    getBackendDropdownOptions().then(dropdowns => {
      setFormContents(oldState => {
        let newState = {
          ...oldState,
        };

        newState.industries.options = dropdowns.industries;
        newState.founderType.options = dropdowns.founderType;
        newState.annualRevenue.options = dropdowns.annualRevenue;
        return newState;
      });
      setIsLoading(false);
    });
  }, []);

  // sync form element inputs with state
  useEffect(() => {
    if (state.translate.curLang !== formContents.preferredLang.value) {
      setFormContents({
        ...formContents,
        preferredLang: {
          ...formContents.preferredLang,
          value: state.translate.curLang,
        },
      });
    }
  }, [state.translate.curLang]);

  const selected = state.grantProfile.payroll_provider === 'Other';
  const handleOther = val => {
    setOtherSelected(val);
  };

  const formSubmit = e => {
    e.preventDefault();
    if (!state.grantProfile.industry_names || state.grantProfile.industry_names.length === 0) {
      setMissingIndustries(true);
      return setFormContents({ ...formContents });
    } else {
      setIsLoading(true);
      setMissingIndustries(false);
      setFormContents({ ...formContents });
      let onboardingInfo = {
        username: state.currentUser.user,
        onboarding_in_progress: false,
      };
      Object.keys(state.grantProfile).forEach(key => {
        onboardingInfo[key] = formatGrantProfileValue(key, state.grantProfile[key]);
      });

      if (otherSelected) {
        onboardingInfo['payroll_provider'] = otherSelected;
      }

      // there's a chance the user changes the value via the LangSwitch, we gotta save it
      onboardingInfo['language'] = state.translate.curLang;

      instance
        .post('/api/grantprofiles/', onboardingInfo)
        .then(res => {
          setIsLoading(false);
          console.log(res);
          Mixpanel.track('Grant Profile Completed', {
            status: 'Success',
            firstName: onboardingInfo['first_name'],
            lastName: onboardingInfo['last_name'],
            email: onboardingInfo['email'],
            companyName: onboardingInfo['company_name'],
            companySize: onboardingInfo['company_size'],
            founderDetails: onboardingInfo['founder_demos'],
            regions: onboardingInfo['regions'],
            industries: onboardingInfo['industries'],
            businessType: onboardingInfo['business'],
            businessGoals: onboardingInfo['bus_goals'],
            incorporated: onboardingInfo['incorporated'],
            revenue: onboardingInfo['annual_revenue'],
            raisedCapital: onboardingInfo['raised_capital'],
            sredSubmitted: onboardingInfo['sred_submitted'],
            accessedFunding: onboardingInfo['accessed_funding'],
            monthlySpending: onboardingInfo['monthly_spending'],
          });
          history.push('/grant-profile-creation/account-created');
        })
        .catch(err => {
          setIsLoading(false);
          const data = { grantprofile: onboardingInfo, err };
          Mixpanel.track('Grant Profile Completed', { status: 'Failed', error: data });
          history.push('/auth/login?gpError=true');
          //SHOW ERROR
        });
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {/* <h2 style={{ textAlign: 'left' }}>Step 2 of 5</h2> */}
        <p>You're almost there. Get ready to access billions in free government money!</p>
      </div>
      <div className={classes.outerFormContainer}>
        <div className={classes.progressContainer}>
          <OnboardingProgress percentage={percentage} />
        </div>
        <form
          className={classes.formContainer}
          onSubmit={formSubmit}
        >
          <h2>Company Information</h2>
          <hr />
          {isLoading ? (
            <div className="d-flex flex-column justify-content-center align-items-center py-5">
              <Loading />
              <p className="pt-2">Loading...</p>
            </div>
          ) : (
            <>
              {Object.keys(formContents).map(key => {
                return (
                  <GrantProfileCreationBuilder
                    customInputProps={formContents[key]}
                    inputName={key}
                    setFormContents={setFormContents}
                    key={key}
                  />
                );
              })}
              <div className={classes.otherContainer}>
                {selected && (
                  <textarea
                    className={classes.textArea}
                    rows={2}
                    required
                    onChange={e => handleOther(e.target.value)}
                    placeholder="Please type your other payroll provider here..."
                  />
                )}
              </div>
            </>
          )}

          {missingIndustries ? <p className={classes.errorMessage}>Please select an industry.</p> : null}
          {!isLoading ? (
            <div className={pageClasses.btnContainer}>
              <OutlineHoverableButton
                presetBtnStyle="clear"
                onClick={() => history.push('/grant-profile-creation')}
              >
                Previous
              </OutlineHoverableButton>
              <OutlineHoverableButton
                presetBtnStyle="pink"
                type="submit"
              >
                Finish
              </OutlineHoverableButton>
            </div>
          ) : (
            <></>
          )}
        </form>
      </div>
    </div>
  );
};

export default withRouter(GrantInformation);
