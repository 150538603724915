import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StoreContext } from '../../../../../../Store/StoreContext';
import { RadioInput } from '@hellopocketed/react-ui.inputs.radio_input';
import classes from './FilterRadioInput.module.css';

import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';

/**
 * A checkbox input section intended for Filters.
 * @param {*} props
 * @returns
 */
const FilterRadioInput = ({ option, filter, checked }) => {
  const { actions } = useContext(StoreContext);

  /**
   * Default onclick for inputs.
   * @param {} option
   */
  const defaultUpdateFilterOnClick = () => {
    actions.setSelectedFilter({ [filter]: option });
    Mixpanel.track('Clicked Filter(Traditional)', { filter: filter });
  };

  return (
    <RadioInput
      label={option}
      name={filter} // same filter == same radio group
      onChange={defaultUpdateFilterOnClick}
      checked={checked}
      wrapperClassname={classes.radio}
    />
  );
};

FilterRadioInput.propTypes = {
  /**
   * The option the user can select as the inputs.
   */
  option: PropTypes.string,

  /**
   * The filter that we want to set when the input is clicked on.
   * Must be a valid value to be sent to `/matches`.
   */
  filter: PropTypes.string,

  /**
   * Whether the radio should be checked.
   */
  checked: PropTypes.bool,
};

export default FilterRadioInput;
