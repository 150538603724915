import React from 'react';
import PropTypes from 'prop-types';
import BaseOverlay from '../BaseOverlay/BaseOverlay';

import classes from './LockedOverlay.module.css';
import lockImg from '../../../../Assets/Images/UI/lock.png';

/**
 * Message shown to the user when they need to sign in to see the content.
 */
export const signInMessage = 'Login to Pocketed to access';

/**
 * Message shown to the user when they need to upgrade to Pocketed+ to see the content.
 */
export const upgradePocketedPlusMessage = 'Upgrade to Pocketed+ to access';

/**
 * A locked overlay that will wrap around passed in elements
 * and display an overlay encompassing it. By default,
 * the overlay's wrapper will take up the entirety of its
 * parent container.
 * @param {} props
 * @returns
 */
const LockedOverlay = ({ children, height, tooltipMessage = 'Locked', onClick, coverClasses = '' }) => {
  return (
    <BaseOverlay
      coverChildren={
        <img
          src={lockImg}
          alt="lock"
          className={classes.lockImg}
        />
      }
      coverOnClick={onClick}
      coverTooltip={tooltipMessage}
      wrapperStyles={{ height }}
      coverClasses={`${classes.cover} ${coverClasses}`}
    >
      {children}
    </BaseOverlay>
  );
};

LockedOverlay.propTypes = {
  /**
   * A message that the user will see when they hover over the overlay.
   */
  tooltipMessage: PropTypes.string,

  children: PropTypes.node,

  /**
   * Height of the overlay as a valid CSS string. Default is 100%;
   */
  height: PropTypes.string,

  /**
   * On click handler for when the overlay is clicked on.
   */
  onClick: PropTypes.func,
  /**
   * On click handler for when the overlay is clicked on.
   */
  coverClasses: PropTypes.string,
};

export default LockedOverlay;
