// see Assets/Types/FilterTypesExample.js for more details on the key names

/**
 * The filters that are available in all situations.
 */
const genericFilters = [
  {
    // standard switch config object
    // the title we want to display to the user. Required.
    title: 'Unviewed Grants',

    // must have either `options`, `filterTypeKeyName`, or `switchOptions`.
    // depending on the `type`. Note: `filterTypeKeyName` and `options` are
    // interchangeable for the `radio` and `checkbox` type.
    switchOptions: {
      on: true,
      off: false,
    },

    // display names for the switch.
    switchDisplayNames: {
      on: 'Unviewed grants only',
      off: 'All grants',
    },

    // the param name used when we query `/matches`. Required.
    queryParam: 'new',

    // type of UI: `checkbox`, `radio` or `switch`.
    type: 'switch',
  },

  {
    // standard switch config object
    // the title we want to display to the user. Required.
    title: 'Open Grants',

    // must have either `options`, `filterTypeKeyName`, or `switchOptions`.
    // depending on the `type`. Note: `filterTypeKeyName` and `options` are
    // interchangeable for the `radio` and `checkbox` type.
    switchOptions: {
      on: true,
      off: false,
    },

    // display names for the switch.
    switchDisplayNames: {
      on: 'Open grants only',
      off: 'All grants',
    },

    // the param name used when we query `/matches`. Required.
    queryParam: 'openGrants',

    // type of UI: `checkbox`, `radio` or `switch`.
    type: 'switch',
  },

  {
    title: 'Sort Grants By',
    options: ['Lowest - Highest', 'Highest - Lowest'],
    queryParam: 'amountGte',
    type: 'radio',
  },

  {
    title: 'Funding Types',
    filterTypeKeyName: 'tax-credit', // key name within the `filterTypes` as returned by the API. If set, script will render the values from the API.
    queryParam: 'taxCredit',
    type: 'radio',
  },

  {
    // standard radio config object
    title: 'Grant Types',
    filterTypeKeyName: 'grant_types', // key name within the `filterTypes` as returned by the API. If set, script will render the values from the API.
    queryParam: 'grantTypes',
    type: 'radio',
  },
];

/**
 * The filters that are available only when the Grant Type is `Hiring` or
 * `Research and Development`.
 */
const hiringRAndDFilters = {
  requirements: ['Hiring', 'Research and Development'], // required grant type to match these values.
  filters: [
    {
      // standard checkbox config object
      title: 'Candidate Requirements', // the title we want to display to the user
      filterTypeKeyName: 'candidate_req', // key name within the `filterTypes` as returned by the API. If set, script will render the values from the API.
      queryParam: 'eligibilityCand', // the param name used when we query `/matches`
      type: 'checkbox', // type of UI: `checkbox`, `radio` or `toggleBtn`.
    },
    {
      title: 'Role Type',
      filterTypeKeyName: 'role_type',
      queryParam: 'roleReq',
      type: 'checkbox',
    },
    {
      title: 'Role Length',
      filterTypeKeyName: 'role_length',
      queryParam: 'roleReq', // same one as Role Type above => same filter but different ways to present it to the user.
      type: 'checkbox',
    },
  ],
};

/**
 * The filters that are available only when the Grant Type is `Marketing`.
 */
const marketingFilters = {
  requirements: ['Market Expansion'], // required grant type to match these values.
  filters: [
    {
      title: 'Project Activities',
      filterTypeKeyName: 'project_activity',
      queryParam: 'eligibileAct',
      type: 'checkbox',
    },
  ],
};

export { genericFilters, hiringRAndDFilters, marketingFilters };
