import { useContext } from 'react';
import { StoreContext } from '../../../Store/StoreContext';
import instance from '../../Axios/AxiosInstance';

/**
 * Global check for whether the API is fetching to prevent multiple
 * concurrent fetching.
 */
let isFetching = false;

/**
 * A hook for accessing the filter types. This method is
 * better than accessing `state.filterTypes` directly
 * because it fills out the state if it doesn't already exist.
 * @returns
 */
export default function useGrantProfile() {
  const { state, actions } = useContext(StoreContext);

  /**
   * Populate the grant profile state via the API, which will return only the
   * profile appropriate to this user.
   */
  const fetchGrantProfile = () => {
    // set this so we only trigger api call once across all callers of `useFilterTypes`
    if (isFetching) return;
    isFetching = true;
    instance
      .get('/api/grantprofiles/')
      .then(res => {
        actions.setGrantProfile(res.data.results[0]); // this would update store => trigger re-render.
        isFetching = false;
      })
      .catch(err => {
        console.log("Unable to get the grant profile. It's likely that the user is not logged in to cause this issue. Check logs for more details.");
        console.error(err);
        isFetching = false;
      });
  };

  /**
   * Populate the filters the first time someone ask for them
   */
  if (state.grantProfile.company_name === undefined || state.grantProfile.company_name == '') {
    if (state.currentUser.loggedIn) {
      fetchGrantProfile();
    }
  }

  return state.grantProfile;
}
