import React from 'react';
import classes from './CreateAccount.module.css';
import icon from '../../../Assets/Images/icon-woman.svg';
import PocketedLogo from '../../../Components/UI/PocketedLogo/PocketedLogo';
import CreateAccountForm from './CreateAccountForm/CreateAccountForm.js';
import { Helmet } from 'react-helmet';

const CreateAccount = () => {
  return (
    <div className={classes.container}>
      <Helmet>
        <title>Create an Account</title>
      </Helmet>
      <div className={classes.section + ' borderRight'}>
        <div className={classes.logoContainer}>
          <PocketedLogo extraClasses={classes.logo} />
        </div>
        <h2>Get Your Pocketed Account Today!</h2>
        <p>Match with funding programs to get free government money for your business!</p>
        <CreateAccountForm />
      </div>
      <div className={`${classes.section} ${classes.rightSection}`}>
        <div className={classes.iconContainer}>
          <img
            src={icon}
            alt="Female nurse typing"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
