import BaseSlice from './BaseSlice';

/**
 * Hold states relevant to the filters.
 */
const initialState = {
  // Filter Selection chosen by the users.
  filterSelection: {
    /**
     * The params that we will send to `/matches` to fetch the grants.
     * The values below are also the default values of the filters.
     */
    selected: {
      new: false,
      openGrants: true,
      eligibilityCand: [],
      eligibileAct: [],
      roleReq: [], // also contains roleLength. Backend treats both as one
      grantTypes: '',
      amountGte: [],
      subcategory: '',
      category: '',
      catgroup: '',
      taxCredit: '',
      closingGrants: false, // whether a grant is closing in 30 days. No UI filters yet, being used from the home page.
    },
    page: 1, // pagination bar
    search: '', // search value
  },

  /**
   * An object containing types of filters and their values as returned by the `/filter-types` query. Some values,
   * such as arrays containing objects of type `{"name": value}` are flatten into just values.
   * Also contains a property `isDummy` for whether the values in here are test (not user-specific) values.
   * Access this using `useFilterTypesGetter`.
   */
  filterTypes: {},
};

const reducerObj = {
  /**
   * Set ALL the filters in the `filterSelection`.
   * @param {*} state
   * @param {*} action
   * @returns
   */
  setFilterSelection: (state, action) => {
    return {
      ...state,
      filterSelection: {
        ...state.filterSelection,
        ...action.payload,
      },
    };
  },

  setSearchValue: (state, action) => {
    let newPayload = {
      search: action.payload,
      page: 1, // everytime a search value changes, reset the page
    };
    return reducerObj.setFilterSelection(state, { payload: newPayload });
  },

  /**
   * Set a specific selected filter.
   * @param {} state
   * @param {} action
   * @returns
   */
  setSelectedFilter: (state, action) => {
    let temp = {
      ...state,
      filterSelection: {
        ...state.filterSelection,
        page: 1, // reset the page whenever a new filter is set
        selected: {
          ...state.filterSelection.selected,
          ...action.payload,
        },
      },
    };

    // for some reason, the syntax below DOESN'T WORK
    // even though the resulting object looks the same
    // let temp2 = {...state}
    // temp2.filterSelection.selected[filter] = value
    // console.log(JSON.stringify(temp) === JSON.stringify(temp2))

    return temp;
  },

  /**
   * Add a new value to a selected filter. ONLY WORKS FOR FILTERS WITH
   * ARRAY VALUES (accept multiple values).
   * @param {*} state
   * @param {*} action
   * @returns
   */
  addSelectedFilter: (state, action) => {
    const { filter, value } = action.payload;
    let temp = [];
    try {
      temp = [...state.filterSelection.selected[filter]];
    } catch (e) {}
    temp.push(value);
    const newAction = {
      payload: { [filter]: temp },
    };
    return reducerObj.setSelectedFilter(state, newAction);
  },

  /**
   * Remove a value from a selected filter. ONLY WORKS FOR FILTERS WITH
   * ARRAY VALUES (accept multiple values).
   * @param {*} state
   * @param {*} action
   * @returns
   */
  removeSelectedFilter: (state, action) => {
    const { filter, value } = action.payload;
    let temp = [];
    try {
      temp = [...state.filterSelection.selected[filter]];
    } catch (e) {}
    const i = temp.indexOf(value);
    temp.splice(i, 1);
    const newAction = {
      payload: { [filter]: temp },
    };
    return reducerObj.setSelectedFilter(state, newAction);
  },

  /**
   * Reset ALL filters (including `page` and `search`).
   * @param {*} state
   * @param {*} action
   * @returns
   */
  resetFilters: (state, action) => {
    let newPayload = initialState.filterSelection;
    return reducerObj.setFilterSelection(state, { payload: newPayload });
  },

  /**
   * Set the filter types.
   * @param {*} state
   * @param {*} action
   * @returns
   */
  setFilterTypes: (state, action) => {
    return {
      ...state,
      filterTypes: {
        ...action.payload,
      },
    };
  },
};

const FilterSlice = new BaseSlice(initialState, reducerObj);
export default FilterSlice;
