import React from 'react';
import classes from './YearMonthToggle.module.css';

const YearMonthToggle = ({ isMonthly, toggleHandler }) => {
  return (
    <div className={classes.toggleSection}>
      <div
        className={`${isMonthly ? classes.inactiveSwitch : classes.activeSwitch}`}
        onClick={isMonthly ? toggleHandler : undefined}
      >
        YEARLY
      </div>
      <div
        className={`${isMonthly ? classes.activeSwitch : classes.inactiveSwitch}`}
        onClick={!isMonthly ? toggleHandler : undefined}
      >
        MONTHLY
      </div>
    </div>
  );
};

export default YearMonthToggle;
