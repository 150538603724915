import React from 'react';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import { useContext } from 'react';
import { StoreContext } from '../../../../Store/StoreContext';

import BusinessWomanImg from '../../../../Assets/Images/AvailableGrants/business_woman.jpg';
import { ReactComponent as Icon } from '../../../../Assets/Images/AvailableGrants/Pocketed Picks.svg';
import classes from './HeroSection.module.css';

const CATEGORY_GROUP = 'Pocketed Picks';
const HeroSection = () => {
  const { actions } = useContext(StoreContext);
  const onClick = () => {
    actions.setSelectedFilter({ catgroup: CATEGORY_GROUP });
  };

  return (
    <div className={classes.container}>
      <div className={classes.pocketedPickContainer}>
        <h1 className={classes.fundingTitle}>FUNDING FOR YOU</h1>
        <Icon className={classes.pocketedPickIcon} />
        <p className="m-0">Your customized grants list to boost your business!</p>
        <HoverableButton
          presetBtnStyle="dark-purple"
          onClick={onClick}
        >
          SHOW ME
        </HoverableButton>
      </div>
      <img
        src={BusinessWomanImg}
        alt="Business Woman"
        className={classes.titleAreaImg}
      />
    </div>
  );
};

export default HeroSection;
