import { formatDate } from '@hellopocketed/react-ui.js_utils.core';

/**
 * Convert the value of a grant profile attribute to something acceptable
 * by the backend.
 * @param {String} key - name of the grant profile attribute.
 * @param {*} value - value that we are formatting.
 */
export function formatGrantProfileValue(key, value) {
  if (Array.isArray(value)) {
    // usually for dropdown-multiple input
    return value.map(option => (typeof option == 'string' ? option : option.value));
  } else if (value instanceof Date) {
    return formatDate(value);
  } else if (key === 'regions') {
    return value.split(', ');
  } else {
    if (key === 'registration_date' && value === '') return null;
    return value;
  }
}

export const getOnboardingAnswered = grantProfile => {
  // set percentage based on user inputs
  console.log(grantProfile);
  let length = Object.keys(grantProfile).length;
  let countAnswered = 0;
  for (let answer in grantProfile) {
    if (grantProfile[answer]) {
      countAnswered++;
    }
  }
  // let percentage = Math.round((countAnswered / length) * 100);
  // return percentage;
  return countAnswered;
};
