import React, { useState } from 'react';
import { useIsUser } from '../../../../Assets/Functions/UserPermissions';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import { withTooltip } from '@hellopocketed/react-ui.widgets.tooltip';

import classes from './ProductToolCard.module.css';

const ProductToolCard = ({ data }) => {
  const isPaying = useIsUser.Paying();
  const [imageShowing, setImageShowing] = useState(true);
  const [hasAnimation, setHasAnimation] = useState(false);

  const claimHandler = () => {
    if (isPaying) {
      window.open(data.downloadLink);
    } else {
      window.open(data.stripeLink);
    }
  };

  const cardClicked = () => {
    setImageShowing(!imageShowing);
    setHasAnimation(true);
  };

  const CardImage = data.img;
  const CardWrapper = withTooltip('div', 'Click for details');

  return (
    <div
      className={classes.card}
      onClick={() => cardClicked()}
    >
      {data.isLogo ? (
        <div className={classes.logoBody}>
          <h2>{data.body}</h2>
          <CardImage className={classes.logoImage} />
        </div>
      ) : imageShowing ? (
        <CardWrapper className={classes.defaultBody}>
          <span>
            <CardImage className={`${classes.backgroundImage} ${hasAnimation && classes.hasAnimation}`} />
            <div className={classes.overlay}>
              <h2 className={classes.title}>{data.title}</h2>
            </div>
          </span>
        </CardWrapper>
      ) : (
        <div className={classes.flippedBody}>
          <div className={classes.text}>
            <p>{data.body}</p>
          </div>
          <div className={classes.text}>
            <p>
              <b>For Pocketed subscribers, this tool is FREE!</b>
            </p>
          </div>
          <HoverableButton
            presetBtnStyle="pink"
            presetSize="normal"
            onClick={() => claimHandler()}
          >
            {isPaying ? 'Download Now!' : 'Buy Now!'}
          </HoverableButton>
          <h1 className={`${classes.price} ${isPaying && classes.premiumPrice}`}>$49</h1>
        </div>
      )}
    </div>
  );
};

export default ProductToolCard;
