import classes from './StripeFormContainer.module.css';
import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeForm from './StripeForm/StripeForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripeFormContainer = () => {
  const [paytier, setPayTier] = useState({
    level: 0,
  });

  const tierArray = [
    {
      title: 'Free Tier',
      body: 'Explanation of what comes with the free account',
    },
    {
      title: 'Paid Tier',
      body: 'Explanation of what comes with the paid account',
    },
  ];

  const changeTier = index => {
    if (paytier.level !== index) {
      setPayTier(old => {
        let res = { ...old };
        res.level = index;
        return res;
      });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.choicesContainer}>
        {tierArray.map((i, k) => {
          return (
            <div
              className={classes.choice}
              key={k}
              onClick={() => changeTier(k)}
            >
              <input
                type="checkbox"
                checked={k === paytier.level}
              />
              <div className={classes.choiceContent}>
                <h4>{i.title}</h4>
                <p>{i.body}</p>
              </div>
            </div>
          );
        })}
      </div>
      {paytier.level > 0 ? (
        <Elements stripe={stripePromise}>
          <StripeForm />
        </Elements>
      ) : (
        ''
      )}
    </div>
  );
};

export default StripeFormContainer;
