/**
 * Gotten by querying `/filter-types`.
 */
export const filterTypesResponse = {
  grant_types: [
    {
      id: 1,
      type: 'Hiring',
    },
    {
      id: 3,
      type: 'Internship/Co-op',
    },
    {
      id: 4,
      type: 'Training',
    },
    {
      id: 5,
      type: 'Market Expansion',
    },
    {
      id: 2,
      type: 'Research and Development',
    },
    {
      id: 7,
      type: 'Capital Expenditure',
    },
  ],
  candidate_req: [
    {
      name: 'student',
    },
    {
      name: 'Indigenous',
    },
    {
      name: 'under 30',
    },
    {
      name: 'over 30',
    },
    {
      name: 'refugee protected status',
    },
    {
      name: 'Student',
    },
    {
      name: 'Woman',
    },
    {
      name: 'Self-identified Woman',
    },
    {
      name: 'Recent Grad',
    },
    {
      name: 'international',
    },
    {
      name: 'Internationally Educated Professionals',
    },
    {
      name: 'Youth',
    },
  ],
  project_activity: [
    {
      name: 'Develop New Products',
    },
    {
      name: 'Strategic Planning',
    },
    {
      name: 'Commercialization',
    },
    {
      name: 'Supply Chain',
    },
    {
      name: 'Market Analyses',
    },
    {
      name: 'Sustainability',
    },
    {
      name: 'market analyses',
    },
    {
      name: 'climate solution',
    },
    {
      name: 'Economic Development',
    },
    {
      name: 'Engineering',
    },
    {
      name: 'engineering',
    },
    {
      name: 'Climate Solution',
    },
    {
      name: 'develop new products',
    },
    {
      name: 'supply chain',
    },
  ],
  project_length: [
    {
      name: '2 years',
    },
    {
      name: 'MSProgram',
    },
    {
      name: '3 years',
    },
    {
      name: 'less than a year',
    },
    {
      name: 'Short-Term',
    },
    {
      name: '1 year',
    },
  ],
  role_length: [
    {
      name: '6-months',
    },
    {
      name: 'full-time',
    },
    {
      name: '8-months',
    },
    {
      name: 'Full-Time',
    },
    {
      name: '16-weeks',
    },
    {
      name: 'Part-Time',
    },
    {
      name: 'part-time',
    },
    {
      name: '1 year',
    },
  ],
  role_type: [
    {
      name: 'stem',
    },
    {
      name: 'sales',
    },
    {
      name: 'Software',
    },
    {
      name: 'Development',
    },
    {
      name: 'STEM',
    },
    {
      name: 'development',
    },
    {
      name: 'Finance',
    },
    {
      name: 'Healthcare',
    },
    {
      name: 'Operations',
    },
    {
      name: 'Sales',
    },
    {
      name: 'operations',
    },
    {
      name: 'business',
    },
    {
      name: 'Environmental',
    },
    {
      name: 'finance',
    },
    {
      name: 'software',
    },
    {
      name: 'Business',
    },
    {
      name: 'Arts',
    },
    {
      name: 'Marketing',
    },
    {
      name: 'marketing',
    },
    {
      name: 'environmental',
    },
  ],
  'tax-credit': [
    {
      name: 'Grant',
    },
    {
      name: 'Competition',
    },
    {
      name: 'Loans',
    },
    {
      name: 'Other',
    },
    {
      name: 'Tax Credit',
    },
  ],
  categories_groups: [
    {
      name: 'Pocketed Picks',
      categories: [
        {
          name: 'Grants that take under 60 minutes',
          subcategories: [
            {
              name: 'Grants that take under 60 minutes',
            },
          ],
        },
        {
          name: 'Grants in under 15 minutes with Pocketed+',
          subcategories: [
            {
              name: 'Grants in under 15 minutes with Pocketed+',
            },
          ],
        },
        {
          name: 'Grants over $500K',
          subcategories: [
            {
              name: 'Grants over $500K',
            },
          ],
        },
        {
          name: 'First come first serve grants',
          subcategories: [
            {
              name: 'First come first serve grants',
            },
          ],
        },
        {
          name: 'Grants to start a new business',
          subcategories: [
            {
              name: 'Grants to start a new business',
            },
          ],
        },
        {
          name: 'Staff picks',
          subcategories: [
            {
              name: 'Staff picks',
            },
          ],
        },
        {
          name: 'Grants our CMO loves for marketing',
          subcategories: [
            {
              name: 'Grants our CMO loves for marketing',
            },
          ],
        },
        {
          name: 'Grants our CTO loves for building tech stacks',
          subcategories: [
            {
              name: 'Grants our CTO loves for building tech stacks',
            },
          ],
        },
        {
          name: 'Grants our CEO loves for market expansion',
          subcategories: [
            {
              name: 'Grants our CEO loves for market expansion',
            },
          ],
        },
        {
          name: "Grants that don't require payroll",
          subcategories: [
            {
              name: "Grants that don't require payroll",
            },
          ],
        },
        {
          name: 'Grants for beginners',
          subcategories: [
            {
              name: 'Grants for beginners',
            },
          ],
        },
      ],
    },
    {
      name: 'Project Categories',
      categories: [
        {
          name: 'MARKETING',
          subcategories: [
            {
              name: 'Improving your online presence',
            },
            {
              name: 'Training your team',
            },
            {
              name: 'Hiring a new employee',
            },
            {
              name: 'A marketing project',
            },
          ],
        },
        {
          name: 'SUSTAINABILITY',
          subcategories: [
            {
              name: 'Making my company more sustainable',
            },
            {
              name: 'Environmental sustainability projects',
            },
            {
              name: 'Wage support to tackle business projects',
            },
          ],
        },
        {
          name: 'R&D',
          subcategories: [
            {
              name: 'Technical product development',
            },
            {
              name: 'Non-technical product development',
            },
            {
              name: 'Research and development',
            },
            {
              name: 'Wage support to tackle business projects',
            },
          ],
        },
        {
          name: 'CAPITAL EXPENDITURE',
          subcategories: [
            {
              name: 'Purchasing new equipment',
            },
            {
              name: "Things I've already purchased",
            },
            {
              name: 'Expanding or improving my physical location',
            },
            {
              name: 'Buying materials',
            },
            {
              name: 'International expansion',
            },
          ],
        },
        {
          name: 'MY NEW BUSINESS',
          subcategories: [
            {
              name: 'Starting a new business',
            },
            {
              name: 'Hiring a new employee',
            },
            {
              name: 'Hiring contractors or freelancers (not on payroll)',
            },
            {
              name: 'Competitions (where you can win money)',
            },
            {
              name: 'Wage support to tackle business projects',
            },
          ],
        },
        {
          name: 'HIRING',
          subcategories: [
            {
              name: "People I've already hired",
            },
            {
              name: 'Hiring a new employee',
            },
            {
              name: 'Hiring contractors or freelancers (not on payroll)',
            },
            {
              name: 'Competitions (where you can win money)',
            },
          ],
        },
        {
          name: 'FOUNDERS LIKE ME',
          subcategories: [
            {
              name: 'BIPOC founders',
            },
            {
              name: 'Newcomers',
            },
          ],
        },
      ],
    },
  ],
  display_categories: [
    'CAPITAL EXPENDITURE',
    'First come first serve grants',
    'FOUNDERS LIKE ME',
    'Grants for beginners',
    'Grants in under 15 minutes with Pocketed+',
    'Grants our CEO loves for market expansion',
    'Grants our CMO loves for marketing',
    'Grants our CTO loves for building tech stacks',
    'Grants over $500K',
    "Grants that don't require payroll",
    'Grants that take under 60 minutes',
    'Grants to start a new business',
    'HIRING',
    'MARKETING',
    'MY NEW BUSINESS',
    'R&D',
    'Staff picks',
    'SUSTAINABILITY',
  ],
  display_subcategories: [
    'Starting a new business',
    'First come first serve grants',
    'COVID relief',
    'BIPOC founders',
    'Newcomers',
    'Competitions (where you can win money)',
    'Grants our CMO loves for marketing',
    "Grants that don't require payroll",
    'Grants our CEO loves for market expansion',
    'Staff picks',
    'Expanding or improving my physical location',
    'Improving your online presence',
    'Purchasing new equipment',
    'Wage support to tackle business projects',
    "Things I've already purchased",
    'Hiring contractors or freelancers (not on payroll)',
    'Non-technical product development',
    'Hiring a new employee',
    'Grants over $500K',
    'Making my company more sustainable',
    'International expansion',
    "People I've already hired",
    'Research and development',
    'Technical product development',
    'Training your team',
    'Grants that take under 60 minutes',
    'Grants to start a new business',
    'Environmental sustainability projects',
    'Grants in under 15 minutes with Pocketed+',
    'A marketing project',
    'Buying materials',
    'Grants our CTO loves for building tech stacks',
    'Grants for beginners',
  ],
};
