import React from 'react';
import Layout from '../../../HOC/Layout/Layout';
import { withRouter } from 'react-router-dom';
import { productToolsData } from './ProductToolsData';

import classes from './ProductTools.module.css';
import ProductToolCard from './ProductToolCard/ProductToolCard';
import { useIsUser } from '../../../Assets/Functions/UserPermissions';
import { Helmet } from 'react-helmet';

const ProductTools = () => {
  const tools = productToolsData;
  const isPaying = useIsUser.Paying();

  return (
    <Layout>
      <Helmet>
        <title>Product Tools</title>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.heading}>
          {isPaying === true ? (
            <>
              <h2 className={classes.title}>
                Pocketed offers essential business tools — and access is <i>free</i> with your Pocketed subscription!
              </h2>
              <p className={classes.subtitle}>Get ahead in the grant game with our tested guides and templates.</p>
            </>
          ) : (
            <>
              <h2 className={classes.title}>Pocketed offers instant access to essential business tools!</h2>
              <p className={classes.subtitle}>Get ahead in the grant game with our tested guides and templates.</p>
              <p className={classes.subtitle}>
                <i>Want FREE access to all of these tools?</i> <a href="/account/subscription">Upgrade to any Pocketed subscription today!</a>
              </p>
            </>
          )}
        </div>
        <div className={classes.cardGrid}>
          {tools.map(tool => (
            <ProductToolCard
              key={tool.title}
              data={tool}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(ProductTools);
