import React, { useState } from 'react';
import { OutlineHoverableButton } from '@hellopocketed/react-ui.widgets.buttons.outline_hoverable_button';

import classes from './FeaturedPartnerCard.module.css';

const FeaturedPartnerCard = props => {
  let { image, title, details, message, perkClaimHandler } = props;

  const [loaded, setLoaded] = useState(false);
  const [claimed, setClaimed] = useState(false);

  return (
    <div className={`${classes.card} pocketed-rounded-card`}>
      <div className={classes.imageContainer}>
        <img
          className={classes.image}
          src={image}
          // prevents image from stretching before render is done
          style={loaded ? {} : { display: 'none' }}
          onLoad={() => setLoaded(true)}
        />
      </div>

      {claimed ? (
        <div className={classes.claimedMessage}>{message}</div>
      ) : (
        <>
          <div className={classes.textContainer}>
            <h3
              className={classes.title}
              translate="no"
            >
              {title}
            </h3>
            <div className={classes.details}>{details}</div>
          </div>
          <div className={classes.button}>
            <OutlineHoverableButton
              presetBtnStyle="whitePink"
              onClick={() => perkClaimHandler(props, setClaimed)}
            >
              CLAIM PERK
            </OutlineHoverableButton>
          </div>
        </>
      )}
    </div>
  );
};

export default FeaturedPartnerCard;
