import React, { useContext, useState, useRef, useEffect } from 'react';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import { StoreContext } from '../../../../Store/StoreContext';
import { XCircleFill } from 'react-bootstrap-icons';
import debounce from '../../../../Assets/Functions/debounce';
import classes from './SearchBar.module.css';

/**
 * A debounce version of mixpanel track.
 */
const [trackSearchValue] = debounce(searchValue => {
  Mixpanel.track('User Search', { searchText: searchValue });
}, 2000);

const SearchBar = () => {
  const { state, actions } = useContext(StoreContext);
  const [clearButtonDisplay, setClearButtonDisplay] = useState('none');
  const searchValue = state.filterSelection.search;
  const inputRef = useRef();

  /**
   * Hide/Show the button display
   */
  useEffect(() => {
    if (state.showSearchBar) {
      inputRef.current.focus();
      if (searchValue !== '') {
        setClearButtonDisplay('flex');
      }
    } else {
      setClearButtonDisplay('none');
    }
  }, [state.showSearchBar]);

  /**
   * Search for a grant with a name matching the value.
   * @param {string} value
   */
  const search = value => {
    actions.setSearchValue(value);
    setClearButtonDisplay(value !== '' ? 'flex' : 'none');
  };

  /**
   * Clear search.
   * @returns
   */
  const clear = () => search('');

  const inputChange = e => {
    e.persist();
    search(e.target.value);
    if (searchValue !== '') {
      trackSearchValue(searchValue);
    }
  };

  let style = {
    height: 0,
    visibility: 'hidden',
  };

  if (state.showSearchBar) {
    style = {
      height: '40px',
    };
  }

  return (
    <div
      className={classes.searchBarContainer}
      style={style}
    >
      <input
        className={classes.inputBar}
        type="text"
        onChange={inputChange}
        value={searchValue}
        ref={inputRef}
        placeholder="search"
        style={{ border: 'none' }}
      />
      <XCircleFill
        className={`pocketed-hoverable ${classes.closeButton}`}
        style={{ display: clearButtonDisplay }}
        onClick={clear}
      />
    </div>
  );
};

SearchBar.propTypes = {};

export default SearchBar;
