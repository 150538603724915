import React, { useContext, useEffect, useState } from 'react';
import { Nav, Dropdown, Button } from 'react-bootstrap';
import { NavLink, withRouter, useLocation } from 'react-router-dom';
import signOut from '../../Assets/Functions/SignOut';
import { PocketedColoredLogoColoredText } from '@hellopocketed/react-ui.images.pocketed_logos';
import { GRANT_PAGE_URL } from '../../Containers/AvailableGrantsView/AvailableGrantsView';
import { SAVED_PAGE_URL } from '../../Containers/SavedGrants/SavedGrants';
import { POCKETED_PERKS_PAGE_URL } from '../../Containers/Perks/PocketedPerksPage/PocketedPerksPage';
import alphaIcon from '../../Assets/Images/alpha_pocketed_logo_white.png';
import clearCoIcon from '../../Assets/Images/ClearAngel/CLEARCO_WHITE-LOGOMARK.png';
import signOutIcon from '../../Assets/Images/Logos/Logout_mobile.svg';
import { StoreContext } from '../../Store/StoreContext';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import useUserCan from '../../Assets/Functions/UserPermissions';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import LayoutHeader from '../LayoutComponents/LayoutHeader/LayoutHeader';
import LangSwitch from '../LangSwitch/LangSwitch';
import classes from './Navigation.module.css';

const Navigation = props => {
  const { history } = props;
  const { actions, state } = useContext(StoreContext);
  const [showResources, setShowResources] = useState(false);
  const [showPerks, setShowPerks] = useState(false);
  const location = useLocation();

  const isAlphaUser = state.currentUser.is_us_user;
  const clearAngel = state.currentUser.clearAngel;
  const canSeeExplore = useUserCan.SeeExploreFunding();
  const resourcesPathnames = ['/book-a-consultation', '/hire-a-grant-writer', '/finance-a-grant', '/office-hours', '/product-tools', '/venture-capital-fund'];
  const perksPathnames = ['/partner-perks', '/pocketed-perks'];
  useEffect(() => {
    const pathname = window.location.pathname;
    if (resourcesPathnames.includes(pathname)) {
      setShowResources(true);
    }
    if (perksPathnames.includes(pathname)) {
      setShowPerks(true);
    }
  }, []);
  /* Display ClearCo icon if user is has ClearAngel status */
  const clearCoLogo = () => {
    return (
      <>
        {clearAngel && (
          // add hover over clearangel logo
          <a
            rel="noreferrer"
            target="_blank"
            href="https://clear.co"
            id="clearco"
          >
            <img
              alt="clearco"
              src={clearCoIcon}
              className={classes.clearCoIcon}
            />
          </a>
        )}
      </>
    );
  };
  const logo = () => {
    return (
      <div className={classes.navHeader}>
        <NavLink
          to="/home"
          exact={true}
          id="home"
        >
          <img
            src={PocketedColoredLogoColoredText}
            className={classes.icon}
          />
        </NavLink>
        {clearCoLogo()}
      </div>
    );
  };
  const alphaLogo = () => {
    return (
      <div className={classes.navHeader}>
        <img
          src={alphaIcon}
          className={classes.icon}
          onClick={() => actions.setAlphaPopup({ flag: true })}
          alt="pocketed logo"
        />
        {clearCoLogo()}
      </div>
    );
  };

  let linkClick = linkName => {
    Mixpanel.track('Clicked on Page View ' + linkName + ' page view');
  };

  const openSideNav = action => {
    if (action) {
      document.getElementById('theSideNav').style.width = '250px';
    } else {
      document.getElementById('theSideNav').style.width = '0';
    }
  };

  return (
    <>
      {/* TODO: Add collapsible navbar functionality */}
      {/* <div className={classes.collapseContainer}>
                <Collapse className={classes.collapseNav} in={open} dimension="width" id="collapse-bar">
                    <div id="collapse-bar"> */}
      <Nav className={`flex-column d-sm-flex d-none ` + classes.navbar}>
        {/* display different logo on top of navbar if alpha user */}
        {!isAlphaUser ? logo() : alphaLogo()}

        <div className={classes.navLinks}>
          <NavLink
            to="/home"
            activeClassName={classes.active}
            exact={true}
            id="home"
          >
            <p>Home</p>
          </NavLink>

          {canSeeExplore ? (
            <NavLink
              to={GRANT_PAGE_URL}
              activeClassName={classes.active}
              exact={true}
              id="available_grants"
            >
              <p>Explore Funding</p>
            </NavLink>
          ) : (
            <NavLink
              to={location.pathname} // redirect to same page aka nothing happens
              exact={true}
              id="available_grants"
            >
              {/* the popup is stored in HOC/Layout/Layout.js since it needs to be available across all pages */}
              <p onClick={() => actions.setShowUpgradeToBasicPopUp(true)}>Explore Funding</p>
            </NavLink>
          )}

          <NavLink
            to="/account"
            activeClassName={classes.active}
            id="account"
          >
            <p>My Profile</p>
          </NavLink>

          <NavLink
            to={SAVED_PAGE_URL}
            activeClassName={classes.active}
            id="saved_grants"
          >
            <p>Saved Grants</p>
          </NavLink>
          <dl className={classes.perksDropdown}>
            <dt>
              <button
                onClick={() => setShowPerks(!showPerks)}
                className={classes.resourceToggle + ' ' + classes.perksLinkText + ' btn p-0 text-start dropdown-toggle'}
              >
                Perks
              </button>
            </dt>
            {showPerks && (
              <>
                <dd className="ms-2 mt-2 mb-3">
                  <NavLink
                    to={POCKETED_PERKS_PAGE_URL}
                    exact={true}
                    activeClassName={classes.active}
                    id="pocketed-perks"
                  >
                    <p>
                      <p>Pocketed Perks</p>
                    </p>
                  </NavLink>
                </dd>
                <dd className="m-2">
                  <NavLink
                    to="/partner-perks"
                    activeClassName={classes.active}
                    id="partner-perks"
                  >
                    <p>Partner Perks</p>
                    {/* Partner Perks */}
                  </NavLink>
                </dd>
              </>
            )}
          </dl>
          <Dropdown className={classes.resourceDropdown}>
            <DropdownToggle
              variant="none"
              className={classes.resourceToggle}
            >
              Get Help
            </DropdownToggle>
            <DropdownMenu
              className={classes.resourceMenu}
              show={showResources}
            >
              <NavLink
                to="/book-a-consultation"
                exact={true}
                activeClassName={classes.active}
                id="book-a-consultation"
                onClick={() => linkClick('Book A Consultation')}
              >
                <p>Book a Consultation</p>
                {/* Book a Consultation */}
              </NavLink>
              <NavLink
                to="/hire-a-grant-writer"
                activeClassName={classes.active}
                id="hire-a-grant-writer"
                onClick={() => linkClick('Hire A Grant Writer')}
              >
                <p>Hire a Grant Writer</p>
                {/* Hire a Grant Writer */}
              </NavLink>
              <NavLink
                to="/finance-a-grant"
                activeClassName={classes.active}
                id="finance-a-grant"
                onClick={() => linkClick('Finance A Grant')}
              >
                <p>Finance a Grant</p>
                {/* Finance a Grant */}
              </NavLink>
              {/*<NavLink
                to="/product-tools"
                activeClassName={classes.active}
                id="product-tools"
              >
                <p>Product Tools</p>
                Product Tools
              </NavLink>*/}
              <NavLink
                to="/office-hours"
                activeClassName={classes.active}
                id="office-hours"
                onClick={() => linkClick('Office Hours')}
              >
                <p>Office Hours</p>
                {/* Office Hours */}
              </NavLink>
              {/* <NavLink
                to="/venture-capital-fund"
                activeClassName={classes.active}
                id="venture-capital-fund"
                onClick={() => linkClick('Venture Capital Fund')}
              >
                <p>Venture Capital Funds</p>
              </NavLink> */}
              <a
                href="https://pocketed.zendesk.com/hc/en-us"
                id="knowledge-center"
                target="blank"
                onClick={() => linkClick('Knowledge Center')}
              >
                <p>Knowledge Center</p>
                {/* Knowledge Center */}
              </a>
            </DropdownMenu>
          </Dropdown>
        </div>
        <LangSwitch className={classes.langSwitch} />
      </Nav>
      {/* <div>
                </Collapse>
                <div className={classes.collapseCol}>
                    <Button className={classes.collapseButton} onClick={() => setOpen(!open)} aria-controls="collapse-bar" aria-expanded={open}>
                        {open ? '<<' : '>>'}
                    </Button>
                </div>
            </div> */}

      <Nav className={`d-sm-none ` + classes.navbarMobile}>
        <NavLink
          to="/"
          exact={true}
          className={classes.navHeaderMobile}
        >
          <img
            src={PocketedColoredLogoColoredText}
            className={classes.icon}
            alt="pocketed logo"
          />
        </NavLink>
        <LayoutHeader
          isPocketedPlus={props.isPocketedPlus}
          isConsultation={props.isConsultation}
        />
        <div className={classes.burgerMenu}>
          <svg
            onClick={() => openSideNav(true)}
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            className={classes.burgerIcon + ' bi bi-justify'}
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </div>
        <div
          id="theSideNav"
          className={classes.sideNav}
        >
          <div className={classes.navLinksMobile}>
            <a
              className={classes.closebtn}
              onClick={() => openSideNav(false)}
            >
              X
            </a>
            <NavLink
              to="/home"
              activeClassName={classes.active}
              exact={true}
              id="home"
            >
              <p>Home</p>
            </NavLink>

            {canSeeExplore ? (
              <NavLink
                to={GRANT_PAGE_URL}
                activeClassName={classes.active}
                exact={true}
                id="available_grants"
              >
                <p>Explore Funding</p>
              </NavLink>
            ) : (
              <NavLink
                to={location.pathname} // redirect to same page aka nothing happens
                exact={true}
                id="available_grants"
              >
                {/* the popup is stored in HOC/Layout/Layout.js since it needs to be available across all pages */}
                <p onClick={() => actions.setShowUpgradeToBasicPopUp(true)}>Explore Funding</p>
              </NavLink>
            )}

            <NavLink
              to="/account"
              activeClassName={classes.active}
              id="account"
            >
              <p>My Profile</p>
            </NavLink>

            <NavLink
              to={SAVED_PAGE_URL}
              activeClassName={classes.active}
              id="saved_grants"
            >
              <p>Saved Grants</p>
            </NavLink>
            <NavLink
              to={POCKETED_PERKS_PAGE_URL}
              exact={true}
              activeClassName={classes.active}
              id="pocketed-perks"
            >
              <p>
                <i>Pocketed Perks</i>
              </p>
            </NavLink>

            <Dropdown className={classes.resourceDropdown}>
              <DropdownToggle
                variant="none"
                className={classes.resourceToggle}
              >
                Get Help
              </DropdownToggle>
              <DropdownMenu
                className={classes.resourceMenu}
                show={state.showNavResources}
                rootCloseEvent={null}
              >
                <NavLink
                  to="/book-a-consultation"
                  exact={true}
                  activeClassName={classes.active}
                  id="book-a-consultation"
                  onClick={() => linkClick('Book A Consultation')}
                >
                  <p>Book a Consultation</p>
                </NavLink>
                <NavLink
                  to="/hire-a-grant-writer"
                  activeClassName={classes.active}
                  id="hire-a-grant-writer"
                  onClick={() => linkClick('Hire A Grant Writer')}
                >
                  <p>Hire a Grant Writer</p>
                </NavLink>
                <NavLink
                  to="/finance-a-grant"
                  activeClassName={classes.active}
                  id="finance-a-grant"
                  onClick={() => linkClick('Finance A Grant')}
                >
                  <p>Finance a Grant</p>
                </NavLink>
                <NavLink
                  to="/product-tools"
                  activeClassName={classes.active}
                  id="product-tools"
                >
                  <p>Product Tools</p>
                </NavLink>
                <NavLink
                  to="/partner-perks"
                  activeClassName={classes.active}
                  id="partner-perks"
                >
                  <p>Partner Perks</p>
                </NavLink>
                <NavLink
                  to="/office-hours"
                  activeClassName={classes.active}
                  id="office-hours"
                  onClick={() => linkClick('Office Hours')}
                >
                  <p>Office Hours</p>
                </NavLink>
                <NavLink
                  to="/venture-capital-fund"
                  activeClassName={classes.active}
                  id="venture-capital-fund"
                  onClick={() => linkClick('Venture Capital Fund')}
                >
                  <p>Venture Capital Funds</p>
                </NavLink>
                <a
                  href="https://pocketed.zendesk.com/hc/en-us"
                  id="knowledge-center"
                  target="blank"
                  onClick={() => linkClick('Knowledge Center')}
                >
                  <p>Knowledge Center</p>
                </a>
                <div
                  className={'ps-2 d-flex'}
                  onClick={() => signOut(state, actions, history)}
                >
                  <img
                    height={20}
                    width={20}
                    src={signOutIcon}
                  ></img>
                  <p className="ps-2">Logout</p>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </Nav>
    </>
  );
};

export default withRouter(Navigation);
