import React from 'react';
import { NavLink, Route, Switch, withRouter } from 'react-router-dom';
import Layout from '../../HOC/Layout/Layout';
import classes from './Account.module.css';
import ChangePassword from './ChangePassword/ChangePassword';
import GrantProfile from './GrantProfile/GrantProfile';
import Subscriptions from './Subscriptions/Subscriptions';
import UserProfile from './UserProfile/UserProfile';
import Preferences from './Preferences/Preferences';

const Account = () => {
  return (
    <Layout>
      <div className={classes.container}>
        <div className={classes.accountNav}>
          <div className={classes.header}>
            <h2>Account Management</h2>
          </div>
          <div className={classes.options}>
            <NavLink
              to="/account"
              exact
              activeClassName={classes.active}
            >
              My Account
            </NavLink>
            <NavLink
              to="/account/grant-profile"
              activeClassName={classes.active}
            >
              Grant Profile
            </NavLink>
            <NavLink
              to="/account/subscription"
              activeClassName={classes.active}
            >
              My Subscription
            </NavLink>
            <NavLink
              to="/account/preferences"
              activeClassName={classes.active}
            >
              Preferences
            </NavLink>
          </div>
        </div>
        <div className={classes.accountDetails}>
          <Switch>
            <Route
              path="/account"
              exact
              component={UserProfile}
            />
            <Route
              path="/account/change-password"
              component={ChangePassword}
            />
            <Route
              path="/account/grant-profile"
              component={GrantProfile}
            />
            <Route
              path="/account/subscription"
              component={Subscriptions}
            />
            <Route
              path="/account/preferences"
              component={Preferences}
            />
          </Switch>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(Account);
