import React from 'react';
import PropTypes from 'prop-types';
import classes from './SidePopUp.module.css';

const SidePopup = ({ children, extraClasses = '' }) => {
  return (
    <div
      className={`${classes.container} ${extraClasses}`}
      onClick={e => e.stopPropagation()}
    >
      {children}
    </div>
  );
};

SidePopup.propTypes = {
  /**
   * Extra classes as needed.
   */
  extraClasses: PropTypes.string,
};
export default SidePopup;
