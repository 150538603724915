import React, { useContext, useEffect } from 'react';
import useUserCan, { useIsUser } from '../../Assets/Functions/UserPermissions';
import GrantDetailsView from '../../Components/AppicationComponents/GrantDetailsView/GrantDetailsView';
import PropType from 'prop-types';
import GrantList from '../../Components/AppicationComponents/GrantList/GrantList';
import GrantSavedPopup from '../../Components/UI/PopUps/GrantSavedPopup/GrantSavedPopup';
import PopupModal from '../../Components/UI/PopUps/PopupModal';
import { StoreContext } from '../../Store/StoreContext';
import ToggleSearchButton from '../../Components/AppicationComponents/Filters/ToggleSearchButton/ToggleSearchButton';
import ProjectCategoriesDropdown from '../../Components/AppicationComponents/Filters/ProjectCategoriesDropdown/ProjectCategoriesDropdown';
import ShowMeDropdown from '../../Components/AppicationComponents/Filters/ShowMeDropdown/ShowMeDropdown';
import FilterDropdown from '../../Components/AppicationComponents/Filters/FilterDropdown/FilterDropdown';
import ClearFiltersButton from '../../Components/AppicationComponents/Filters/ClearFiltersButton/ClearFiltersButton';
import ExploreGrantView from '../../Components/AppicationComponents/ExploreGrantView/ExploreGrantView';
import { HOME_PAGE_URL } from '../HomePage/HomePage';
import { withRouter, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import instance from '../../Assets/Axios/AxiosInstance';
import TrialModal from '../../Components/UI/PopUps/TrialModal/TrialModal';
import TalktomePopup from '../../Components/UI/PopUps/TalktomePopup/TalktomePopup';
import ConciergeModal from '../../Components/UI/PopUps/ConciergeModal/ConciergeModal';
// import Nonicppopup from '../../Components/UI/PopUps/Nonicppopup/Nonicppopup';
import axios from 'axios';

import { MY_SUBSCRIPTIONS_URL } from '../Account/Subscriptions/Subscriptions';
import GrantView from '../../Components/UI/GrantView/GrantView';

import classes from './AvailableGrantsView.module.css';
import closeImg from '../../Assets/Images/AvailableGrants/close.png';
import { useDropdownLinking } from '@hellopocketed/react-ui.dropdown';
import { useMediaQuery } from 'react-responsive';
import IPFundingPopup from '../../Components/UI/PopUps/IPFundingPopup/IPFundingPopup';

/**
 * The URL to see this component.
 */
export const GRANT_PAGE_URL = '/available-grants';

/**
 * Extract special filters that are passed to us via the URL.
 * Note: this only works when these special filters have the same name
 * as the filters for `api/match` call. See the `Store/FilterSlice.js` and
 * look at the `filterSelection` object.
 */
const specialUrlFilters = ['subcategory', 'catgroup', 'grantTypes', 'closingGrants', 'eligibilityCand'];

/**
 * Display all the grants that the user are matched with, along with any filters that they might set.
 * @param {*} props
 * @returns
 */
const AvailableGrantsView = ({ savedGrants }) => {
  const { state, actions } = useContext(StoreContext);
  const canSeeInsightsKeywords = useUserCan.DoAll();
  const canSeeExplore = useUserCan.SeeExploreFunding();
  const isUserTypeLoading = useIsUser.TypeLoading();
  const isConciergeCandidate = useIsUser.ConciergeCandidate();
  const isNonConciergeCandidate = useIsUser.NonConciergeCandidate();
  const isICPCandidate = useIsUser.ICPCandidate();
  const isNonICPCandidate = useIsUser.NonICPCandidate();
  const isIPFundingCandidate = useIsUser.IPFundingCandidate();
  // const isCanExportCandidate = useIsUser.CanExportCandidate();

  // connect the following dropdowns so opening one closes the others
  const dropdowns = [ProjectCategoriesDropdown, ShowMeDropdown, FilterDropdown];
  const linkedDropdownStates = useDropdownLinking(dropdowns.length);
  const history = useHistory();
  const isMobile = useMediaQuery({ query: `(max-width: 479px)` });
  let count;
  if (state.grantListLoading) count = '(...)';
  else {
    count = state.totalGrantCount ? `(${state.totalGrantCount})` : '';
  }
  const title = `Your Matches ${count}`;

  /**
   * Redirect user to home page if they don't have permission to see this.
   */
  useEffect(() => {
    if (isUserTypeLoading) return; // prevent the page from kicking the user out before we are sure the user can't see the page.
    if (!canSeeExplore) history.push(HOME_PAGE_URL);
  }, [isUserTypeLoading, canSeeExplore, history]);

  /**
   * Get the allowed filters from the page's URL and sync it with our filter state.
   */
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let urlParams = {};
    specialUrlFilters.forEach(filter => {
      if (params.get(filter)) urlParams[filter] = params.get(filter);
    });

    if (Object.keys(urlParams).length > 0) {
      actions.setSelectedFilter(urlParams);
    }
  }, [window.location.search]);

  /**
   * Handles currentMatch and search persistence/clean up. If a search is set, we
   * display the search bar. On unmount, clear all filters and grant picked so
   * next navigation won't see old results.
   */
  useEffect(() => {
    if (state.filterSelection.search) {
      actions.showSearchBar(true);
    }

    // clear the filters and grant picked when unmount so user don't see old results
    // when going to a new page
    return () => {
      actions.resetFilters();
      actions.clearCurrentMatch();
      actions.showSearchBar(false);
    };
  }, []);

  /**
   * Query the backend whenever a filter is changed.
   */
  useEffect(() => {
    actions.setGrantListLoading(true);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const filterParams = createFilterParamString(state.filterSelection, state.translate.curLang);

    instance
      .get(`/api/matches/?${filterParams}`, { cancelToken: source.token })
      .then(res => {
        actions.setGrantList(res.data);
        actions.setGrantListLoading(false);
      })
      .catch(err => {
        console.log(err);
      });

    return () => {
      source.cancel('axios cancelled existing query');
    };
  }, [state.filterSelection, savedGrants]);

  /**
   * An on click handler for closing the GrantDetailsView.
   */
  const closeGrantDetailsView = () => {
    actions.clearCurrentMatch();
  };

  // clear the URL params if any exists.
  const clearURLParams = () => {
    // navigate to the clean URL with no query params attached
    history.push(GRANT_PAGE_URL);
  };

  return (
    <>
      <Helmet>
        <title>Matched Grants</title>
      </Helmet>
      <GrantView
        headerLeft={
          (isMobile && !state.currentMatch) || !isMobile ? (
            <div className={classes.titleArea}>
              <h2 className={classes.grantListTitle}>{title}</h2>
              <ToggleSearchButton />
            </div>
          ) : (
            <div style={{ display: 'none' }} />
          )
        }
        headerRight={
          (isMobile && !state.currentMatch) || !isMobile ? (
            <div className={classes.filters}>
              {dropdowns.map((Dropdown, idx) => {
                const [isShowing, setIsShowing] = linkedDropdownStates[idx];
                return (
                  <Dropdown
                    isShowing={isShowing}
                    setIsShowing={setIsShowing}
                    key={idx}
                  />
                );
              })}
              <ClearFiltersButton onClick={clearURLParams} />
            </div>
          ) : (
            <div style={{ display: 'none' }} />
          )
        }
        bodyLeft={isMobile && !state.currentMatch ? <GrantList /> : !isMobile ? <GrantList /> : <div style={{ display: 'none' }} />}
        bodyRight={
          state.currentMatch ? (
            <div className={classes.bodyRight}>
              <div className={classes.grantDetailsCloseBtn}>
                <img
                  src={closeImg}
                  alt="An x for closing the grant details view"
                  className={classes.grantDetailsCloseBtnImg + ` pocketed-hoverable pocketed-medium-icon`}
                  onClick={closeGrantDetailsView}
                />
              </div>
              <GrantDetailsView upgradeCallback={canSeeInsightsKeywords ? undefined : () => window.open(MY_SUBSCRIPTIONS_URL)} />
            </div>
          ) : isMobile && !state.currentMatch ? (
            <div style={{ display: 'none' }} />
          ) : (
            <ExploreGrantView />
          )
        }
        footer={
          <>
            {/* {state.popup.flag ? <PopupModal /> : ''} */}
            {<GrantSavedPopup />}
            {state.alphaPopup.flag && (
              <PopupModal
                type="alphaUsPopup"
                backgroundClicked={() => actions.setAlphaPopup({ flag: false })}
              />
            )}
          </>
        }
      />
      {/* Active popups*/}
      {/* {!isUserTypeLoading && isCanExportCandidate && <Nonicppopup />} */}
      {!isUserTypeLoading && isICPCandidate && state.currentUser.login_count < 4 && isNonConciergeCandidate && <TalktomePopup />}
      {!isUserTypeLoading && isNonICPCandidate && !canSeeExplore && <PopupModal type="upgradeToBasic" />}
      {!isUserTypeLoading && isNonConciergeCandidate && <TrialModal />}
      {!isUserTypeLoading && isConciergeCandidate && <ConciergeModal />}
      {!isUserTypeLoading && isIPFundingCandidate && state.currentUser.login_count < 6 && <IPFundingPopup />}

      {/* Inactive popups*/}
      {/* {state.officeHoursPopup.flag && <PopupModal type="officeHours" backgroundClicked={() => actions.setOfficeHoursPopup({ flag: false })} />} */}
      {/* {!userTypeLoading && state.holidaysPopup.flag && <PopupModal type="holidaysPopup" backgroundClicked={() => actions.setHolidaysPopup({ flag: false })}/>} */}
    </>
  );
};

AvailableGrantsView.propTypes = {
  /**
   * Whether the user wants to see only the saved grants
   */
  savedGrants: PropType.bool,
};

/**
 * Create the URL params using filters set in the filterSelection.
 * @param {*} filterSelection
 * @param {string} curLang - the current language the user is in.
 * @returns
 */
function createFilterParamString(filterSelection, curLang) {
  let selections = filterSelection.selected;
  let params = [];

  for (let [key, value] of Object.entries(selections)) {
    if (key === 'subcategory') {
      value = encodeURIComponent(value);
    }
    if (value && value.length !== 0) {
      params.push(`${key}=${value}`);
    }
  }

  if (filterSelection.page !== 1) {
    params.push(`page=${filterSelection.page}`);
  }

  if (filterSelection.search !== '') {
    params.push(`searchInput=${filterSelection.search}`);
    params.push(`language=${curLang}`);
  }

  return params.join('&');
}

export default withRouter(AvailableGrantsView);
