import React, { useEffect, useContext, useState } from 'react';
import instance from '../../../Assets/Axios/AxiosInstance';
import { StoreContext } from '../../../Store/StoreContext';
import PropTypes from 'prop-types';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import withTooltip from '../../../HOC/Tooltip/Tooltip';

import { ReactComponent as HeartOutline } from '../../../Assets/Images/UI/heart.svg';
import { ReactComponent as HeartFilled } from '../../../Assets/Images/AvailableGrants/heart-filled.svg';
import classes from './SaveGrantIcon.module.css';

/**
 * Toggle whether a grant is saved or not. By default when clicked would
 * toggle the save state of the grant passed in via `id`. Can be overridden
 * with another callback.
 * @param {object} props
 * @returns
 */
const SaveGrantIcon = ({ className = '', id, saved, overrideCallback }) => {
  const { actions } = useContext(StoreContext);
  // allows us to update the isSaved state as needed
  const [isSaved, setIsSaved] = useState(saved);

  // update the state when the props changes
  useEffect(() => {
    setIsSaved(saved);
  }, [saved]);

  /**
   * Save or unsave a grant depending on its status.
   */
  const toggleSaveGrant = e => {
    e.stopPropagation();
    const oldIsSaved = isSaved;
    setIsSaved(!isSaved); // update the UI right away since 99% of the time, the request succeeds.

    instance
      .patch('/api/matches/' + id + '/', { saved: !isSaved })
      .then(res => {
        // upgrade the state so our source of truth is synced
        actions.replaceCurrentMatch(res.data);
        Mixpanel.track('Grant Saved', {
          grant_name: res.data.grant.name,
          amount: res.data.grant.amount,
          region: res.data.grant.region,
          type: res.data.grant.grant_type.type,
          saved: true,
        });
      })
      .catch(err => {
        console.log("Found an error changing a grant's saved state. See below: ");
        console.error(err);
        setIsSaved(oldIsSaved); // flip back since we got an error
      });
  };

  let ImgWithToolTip;
  if (isSaved) {
    ImgWithToolTip = withTooltip(HeartFilled, 'Unsave this grant to stop getting email alerts');
  } else {
    ImgWithToolTip = withTooltip(HeartOutline, 'Save this grant to get email alerts');
  }

  return (
    <ImgWithToolTip
      className={`pocketed-hoverable pocketed-medium-icon ${classes.icon} ${isSaved && classes.grantSavedIcon} ${className}`}
      fill="var(--pink)"
      alt="saved icon"
      onClick={overrideCallback ? overrideCallback : toggleSaveGrant}
    />
  );
};

SaveGrantIcon.propTypes = {
  className: PropTypes.string,
  /**
   * The id of the `currentMatch` object, NOT the the id of the `grant` object (see Assets/Types/TypeExamples).
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  /**
   * Whether the icon should be in saved (solid filled heart) or unsaved (translucent empty heart) mode.
   */
  saved: PropTypes.bool.isRequired,

  /**
   * A callback that will override the component's default onclick. Supplying this means the icon
   * won't save the grant when clicked.
   */
  overrideCallback: PropTypes.func,
};

export default SaveGrantIcon;
