import { useHistory } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import classes from './SignInSignUpPrompt.module.css';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import { OutlineHoverableButton } from '@hellopocketed/react-ui.widgets.buttons.outline_hoverable_button';
import { MY_SUBSCRIPTIONS_URL } from '../../Account/Subscriptions/Subscriptions';

const SignInSignUpPrompt = ({ toggleShowSignInPopUp, isFreeUser }) => {
  const history = useHistory();
  const showPlansPage = () => {
    history.push(MY_SUBSCRIPTIONS_URL);
  };
  return (
    <div className={`${classes.popup} pocketed-rounded-card`}>
      <h3 className={classes.title}>Want access to all of Pocketed's features?</h3>
      {isFreeUser ? (
        <HoverableButton
          presetBtnStyle="pink"
          onClick={showPlansPage}
        >
          Upgrade Now!
        </HoverableButton>
      ) : (
        <div className={classes.popupBtnContainer}>
          <HoverableButton
            presetBtnStyle="pink"
            onClick={toggleShowSignInPopUp}
          >
            Sign in
          </HoverableButton>
          <p className={classes.orTxt}>or</p>
          <OutlineHoverableButton
            presetBtnStyle="dark-purple"
            href="/create-account"
          >
            Create an account
          </OutlineHoverableButton>
        </div>
      )}
    </div>
  );
};

SignInSignUpPrompt.propTypes = {
  /**
   * Show the popup.
   */
  toggleShowSignInPopUp: PropTypes.func.isRequired,

  /**
   * Whether the user is a free user.
   */
  isFreeUser: PropTypes.bool,
};

export default SignInSignUpPrompt;
