import React from 'react';
import PropTypes from 'prop-types';
import LockedOverlay, { signInMessage, upgradePocketedPlusMessage } from '../../../../UI/Overlays/LockedOverlay/LockedOverlay';
import BaseResourcesSection from '../BaseResourcesSection/BaseResourcesSection';

import grantDetailsClasses from '../../GrantDetailsView.module.css';
import classes from './LockedResourcesSection.module.css';

/**
 * Resources that all grants should have.
 */
const defaultResources = [
  {
    title: 'Getting started with grants',
    url: 'https://hellopocketed.io/2021/02/05/pocketed-grants-101/',
  },
  {
    title: 'How grants work',
    url: 'https://hellopocketed.io/2021/05/14/pocketed-grant-basics/',
  },
  {
    title: 'Your first application',
    url: 'https://hellopocketed.io/2021/05/20/the-grant-application-process/',
  },
  {
    title: 'Recruiting for hiring grants',
    url: 'https://hellopocketed.io/2021/08/30/recruiting-for-hiring-grants/',
  },
  {
    title: 'What is grant-based financing?',
    url: 'https://hellopocketed.io/2021/07/16/what-is-grant-based-financing/',
  },
];

const LockedResourcesSection = ({ signInCallback, upgradeCallback }) => {
  let links = (
    <ul>
      {defaultResources.map(resource => {
        return (
          <li
            key={resource.title}
            className="blurred-text"
          >
            <a className={`${classes.blurredLink}`}>
              {/* eslint-disable-line jsx-a11y/anchor-is-valid*/}
              {resource.title}
            </a>
          </li>
        );
      })}
    </ul>
  );

  return (
    <BaseResourcesSection>
      <LockedOverlay
        tooltipMessage={signInCallback ? signInMessage : upgradePocketedPlusMessage}
        onClick={signInCallback ? signInCallback : upgradeCallback}
      >
        {links}
      </LockedOverlay>
    </BaseResourcesSection>
  );
};

LockedResourcesSection.propTypes = {
  /**
   * On click handler for asking user to sign in. Also functions
   * as a switch to let the component knows the user is NOT signed in yet.
   */
  signInCallback: PropTypes.func,

  /**
   * On click handler for asking user to upgrade. Also functions
   * as a switch to let the component knows the user is NOT signed in yet.
   */
  upgradeCallback: PropTypes.func,
};

export default LockedResourcesSection;
