/**
 * The value for the `like` column of a Matches object in the backend.
 * Note: this is copied over from the backend repo (models/matches.py).
 * If the value changes there, please update it here.
 */
export const LikeEnum = {
  LIKED: 1,
  NEUTRAL: 2,
  DISLIKED: 0,
};

/**
 * Whether a grant is hidden based on its `like` value.
 * @param {number} likeValue - the value in a `currentMatch`'s `like` column.
 * @returns
 */
export default function isGrantHidden(likeValue) {
  return likeValue === LikeEnum.DISLIKED;
}
