import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import classes from './SimpleModal.module.css';

/**
 * A simple modal that doesn't have any title and only show a simple content.
 * Center its content by default.
 *
 * Accepts any props from react-bootstrap's `Modal` component.
 * @param {*} param0
 * @returns
 */
const SimpleModal = ({ children, withBorder = false, className = '', dialogClassName = '', modalBodyClassName = '', ...modalProps }) => {
  return (
    <Modal
      {...modalProps}
      className={`${classes.modal} ${className}`}
      dialogClassName={`${classes.modalDialog} ${dialogClassName}`}
      centered
    >
      <Modal.Body
        className={`${classes.modalBody} ${modalBodyClassName}`}
        style={{ padding: withBorder ? 'var(--space-half)' : 0 }}
      >
        {children}
      </Modal.Body>
    </Modal>
  );
};

SimpleModal.propTypes = {
  /**
   * Whether the show a thin white border around the image.
   * Default false.
   */
  withBorder: PropTypes.bool,

  modalBodyClassName: PropTypes.string,
};

export default SimpleModal;
