import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import classes from './TalktomePopup.module.css';
import TalktomeImage from '../../../../Assets/Images/UI/Talk_to_me.png';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import { StoreContext } from '../../../../Store/StoreContext';

const TalktomePopup = () => {
  const { state, actions } = useContext(StoreContext);

  return (
    <Modal
      show={state.talkToMePopup}
      onHide={() => actions.setTalkToMePopup(false)}
      dialogClassName={classes.modalDialog}
      contentClassName={classes.modalContent}
      centered
    >
      <div
        className={classes.image}
        onClick={() => {
          Mixpanel.track('Click on ICP popup', {});
          window.open('https://meetings.hubspot.com/jamie-proctor-boyce/welcome-to-pocketed');
        }}
      >
        <img
          src={TalktomeImage}
          alt="your business has high potential for grants."
          className={classes.img}
        ></img>
      </div>
    </Modal>
  );
};

export default TalktomePopup;
