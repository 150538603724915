import Layout from '../../HOC/Layout/Layout';
import React, { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import csvFile from './VC_list.csv';
import classes from './VentureCapitalFund.module.css';
import { OutlineHoverableButton } from '@hellopocketed/react-ui.widgets.buttons.outline_hoverable_button';
import { Helmet } from 'react-helmet';

// seperate component for filtering - outside of the main component because otherwise we run into State issues
const FilterComponent = ({ filterText, onFilter, onClear }) => {
  return (
    <div className={classes.filterContainer}>
      <Helmet>
        <title>Venture Capital Fund</title>
      </Helmet>
      <input
        className={classes.searchInput}
        id="search"
        type="text"
        placeholder="Filter By Type"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />

      <OutlineHoverableButton
        presetBtnStyle="pink"
        presetSize={'small'}
        onClick={onClear}
      >
        X
      </OutlineHoverableButton>
    </div>
  );
};

const VentureCapitalFund = () => {
  const [data, setData] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = data.filter(item => item.Type && item.Type.toLowerCase().includes(filterText.toLowerCase()));

  useEffect(() => {
    fetch(csvFile)
      .then(r => r.text())
      .then(text => {
        let data = csvFileToArray(text);

        setData(data);
      });
  }, []);

  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf('\n')).trim().split(',');
    const csvRows = string
      .slice(string.indexOf('\n') + 1)
      .trim()
      .split('\n');

    const array = csvRows.map((i, count) => {
      const values = i.split(',');
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        object.id = count;
        return object;
      }, {});
      return obj;
    });
    return array;
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: '60px', // override the row height
        '&:nth-child(odd)': {
          // every odd row, change the background color
          backgroundColor: '#F9F5FB',
        },
      },
    },
  };

  const columns = [
    {
      name: 'Name',
      selector: row => row.Name,
      sortable: true,
      maxWidth: '300px',
      style: {
        color: '#231E5F',
      },
    },
    {
      name: 'Type',
      selector: row => row.Type,
      sortable: true,
      maxWidth: '250px',
      style: {
        color: '#231E5F',
      },
    },
    {
      name: 'Location',
      selector: row => row.Location,
      sortable: true,
      maxWidth: '200px',
      style: {
        color: '#231E5F',
      },
    },
    {
      name: 'Website',
      maxWidth: '300px',
      style: {
        color: '#231E5F',
      },
      cell: row => (
        <a
          style={{ color: '#231E5F' }}
          href={row.Website}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.Website}
        </a>
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Layout>
      <div className={classes.outerContainer}>
        <h2>Venture Capital Funds</h2>
        <div className={classes.outerDiv}>
          <DataTable
            columns={columns}
            data={filteredItems}
            // conditionalRowStyles={conditionalRowStyles}
            customStyles={customStyles}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            pagination
          />
        </div>
      </div>
    </Layout>
  );
};

export default VentureCapitalFund;
