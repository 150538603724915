import React from 'react';
import { Dropdown } from '@hellopocketed/react-ui.dropdown';
import LockedOverlay, { signInMessage } from '../../../UI/Overlays/LockedOverlay/LockedOverlay';
import PropTypes from 'prop-types';
import classes from './BaseFilterBarDropdown.module.css';

/**
 * Base version of dropdown specifically for the dropdowns in the filter bar.
 * Can also pass any props accepted by <Dropdown /> from @hellopocketed/react-ui.dropdown.
 * @param {*} param0
 * @returns
 */
const BaseFilterBarDropdown = ({ signInCallback, children, ...dropDownProps }) => {
  let elems = children;
  if (signInCallback) {
    elems = (
      <LockedOverlay
        onClick={signInCallback}
        tooltipMessage={signInMessage}
      >
        {elems}
      </LockedOverlay>
    );
  }

  return (
    <Dropdown
      {...dropDownProps}
      extraTitleClasses={classes.title}
    >
      {elems}
    </Dropdown>
  );
};

BaseFilterBarDropdown.propTypes = {
  /**
   * On click handler for asking user to sign in. Also functions
   * as a switch to let the component knows the user is NOT signed in yet.
   */
  signInCallback: PropTypes.func,

  children: PropTypes.node,
};

export default BaseFilterBarDropdown;
