import React, { useContext, useRef, useState } from 'react';
import instance from '../../../../Assets/Axios/AxiosInstance';
import { OutlineHoverableButton } from '@hellopocketed/react-ui.widgets.buttons.outline_hoverable_button';
import closeImg from '../../../../Assets/Images/AvailableGrants/close.png';
import holdUpImage from '../../../../Assets/Images/CancellationPopUp.png';
import { StoreContext } from '../../../../Store/StoreContext';

import classes from './CancellationPopup.module.css';

const ReasonPopup = ({ setShowDeal, setButtonMessage, closePopup, cancelReasons }) => {
  const { state } = useContext(StoreContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showOtherField, setShowOtherField] = useState(false);
  const otherTextRef = useRef();
  const defaultButton = <>Make us a deal</>;
  const defaultMessage = (
    <>
      <p>We’re Brianna and Aria, Pocketed's co-founders.</p>

      <p>We hear you loud and clear - grants are time-consuming - and we get it.</p>

      <p>We also know (from experience!) that there is money out there for every business spending money AND how crucial this investment could be to kick-start your growth.</p>

      <p>Contact us to chat more about your business needs so we can get you the funding you are eligible for! With our team in your back pocket, you’ll never miss another penny.</p>

      <p>Or, make us a deal and let’s get you started on your grant funding journey!</p>
    </>
  );

  // if any radio that is not "other" is selected, remove text input from user's view
  const radioSelectedHandler = () => {
    setIsDisabled(false);
    setShowOtherField(false);
  };

  // if the "other" field is blank, do not allow user to submit
  const otherTextHandler = () => {
    if (otherTextRef.current?.value.trim() !== '') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  // if the "other" radio is checked, show text input field and initially disable submit
  const otherRadioHandler = otherRadioRef => {
    if (otherRadioRef.current.checked) {
      setIsDisabled(true);
      setShowOtherField(true);
    }
  };

  const formSubmissionHandler = event => {
    event.preventDefault(); // don't send http request or reload page

    let radioChoice = 'None',
      dealMessage = defaultMessage,
      buttonMessage;

    // find which radio button was selected, if other -> take other input text
    // "some" function breaks out of loop if return is true
    cancelReasons.some(r => {
      if (r.ref.current.checked) {
        if (r.title === 'other') {
          radioChoice = otherTextRef.current.value;
          dealMessage = r.dealMessage;
        } else {
          radioChoice = r.text;
          dealMessage = r.dealMessage;
        }
        buttonMessage = r?.buttonMessage || defaultButton;
        return true;
      }
    });

    instance
      .post('/hubspot-api/cancel', { reason: radioChoice })
      .catch(function (error) {
        console.log(error.toJSON());
      })
      .finally(() => {
        setShowDeal(dealMessage);
        setButtonMessage(buttonMessage);
      });
  };

  return (
    <div
      className={classes.reasonContainer}
      onClick={e => e.stopPropagation()}
    >
      <img
        src={closeImg}
        alt="Close"
        className={`pocketed-small-icon ${classes.closeIcon} pocketed-hoverable`}
        onClick={() => closePopup()}
      />
      <div className={classes.reasonBody}>
        <div className={classes.image}>
          <img
            alt="Hold up image"
            src={holdUpImage}
          />
        </div>

        <form
          className={classes.form}
          onSubmit={formSubmissionHandler}
        >
          <h2 className={classes.title}>Hold up!</h2>
          <h3>Tell us what happened?</h3>
          <br />

          {/* Map all reasons to a radio button */}
          {cancelReasons.map(reason => (
            <div
              key={reason.title}
              className={classes.radio}
            >
              <input
                ref={reason.ref}
                type="radio"
                name="reason"
                id={reason.title}
                onChange={() => (reason.title !== 'other' ? radioSelectedHandler() : otherRadioHandler(reason.ref))}
              />
              <label
                htmlFor={reason.title}
                className={`${classes.label} ${classes.labelRight}`}
              >
                {reason.text}
              </label>
            </div>
          ))}

          {/* Only show when "other" radio selected, enable submit if not blank */}
          {showOtherField && (
            <div className={classes.radio}>
              <label
                htmlFor="otherReason"
                className={`${classes.label} ${classes.labelLeft}`}
              >
                Reason:{' '}
              </label>
              <input
                ref={otherTextRef}
                type="text"
                id="otherReason"
                onChange={() => otherTextHandler()}
              />
            </div>
          )}

          <div className={classes.buttons}>
            <OutlineHoverableButton
              presetBtnStyle="pink"
              presetSize="chunky"
              type="submit"
              disabled={isDisabled}
            >
              Submit
            </OutlineHoverableButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReasonPopup;
