import { loadStripe } from '@stripe/stripe-js';
import instance from '../../Axios/AxiosInstance';

export const createStripeCheckout = async (plan, monthly) => {
  const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const session = await createCheckoutSession(plan, monthly);
  if (!session) {
    console.log('session not created successfully');
    return;
  } else {
    stripe
      .redirectToCheckout({
        sessionId: session.data.sessionId,
      })
      .then(success => {
        // store user status or GET /user again to get updated status
        return success;
      })
      .catch(err => {
        console.log('failed to redirect to checkout', err);
        return;
      });
  }
};

export const portalSubmit = async () => {
  //stripe-checkout/
  const portalSessionURL = await goToCustomerPortal();
  // if session was created succcessfully
  if (portalSessionURL) {
    window.open(portalSessionURL, '_self', 'noopener,noreferrer');
  } else {
    console.log('portal session not created successfully');
  }
};

const goToCustomerPortal = async () => {
  return await instance
    .post('/stripe-customer-portal/')
    .then(res => {
      return res.data.url;
    })
    .catch(err => {
      console.log(err);
    });
};

/**
 * Rewardful function for getting their tracking code.
 * @returns
 */
function getClientReferenceId() {
  return (window.Rewardful && window.Rewardful.referral) || 'checkout_' + new Date().getTime();
}

const createCheckoutSession = (plan, monthly) => {
  let formdata = new FormData();
  formdata.append('plan', plan);
  formdata.append('monthly', monthly);
  formdata.append('clientReferenceId', getClientReferenceId());

  return instance.post('/stripe-checkout/', formdata).catch(err => {
    return err;
  });
};
