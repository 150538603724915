import Layout from '../../../HOC/Layout/Layout';
import StaticPage from '../../../HOC/StaticPage/StaticPage';
import classes from './OfficeHours.module.css';
import image from '../../../Assets/Images/Office hours.jpg';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import { Helmet } from 'react-helmet';

function OfficeHours() {
  return (
    <Layout>
      <Helmet>
        <title>Office Hours</title>
      </Helmet>
      <StaticPage
        title="Office Hours"
        imgSrc={image}
      >
        <p className={classes.subtitle}>Meet with Pocketed's Grant Specialists</p>
        <p>Join us to learn how Pocketed's software simplifies your access to grants and how our solutions support your funding success.</p>
        <p>These sessions are your free opportunity to connect with Pocketed's specialists, and are offered several times per week.</p>
        <HoverableButton
          href="https://us06web.zoom.us/meeting/register/tZMrc--uqzopGdIds6hvidCbWyeiXDCparBU"
          target="_blank"
          presetBtnStyle="pink"
          presetSize={'chunky'}
          style={{ margin: '0 0 20px 0' }}
        >
          Register Now
        </HoverableButton>
        <p>We can't wait to meet you and answer all of your non-dilutive funding questions. See you soon!</p>
      </StaticPage>
    </Layout>
  );
}

export default OfficeHours;
