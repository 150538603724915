import React from 'react';
import PropTypes from 'prop-types';
import { formatAmount } from '@hellopocketed/react-ui.js_utils.core';

import classes from './GrantAmount.module.css';

/**
 * A grant list item in the grants view.
 * @param {Object} props
 * @returns
 */
const GrantAmount = ({ amount, extraClasses }) => {
  return <p className={`${classes.grantAmount} ${extraClasses}`}>{formatAmount(amount)}</p>;
};

GrantAmount.propTypes = {
  /**
   * Amount of money that this grant can give to its applicant.
   */
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Extra styling classes if needed.
   */
  extraClasses: PropTypes.string,
};

export default GrantAmount;
