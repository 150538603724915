import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../../HOC/Layout/Layout';
import PartnerPerksCard from './PartnerPerksCard/PartnerPerksCard';
import { partnerData, featuredData } from './PartnerPerksData';
import { Helmet } from 'react-helmet';

import classes from './PartnerPerks.module.css';
import FeaturedPartnerCard from './FeaturedPartnerCard/FeaturedPartnerCard';
import { useIsUser } from '../../../Assets/Functions/UserPermissions';
import { StoreContext } from '../../../Store/StoreContext';
import { createDeal } from '../../../Assets/Functions/HubSpot/PrivateApp';
import goToLink from '../../../Assets/Functions/ForwardLink';

const PartnerPerks = () => {
  const history = useHistory();
  const { state } = useContext(StoreContext);
  const premiumUser = useIsUser.PPlusOrConcierge();

  const perkClaimHandler = (cardData, setClaimed) => {
    if (cardData.email) {
      createDeal({
        userEmail: state.currentUser.user,
        partnerEmail: cardData.email,
        title: `Partner Perks - ${cardData.title} Discount`,
        // details: details,
      });
      setClaimed(true);
    } // Discount code is used
    else {
      // Special case - decklinks has discount for basic & premium users
      if (cardData.title === 'DeckLinks') {
        if (premiumUser) {
          goToLink(history, cardData.link.premium);
        } else {
          goToLink(history, cardData.link.basic);
        }
      } // Normal case - partners with perks only for paying users
      else {
        goToLink(history, cardData.link);
      }
      setClaimed(true);
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Pocketed Perks</title>
      </Helmet>
      <div className={classes.container}>
        <h2 className={classes.pageTitle}>Featured Perks</h2>

        <div className={classes.featuredGrid}>
          {featuredData.map(data => {
            return (
              <FeaturedPartnerCard
                key={data.title}
                perkClaimHandler={perkClaimHandler}
                {...data}
              />
            );
          })}
        </div>
        <h2 className={classes.pageTitle}>Explore More Partner Perks</h2>

        <div className={classes.cardGrid}>
          {partnerData.map(data => {
            return (
              <PartnerPerksCard
                key={data.title}
                perkClaimHandler={perkClaimHandler}
                {...data}
              />
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default PartnerPerks;
