import React, { useState } from 'react';

import classes from './MaintenanceBanner.module.css';

/**
 * A maintenance banner that will only be shown to the user when needed and will
 * disappear when clicked close.
 * @returns
 */
const MaintenanceBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  if (!showBanner) {
    return <></>;
  }

  return (
    <div className={classes.banner}>
      <div className={classes.bannerContent}>We’ll be completing maintenance &nbsp; 🔧 &nbsp; on June 15th. The website will be temporarily offline.</div>
      <div
        className={classes.bannerButton}
        onClick={() => setShowBanner(false)}
      >
        Close
      </div>
    </div>
  );
};

export default MaintenanceBanner;
