import React, { useContext } from 'react';
import { StoreContext } from '../../../../Store/StoreContext';
import useFilterTypes from '../../../../Assets/Functions/StateSingletonHelper/useFilterTypesGetter';
import * as filterConfigs from '../filterConfigs';
import Loading from '../../../UI/Loading/Loading';
import { InputSection } from '@hellopocketed/react-ui.inputs.input_section';
import FilterCheckboxInput from './FilterSections/FilterCheckboxInput/FilterCheckboxInput';
import FilterRadioInput from './FilterSections/FilterRadioInput/FilterRadioInput';
import FilterToggleSwitch from './FilterSections/FilterToggleSwitch/FilterToggleSwitch';
import BaseFilterBarDropdown from '../BaseFilterBarDropdown/BaseFilterBarDropdown';
import PropTypes from 'prop-types';

import classes from './FilterDropdown.module.css';

/**
 * The button and popup of the filters for AvailableGrants (New, Role Type, etc.).
 * @param {Object} props
 * @returns
 */
const FilterDropdown = ({ isShowing, setIsShowing, signInCallback }) => {
  const { state } = useContext(StoreContext);
  const selectedFilters = state.filterSelection.selected;
  const filterTypes = useFilterTypes(typeof signInCallback === 'function');

  /**
   * Factory helper method for creating specialized sections.
   * @param {*} config - a config object as seen in `filterConfigs.js`.
   * @returns
   */
  const createSection = config => {
    let options = null;
    if (config.filterTypeKeyName) {
      options = filterTypes[config.filterTypeKeyName];
    } else if (config.switchOptions) {
      options = config.switchOptions;
    } else {
      options = config.options;
    }

    // necessary check since `filterTypes` might be loading.
    if (!options) return <Loading key={config.title} />;

    let input;
    switch (config.type) {
      case 'checkbox':
        input = options.map(option => (
          <FilterCheckboxInput
            option={option}
            key={option}
            filter={config.queryParam}
            checked={selectedFilters[config.queryParam].includes(option)}
          />
        ));
        break;

      case 'switch':
        input = (
          <FilterToggleSwitch
            filter={config.queryParam}
            onDisplayName={config.switchDisplayNames.on}
            offDisplayName={config.switchDisplayNames.off}
            onValue={config.switchOptions.on}
            offValue={config.switchOptions.off}
            checked={selectedFilters[config.queryParam]}
          />
        );
        break;

      case 'radio':
        input = options.map(option => (
          <FilterRadioInput
            option={option}
            key={option}
            filter={config.queryParam}
            checked={selectedFilters[config.queryParam] === option}
          />
        ));
        break;

      default:
        input = <Loading />;
    }

    return (
      <InputSection
        title={config.title}
        key={config.title}
        twoColumns
        sectionClasses={classes.section}
      >
        {input}
      </InputSection>
    );
  };

  let contents = (
    <div className={classes.dropdownContent}>
      <h2 className={classes.header}>Filters</h2>

      {filterConfigs.genericFilters.map(createSection)}

      {filterConfigs.hiringRAndDFilters.requirements.includes(selectedFilters.grantTypes) && filterConfigs.hiringRAndDFilters.filters.map(createSection)}

      {filterConfigs.marketingFilters.requirements.includes(selectedFilters.grantTypes) && filterConfigs.marketingFilters.filters.map(createSection)}
    </div>
  );

  return (
    <BaseFilterBarDropdown
      title="More filters"
      isShowing={isShowing}
      setIsShowing={setIsShowing}
      extraDropdownClasses={classes.filterDropdown}
      signInCallback={signInCallback}
    >
      {contents}
    </BaseFilterBarDropdown>
  );
};

FilterDropdown.propTypes = {
  /**
   * Whether to show the content.
   */
  isShowing: PropTypes.bool.isRequired,

  /**
   * A function that can set the `setIsShowing` value.
   */
  setIsShowing: PropTypes.func.isRequired,

  /**
   * On click handler for asking user to sign in. Also functions
   * as a switch to let the component knows the user is NOT signed in yet.
   */
  signInCallback: PropTypes.func,
};

export default FilterDropdown;
