import { useContext, useEffect } from 'react';
import { StoreContext } from '../../../Store/StoreContext';
import instance from '../../Axios/AxiosInstance';
import { filterTypesResponse } from '../../Types/FilterTypesExample';

/**
 * Global check for whether the API is fetching to prevent multiple
 * concurrent fetching.
 */
let isFetching = false;

/**
 * A hook for accessing the filter types. This method is
 * better than accessing `state.filterTypes` directly
 * because it fills out the state if it doesn't already exist.
 * @param {boolean} dummyFilters - whether to get the test/dummy filter
 * values rather than the actual filters associated with a user. This
 * is best used when we want to get filters but the user isn't logged in.
 * @returns
 */
export default function useFilterTypes(dummyFilters = false) {
  const { state, actions } = useContext(StoreContext);

  /**
   * Populate the filterTypes state via the API, which will return only the
   * filters appropriate to this user.
   */
  const fetchFilterTypes = () => {
    // set this so we only trigger api call once across all callers of `useFilterTypes`
    if (isFetching) return;
    isFetching = true;
    instance
      .get('/filter-types/')
      .then(res => {
        let filterTypes = flattenFilterTypes(res.data);

        /**
         * Special property to track whether the filterTypes is a dummy value.
         */
        filterTypes.isDummy = false;
        actions.setFilterTypes(filterTypes); // this would update store => trigger re-render.
        isFetching = false;
      })
      .catch(err => {
        console.log("Unable to get the filter types. It's likely that the user is not logged in to cause this issue. Check logs for more details.");
        console.error(err);
        isFetching = false;
      });
  };

  /**
   * Flatten some filter values in the data.
   * @param {Object} data - the data result of a `/filter-types` query.
   * @returns
   */
  const flattenFilterTypes = data => {
    // flatten the array values (which are object of {"name": value}) into just string.
    // keys gotten from `Types/FilterTypesExample.js`.
    const flattenKeyNames = ['candidate_req', 'project_activity', 'project_length', 'role_length', 'role_type', 'tax-credit', 'grant_types'];

    const copy = { ...data };
    for (let keyName of flattenKeyNames) {
      copy[keyName] = data[keyName].map(obj => {
        if (keyName === 'grant_types') return obj.type;
        return obj.name;
      });
    }

    return copy;
  };

  /**
   * Format the dummy filters and save it into the state.
   */
  const fetchDummyFilters = () => {
    const filterTypes = flattenFilterTypes(filterTypesResponse);
    /**
     * Special property to track whether the filterTypes is a dummy value.
     */
    filterTypes.isDummy = true;
    actions.setFilterTypes(filterTypes); // this would update store => trigger re-render.
  };

  useEffect(() => {
    /**
     * Populate the filters the first time someone ask for them
     */
    if (Object.keys(state.filterTypes).length === 0) {
      dummyFilters ? fetchDummyFilters() : fetchFilterTypes();
    } else if (state.filterTypes.isDummy !== dummyFilters) {
      // the state of dummy changes => we should update it with the appropriate one.
      dummyFilters ? fetchDummyFilters() : fetchFilterTypes();
    }
  });

  return state.filterTypes;
}
