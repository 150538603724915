import React, { useContext, useState } from 'react';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import { StoreContext } from '../../../Store/StoreContext';
import classes from './ConfirmPasswordReset.module.css';
import PocketedLogo from '../../../Components/UI/PocketedLogo/PocketedLogo';
import instance from '../../../Assets/Axios/AxiosInstance';
import InputArrayMapper from '../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper';
import { UserAuthInputElemConfigs } from '../../../Assets/Functions/GrantProfileCreationFormElements/FormElementConfigs';
import { commonPasswords } from '../../../Assets/Functions/CommonPasswords';
import { useParams, withRouter } from 'react-router-dom';

const ConfirmPasswordReset = () => {
  const { actions } = useContext(StoreContext);
  let { path, token } = useParams();

  const [profileState, setProfileState] = useState({
    form: {
      password: { ...UserAuthInputElemConfigs.password },
      confirmPassword: {
        ...UserAuthInputElemConfigs.password,
        label: 'Confirm Password',
        id: 'confirmPassword',
        belowInputContent: (
          <>
            <ul className={classes.passwordHint}>
              <li>Your password must contain at least 8 characters</li>
              <li>Your password can't be a commonly used password</li>
              <li>Your password can't be entirely numeric</li>
            </ul>
          </>
        ),
      },
    },
    formComplete: false,
  });

  const formChange = (key, value) => {
    setProfileState(old => {
      let temp = { ...old };
      temp.form[key].value = value;
      return temp;
    });
  };

  const formSubmit = e => {
    e.preventDefault();
    let formdata = new FormData();
    let p1 = document.getElementById('password').value;
    let p2 = document.getElementById('confirmPassword').value;
    if (p1 !== p2) {
      setProfileState(old => {
        let temp = { ...old };
        temp.form['password'].belowInputContent = <p className={classes.error}>Error: this password does not match the password below</p>;

        return temp;
      });
    } else if (!isNaN(p1)) {
      setProfileState(old => {
        let temp = { ...old };
        temp.form['password'].belowInputContent = <p className={classes.error}>Error: Your password can not be entirely numeric</p>;

        return temp;
      });
    } else if (p1.length < 8) {
      setProfileState(old => {
        let temp = { ...old };
        temp.form['password'].belowInputContent = <p className={classes.error}>Error: Your password must be at least 8 characters long</p>;

        return temp;
      });
    } else if (commonPasswords.includes(p1)) {
      setProfileState(old => {
        let temp = { ...old };
        temp.form['password'].belowInputContent = <p className={classes.error}>Error: Your password must be at not be a commonly used password</p>;
        return temp;
      });
    } else {
      formdata.append('new_password1', p1);
      formdata.append('new_password2', p2);
      instance
        .post('/accounts/reset/' + path + '/' + token + '/', formdata)
        .then(res => {
          setProfileState(old => {
            let res = { ...old, formComplete: true };
            return res;
          });
        })
        .catch(err => {
          // console.log(err.response.status, err.response.data.error)
          if (err.response.status == 422) {
            actions.setPopup({ flag: true, message: 'error' });
          }
        });
    }
  };

  return (
    <form
      className={classes.form}
      onSubmit={e => formSubmit(e)}
    >
      <a href="/">
        <PocketedLogo extraClasses={classes.img} />
      </a>
      <div className={classes.header}>
        <h2>Create New Password</h2>
      </div>
      {profileState.formComplete ? (
        <div className={classes.formCompleteMsg}>
          <div className={classes.success}>
            <p>Password reset complete</p>
            <a href="/auth/signin">Sign in</a>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.content}>
            <InputArrayMapper
              customInputProps={profileState.form}
              containerClassName={classes.inputContainer}
              inputElementsClassName={classes.inputElements}
              formChange={formChange}
            />
          </div>
          <div className={classes.formButtons}>
            <HoverableButton
              presetBtnStyle="dark-purple"
              type="submit"
              id="reset-password-submit-btn"
            >
              Reset Password
            </HoverableButton>
          </div>
        </>
      )}
    </form>
  );
};

export default withRouter(ConfirmPasswordReset);
