import React from 'react';
import PropTypes from 'prop-types';
import CustomInput from '../CustomInput';

/**
 * Loop through an array of input element configs and generate the appropriate elements.
 * Used for components that has a state containing the element configs.
 * For styling, pass in a CSS module `classes` object with the class `inputElements` to
 * style the container of the inputs.
 * @param {*} param0
 * @returns
 */
const InputArrayMapper = ({ customInputProps, containerClassName = '', inputElementsClassName = '', formChange, horizontal = false }) => {
  return (
    <div className={containerClassName}>
      {Object.keys(customInputProps).map(key => {
        let theForm = customInputProps;
        if (theForm[key].show === false) return <div key={key} />; // explicit check for show === false. Default assume is true. See FormElementConfigs/index.js

        return (
          <div
            key={key}
            className={`${inputElementsClassName} ${theForm[key].additionalClass ? theForm[key].additionalClass : ''}`}
          >
            <CustomInput
              inputName={key}
              horizontal={horizontal}
              onChange={formChange}
              {...theForm[key]}
            />
          </div>
        );
      })}
    </div>
  );
};

InputArrayMapper.propTypes = {
  /**
   * A config objects in the FormElementConfigs.
   */
  customInputProps: PropTypes.object.isRequired,

  /**
   * Styling classes as needed for the container surrounding all the
   * input elements.
   */
  containerClassName: PropTypes.string,

  /**
   * Styling classes as needed for the container around each
   * label and input group.
   */
  inputElementsClassName: PropTypes.string,

  /**
   * Callback for when the input changed.
   */
  formChange: PropTypes.func,

  /**
   * Whether to display the inputs in horizontal styling.
   */
  horizontal: PropTypes.bool,
};

export default InputArrayMapper;
