import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import GrantListItem from './GrantListItem/GrantListItem';
import SearchBar from '../Filters/SearchBar/SearchBar';
import { StoreContext } from '../../../Store/StoreContext';
import PaginationBar from './PaginationBar/PaginationBar';
import Loading from '../../UI/Loading/Loading';

import classes from './GrantList.module.css';

const GrantList = ({ itemOnClick }) => {
  const { state } = useContext(StoreContext);

  const lastEntry = state?.grantList[state?.grantList?.length - 1]?.display_list; // keep track of the display_list of the last grant on the CURRENT filterPage
  const firstEntry = state?.grantList[0]?.display_list; // keep track of the display_list of the first grant on the CURRENT filterPage
  const lastRef = useRef(); // keep track of the display_list of the last grant on the PREVIOUS filterPage
  const showRef = useRef(); // based on display_lists, should the banner show (for page separation)

  /**
   * The grant data including various metadata (selected, saved, etc.).
   * This is whichever grant is saved in the state so it works well even for
   * the transition from single grant view to available grants.
   */
  const filterPage = state.filterSelection.page;
  const currentMatch = state.currentMatch;
  const splitList = {
    Focused: 1,
    General: 2,
  };

  let focusedList = (
    <div className={classes.loading}>
      <Loading />
      <p>Loading...</p>
    </div>
  );
  let generalList = <></>;

  // logic to check if display lists match on first entry of new filter page and last entry of previous page
  useEffect(() => {
    if (firstEntry !== lastRef.current) {
      showRef.current = true;
    } else if (lastRef.current === 1 && filterPage > 1) {
      showRef.current = true;
    } else {
      showRef.current = false;
    }
    lastRef.current = lastEntry;
  }, [firstEntry, lastEntry, filterPage]);

  const mapGrantList = (grantList, focused = false) => {
    return grantList.map((grantData, index) => {
      return (
        <GrantListItem
          className={focused && classes.focused}
          title={grantData.grant.name}
          subTitle={grantData.grant.issuer}
          amount={grantData.grant.amount}
          type={grantData.grant.grant_type[0]?.type}
          expiroryDate={grantData.grant.deadlines}
          new={grantData.new}
          saved={grantData.saved}
          liked={grantData.liked}
          selected={currentMatch?.grant?.id === grantData.grant.id}
          id={grantData.id}
          key={grantData.grant.id}
          onClick={itemOnClick}
        />
      );
    });
  };

  // determine which list to render, if dividers should be rendered
  if (!state.grantListLoading) {
    if (state.grantList.length) {
      // focused grant divider (first page only)
      // we are only showing top 10 "focused" grants, so anything beyond page 1 is considered "general" (hence && filterPage === 1 in line below)
      const focusedGrants = state.grantList.filter(grantData => grantData.grant !== undefined && grantData.display_list === splitList.Focused && filterPage === 1);
      if (focusedGrants.length > 0) {
        focusedList = (
          <>
            {filterPage === 1 && (
              <div className={classes.divider}>
                <p className={classes.dividerText}>Get started with these programs!</p>
              </div>
            )}
            {mapGrantList(focusedGrants, true)}
          </>
        );
      } else {
        focusedList = <></>;
      }

      // general grant divider, could be on first page or any other, needed extra 'dividerIncluded' step to check if new filter page is start of list 2
      const generalGrants = state.grantList.filter(grantData => grantData.grant !== undefined && (grantData.display_list === splitList.General || filterPage > 1));
      let dividerIncluded = false; // default not showing
      let generalDivider = (
        <div className={classes.divider}>
          <p className={classes.dividerText}>Jump into these next!</p>
        </div>
      );

      if (generalGrants.length > 0) {
        // check for general list on page 1
        if (filterPage === 1) {
          if (focusedGrants.length > 0) dividerIncluded = true;
        }
        // check for general list on page 2+
        else if (showRef.current && filterPage === 2) dividerIncluded = true;

        generalList = (
          // conditionally render general divider, always render generalGrants if the length > 0
          <>
            {dividerIncluded && generalDivider}
            {mapGrantList(generalGrants)}
          </>
        );
      }
    } else {
      // no grants to show, display default message
      focusedList = (
        <div className={classes.emptyList}>
          <div>
            <p>There are no grants matching the name or filters selected. Try adjusting the search and filters to view more opportunities.</p>
            {state.filterSelection.selected.openGrants && (
              <p className={classes.filterNote}>
                <b>Note:</b> to see closed grants, please adjust the filter under <span>More filters {'>'} Open Grants</span>.
              </p>
            )}
            <p>
              <b>P.S:</b> Adjusting your grant profile settings on your account page should help you discover more funding!
            </p>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={`container ` + classes.container}>
      <SearchBar />
      <div className={classes.cardsBody}>
        {focusedList}
        {generalList}
      </div>
      <PaginationBar />
    </div>
  );
};

GrantList.propTypes = {
  /**
   * Whether the user navigated to this page from the SingleGrantView page.
   */
  fromSingleGrantView: PropTypes.bool,

  /**
   * Extra onclick handler for when a card item is clicked.
   */
  itemOnClick: PropTypes.func,
};

export default GrantList;
