import React, { useEffect, useState } from 'react';
import instance from '../../../../Assets/Axios/AxiosInstance';
import { OutlineHoverableButton } from '@hellopocketed/react-ui.widgets.buttons.outline_hoverable_button';
import closeImg from '../../../../Assets/Images/AvailableGrants/close.png';
import AriaBrianna from '../../../../Assets/Images/AriaBrianna.png';
import goToLink from '../../../../Assets/Functions/ForwardLink';
import { useHistory } from 'react-router-dom';

import classes from './CancellationPopup.module.css';

const DealPopup = ({ dealMessage, buttonMessage, closePopup, portalSubmit }) => {
  const [firstName, setFirstName] = useState(null);
  const history = useHistory();

  useEffect(() => {
    instance.get('/api/users/').then(res => {
      // if no user, we'll still have null
      if (res.data.results.length !== 0) {
        // if user doesn't have a first name, we'll get a ""
        setFirstName(res.data.results[0]['first_name']);
      }
    });
  }, []);

  return (
    <div
      className={classes.dealContainer}
      onClick={e => e.stopPropagation()}
    >
      <img
        src={closeImg}
        alt="Close"
        className={`pocketed-small-icon ${classes.closeIcon} pocketed-hoverable`}
        onClick={() => closePopup()}
      />
      <div className={classes.dealBody}>
        <div className={classes.foundersImage}>
          <img
            alt="Aria & Brianna"
            src={AriaBrianna}
          />
        </div>

        <div className={classes.message}>
          <p>Hi {firstName !== '' ? firstName : 'there'},</p>

          {dealMessage}

          <div className={classes.buttons}>
            <OutlineHoverableButton
              presetBtnStyle="dark-purple"
              presetSize="chunky"
              className={classes.CTAButton}
              onClick={e => {
                portalSubmit(e);
                closePopup();
              }}
            >
              Skip
            </OutlineHoverableButton>

            <OutlineHoverableButton
              presetBtnStyle="pink"
              presetSize="chunky"
              className={classes.CTAButton}
              onClick={e => {
                goToLink(history, 'https://hellopocketed.io/contact/');
                closePopup();
              }}
            >
              {buttonMessage}
            </OutlineHoverableButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealPopup;
