import React from 'react';
import classes from './LoadingPopup.module.css';
import Loading from '../../Loading/Loading';

/**
 * A pop up that appears when a user action requires a loading screen.
 * @param {} props
 * @returns
 */
const LoadingPopup = () => {
  return (
    <div
      className={classes.container}
      onClick={e => e.stopPropagation()}
    >
      <Loading />
    </div>
  );
};

export default LoadingPopup;
