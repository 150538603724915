import { DEFAULT_CATEGORY_NAME } from './Functions/CategoryUtil';
import { ReactComponent as aMarketProject } from './Images/CategoryIcons/A marketing project.svg';
import { ReactComponent as Apply } from './Images/CategoryIcons/Apply.svg';
import { ReactComponent as bipoc } from './Images/CategoryIcons/BIPOC founders.svg';
import { ReactComponent as materials } from './Images/CategoryIcons/Buying materials.svg';
import { ReactComponent as competitions } from './Images/CategoryIcons/Competitions (where you can win money).svg';
import { ReactComponent as covid } from './Images/CategoryIcons/COVID relief.svg';
import { ReactComponent as disaster } from './Images/CategoryIcons/Disaster relief.svg';
import { ReactComponent as environmental } from './Images/CategoryIcons/Environmental sustainability projects.svg';
import { ReactComponent as physical } from './Images/CategoryIcons/Expanding or improving my physical location.svg';
import { ReactComponent as firstCome } from './Images/CategoryIcons/First come first serve grants.svg';
import { ReactComponent as forBeginners } from './Images/CategoryIcons/Grants for beginners.svg';
import { ReactComponent as underFifteen } from './Images/CategoryIcons/Grants in under 15 minutes with Pocketed+.svg';
import { ReactComponent as matchingBusiness } from './Images/CategoryIcons/Grants matching your business.svg';
import { ReactComponent as ceo } from './Images/CategoryIcons/Grants our CEO loves for market expansion.svg';
import { ReactComponent as cmo } from './Images/CategoryIcons/Grants our CMO loves for marketing.svg';
import { ReactComponent as cto } from './Images/CategoryIcons/Grants our CTO loves for building tech stacks.svg';
import { ReactComponent as over500k } from './Images/CategoryIcons/Grants over $500K.svg';
import { ReactComponent as noPayroll } from "./Images/CategoryIcons/Grants that don't require payroll.svg";
import { ReactComponent as takeUnder60 } from './Images/CategoryIcons/Grants that take under 60 minutes.svg';
import { ReactComponent as newBusiness } from './Images/CategoryIcons/Grants to start a new business.svg';
import { ReactComponent as contractors } from './Images/CategoryIcons/Hiring contractors or freelancers (not on payroll).svg';
import { ReactComponent as hiringEmployees } from './Images/CategoryIcons/Hiring a new employee.svg';
import { ReactComponent as onlinePresence } from './Images/CategoryIcons/Improving your online presence.svg';
import { ReactComponent as international } from './Images/CategoryIcons/International expansion.svg';
import { ReactComponent as lgbtq } from './Images/CategoryIcons/LGBTQ+.svg';
import { ReactComponent as moreSustainable } from './Images/CategoryIcons/Making my company more sustainable.svg';
import { ReactComponent as newcomers } from './Images/CategoryIcons/Newcomers.svg';
import { ReactComponent as nonTechProd } from './Images/CategoryIcons/Non-technical product development.svg';
import { ReactComponent as alreadyHired } from "./Images/CategoryIcons/People I've already hired.svg";
import { ReactComponent as purchaseEquipment } from './Images/CategoryIcons/Purchasing new equipment.svg';
import { ReactComponent as researchDev } from './Images/CategoryIcons/Research and development.svg';
import { ReactComponent as staffPicks } from './Images/CategoryIcons/Staff picks.svg';
import { ReactComponent as start } from './Images/CategoryIcons/Start.svg';
import { ReactComponent as startNewBusiness } from './Images/CategoryIcons/Starting a new business.svg';
import { ReactComponent as techProd } from './Images/CategoryIcons/Technical product development.svg';
import { ReactComponent as alreadyPurchased } from "./Images/CategoryIcons/Things I've already purchased.svg";
import { ReactComponent as trainingTeam } from './Images/CategoryIcons/Training your team.svg';
import { ReactComponent as veteran } from './Images/CategoryIcons/Veteran founders.svg';
import { ReactComponent as wageSupport } from './Images/CategoryIcons/Wage support to tackle business projects.svg';
import { ReactComponent as women } from './Images/CategoryIcons/Women founders.svg';

const categoryIcons = {
  'A marketing project': aMarketProject,
  Apply: Apply,
  'BIPOC founders': bipoc,
  'Buying materials': materials,
  'Competitions (where you can win money)': competitions,
  'COVID relief': covid,
  'Disaster relief': disaster,
  'Environmental sustainability projects': environmental,
  'Expanding or improving my physical location': physical,
  'First come first serve grants': firstCome,
  'Grants for beginners': forBeginners,
  'Grants in under 15 minutes with Pocketed+': underFifteen,
  'Grants matching your business': matchingBusiness,
  'Grants our CEO loves for market expansion': ceo,
  'Grants our CMO loves for marketing': cmo,
  'Grants our CTO loves for building tech stacks': cto,
  'Grants over $500K': over500k,
  "Grants that don't require payroll": noPayroll,
  'Grants that take under 60 minutes': takeUnder60,
  'Grants to start a new business': newBusiness,
  'Hiring a new employee': hiringEmployees,
  'Hiring contractors or freelancers (not on payroll)': contractors,
  'Improving your online presence': onlinePresence,
  'International expansion': international,
  'LGBTQ+': lgbtq,
  'Making my company more sustainable': moreSustainable,
  Newcomers: newcomers,
  'Non-technical product development': nonTechProd,
  "People I've already hired": alreadyHired,
  'Purchasing new equipment': purchaseEquipment,
  'Research and development': researchDev,
  'Staff picks': staffPicks,
  Start: start,
  'Starting a new business': startNewBusiness,
  'Technical product development': techProd,
  "Things I've already purchased": alreadyPurchased,
  'Training your team': trainingTeam,
  'Veteran founders': veteran,
  'Wage support to tackle business projects': wageSupport,
  'Women founders': women,
};

/**
 * Get the associated SVG with the icon name.
 * @param {String} name - name of the category.
 * @returns a React SVG component.
 */
export default function getCategoryIcon(name) {
  let svg = categoryIcons[name];
  if (!svg) svg = categoryIcons[DEFAULT_CATEGORY_NAME];
  return svg;
}
