import React from 'react';
import classes from './CustomPopUp.module.css';

const CustomPopUp = props => {
  return (
    <div
      className={classes.container}
      onClick={e => e.stopPropagation()}
    >
      <div className={classes.header}>
        <div className={classes.icon}>
          <img src={props.image} />
        </div>
        <h2>{props.title}</h2>
      </div>
      <div className={classes.message}>
        <p>{props.message}</p>
      </div>
    </div>
  );
};

export default CustomPopUp;
