import { Route, Switch, Redirect } from 'react-router-dom';
import AvailableGrantsView, { GRANT_PAGE_URL } from './Containers/AvailableGrantsView/AvailableGrantsView';
import SavedGrants, { SAVED_PAGE_URL } from './Containers/SavedGrants/SavedGrants';
import Auth from './Containers/Auth/Auth';
import Account from './Containers/Account/Account';
import SingleGrantView from './Containers/SingleGrantView/SingleGrantView';
import PrivateRoute from './HOC/PrivateRoute/PrivateRoute';
import { ErrorPage } from '@hellopocketed/react-ui.pages.error_page';
import HomePage, { HOME_PAGE_URL } from './Containers/HomePage/HomePage';
import GrantBasedFinancing from './Containers/SocialForms/PrivateForms/GrantBasedFinancing/GrantBasedFinancing';
import SemiPrivateRoute from './HOC/SemiPrivateRoute/SemiPrivateRoute';
import CreateAccount from './Containers/Auth/CreateAccount/CreateAccount';
import GrantProfileCreationV2 from './Containers/Auth/GrantProfileCreation-v2/GrantProfileCreation-v2';
import HireGrantWriter, { HIRE_GRANT_WRITER_URL } from './Containers/GetHelp/HireGrantWriter/HireGrantWriter';
import PocketedPerksPage, { POCKETED_PERKS_PAGE_URL } from './Containers/Perks/PocketedPerksPage/PocketedPerksPage';
import ManagedSolution from './Containers/ManagedSolution/ManagedSolution';
import BookAConsultation from './Containers/GetHelp/BookAConsultation/BookAConsultation';
import FinanceAGrant from './Containers/GetHelp/FinanceAGrant/FinanceAGrant';
import OfficeHours from './Containers/GetHelp/OfficeHours/OfficeHours';
import ProductTools from './Containers/Perks/ProductTools/ProductTools';
import PartnerPerks from './Containers/Perks/PartnerPerks/PartnerPerks';
import VentureCapitalFund from './Containers/VClistFund/VentureCapitalFund';

function App() {
  return (
    <Switch>
      <Route
        path="/"
        exact
        component={Auth}
      />
      <PrivateRoute
        path={HOME_PAGE_URL}
        component={HomePage}
      />
      {/* <PrivateRoute path="/pocketed-plans" component={PocketedPlans} /> */}
      <PrivateRoute
        path={GRANT_PAGE_URL}
        component={() => <AvailableGrantsView />}
      />
      <PrivateRoute
        path={SAVED_PAGE_URL}
        component={SavedGrants}
      />
      {/* <PrivateRoute path="/saved-grants" component={() => <AvailableGrants savedGrants pageName="Saved Grants" />} /> */}
      <SemiPrivateRoute
        path="/Auth/:path/:uid"
        component={Auth}
      />
      <SemiPrivateRoute
        path="/Auth/:path"
        component={Auth}
      />
      <SemiPrivateRoute
        path="/activate-account/:path/:token"
        component={Auth}
      />
      <SemiPrivateRoute
        path="/confirm-password-reset/:path/:token"
        component={Auth}
      />
      <PrivateRoute
        path="/account"
        component={Account}
      />
      <Route
        path="/grant"
        component={SingleGrantView}
      />
      {/* <Route path="/form-complete/:path" component={FormComplete} /> */}
      {/* <Route path="/pocketed-plans" component={PocketedPlans} /> */}
      {/* <Route path="/get-funded" component={GetFunded} /> */}
      <Redirect
        path="/pocketed-plus"
        to={'/account/subscription'}
      />
      <PrivateRoute
        path={POCKETED_PERKS_PAGE_URL}
        component={PocketedPerksPage}
      />
      <PrivateRoute
        path="/book-a-consultation"
        component={BookAConsultation}
      />
      <PrivateRoute
        path="/opt-in"
        component={ManagedSolution}
      />
      <PrivateRoute
        path="/grant-based-financing"
        component={GrantBasedFinancing}
      />
      <PrivateRoute
        path={HIRE_GRANT_WRITER_URL}
        component={HireGrantWriter}
      />
      <PrivateRoute
        path="/finance-a-grant"
        component={FinanceAGrant}
      />
      <PrivateRoute
        path="/office-hours"
        component={OfficeHours}
      />
      <PrivateRoute
        path="/venture-capital-fund"
        component={VentureCapitalFund}
      />
      <PrivateRoute
        path="/product-tools"
        component={ProductTools}
      />
      <PrivateRoute
        path="/partner-perks"
        component={PartnerPerks}
      />

      {/* <PrivateRoute path="/advisor-plus" component={AdvisorPlus} /> */}
      {/* <Route path="/experts/:path" component={HireExpert} /> */}
      {/* <Route path="/info/:path" component={PublicForms} /> */}
      {/* <Route path="/checkback" component={CheckBack} /> */}
      {/* <Route path="/checkback-soon" component={CheckBackBetaConfirmed} /> */}
      {/* <Route path="/refer-your-friends" component={ReferFriend} /> */}
      {/* <Route path="/pocketed-funding-announcement" component={FundingAnnouncement} /> */}
      {/* <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/terms-of-use" component={TermsOfUse} /> */}
      <SemiPrivateRoute
        path="/create-account"
        component={CreateAccount}
      />
      <Redirect
        from="/ms-opt-in"
        to="/opt-in"
      />
      <Redirect
        from="/managed-solution"
        to="/opt-in"
      />
      <Redirect
        from="/grant-profile-creation/paid-tier"
        to={HOME_PAGE_URL}
      />
      {/* <SemiPrivateRoute path="/create-account-success" component={CreateAccountSuccess} /> */}
      <SemiPrivateRoute
        path="/grant-profile-creation"
        component={GrantProfileCreationV2}
      />
      <Route
        path="*"
        component={() => <ErrorPage is404 />}
      />
    </Switch>
  );
}

export default App;
