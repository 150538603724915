import React, { useRef, useState } from 'react';
import classes from './SharePopUp.module.css';
import BasePopUp from '../BasePopUp/BasePopUp';
import copyPng from '../../../../Assets/Images/AvailableGrants/copy.png';
import collabPng from '../../../../Assets/Images/AvailableGrants/collaboration.png';

/**
 * A pop up that appears when the user wants to share a grant.
 * @param {} props
 * @returns
 */
const ShareGrantPopUp = props => {
  const [linkIsCopied, setLinkIsCopied] = useState(false);
  const textBoxRef = useRef();
  /**
   * Copy the user's link to their clipboard.
   */
  const copyLink = () => {
    navigator.clipboard.writeText(props.grantLink);
    setLinkIsCopied(true);
  };

  return (
    <BasePopUp imgSrc={collabPng}>
      <h2>Share this grant with a friend!</h2>
      <div className={classes.linkContainer}>
        <input
          className={classes.link}
          ref={textBoxRef}
          value={props.grantLink}
          readOnly
        />
        <img
          src={copyPng}
          alt="copy"
          className={classes.copyIcon}
          onClick={copyLink}
        />
      </div>
      {linkIsCopied ? <p className={classes.success}>Link copied to clipboard!</p> : <p></p>}
    </BasePopUp>
  );
};

export default ShareGrantPopUp;
