import React, { useContext } from 'react';
import Navigation from '../../Components/Navigation/Navigation';
import PopupModal from '../../Components/UI/PopUps/PopupModal';
import LayoutHeader from '../../Components/LayoutComponents/LayoutHeader/LayoutHeader';
import { StoreContext } from '../../Store/StoreContext';
import DiscountBanner from '../../Components/AppicationComponents/DiscountBanner/DiscountBanner';
import { useIsUser } from '../../Assets/Functions/UserPermissions';
import { SideBarLayout } from '@hellopocketed/react-ui.layouts.side_bar_layout';
// import Outofoffice from '../../Containers/OutofofficeBanner/Outofofficebanner';

const Layout = ({ children, isPocketedPlus, isConsultation, isGrantWriter }) => {
  const { state, actions } = useContext(StoreContext);

  // Only show discount banner if user is basic legacy -> use ternary, otherwise a '0' appears when checking multiple conditions
  const isBasicLegacy = useIsUser.BasicLegacy();

  return (
    <>
    {/* <Outofoffice/> */}
    <SideBarLayout
      sideBar={
        <Navigation
          isPocketedPlus={isPocketedPlus}
          isConsultation={isConsultation}
          isGrantWriter={isGrantWriter}
        />
      }
      header={
        <>
          {isBasicLegacy && <DiscountBanner />}
          <div className={'d-sm-block d-none '}>
            <LayoutHeader
              isPocketedPlus={isPocketedPlus}
              isConsultation={isConsultation}
              isGrantWriter={isGrantWriter}
            />
          </div>
        </>
      }
      content={
        <>
          {children}
          {state.showUpgradeToBasicPopUp && (
            <PopupModal
              type="upgradeToBasic"
              backgroundClicked={() => actions.setShowUpgradeToBasicPopUp(false)}
            />
          )}
        </>
      }
    />
    </>
  );
};

export default Layout;
