/*
 * DropDownSelections.js
 *
 * This file contains arrays that populate the dropdown fields used in account creation,
 * located in GrantProfileCreationFormElements.js. Note that not all fields are filled
 * using these arrays - some are populated using GrantProfileOptionsGetter.js.
 * This makes a call to /dropdowns/ instead. To change those dropdowns,
 * go to backend/models/choices/. It's better to set up dropdown options in the backend
 * so we always get the latest data. This file contains options that's unlikely to change.
 *
 * NOTE: Options set in backend/models/choices must then be set in the Django admin page
 * (https://hellopocketed.io/admin/)
 */

// Used by incorporated field
export const incorporated = [
  { label: 'Incorporated', value: true },
  { label: 'Unincorporated', value: false },
];

// Used by the following fields:
// raisedCapitol, accessedFunding, cashRestraint, sredSubmitted, sredConsultant, sredFuture,
// engageRD, taxCredits,
export const yesNoCheck = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

// Only used by nonDilutive field
export const heckYesCheck = [
  { label: 'Heck Yes', value: true },
  { label: 'Not Yet', value: false },
];

export const payrollOptions = [
  { value: 'Wave', label: 'Wave' },
  { value: 'Quickbooks', label: 'Quickbooks' },
  { value: 'ADP', label: 'ADP' },
  { value: 'Freshbooks', label: 'Freshbooks' },
  { value: 'Xero', label: 'Xero' },
  { value: 'Payworks', label: 'Payworks' },
  { value: 'Ceridian', label: 'Ceridian' },
  { value: 'Humi', label: 'Humi' },
  { value: 'Other', label: 'Other' },
];
