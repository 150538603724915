import React, { useState, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import instance from '../../../Assets/Axios/AxiosInstance';
import { StoreContext } from '../../../Store/StoreContext';
import pocketedFriends from '../../../Assets/Images/group-celebrating.png';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import Loading from '../../../Components/UI/Loading/Loading';
import classes from './MMOptIn.module.css';
import goToLink from '../../../Assets/Functions/ForwardLink';

const MMOptIn = props => {
  const { history, existingUser, msUser, setMSUser } = props;
  const { state, actions } = useContext(StoreContext);
  // const [checked, setChecked] = useState(state.grantProfile.opt_in_contract || false);
  const [checked, setChecked] = useState(state.grantProfile.contract_signed || false); // Updated terms for Pocketed Grant Writing
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    if (!state.currentUser.user) {
      instance
        .get('/user/')
        .then(res => {
          if ('isAuthenticated' in res.data && !res.data.isAuthenticated) {
            history.push('/auth/sign-in?forwardTo=/opt-in');
          } else {
            if (!res.data.grantprofile) {
              history.push('/grant-profile-creation');
            } else if (res.data.username) {
              actions.setCurrentUser({ user: res.data.username, loggedIn: true });
            }
          }
        })
        .catch(err => {
          history.push('/auth/sign-in?forwardTo=/opt-in');
          console.log(err);
        });
    } else {
      return;
    }
  }, []);

  const handleCheck = e => {
    actions.createGrantProfile({ value: e.target.checked, target: 'contract_signed' }); // Updated terms for Pocketed Grant Writing
    // actions.createGrantProfile({ value: e.target.checked, target: 'opt_in_contract' })
    setChecked(e.target.checked);
    setChecked2(false);
    setChecked3(false);
  };

  const handleChecked2 = e => {
    setChecked2(e.target.checked);
  };
  const handleChecked3 = e => {
    setChecked3(e.target.checked);
  };
  const handlePatch = (grantprofile, URL, failedURL) => {
    instance
      .patch('/api/grantprofiles/', grantprofile)
      .then(res => {
        setLoading(false);
        Mixpanel.track('Grant Profile Updated', grantprofile);
        history.push(URL);
      })
      .catch(err => {
        setLoading(false);
        Mixpanel.track('Grant Profile Update Failed', grantprofile);
        const data = { grantprofile, err };
        history.push(failedURL);

        //SHOW ERROR
      });
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////                                                                      //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////                          hellopocketed.io                            //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////                                                                      //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleNext = () => {
    setLoading(true);
    setMSUser(true);

    const grantprofile = { contract_signed: true }; // Updated terms for Pocketed Grant Writing
    // const grantprofile = { opt_in_contract: true };
    grantprofile['username'] = state.currentUser.user;

    let nextUrl;
    let failedNextUrl;
    // Logic for users who haven't seen the opt-in screen before (signed up before 02-24-22)
    if (existingUser) {
      nextUrl = '/opt-in';
      failedNextUrl = '/home';
    } else {
      nextUrl = '/grant-profile-creation/paid-tier';
      failedNextUrl = '/auth/login?gpError=true';
    }
    handlePatch(grantprofile, nextUrl, failedNextUrl);
  };

  const handleScroll = e => {
    const clientHeight = e.target.clientHeight;

    const scrollTop = e.target.scrollTop;

    const scrollHeight = e.target.scrollHeight;

    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
      return setScrolled(true);
    }
    return;
  };

  const renderCheckboxes = () => {
    return (
      <div className={classes.heading}>
        <h2 style={{ textAlign: 'center' }}>
          Awesome! We're committed to your funding success. <br /> Please confirm the following:
        </h2>
        <div className={classes.optIn}>
          <br />
          <label htmlFor="optIn2"> By checking this box, I consent to Pocketed applying on behalf of my company to funding programs. </label>
          <input
            type="checkbox"
            style={{ minHeight: '15px', minWidth: '15px' }}
            name="optIn2"
            id="optIn2"
            checked={checked2}
            onChange={e => handleChecked2(e)}
          />
        </div>
        <div className={classes.optIn}>
          <br />
          <label htmlFor="optIn3"> I understand that any information I submit through Pocketed may be shared with granting agencies and similar third parties at Pocketed’s discretion for the purpose of finding me money. </label>
          <input
            type="checkbox"
            style={{ minHeight: '15px', minWidth: '15px' }}
            name="optIn3"
            id="optIn3"
            checked={checked3}
            onChange={e => handleChecked3(e)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      {/* {!existingUser && <h2 className={classes.stepsCounter}>Step 4 of 5 (optional)</h2>} */}

      <div className={classes.content}>
        {!msUser ? (
          <>
            {/* <div className={classes.heading}>
              <h1>Opt in to Pocketed Grant Writing</h1>
              <p>Through our Grant Writing service, Pocketed secures funding for your business - risk free!</p>
              <ul>
                <li>Sign this contract so we can apply on your behalf! &#128077;</li>
                <li>
                  We charge a flat fee based on the number of hires you include in your application:
                  <ul>
                    <li>1 hire: $900</li>
                    <li>2-5 hires: $1,200</li>
                    <li>6-9 hires: $1,950</li>
                    <li>10+ hires: $2,700</li>
                  </ul>
                </li>
                <li>The best part: you only pay if we successfully get you approved! &#127775;</li>
              </ul>
              <p>
                PS - Our fee is due upon{' '}
                <b>
                  <u>approval</u>
                </b>
                . Once you are approved, if you choose not to move forward with the grant for any reason, our fee is still payable. <br /> Scroll down for details and to opt in!
              </p>
            </div> */}
            <div className={classes.heading}>
              <h1>Opt In to Grant Writing Services</h1>
            </div>
            <div
              className={classes.tsAndCs}
              onScroll={handleScroll}
            >
              <div className={classes.tsHeader}>Professional Services Addendum </div>
              <div className={classes.tsContent}>
                <p>
                  THIS PROFESSIONAL SERVICES ADDENDUM (<b>"Professional Services Addendum"</b>) is entered into the earlier of the date the Customer uses the Professional Services or the date Customer agrees to be bound by this Professional Services
                  Addendum, by and between deepND Inc. (<b>"Pocketed"</b>) and Customer, pursuant to the Pocketed Terms of Use (the <b>"Agreement"</b>).
                </p>
                <h2>1. Effect on this Addendum.</h2>
                This Professional Services Addendum is part of, and subject to and governed by the terms and conditions of, the Agreement, as amended from time to time. Capitalized terms used but not defined in this Professional Services Addendum
                have the meanings set forth elsewhere in the Agreement.
                <h2>2. Definitions</h2>
                <div className={classes.inset}>
                  (a) <b>"Application"</b> means an application utilized by the Customer can apply for a Funding Program for which Pocketed has provided the Professional Services; <br />
                  (b) <b>"Awarded Funds"</b> means any Funding Program in which of which Customer has received funds following a submitted Application; <br />
                  (c) <b>"Funder"</b> means the organization, agency, or other entity making the Funding Program available; and <br />
                  (d) <b>"Funding Program"</b> means a sum of money given or made available by a government or other organization for a particular purpose, including grants and other non-dilutive funding. <br />
                </div>
                <h2>3. Services</h2>
                <div className={classes.inset}>
                  (a) Pocketed will use commercially reasonable efforts to deliver the following professional services (the <b>"Professional Services"</b>):
                  <div className={classes.inset}>
                    (i) Review information provided by Customer to assist in determining whether the Customer is eligible for available Funding Programs, and reporting to Customer on specific Funding Programs; <br />
                    (ii) Gather the information required to complete the Application, including engaging in iterative conversations with the Customer; <br />
                    (iii) Assist the Customer in preparing the Application; <br />
                    (iv) Preparing and utilizing an email account for the purpose of the Application (the <b>"Customer Application Email"</b>) and provide user access to Customer; <br />
                    (v) Assist in responding to any follow-up questions by the Funder following the submission of the Application.
                  </div>
                  (b) All Professional Services will be performed remotely. <br />
                  (c) Pocketed will, in its sole discretion, determine the number of personnel and the appropriate skill sets of such personnel necessary to perform the Professional Services. Pocketed's resources may include employees of Pocketed or
                  subcontractors. <br />
                </div>
                <h2>4. Professional Services Fees </h2>
                <div className={classes.inset}>
                  (a) In consideration for the Professional Services performed or provided to Customer under this Professional Services Addendum, Customer will pay to Pocketed a fee of $1,000 on a pre-tax basis (the <b>"Professional Services Fee"</b>
                  ). <br />
                  (b) Customer agrees that the Professional Services Fee will equally be payable to Pocketed in the event Customer is approved for Awarded Funds from Funder for a different Funding Program or in a subsequent Funding Program award
                  cycle using the Application. <br />
                  (c) Customer agrees that the Professional Services Fee will be payable upon written approval from the Funder. Should the Customer subsequently choose not to use the Awarded Funds for any reason, the Professional Services Fee is
                  still payable. <br />
                  (d) For the purpose of clarity, Customer will not owe any Professional Services Fees to Pocketed in the event Customer is not approved for any Funding Programs. <br />
                  (e) Upon the earlier of notification by the Funder or the Customer that Customer has been approved for a Funding Program, Pocketed will prepare and provide to Customer an invoice for the Professional Services Fee. All payments for
                  the Professional Services Fee will be due and payable within 30 days of Pocketed's provision of the invoice to Customer. <br />
                  (f) Any suspension of the Professional Services by Pocketed pursuant to the Agreement will not excuse Customer from its obligation to make payments for the Professional Services Fees. <br />
                </div>
                <h2>5. Customer Dependencies </h2>
                <div className={classes.inset}>
                  (a) Customer will cooperate with Pocketed in the performance of the Professional Services and in the development any Application, including by completing the following activities (the <b>"Customer Dependencies"</b>
                  ): <br />
                  <div className={classes.inset}>
                    (i) Collaborating with Pocketed to provide all information reasonably required in order for Pocketed to provide the Professional Services; <br />
                    (ii) Reviewing the Funding Program to confirm Customer's eligibility; <br />
                    (iii) Providing accurate and complete information to Pocketed, including full disclosure of any information reasonably relevant to the Application; <br />
                    (iv) Finalizing and reviewing the Application to ensure accuracy and completion; <br />
                    (v) Complying with all terms of the Application and the Funding Program, including hiring and recruiting eligible employees if necessary; <br />
                    (vi) Notifying Pocketed immediately upon being approved for any Funding Program following an Application; <br />
                    (vii) Monitoring the Customer Application Email on an ongoing basis; and <br />
                    (viii) Maintaining all accounting and records. <br />
                  </div>
                  (b) Customer acknowledges and agrees that if it has not performed the Customer Dependencies including but not limited to providing provided all necessary cooperation or information to Pocketed, or if Pocketed is otherwise denied or
                  delayed access or information by Customer, then Pocketed will be excused, without liability, from performing any further Professional Services. Such failure to perform the Professional Services resulting from Customer's failures
                  will not relieve Customer from any payment obligations to Pocketed. <br />
                  (c){' '}
                  <b>
                    {' '}
                    <u>Customer acknowledges and agrees that the ultimate responsibility for the Application is with the Customer.</u>{' '}
                  </b>{' '}
                  <br />
                  (d) Upon receiving any confirmation that an Application has been successful, Customer will immediately provide Pocketed all relevant details of the Funding Program, including but not limited to the amount, duration, any further
                  requirements, and any further information required by Pocketed to comply with its obligations. <br />
                </div>
                <h2>6. No Assignment or Transfer of Intellectual Property </h2>
                Without limiting Section 3 of the Agreement, Pocketed retains all ownership and Intellectual Property Rights in and to: (a) the Professional Services; (b) anything developed or delivered by or on behalf of Pocketed under this
                Professional Services Addendum; and (c) any Modifications or enhancements to (a) or (b). All rights not expressly granted by Pocketed to Customer under this Professional Services Addendum are reserved. <br />
                <h2>7. Change Requests. </h2>
                The Parties may agree to changes in the scope of the Professional Services by mutual agreement in writing. <br />
                <h2>8. Limitation of Liability. </h2>
                NOTWITHSTANDING SECTION 9 OF THE AGREEMENT, NO EVENT WILL THE TOTAL AGGREGATE LIABILITY OF EITHER PARTY IN CONNECTION WITH OR UNDER THIS PROFESSIONAL SERVICES ADDENDUM, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE OR GROSS
                NEGLIGENCE), OR OTHERWISE, EXCEED THE AMOUNT OF FEES PAID BY CUSTOMER UNDER THE SPECIFIC APPLICATION OUT OF WHICH THE LIABILITY AROSE. POCKETED'S LIABILITY WILL BE FURTHER LIMITED AS PROVIDED IN THE AGREEMENT. <br />
                <h2>9. Disclaimers </h2>
                Without limiting Section 9 of the Agreement: (a) Pocketed will have no liability for loss or recovery of data or records or for Customer's failure to properly back-up any data or records; and (b) Customer will be responsible for, and
                assumes the risk of, any problems resulting from the content, accuracy, completeness and consistency of Customer Dependencies.
                <br />
                <br />
                <b>
                  Survival: &nbsp; Upon any termination or expiration of this Professional Services Addendum, provisions contained in this Professional Services Addendum that by their nature and context are intended to survive completion of
                  performance, expiration, termination, or cancellation of this Professional Services Addendum will survive.
                </b>
              </div>
            </div>

            <div
              className={classes.optIn}
              style={{ opacity: !scrolled && '0.4', backgroundColor: !scrolled && 'grey' }}
            >
              <br />
              <label htmlFor="optIn">Opt in to Pocketed Grant Writing </label>
              <input
                type="checkbox"
                style={{ minHeight: '15px', minWidth: '15px' }}
                name="optIn"
                id="optIn"
                checked={checked}
                disabled={!scrolled}
                onChange={e => handleCheck(e)}
              />
            </div>

            {checked && renderCheckboxes()}

            {checked2 && checked3 && (
              <div className={classes.btnContainer}>
                {loading ? (
                  <Loading />
                ) : (
                  <Button
                    className={classes.btn}
                    onClick={handleNext}
                  >
                    Finish
                  </Button>
                )}
              </div>
            )}
          </>
        ) : (
          <div className={classes.optedIn}>
            <h2>You have opted in to Pocketed Grant Writing! Check your email for next steps.</h2>
            <br />
            <p className={classes.subheading}>
              Questions? Contact{' '}
              <a href="mailto:hello@hellopocketed.io">
                <u>hello@hellopocketed.io</u>
              </a>
            </p>
            <img
              src={pocketedFriends}
              alt="Pocketed"
            />
            <Button
              className={classes.btn}
              onClick={() => {
                props.history.push('/home');
              }}
            >
              {' '}
              Go to Home Page
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
export default withRouter(MMOptIn);
