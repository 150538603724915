import { payrollOptions, incorporated, yesNoCheck, heckYesCheck } from '../DropDownSelection';
import { allLangs } from '../../translate/translateGlobal';

/**
 * Contains configs for elements related to the grant profiles.
 * Note: some elements are no longer in used but are still kept just in case.
 */
export const GrantProfileInputElemConfigs = {
  betaCode: {
    type: 'text',
    label: (
      <p className="beta-label">
        Have a <b>Referral Code</b>? Great, enter it below for added benefits!
      </p>
    ),
    id: 'beta-code',
    edit: true,
    value: '',
    entryValue: 'referral_code',
  },
  businessName: {
    type: 'text',
    label: 'Company name',
    elementConfig: {
      required: true || '',
      maxLength: 50,
      translate: 'no',
    },
    edit: true,
    value: '',
    entryValue: 'company_name',
  },
  website: {
    type: 'text',
    label: 'Company website',
    edit: true,
    value: '',
    entryValue: 'company_site',
  },
  country: {
    type: 'dropdown',
    label: 'In which country does your company operate?',
    elementConfig: {
      required: true,
    },
    options: [],
    edit: true,
    value: '',
    entryValue: 'country_abbrev',
    reliantInputs: [
      { match: 'CA', display: 'region_ca' },
      { match: 'US', display: 'region_us' },
    ],
  },
  region_ca: {
    type: 'dropdown',
    label: 'In which province is your business headquartered? (Or where are your primary business activities?)',
    elementConfig: {
      required: true,
    },
    options: [],
    edit: true,
    value: '',
    initialValue: '',
    entryValue: 'regions',
  },
  region_us: {
    type: 'dropdown',
    label: 'In which state is your company headquartered?',
    elementConfig: {
      required: true,
    },
    options: [],
    edit: true,
    value: '',
    initialValue: '',
    entryValue: 'regions',
    show: false,
  },
  phone: {
    type: 'phone',
    label: 'Phone number (include your country code)',
    elementConfig: {
      required: true,
    },
    edit: true,
    value: '',
    entryValue: 'phone_number',
  },
  companyStatus: {
    type: 'dropdown',
    label: 'Company status',
    elementConfig: {
      required: true,
    },
    options: [...incorporated], // filled by DropDownSelections
    edit: true,
    value: '',
    entryValue: 'incorporated',
  },
  companySize: {
    type: 'number',
    label: 'Company size',
    elementConfig: {
      required: true,
    },
    edit: true,
    value: '',
    belowLabelText: 'Other than business owners, how many employees are on payroll? ',
    entryValue: 'company_size',
  },
  annualRevenue: {
    type: 'dropdown',
    label: 'Annual revenue',
    elementConfig: {
      required: true,
    },
    options: [],
    edit: true,
    value: '',
    entryValue: 'annual_revenue_name',
  },
  monthlySpending: {
    type: 'number',
    label: 'How much money do you spend per month?',
    elementConfig: {
      required: true,
      min: 0,
    },
    edit: true,
    value: '',
    entryValue: 'monthly_spending',
  },
  registrationDate: {
    type: 'date-picker',
    label: 'Date of incorporation (if not incorporated, business start date)',
    elementConfig: {
      required: true,
    },
    edit: true,
    entryValue: 'registration_date',
    value: '',
  },
  founderType: {
    type: 'dropdown-multiple',
    label: 'Founder demographics',
    elementConfig: {},
    options: [],
    edit: true,
    value: [],
    entryValue: 'founder_demos',
  },
  industries: {
    type: 'dropdown-multiple',
    label: 'Industry',
    elementConfig: {
      required: true,
    },
    options: [],
    edit: true,
    value: [],
    entryValue: 'industry_names',
  },
  businessType: {
    type: 'dropdown',
    label: 'Business type',
    elementConfig: {
      required: true,
    },
    options: [],
    edit: true,
    value: '',
    entryValue: 'business_type_name',
  },
  description: {
    type: 'textarea',
    label: 'Company description',
    elementConfig: {
      rows: 4,
    },
    edit: true,
    value: '',
    entryValue: 'description',
  },
  businessGoals: {
    type: 'dropdown-multiple',
    label: 'What are your key business goals in the next 12 months?*',
    elementConfig: {
      required: true,
    },
    options: [],
    edit: true,
    value: [],
    entryValue: 'bus_goals',
  },
  raisedCapitol: {
    type: 'dropdown',
    label: 'Have you raised dilutive capital?*',
    elementConfig: {
      required: true,
    },
    options: [...yesNoCheck], // filled by DropDownSelections
    edit: true,
    value: '',
    entryValue: 'raised_capital',
    // no longer used but kept just in case
    // reliantInputs: [
    //   { match: 'true', display: 'capitolAmount' },
    //   { match: 'true', display: 'lastRaisedDate' },
    // ],
  },
  capitolAmount: {
    type: 'dropdown',
    label: 'If yes, how much dilutive capital have you raised?',
    elementConfig: {},
    options: [],
    edit: true,
    value: '',
    entryValue: 'capital_amount',
    show: false,
  },
  lastRaisedDate: {
    type: 'date-picker',
    label: 'When did you complete your last raise?*',
    elementConfig: {
      selected: '',
      required: true,
    },
    edit: true,
    value: undefined,
    entryValue: 'last_raise',
    show: false,
  },
  accessedFunding: {
    type: 'dropdown',
    label: 'Have you accessed grant funding before?*',
    elementConfig: {
      required: true,
    },
    options: [...yesNoCheck], // filled by DropDownSelections
    edit: true,
    value: '',
    entryValue: 'accessed_funding',
    // no longer used but kept just in case
    // reliantInputs: [
    //   { match: 'true', display: 'awardedAmount' },
    //   { match: 'true', display: 'awardedTypes' },
    //   { match: 'true', display: 'fundingUsed' },
    //   { match: 'true', display: 'cashRestraint' },
    // ],
  },
  awardedAmount: {
    type: 'dropdown',
    label: 'If yes, approximately how much grant funding have you been awarded?',
    elementConfig: {},
    options: [],
    edit: true,
    value: '',
    entryValue: 'awarded_amount',
    show: false,
  },
  awardedTypes: {
    type: 'dropdown-multiple',
    label: 'What types of grants have you been awarded?',
    elementConfig: {},
    options: [],
    edit: true,
    value: [],
    entryValue: 'awarded_grant_types',
    show: false,
  },
  fundingUsed: {
    type: 'text',
    label: 'Of the grants you have been awarded, approximately how much grant funding have you actually used?',
    elementConfig: {},
    edit: true,
    value: '',
    entryValue: 'funding_used',
    show: false,
  },
  cashRestraint: {
    type: 'dropdown',
    label: 'Have you ever been awarded grant funding, but been unable to use it due to cash restraints?',
    elementConfig: {},
    options: [...yesNoCheck], // filled by DropDownSelections
    edit: true,
    value: '',
    entryValue: 'cash_restraint',
    show: false,
  },
  nonDilutive: {
    type: 'dropdown',
    label: 'If you could access non-dilutive financing (at founder-friendly interest rates) to bridge cash flow constraints so that you can use more grant funding, would you be interested in learning more?*',
    elementConfig: {
      required: true,
    },
    options: [...heckYesCheck], // filled by DropDownSelections
    edit: true,
    value: false,
    entryValue: 'non_dilutive',
  },
  sredSubmitted: {
    type: 'dropdown',
    label: 'Have you accessed R&D tax credits?',
    elementConfig: {},
    options: [...yesNoCheck], // filled by DropDownSelections
    edit: true,
    value: '',
    entryValue: 'sred_submitted',
    // currently not used but kept just in case
    // reliantInputs: [
    //   { match: 'true', display: 'sredDate' },
    //   { match: 'true', display: 'sredValue' },
    //   { match: 'true', display: 'sredConsultant' },
    //   { match: 'true', display: 'sredFuture' },
    //   { match: 'false', display: 'engageRD' },
    // ],
  },
  sredDate: {
    type: 'date-picker',
    label: 'When did you make the R&D claim?*',
    elementConfig: {
      required: true,
    },
    id: 'sredDate',
    edit: true,
    value: '',
    entryValue: 'sred_date',
    show: false,
  },
  sredValue: {
    type: 'text',
    label: 'What was the value of the claim?*',
    elementConfig: {
      required: true,
    },
    id: 'sredDate',
    edit: true,
    value: '',
    entryValue: 'sred_value',
    show: false,
  },
  sredConsultant: {
    type: 'dropdown',
    label: 'Did you use a R&D consultant?*',
    elementConfig: {
      required: true,
    },
    options: [...yesNoCheck], // filled by DropDownSelections
    edit: true,
    value: '',
    entryValue: 'sred_consultant',
    show: false,
  },
  sredFuture: {
    type: 'dropdown',
    label: 'Do you anticipate additional R&D claims in the future?',
    elementConfig: {
      required: true,
    },
    options: [...yesNoCheck], // filled by DropDownSelections
    edit: true,
    value: '',
    entryValue: 'sred_future',
    show: false,
  },
  engageRD: {
    type: 'dropdown',
    label: 'Do you engage in research and development?',
    elementConfig: {},
    options: [...yesNoCheck], // filled by DropDownSelections
    edit: true,
    value: false,
    entryValue: 'engage_rd',
    reliantInputs: [{ match: 'true', display: 'taxCredits' }],
  },
  taxCredits: {
    type: 'dropdown',
    label: 'Would you be interested in learning more about tax credits for which your eligible?',
    elementConfig: {
      required: true,
    },
    options: [...yesNoCheck], // filled by DropDownSelections
    edit: true,
    value: '',
    entryValue: 'tax_credits',
    show: false,
  },
  payrollProvider: {
    type: 'dropdown',
    label: 'What payroll system do you use? (Optional)',
    elementConfig: {
      placeholder: '',
    },
    options: [...payrollOptions], // filled by setDropdownOptions
    value: '',
    entryValue: 'payroll_provider',
    show: true,
  },
  preferredLang: {
    type: 'dropdown',
    label: "What's your preferred language?",
    options: allLangs,
    value: 'English',
    entryValue: 'language',
    elementConfig: {
      translate: 'no',
    },
    dropdownOptionConfig: {
      translate: 'no',
    },
    show: true,
    belowLabelText: '*Our French content are auto-translated by Google Translate.',
  },
};
