import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classes from './PlanSection.module.css';
import { ReactComponent as EnterpriseIcon } from '../../Assets/Images/Icons/Enterprise_Icon.svg';
import { StoreContext } from '../../Store/StoreContext';
import PlanCard from './PlanCard/PlanCard';
import { useIsUser } from '../../Assets/Functions/UserPermissions';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';

/**
 * Card component for each of the types of plans
 */
const PlanSection = ({ checkoutSubmit, portalSubmit, isMonthly, urlPlan }) => {
  const { state } = useContext(StoreContext);
  const userType = state.currentUser.user_type;
  const isPaying = useIsUser.Paying();
  const isBasicLegacy = useIsUser.BasicLegacy();
  const isBasicReferral = useIsUser.BasicReferral();
  const showZeroDollars = isBasicLegacy || isBasicReferral;
  const conciergeMailTo = `mailto:hello@hellopocketed.io?subject=Upgrade to Concierge`;
  let plans = [
    {
      color: 'orange',
      plan: 'basic',
      price: showZeroDollars ? 0 : isMonthly ? 36 : 29,
      subtitle: 'PER MONTH',
      button: (
        <HoverableButton
          presetBtnStyle="orange"
          disabled={userType.toLowerCase() === 'basic'}
          onClick={e => {
            isPaying ? portalSubmit() : checkoutSubmit(e, 'basic', isMonthly);
          }}
        >
          {userType.toLowerCase() === 'basic' ? 'Selected' : isPaying ? 'Change Plan' : 'Get Started'}
        </HoverableButton>
      ),
      details: (
        <>
          <p>Grant Matching</p>
          <p>Explore Funding</p>
          <p>Filtering</p>
          <p>Priority access to grant services</p>
          <p>Partner Perks</p>
          <br />
          <p>
            <a href="https://hellopocketed.io/contact/">Or make us an offer</a>
          </p>
        </>
      ),
    },
    {
      color: 'pink',
      plan: 'plus',
      price: isMonthly ? 61 : 49,
      subtitle: 'PER MONTH',
      // buttonText: isPaying ? 'Change Plan' : 'Get Started',
      button: (
        <HoverableButton
          presetBtnStyle="pink"
          disabled={userType.toLowerCase() === 'plus'}
          onClick={e => {
            isPaying ? portalSubmit() : checkoutSubmit(e, 'plus', isMonthly);
          }}
        >
          {userType.toLowerCase() === 'plus' ? 'Selected' : isPaying ? 'Change Plan' : 'Get Started'}
        </HoverableButton>
      ),
      details: (
        <>
          <p>
            <b>EVERYTHING IN BASIC AND</b>
          </p>
          <p>Pocketed Intelligence</p>
          <p>Pocketed Application Wizard</p>
          <p>Email Alerts</p>
          <p>Discount Consultations</p>
        </>
      ),
    },
    {
      color: 'bright-blue',
      plan: 'concierge',
      price: isMonthly ? 149 : 99,
      subtitle: 'PER MONTH',
      // buttonText: isPaying ? 'Contact Sales' : 'Get Started',
      button: (
        <HoverableButton
          presetBtnStyle="bright-blue"
          disabled={userType.toLowerCase() === 'concierge'}
          onClick={e => {
            isPaying ? window.open(conciergeMailTo) : checkoutSubmit(e, 'concierge', isMonthly);
          }}
        >
          {userType.toLowerCase() === 'concierge' ? 'Selected' : isPaying ? 'Upgrade to Concierge' : 'Get Started'}
        </HoverableButton>
      ),
      details: (
        <>
          <p>
            <b>EVERYTHING IN PLUS AND</b>
          </p>
          <p>Strategy Session with a Grant Specialist</p>
          <p>Grant Roadmap Report</p>
          <p>Ongoing Grant Recommendations</p>
          <p>Access to Concierge Support</p>
          <br />
        </>
      ),
    },
    // {
    //   color: 'dark-purple',
    //   plan: 'enterprise',
    //   Image: EnterpriseIcon,
    //   subtitle: '',
    //   button: (
    //     <HoverableButton
    //       presetBtnStyle="dark-purple"
    //       disabled={userType.toLowerCase() === 'enterprise'}
    //       onClick={e => {
    //         window.open('https://hellopocketed.io/contact');
    //       }}
    //     >
    //       Contact Sales
    //     </HoverableButton>
    //   ),
    //   details: (
    //     <>
    //       <p>Discover grants, tax credits, and incentives for your customers, on demand.</p>
    //       <p>
    //         <b>Add more value to your customers. Make more money for yourself.</b>
    //       </p>
    //       <p>Contact our sales team today to learn more.</p>
    //     </>
    //   ),
    // },
  ];
  // filter out concierge for monthly plans
  if (isMonthly) {
    plans = plans.filter(item => item.plan !== 'concierge');
  }

  return (
    <div className={classes.container}>
      {plans.map(data => (
        <PlanCard
          key={data.plan}
          data={data}
          isMonthly={isMonthly}
          urlPlan={urlPlan}
        />
      ))}
    </div>
  );
};

PlanSection.propTypes = {
  /** Boolean value to distinguish if monthly or yearly prices. */
  isMonthly: PropTypes.bool,

  /** Function to open stripe Checkout */
  checkoutSubmit: PropTypes.func,

  /** Function to open stripe Billing Portal */
  portalSubmit: PropTypes.func,

  /** String value to track the plan selected from our website */
  urlPlan: PropTypes.string,
  // loadingBasic: PropTypes.bool,
  // loadingPlus: PropTypes.bool,
  // loadingConcierge: PropTypes.bool,
};

export default PlanSection;
