import React, { useContext, useState, useEffect } from 'react';
import { StoreContext } from '../../../../Store/StoreContext';
import { useHistory } from 'react-router-dom';
import SidePopup from '../../../UI/PopUps/SidePopup/SidePopUp';
import GrantNotificationItem from './GrantNotificationItem/GrantNotificationItem';
import withTooltip from '../../../../HOC/Tooltip/Tooltip';
import instance from '../../../../Assets/Axios/AxiosInstance';
import { GRANT_PAGE_URL } from '../../../../Containers/AvailableGrantsView/AvailableGrantsView';
import { useScreenWidth, DEVICE_WIDTH } from '@hellopocketed/react-ui.js_utils.core';

import { ReactComponent as BellIcon } from '../../../../Assets/Images/UI/bell.svg';

import classes from './GrantNotificationsBell.module.css';

const GrantNotificationsBell = () => {
  const { state, actions } = useContext(StoreContext);
  const [showNotifications, setShowNotifications] = useState(false);
  const NotifWrapperWithTooltip = withTooltip('span', 'View notifications');
  const unreadNotifCount = state.notificationData?.unread_count || 0;
  const history = useHistory();
  const width = useScreenWidth();

  useEffect(() => {
    if (!state.notificationData && state.currentUser.loggedIn) {
      instance
        .get('/api/notifications/')
        .then(res => actions.setNotificationData(res.data))
        .catch(err => {
          console.log("Can't access API. Might be because user is not logged in. Failing gracefully.");
          console.error(err);
        });
    }
  }, []);

  const onNotificationItemClick = (notification, index) => {
    // set the grant so the user can see it in the `available-grants` page
    actions.setIndividualGrant({ grant: notification.grant });
    actions.setSearchValue(notification.grant.name);
    setShowNotifications(false);

    // update the unread status if needed
    if (notification.status) {
      // update both the front and backend
      instance.patch(`/api/notifications/${notification.id}/`, { status: false }).then(res => {
        let updatedNotifications = { ...state.notificationData };
        updatedNotifications.notifications[index] = res.data;
        updatedNotifications.unread_count--;
        actions.setNotificationData(updatedNotifications);
      });
    }

    history.push(GRANT_PAGE_URL);
  };

  if (width > DEVICE_WIDTH.mobile) {
    return (
      <div className={classes.container}>
        <NotifWrapperWithTooltip className={classes.notifIconWrapper}>
          <BellIcon
            title="Notifications"
            onClick={() => setShowNotifications(!showNotifications)}
            className="pocketed-big-icon"
          />
          {unreadNotifCount > 0 && <div className={classes.unreadNotifMark}>{unreadNotifCount > 9 ? '9+' : unreadNotifCount}</div>}
        </NotifWrapperWithTooltip>

        {showNotifications && (
          <SidePopup extraClasses={classes.popupExtraClass}>
            <div className={classes.notificationContainer}>
              <h2 className={classes.header}>Notifications</h2>
              {state.notificationData.notifications?.map((notification, i) => {
                return (
                  <GrantNotificationItem
                    key={notification.id}
                    notification={notification}
                    index={i}
                    onClick={onNotificationItemClick}
                  />
                );
              })}
            </div>

            <div
              className={classes.background}
              onClick={() => setShowNotifications(false)}
            />
          </SidePopup>
        )}
      </div>
    );
  } else {
    return (
      <div className={classes.container}>
        <>
          <BellIcon
            title="Notifications"
            onClick={() => setShowNotifications(!showNotifications)}
            className="pocketed-big-icon"
          />
          {unreadNotifCount > 0 && <div className={classes.unreadNotifMark}>{unreadNotifCount > 9 ? '9+' : unreadNotifCount}</div>}
        </>

        {showNotifications && (
          <SidePopup extraClasses={classes.popupExtraClass}>
            <div className={classes.notificationContainer}>
              <h2 className={classes.header}>Notifications</h2>
              {state.notificationData.notifications?.map((notification, i) => {
                return (
                  <GrantNotificationItem
                    key={notification.id}
                    notification={notification}
                    index={i}
                    onClick={onNotificationItemClick}
                  />
                );
              })}
            </div>

            <div
              className={classes.background}
              onClick={() => setShowNotifications(false)}
            />
          </SidePopup>
        )}
      </div>
    );
  }
};

export default GrantNotificationsBell;
