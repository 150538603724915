import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../Layout/Layout';
import CircularImage from '../../Components/UI/CircularImage/CircularImage';

import classes from './StaticPage.module.css';
import FAQSection from '../../Components/AppicationComponents/FAQComponents/FAQSection/FAQSection';

/**
 * A standard StaticPage that can be used as a HOC. Its CSS file
 * can also be used by its children to style subsections within itself.
 * @param {} props
 * @returns
 */
const StaticPage = ({ title, imgSrc, shiftXValue, shiftYValue, form, FAQ, children }) => {
  return (
    <>
      {form ? (
        <>
          <div className={classes.containerForm}>
            <div>
              <div className={`${classes.details} ${classes.smallPadding}`}>
                <h2>{title}</h2>
                {children}
              </div>
              <CircularImage
                imgSrc={imgSrc}
                shiftXValue={shiftXValue}
                shiftYValue={shiftYValue}
                extraClasses={classes.img}
              />
            </div>
            <div>{form}</div>
          </div>
          {FAQ ? (
            <div className={classes.faq}>
              <FAQSection data={FAQ} />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div className={classes.container}>
            <div className={classes.details}>
              <h2>{title}</h2>
              {children}
            </div>
            <CircularImage
              imgSrc={imgSrc}
              shiftXValue={shiftXValue}
              shiftYValue={shiftYValue}
              extraClasses={classes.img}
            />
          </div>
          {FAQ ? (
            <div className={classes.faq}>
              <FAQSection data={FAQ} />
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

StaticPage.propTypes = {
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,

  /* HubSpot form info - formId, portalId */
  form: PropTypes.element,

  /* FAQ Section data */
  FAQ: PropTypes.array,

  /* A shift percentage value that will be passed down to the <CircularImage> used in this component.*/
  shiftXValue: PropTypes.number,
  shiftYValue: PropTypes.number,
};

export default StaticPage;
