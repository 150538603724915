import React from 'react';
import PropTypes from 'prop-types';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';

import classes from './CallToAction.module.css';

/**
 * A customizable call to action.
 * @param {} props
 * @returns
 */
const BaseCallToAction = ({ title, subtitle, btnTxt, btnUrl, extraClasses = '' }) => {
  return (
    <div className={`${classes.container} pocketed-rounded-card ${extraClasses}`}>
      <h2>{title}</h2>
      <p className={classes.subtitle}>{subtitle}</p>
      <HoverableButton
        href={btnUrl}
        presetBtnStyle="pink"
      >
        {btnTxt}
      </HoverableButton>
    </div>
  );
};

BaseCallToAction.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  btnTxt: PropTypes.string,
  btnUrl: PropTypes.string,
  /**
   * Extra styling classes if needed.
   */
  extraClasses: PropTypes.string,
};

/**
 * A pre-made version of the CallToAction for upgrading
 * to Pocketed+.
 * @returns
 */
const UpgradeCallToAction = props => {
  return (
    <BaseCallToAction
      title="Want to win more funding?"
      subtitle="Upgrade to Pocketed+ to help you succeed!"
      btnTxt="Upgrade to Pocketed+"
      btnUrl="/account/subscription"
      {...props}
    />
  );
};

UpgradeCallToAction.propTypes = {
  /**
   * Extra styling classes if needed.
   */
  extraClasses: PropTypes.string,
};

/**
 * A pre-made version of the CallToAction for booking
 * a consultation.
 * @returns
 */
const BookConsultationCallToAction = props => {
  return (
    <BaseCallToAction
      title="Want to win more funding?"
      subtitle="Book a consultation to get started with grants!"
      btnTxt="Book a Consultation"
      btnUrl="/book-a-consultation"
      {...props}
    />
  );
};

BookConsultationCallToAction.propTypes = {
  /**
   * Extra styling classes if needed.
   */
  extraClasses: PropTypes.string,
};

export { UpgradeCallToAction, BookConsultationCallToAction };
