import React, { useContext } from 'react';
import BasePopUp from '../BasePopUp/BasePopUp';
import hiringPng from '../../../../Assets/Images/group-celebrating.png';
import { PocketedColoredLogoColoredText, PocketedPlusColoredLogoColoredText } from '@hellopocketed/react-ui.images.pocketed_logos';
import { StoreContext } from '../../../../Store/StoreContext';
import { OutlineHoverableButton } from '@hellopocketed/react-ui.widgets.buttons.outline_hoverable_button';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';

/**
 * A pop up that appears when the user subscribes to either P+ or Concierge.
 * @param {} props
 * @returns
 */
const SubscribedPopup = props => {
  const { actions } = useContext(StoreContext);
  const { concierge, plus } = props;
  const userType = concierge ? 'concierge' : plus ? 'plus' : 'basic';
  const closePopup = () => {
    Mixpanel.track("Checkout - 'Subscribed'", { status: 'Success', msg: 'Subscribed popup closed', userType: userType });
    actions.setSubscribedPopup({ flag: false });
  };
  return (
    <BasePopUp
      imgSrc={concierge ? hiringPng : plus ? PocketedPlusColoredLogoColoredText : PocketedColoredLogoColoredText}
      imgSize={!concierge && 'small'}
    >
      <h2 style={{ marginBottom: '1em' }}>Welcome to {concierge ? 'Pocketed Concierge' : plus ? 'Pocketed+' : 'Pocketed'}!</h2>
      {concierge ? (
        <OutlineHoverableButton
          presetBtnStyle="pink"
          onClick={closePopup}
          target="_blank"
          href="https://meetings.hubspot.com/michael-zhang3/pocketed-concierge-strategy-session"
        >
          Book Your First Strategy Session
        </OutlineHoverableButton>
      ) : (
        <OutlineHoverableButton
          onClick={closePopup}
          presetBtnStyle="clear"
        >
          Start Exploring
        </OutlineHoverableButton>
      )}
    </BasePopUp>
  );
};

export default SubscribedPopup;
