import React, { useEffect, useRef, useContext } from 'react';
import { StoreContext } from '../../../../Store/StoreContext';
import classes from './GrantSavedPopup.module.css';

const GrantSavedPopup = () => {
  const { state, actions } = useContext(StoreContext);
  const fadeRef = useRef();

  // start the fade out animation only when the grantSavedPopUp is true
  useEffect(() => {
    if (!state.grantSavedPopUp) return;

    let currentOpacity = 95;
    fadeRef.current.opacity = currentOpacity + '%';
    let interval;

    setTimeout(() => {
      interval = setInterval(() => {
        if (currentOpacity > 0 && fadeRef.current !== null) {
          fadeRef.current.style.opacity = currentOpacity + '%';
          currentOpacity--;
        } else {
          clearInterval(interval);
          actions.setGrantSavedPopUp(false);
        }
      }, 10);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [state.grantSavedPopUp]);

  return (
    state.grantSavedPopUp && ( // only show if we are triggered via state
      <div className={classes.container + ' ' + classes.fadeOut}>
        <div
          className={classes.message}
          ref={fadeRef}
        >
          Grant Saved!
        </div>
      </div>
    )
  );
};

export default GrantSavedPopup;
