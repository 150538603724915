import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { StoreContext } from '../../Store/StoreContext';
import instance from '../../Assets/Axios/AxiosInstance';
import { GRANT_PAGE_URL } from '../../Containers/AvailableGrantsView/AvailableGrantsView';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import { formatAmount } from '@hellopocketed/react-ui.js_utils.core';

import { OutlineHoverableButton } from '@hellopocketed/react-ui.widgets.buttons.outline_hoverable_button';
import SaveGrantIcon from '../UI/SaveGrantIcon/SaveGrantIcon';

import classes from './GrantTrackingCard.module.css';

const GrantTrackingCard = ({ grantData, callback, className }) => {
  const { actions } = useContext(StoreContext);
  let history = useHistory();

  const match_id = grantData.id;
  const title = grantData.grant.name;
  const fundedBy = grantData.grant.issuer;
  const amount = grantData.grant.amount;
  const tracking = grantData.tracking;
  const saved = grantData.saved;

  let trackingObject;

  if (tracking == null) {
    trackingObject = {
      has_applied: false,
      is_approved: false,
      is_using: false,
      missed_grant: false,
    };
  } else {
    trackingObject = tracking;
  }

  const Handler = (e, updatedField) => {
    e.stopPropagation();

    const updatedTracking = {
      ...trackingObject,
      ...updatedField,
    };

    // Set up mapping for 4 different mixpanel Events - one for each button on the card
    let eventName = 'Grant Tracking';
    let eventMapping = {
      has_applied: 'Has Applied',
      is_approved: 'Is Approved',
      is_using: 'Is Using',
      missed_grant: 'Missed Grant',
    };
    // add updatedField key name to the event name
    eventName += ' - ' + eventMapping[Object.keys(updatedField)[0]];

    instance
      .post('/api/grant-tracking/', { matches: match_id, ...updatedTracking })
      .then(res => {
        callback(false); // update store using callback from SavedGrants, loading=false
        Mixpanel.track(eventName, {
          value: Object.values(updatedField)[0],
          grantname: grantData.grant.name,
        });
      })
      .catch(err => console.error(err));
  };

  /**
   * Display the grant's information on available grants page when user clicks.
   */
  const showGrantDetails = (e, searchValue) => {
    e.stopPropagation();

    // selects the first element after searching, but if there is no search result returned (i.e. searching fails), throw an error
    try {
      actions.setCurrentMatch(grantData);
    } catch (err) {
      console.error(err);
    }

    actions.setSearchValue(searchValue);
    history.push(GRANT_PAGE_URL);
  };

  return (
    <div
      title="More Details"
      className={`pocketed-rounded-card ${classes.container} `}
      onClick={e => {
        showGrantDetails(e, title);
      }}
    >
      <div className={classes.headerRow}>
        <h3 className={classes.title}>{title}</h3>
        <SaveGrantIcon
          className={classes.icon}
          id={match_id}
          saved={saved}
        />
      </div>
      <div className={classes.headerRow}>
        <p className={classes.fundedBy}>Funded by: {fundedBy}</p>
      </div>
      <div
        className={classes.amountRow}
        title=""
      >
        <p className={classes.amount}>{formatAmount(amount)}</p>
        <OutlineHoverableButton
          className={classes.missedButton}
          active={trackingObject.missed_grant}
          onClick={e => {
            Handler(e, { missed_grant: !trackingObject.missed_grant });
          }}
          presetBtnStyle={'lightPink'}
          fontSize={'10px'}
        >
          I missed it!
        </OutlineHoverableButton>
      </div>
      <div
        className={classes.buttonContainer}
        title=""
      >
        <div className={classes.row}>
          <OutlineHoverableButton
            className={classes.trackingButton}
            active={trackingObject.has_applied}
            onClick={e => {
              Handler(e, { has_applied: !trackingObject.has_applied });
            }}
            presetBtnStyle={'lightPink'}
          >
            I Applied
          </OutlineHoverableButton>
        </div>
        <div className={classes.row}>
          <OutlineHoverableButton
            className={classes.trackingButton}
            active={trackingObject.is_approved}
            onClick={e => {
              Handler(e, { is_approved: !trackingObject.is_approved });
            }}
            presetBtnStyle={'lightPink'}
          >
            I'm Approved
          </OutlineHoverableButton>
        </div>
        <div className={classes.row}>
          <OutlineHoverableButton
            className={classes.trackingButton}
            active={trackingObject.is_using}
            onClick={e => {
              Handler(e, { is_using: !trackingObject.is_using });
            }}
            presetBtnStyle={'lightPink'}
          >
            I'm Using the Funds!
          </OutlineHoverableButton>
        </div>
      </div>
    </div>
  );
};

export default GrantTrackingCard;
