import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import img from '../../../../Assets/Images/UI/trial_modal_computer.png';
import instance from '../../../../Assets/Axios/AxiosInstance';
import { StoreContext } from '../../../../Store/StoreContext';
import { useHistory } from 'react-router-dom';
import classes from './TrialModal.module.css';
import { useIsUser } from '../../../../Assets/Functions/UserPermissions';

const TRIAL_MODAL_SEEN_LIMIT = 3;

const TrialModal = () => {
  const { state, actions } = useContext(StoreContext);
  const history = useHistory();
  const isBasicOrPlusReferral = useIsUser.BasicReferral() || useIsUser.PlusReferral();

  return (
    <Modal
      show={state.trialModal && state.currentUser.popup_seen_count < TRIAL_MODAL_SEEN_LIMIT && !isBasicOrPlusReferral}
      onHide={() => {
        actions.setTrialModal(false);
        instance.post(`/free-trial`, { popup_seen_count: state.currentUser.popup_seen_count + 1 }).catch(err => {
          console.error(err);
        });
      }}
      dialogClassName={classes.modalDialog}
      contentClassName={classes.modalContent}
      className={classes.modal}
      centered
    >
      <Modal.Body className={classes.modalBody}>
        <div className={classes.image}>
          <img
            src={img}
            alt="Enjoy your 14 days trial"
            className={classes.img}
            onClick={() => {
              actions.setTrialModal(false);
              instance.post(`/free-trial`, { popup_seen_count: state.currentUser.popup_seen_count + 1 }).catch(err => {
                console.error(err);
              });
              history.push('/available-grants');
            }}
          />
        </div>
        <a
          style={{ paddingBottom: '1em', textAlign: 'center' }}
          href="/account/subscription"
        >
          Ready for Pocketed Plus or Concierge? Click here!
        </a>
      </Modal.Body>
    </Modal>
  );
};

export default TrialModal;
