import apollo from '../../../Assets/Images/PartnerLogos/Apollo Insurance Logo.png';
import bonsai from '../../../Assets/Images/PartnerLogos/Bonsai Growth Logo.png';
import canWCC from '../../../Assets/Images/PartnerLogos/CANWCC_Logo_Vert.png';
import cansulta from '../../../Assets/Images/PartnerLogos/Cansulta Logo.png';
import clickup from '../../../Assets/Images/PartnerLogos/ClickUp Logo.png';
import deckLinks from '../../../Assets/Images/PartnerLogos/DeckLinks_New_Logo.png';
import entrepreneursHQ from '../../../Assets/Images/PartnerLogos/Entreprenuers HQ Logo.png';
import ey from '../../../Assets/Images/PartnerLogos/EY Logo.png';
import freshworks from '../../../Assets/Images/PartnerLogos/Freshworks.png';
import goodlawyer from '../../../Assets/Images/Logos/goodlawyer.png';
import jeeves from '../../../Assets/Images/PartnerLogos/Jeeves Logo.png';
import okr from '../../../Assets/Images/PartnerLogos/OKR Logo.png';
import ontop from '../../../Assets/Images/PartnerLogos/Ontop_Logo_Main.png';
import peachscore from '../../../Assets/Images/PartnerLogos/Peachscore Logo.png';
import rewind from '../../../Assets/Images/PartnerLogos/REWIND.png';
import safeguard from '../../../Assets/Images/PartnerLogos/Safeguard Global Logo.png';
import venbridge from '../../../Assets/Images/PartnerLogos/Venbridge Logo.png';
import { withTooltip } from '@hellopocketed/react-ui.widgets.tooltip';

let CopyTooltip = withTooltip('span', 'Click to copy');
const copyHandler = () => {
  navigator.clipboard.writeText('CAN50WCC');
};

export const featuredData = [
  {
    title: 'Ernst & Young',
    details: (
      <>
        <p>EY provides consulting, assurance, tax and transaction services that help solve our client's toughest challenges and build a better working world for all.</p>
      </>
    ),
    image: ey,
    link: 'https://welcome.hellopocketed.io/sred-referral-2',
    message: <p>Please sign up through our partner’s website to automatically apply your perk!</p>,
  },
  {
    title: 'Bonsai ',
    details: (
      <>
        <p>Non-dilutive funding solutions, that leverages Tax Credits, Grants and Contracts for companies to receive immediate cashflow without giving up Equity.</p>
      </>
    ),
    image: bonsai,
    message: (
      <p>
        Perk Claimed! We're pumped to get you started with <b>Bonsai</b>! An intro is coming your way shortly.
      </p>
    ),
    email: 'bhavik@bonsaigrowth.ca',
  },
];

export const partnerData = [
  {
    title: 'Apollo',
    details: (
      <>
        <p>Protect your business with coverage from Canada's leading InsurTech.</p>
        <ul>
          <li>Get a Free Quote Today</li>
        </ul>
      </>
    ),
    image: apollo,
    link: 'https://info.apollocover.com/hellopocketed',
  },
  {
    title: 'Canadian Womens Chamber of Commerce',
    details: (
      <>
        <p>The first – and only – Chamber of Commerce for women-identified and non-binary business owners in Canada.</p>
        <ul>
          <li>
            50% off a premium membership with code{' '}
            <CopyTooltip>
              <b onClick={() => copyHandler()}>CAN50WCC</b>
            </CopyTooltip>
            .
          </li>
        </ul>
      </>
    ),
    image: canWCC,
    link: 'https://canwcc.ca/membership',
  },
  {
    title: 'Cansulta',
    details: (
      <>
        <p>Connect with subject matter experts, from the comfort of your own office.</p>
        <ul>
          <li>Waive $19 Booking Fees (CAD)</li>
        </ul>
      </>
    ),
    image: cansulta,
    email: 'concierge@cansulta',
  },
  {
    title: 'ClickUp',
    details: (
      <>
        <p>Manage tasks, docs, goals, and projects in Clickup to be more productive.</p>
      </>
    ),
    image: clickup,
    link: 'https://clickup.com/?fp_ref=b1shv',
  },
  {
    title: 'DeckLinks',
    details: (
      <>
        <p>Control your sales process with trackable and customizable B2B sales presentations, in a single link.</p>
        <ul>
          <li>10% Off for Pocketed Users</li>
        </ul>
      </>
    ),
    image: deckLinks,
    // Different discount for Basic/Plus Pocketed users
    link: { basic: 'https://dashboard.decklinks.com/register/deck-links?promo=B4XDQqH0Dqgw', premium: 'https://dashboard.decklinks.com/register/deck-links?promo=MAV9KwHZ9j1w' },
  },
  {
    title: 'Entrepreneurs HQ',
    details: (
      <>
        <p> Acquisition and authority through virtual events, online summits, and workshops.</p>
        <ul>
          <li>
            Free Access to the <i>Ultimate Workshop Checklist</i>
          </li>
        </ul>
      </>
    ),
    image: entrepreneursHQ,
    link: 'https://benwalter--entrepreneurshq.thrivecart.com/quick-workshop-win/',
  },
  {
    title: 'Freshworks',
    details: (
      <>
        <p>Freshworks makes ridiculously easy-to-use marketing, sales, support and IT solutions.</p>
        <ul>
          <li>Up to $10,000 Credit for Freshworks Products (USD)</li>
        </ul>
      </>
    ),
    image: freshworks,
    link: 'https://www.freshworks.com/partners/startup-program/pocketed/',
  },
  {
    title: 'Goodlawyer',
    details: (
      <>
        <p>Comprehensive legal support, from incorporation to financing.</p>
        <ul>
          <li>$100 Credit for Goodlawyer Services (CAD)</li>
          <li>Free Legal Advice Session OR</li>
          <li>50% Off Your First Contract Review</li>
        </ul>
      </>
    ),
    image: goodlawyer,
    link: 'https://www.goodlawyer.ca/partner/pocketed',
  },
  {
    title: 'Jeeves',
    details: (
      <>
        <p>The all-in-one credit card for your startup company. No fees, no hassles.</p>
        <ul>
          <li>$200 Credit on Approved Limits Up To $100k (CAD)</li>
          <li>$325 Credit on Approved Limits Over $100k (CAD)</li>
        </ul>
      </>
    ),
    image: jeeves,
    email: 'kevin@tryjeeves.com',
  },
  {
    title: 'OKR Financial',
    details: (
      <>
        <p>Bridge the gap between your grant funding timelines and your immediate funding needs.</p>
      </>
    ),
    image: okr,
    email: 'megansutherland@okrfinancial.com',
  },
  {
    title: 'Ontop',
    details: (
      <>
        <p>Hire internationally, pay legally, and meet compliance needs.</p>
        <ul>
          <li>$150 Platform Credit (USD)</li>
        </ul>
      </>
    ),
    image: ontop,
    email: 'klopez@getontop.com',
  },
  {
    title: 'Peachscore',
    details: <p>Business intelligence to help you continuously monitor and benchmark your growth.</p>,
    image: peachscore,
    link: 'https://peachscore.com/pricing/?via=ben',
  },
  {
    title: 'Rewind',
    details: (
      <>
        <p>Back up, copy, and restore your SaaS and cloud data.</p>
        <ul>
          <li>7 Day Free Trial of Rewind Backups</li>
        </ul>
      </>
    ),
    image: rewind,
    link: 'https://rewindio.grsm.io/uag21b0ywo18',
  },
  {
    title: 'Safeguard Global',
    details: (
      <>
        <p>Hire anywhere, pay everywhere. Enabling business success through efficient payroll, onboarding, and expense management.</p>
      </>
    ),
    image: safeguard,
    // TODO: PARTNER REFERRAL LANDING PAGE IS NOT COMPLETE
    link: 'https://safeguardglobal.referralrock.com/l/1BENWALTER84/',
  },
  {
    title: 'Venbridge',
    details: (
      <>
        <p>Build great companies through non-dilutive venture debt, SR&ED Financing, SaaS MRR Loans, and tax credit consulting services.</p>
      </>
    ),
    image: venbridge,
    email: 'nmacintosh@venbridge.com',
  },
];
