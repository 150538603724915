import React, { useState, useEffect, useContext } from 'react';
import classes from './CreateAccountForm.module.css';
import { ArrowUpRightSquare } from 'react-bootstrap-icons';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { commonPasswords } from '../../../../Assets/Functions/CommonPasswords';
import instance from '../../../../Assets/Axios/AxiosInstance';
import { StoreContext } from '../../../../Store/StoreContext';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import LangSwitch from '../../../../Components/LangSwitch/LangSwitch';

const CreateAccountForm = () => {
  const { actions } = useContext(StoreContext);
  const [validators, setValidators] = useState({
    length: false,
    lettersAndNumbers: false,
    uncommonPassword: false,
  });
  const [userExists, setUserExists] = useState(false);
  const [invalidReferral, setInvalidReferral] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [referralCode, setReferralCode] = useState(null);

  let history = useHistory();

  useEffect(() => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const refCode = params.get('referral_code');
    if (refCode) {
      if (refCode.toLowerCase() === 'collision') {
        history.push('/create-account?referral_code=ForumSummitYVR');
        setReferralCode('ForumSummitYVR');
      } else {
        setReferralCode(refCode);
      }
    }
  }, [setReferralCode]);

  const passwordValidator = e => {
    const value = e.target.value;
    const length = value.length >= 8;
    setValidators({
      length: length,
      lettersAndNumbers: value.match(/^(?=.*[a-zA-Z])(?=.*[0-9])/),
      uncommonPassword: !commonPasswords.includes(value) && length,
    });
  };

  const formSubmit = e => {
    setIsRegistering(true);
    e.preventDefault();
    if (validators.length && validators.lettersAndNumbers && validators.uncommonPassword) {
      let data = {
        username: e.target.email.value,
        email: e.target.email.value,
        first_name: e.target.fname.value,
        last_name: e.target.lname.value,
        password1: e.target.pword.value,
        password2: e.target.pword.value,
        referral_code: e.target.rCode.value.toUpperCase(),
      };
      let formdata = new FormData();
      formdata.append('email', e.target.email.value);

      instance
        .post('/user-exists/', formdata)
        .then(res => {
          console.log('Success', res);
          if (!res.data['user-exists']) {
            return instance
              .post('/register/', data)
              .then(res => {
                setIsRegistering(false);
                actions.setCurrentUser({ user: res.data.email, user_type: res.data.user_type, user_subtype: res.data.user_subtype });
                Mixpanel.track('Sign Up', { status: 'Success' });
                // FACEBOOK PIXEL - AD TRACKING
                const fbPixelObj = { ...data, status: 'signup success' };
                if (window.fbq) window.fbq('track', 'CompleteRegistration', fbPixelObj);
                if (window.lintrk) window.lintrk('track', { conversion_id: 6810052 });
                let url = '/grant-profile-creation';
                history.push(url);
              })
              .catch(err => {
                Mixpanel.track('Sign Up', { status: 'Failed', error: err });
                const fbPixelObj = { ...data, status: 'signup failed' };
                if (window.fbq) window.fbq('track', 'CompleteRegistration', fbPixelObj);

                if ('invalid-referral' in err.response.data && err.response.data['invalid-referral']) {
                  setInvalidReferral(true);
                  setIsRegistering(false);
                } else {
                  setIsRegistering(false);
                  setGeneralError(true);
                }
                console.log('register failed', err);
              });
          }
        })

        .catch(err => {
          if ('user-exists' in err.response.data && err.response.data['user-exists']) {
            setUserExists(true);
            setIsRegistering(false);
          } else {
            setIsRegistering(false);
            setGeneralError(true);
            console.log('Something went wrong');
          }
        });
    } else {
      console.log('validation failed');
    }
  };

  return (
    <form
      className={classes.container}
      onSubmit={formSubmit}
    >
      <div className={classes.nameContainer}>
        <div className={classes.inputGroup}>
          <label htmlFor="fname">First Name</label>
          <input
            type="text"
            id="fname"
            required
          />
        </div>
        <div className={classes.inputGroup}>
          <label htmlFor="lname">Last Name</label>
          <input
            type="text"
            id="lname"
            required
          />
        </div>
      </div>
      <div className={classes.inputGroup}>
        <label htmlFor="email">Company Email</label>
        <input
          type="email"
          id="email"
          required
          onFocus={() => setUserExists(false)}
        />
        <div className={classes.errorMessage}>{userExists && <p>&#x274c; This email is already registered with our platform </p>}</div>
      </div>
      <div className={classes.inputGroup}>
        <label htmlFor="rCode">Referral Code</label>
        <input
          type="text"
          id="rCode"
          placeholder="Optional"
          defaultValue={referralCode || ''}
          onFocus={() => setInvalidReferral(false)}
        />
        <div className={classes.errorMessage}>{invalidReferral && <p>&#x274c; Please enter a valid referral code.</p>}</div>
      </div>
      <div className={classes.inputGroup}>
        <label htmlFor="pword">Password</label>
        <input
          type="password"
          id="pword"
          onChange={passwordValidator}
          required
        />
        <div className={classes.passwordValidators}>
          <p className={validators.length ? classes.valid : classes.invalid}>8 characters min.</p>
          <p className={validators.lettersAndNumbers ? classes.valid : classes.invalid}>Letters and Numbers</p>
          <p className={validators.uncommonPassword ? classes.valid : classes.invalid}>Uncommon Password</p>
        </div>
      </div>
      {/* General error message for when registration fails.*/}
      <div className={classes.errorMessage}>{generalError && <p>Something went wrong, please check that all fields are correct.</p>}</div>
      <div className={classes.submitContainer}>
        <div>
          <input
            type="checkbox"
            required
          />
          <label>
            I Accept the Terms of Service{' '}
            <a
              href="https://hellopocketed.io/terms-of-service"
              target="_blank"
              rel="noreferrer"
            >
              <ArrowUpRightSquare />
            </a>
          </label>
        </div>

        {validators.length && validators.lettersAndNumbers && validators.uncommonPassword ? (
          <>
            {isRegistering ? (
              <Spinner
                animation="border"
                role="status"
              />
            ) : (
              <button type="submit">Sign Up</button>
            )}
          </>
        ) : (
          <button
            disabled
            type="submit"
          >
            Sign Up
          </button>
        )}

        <a href="/auth/sign-in">Already have an account? Sign in!</a>
        <LangSwitch className={classes.langSwitch} />
      </div>
    </form>
  );
};

export default CreateAccountForm;
