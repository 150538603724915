import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExpandableText from '../../../../UI/ExpandableText/ExpandableText';
import { UpDownArrow } from '@hellopocketed/react-ui.widgets.up_down_arrow';

import classes from './IntelCard.module.css';
import grantDetailsClasses from '../../GrantDetailsView.module.css';

/**
 * A card that shows Pocketed intel on a grant. This card can handles overflow
 * by adding a show/hide button if necessary.
 * @param {} props
 * @returns
 */
const IntelCard = ({ title, children, textHeight, presetStyle = 'white' }) => {
  const [isShowing, setIsShowing] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);

  let backgroundStyle = '';
  if (presetStyle === 'faded-pink') {
    backgroundStyle = classes.fadedPink;
  }

  /**
   * Hide the content when the children are changed. This is useful
   * when the user switches between different grants.
   */
  useEffect(() => {
    setIsShowing(false);
  }, [children]);

  return (
    <div className={`pocketed-rounded-card ${classes.card} ${backgroundStyle}`}>
      <h3 className={`${grantDetailsClasses.sectionHeader} ${classes.title}`}>{title}</h3>
      <ExpandableText
        show={isShowing}
        isOverflowCallback={setIsOverflow}
        initialHeight={textHeight}
      >
        {children}
      </ExpandableText>

      {
        // if overflow => show the "open" arrow.
        // if showing => we aren't overflowing anymore => just the "close" arrow for this case.
        (isOverflow || isShowing) && (
          <div
            className={`${classes.footer} ${backgroundStyle}`}
            onClick={() => setIsShowing(!isShowing)}
          >
            {!isShowing && <div className={`${classes.fadedScreen} ${backgroundStyle}`} />}
            <div className={`${classes.toggleShowButtonContainer} ${backgroundStyle}`}>
              <UpDownArrow
                flipped={!isShowing}
                extraClasses={classes.toggleShowButton}
              />
            </div>
          </div>
        )
      }
    </div>
  );
};

IntelCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,

  /**
   * A special preset style for the intel card. Available in `faded-pink` and `white`.
   * Default is `white`. Invalid values will be treated as `white`.
   */
  presetStyle: PropTypes.string,

  /**
   * Height of the text part of intel card by default (not-extended).
   */
  textHeight: PropTypes.string,
};

export default IntelCard;
