import React, { useState, useMemo, useEffect, useContext, useRef } from 'react';
import classes from './LangSwitch.module.css';
import PropTypes from 'prop-types';
import { StoreContext } from '../../Store/StoreContext';
import debounce from '../../Assets/Functions/debounce';
import { translateOnDOMChange, translateSite, observeIframe, disconnectIframeObserver, startTranslateMessage, endTranslateMessage, cancelTranslateOnDOMChange } from '../../Assets/Functions/translate/translate';
import { allLangs } from '../../Assets/Functions/translate/translateGlobal';
import { Collapse } from 'react-bootstrap';
import googleTransAttr from './color-short.png';
import { Spinner } from 'react-bootstrap';

const pageObserver = new MutationObserver(() => translateOnDOMChange(document.body));

/**
 * Sometimes, the translations are requested but aren't translated
 * automatically due to the `shouldAutoTranslate` prop.
 */
let translateRequested = false;

/**
 * Trigger the translation process.
 * @param {string} newLang, name of the new language that we want to translate.
 * @returns
 */
const [triggerTranslation] = debounce(newLang => {
  if (newLang === 'Français') {
    observeIframe();
    return translateSite(document.body, true).then(() => {
      pageObserver.observe(document.body, {
        childList: true,
        subtree: true,
        characterData: true,
      });
    });
  } else if (newLang === 'English') {
    cancelTranslateOnDOMChange();
    pageObserver.disconnect();
    disconnectIframeObserver();
    return translateSite(document.body, false);
  }
}, 300);

function LangSwitch({ className, blockAutoTranslate = false }) {
  const { state, actions } = useContext(StoreContext);
  let curLang = state.translate.curLang;
  const [showOption, setShowOption] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  // only translate when the user interacts with the widget
  const [blockTillTouch, setBlockTillTouch] = useState(blockAutoTranslate);
  const previousLang = useRef({ language: curLang }).current;

  const option = useMemo(() => {
    return allLangs.filter(otherLang => otherLang !== curLang)[0];
  }, [curLang]);

  /**
   * Set up the update process for the state's `showLoading` based on window's messaging.
   * This is because once the Observer starts running, React no longer knows whether
   * we are translating.
   */
  useEffect(() => {
    let callback = event => {
      if (event.data === startTranslateMessage) {
        setShowLoading(true);
      } else if (event.data === endTranslateMessage) {
        setShowLoading(false);
      }
    };
    window.addEventListener('message', callback);

    return () => window.removeEventListener('message', callback);
  }, []);

  /**
   * Trigger the translation process when:
   * - we canTranslate AND the curLang changes OR
   * - if there's a translateRequested
   */
  useEffect(() => {
    if (curLang !== previousLang.language || translateRequested) {
      // can only run if we aren't blockTillTouch
      if (blockTillTouch) {
        // however, we got a request => save it so we remember next time
        translateRequested = true;
        previousLang.language = curLang;
        return;
      }

      // translate request is handled
      translateRequested = false;
      // run the translation
      setShowLoading(true);
      triggerTranslation(curLang)
        .catch(err => {
          console.error(err);
          actions.setCurLang('English');
          alert("Sorry! We can't translate our site at the moment.");
        })
        .finally(() => setShowLoading(false));
    }
    previousLang.language = curLang;
  }, [curLang, translateRequested]);

  return (
    <div className={`${classes.container} ${className}`}>
      <div
        className={classes.loadingDiv}
        style={{ visibility: showLoading ? 'visible' : 'hidden' }}
      >
        <Spinner
          animation="border"
          role="status"
          className={classes.loadingIcon}
        />
        <a
          href="http://translate.google.com"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={googleTransAttr}
            alt="translated by Google"
            className={classes.attributeImg}
          />
        </a>
      </div>
      <Collapse in={showOption}>
        <div
          className={classes.option}
          translate="no"
          onClick={() => {
            setBlockTillTouch(false);
            setShowOption(false);
            setShowLoading(true);
            actions.setCurLang(option);
          }}
        >
          {option}
        </div>
      </Collapse>
      <div
        className={classes.selected}
        translate="no"
        onClick={() => {
          setShowOption(!showOption);
        }}
      >
        {curLang}
      </div>
    </div>
  );
}

LangSwitch.propTypes = {
  /**
   * Extra class name.
   */
  className: PropTypes.string,

  /**
   * Whether the switch should automatically translate
   * on render. Default `false`. If set to `true`, will
   * only translate when the user interacts with the widget.
   */
  blockAutoTranslate: PropTypes.bool,
};

export default LangSwitch;
