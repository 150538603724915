import instance from '../Axios/AxiosInstance';

/**
 * Keep the singleton option in memory until the page reloads.
 * Use this rather than StateContext because this data is unlikely to
 * change during a user session. This also prevents unnecessary state re-check.
 * Can be change in the future if needed
 */
let optionsSingleton = null;

/**
 * Get the dropdown options for the grant profile data.
 * @returns a Promise containing the `options`, an object containing the options
 * for each grant profile field.
 */
export default async function getBackendDropdownOptions() {
  if (!optionsSingleton) {
    let { data } = await instance.get('/dropdowns/');
    let options = {
      industries: data.industries.map(industry => ({ label: industry, value: industry })).sort(defaultComparisonCallback),
      founderType: data.founder_types.map(founder => ({ label: founder, value: founder })).sort(founderTypesComparisionCallback),
      // revenue is sorted first then change to label, value obj because we need access to attributes not in the `label` or `value`
      annualRevenue: data.annual_revenue.sort(revenueComparisionCallback).map(revenue => ({ label: revenue.name, value: revenue.name })),
      country: data.countries.map(country => ({ label: country.name, value: country.abbreviation })),
      region_ca: data.regions_ca.map(region => ({ label: region.name, value: region.name })).sort(defaultComparisonCallback),
      region_us: data.regions_us.map(region => ({ label: region.name, value: region.name })).sort(defaultComparisonCallback),
      businessType: data.business_types.map(business => ({ label: business.type, value: business.type })).sort(defaultComparisonCallback),
      businessGoals: data.business_goals.map(business => ({ label: business.type, value: business.type })).sort(defaultComparisonCallback),
    };
    optionsSingleton = options;
  }

  // return a copy, not the obj itself to prevent modification by accident
  return Promise.resolve({ ...optionsSingleton });
}

/**
 * Default comparison algo for sorting the options
 * @param {*} a
 * @param {*} b
 * @returns
 */
function defaultComparisonCallback(a, b) {
  return a.value > b.value ? 1 : -1;
}

/**
 * Comparison algo for sorting the founder type options specifically
 * @param {*} a
 * @param {*} b
 * @returns
 */
function founderTypesComparisionCallback(a, b) {
  // always put "Other" at the end
  if (a.value === 'Other') return 1;
  if (b.value === 'Other') return -1;
  return a.value > b.value ? 1 : -1;
}

/**
 * Comparison algo for sorting the revenue options specifically
 * @param {*} a
 * @param {*} b
 * @returns
 */
function revenueComparisionCallback(a, b) {
  // always put I don't want to say at the end
  if (a.name === "I don't want to say") return 1;
  if (b.name === "I don't want to say") return -1;
  return a.min_value - b.min_value;
}
