import BaseSlice from './BaseSlice';
import { GrantProfileInputElemConfigs } from '../../Assets/Functions/GrantProfileCreationFormElements/FormElementConfigs';

/**
 * Hold states relevant about the user.
 */
const initialState = {
  // Setting the current platform user for Mixpanel tracking purposes, logged in state,
  // for displaying sign in popup, and for eventually determining account access level.
  // See Assets/Types/UserAPIExample.js for more details on the `customer` object.
  currentUser: {
    loggedIn: false,
    user: '',
    adminLevel: 0,
    showSignInPopUp: false,
    paidTier: false,
    is_us_user: false,
    clearAngel: false,
    customer: null,
    user_type: '',
    user_subtype: '',
    is_trialing: false,
    popup_seen_count: '',
    preference: {},
  },

  /**
   * The current language of the site as selected by the user
   */
  translate: {
    // current language
    curLang: 'English',

    // whether we already checked backend for user's preferred language
    // this allow user to change their language for the session rather
    // than entirely
    // noUpdateCurLang: false,
  },

  /**
   * The user's grant profile that they create on sign up.
   */
  grantProfile: (function () {
    let obj = {};
    for (let config of Object.values(GrantProfileInputElemConfigs)) {
      obj[config['entryValue']] = config['value'];
    }
    return obj;
  })(),

  // mmProfile: mmProfileState,

  // Used to display popup on Auth forms when an API call fails
  authFailed: false,
};

const reducerObj = {
  setCurrentUser: (state, action) => {
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        ...action.payload,
      },
    };
  },

  /**
   * Set basic user info often gotten from
   * the `/user` query.
   * @param {*} state
   * @param {*} action, contains a payload which
   * is a data object gotten from GET `/api/user/`.
   * @returns
   */
  setBasicUserInfo: (state, action) => {
    let data = action.payload;
    // reuse code from setCurrentUser

    let prevPrefLang = state.currentUser.preference.language;
    let returnObj = reducerObj.setCurrentUser(state, action);
    returnObj.currentUser.loggedIn = true;

    // modify the necessary `translate` props ONLY if we haven't done so before
    if (prevPrefLang !== returnObj.currentUser.preference.language) {
      returnObj.translate = {
        curLang: data.preference.language,
      };
    }
    return returnObj;
  },

  setAuthFailedState: (state, action) => {
    return {
      ...state,
      authFailed: action.payload,
    };
  },

  setClearAngel: (state, action) => {
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        clearAngel: action.payload.clearAngel,
      },
    };
  },

  /**
   * Add a grant profile value to the state.
   * Note: this is often used during the registering process,
   * hence the 'create' in the name.
   * @param {*} state
   * @param {*} action
   * @returns
   */
  createGrantProfile: (state, action) => {
    const target = action.payload.target;
    const value = action.payload.value;

    return {
      ...state,
      grantProfile: {
        ...state.grantProfile,
        [target]: value,
      },
    };
  },

  /**
   * Set the entire grant profile.
   * @param {} state
   * @param {*} action
   */
  setGrantProfile: (state, action) => {
    return {
      ...state,
      grantProfile: action.payload,
    };
  },

  createMMProfile: (state, action) => {
    const label = action.payload.target;
    const val = action.payload.value;

    return {
      ...state,
      mmProfile: {
        ...state.mmProfile,
        [label]: val,
      },
    };
  },

  resetCurrentUser: state => {
    return {
      ...state,
      currentUser: {
        ...initialState.currentUser,
      },
    };
  },

  setCurLang: (state, action) => {
    return {
      ...state,
      translate: {
        curLang: action.payload,
      },
    };
  },
};

const UserInfoSlice = new BaseSlice(initialState, reducerObj);
export default UserInfoSlice;
