import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StoreContext } from '../../../../../../Store/StoreContext';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import { ToggleSwitch } from '@hellopocketed/react-ui.inputs.toggle_switch';
import classes from './FilterToggleSwitch.module.css';

/**
 * A toggle input section intended for Filters. Default state is in the off position.
 * @param {*} props
 * @returns
 */
const FilterToggleSwitch = ({ onDisplayName, offDisplayName, onValue, offValue, checked, filter }) => {
  const { actions } = useContext(StoreContext);

  /**
   * Default onclick for inputs.
   * @param {} option
   */
  const defaultUpdateFilterOnClick = () => {
    // when clicked, user wants to see the target state => get the opposite option.
    actions.setSelectedFilter({ [filter]: checked ? offValue : onValue });
    Mixpanel.track('Clicked Filter(Traditional)', { filter: filter });
  };

  return (
    <ToggleSwitch
      onChange={defaultUpdateFilterOnClick}
      checked={checked}
      onDisplayName={onDisplayName}
      offDisplayName={offDisplayName}
      wrapperClassName={classes.switchWrapper}
    />
  );
};

FilterToggleSwitch.propTypes = {
  /**
   * Display name for the 'on' state of the switch.
   */
  onDisplayName: PropTypes.string,

  /**
   * Display name for the 'off' state of the switch.
   */
  offDisplayName: PropTypes.string,

  /**
   * Value to be set in the filter when turned `on`.
   */
  onValue: PropTypes.any,

  /**
   * Value to be set in the filter when turned `off`.
   */
  offValue: PropTypes.any,

  /**
   * The filter that we want to set when the input is clicked on.
   * Must be a valid value to be sent to `/matches`.
   */
  filter: PropTypes.string,

  /**
   * Whether the switch should be checked/turned on.
   */
  checked: PropTypes.bool,
};

export default FilterToggleSwitch;
