import React from 'react';
import GrantUsageTextbox from './GrantUsageTextbox/GrantUsageTextbox';

import { ReactComponent as training } from '../../../../Assets/Images/AvailableGrants/training.svg';
import { ReactComponent as expansion } from '../../../../Assets/Images/AvailableGrants/expansion.svg';
import { ReactComponent as hiring } from '../../../../Assets/Images/AvailableGrants/hiring.svg';
import businessManSrc from '../../../../Assets/Images/AvailableGrants/business_man.jpg';
import classes from './GrantUsage.module.css';

const GrantUsage = () => {
  return (
    <div className={classes.container}>
      <img
        src={businessManSrc}
        alt="business man"
        className={classes.leftImg}
      />
      <div className={classes.textBoxesColumn}>
        <GrantUsageTextbox
          Icon={hiring}
          title="HIRING"
          grantType="Hiring"
          subtitle="More staff for more growth"
        />

        <GrantUsageTextbox
          Icon={training}
          title="TRAINING"
          grantType="Training"
          subtitle="Upskill to success"
        />

        <GrantUsageTextbox
          Icon={expansion}
          title="EXPANSION"
          grantType="Market Expansion"
          subtitle="Reach new markets"
        />
      </div>
    </div>
  );
};

export default GrantUsage;
