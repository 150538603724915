import React, { useContext } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import classes from './Auth.module.css';
import LoginForm from './LoginForm/LoginForm';
import { BigLogoLayout } from '@hellopocketed/react-ui.layouts.big_logo_layout';
import FooterCOA from '../../Components/Footer/FooterCOA/FooterCOA';
import AccountActivated from './AccountActivated/AccountActivated';
import PasswordReset from './PasswordReset/PasswordReset';
import ConfirmPasswordReset from './ConfirmPasswordReset/ConfirmPasswordReset';
import ResendActivationLink from './ResendActivationLink/ResendActivationLink';
import AccountActivatedBeta from './AccountActivated/AccountActivated-Beta/AccountActivated-Beta';
import StripeForm from './StripeFormContainer/StripeFormContainer';
import { StoreContext } from '../../Store/StoreContext';
import PopupModal from '../../Components/UI/PopUps/PopupModal';
import warningIcon from '../../Assets/Images/warning-icon.svg';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import { PocketedColoredLogo } from '@hellopocketed/react-ui.images.pocketed_logos';
import { useScreenWidth, DEVICE_WIDTH } from '@hellopocketed/react-ui.js_utils.core';
import { Helmet } from 'react-helmet';

const Auth = () => {
  const { actions, state } = useContext(StoreContext);
  const width = useScreenWidth();

  const innerAuthComp = (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <Switch>
          {/* <Route path="/auth/create-account" component={GrantProfileStripe} /> */}
          <Route
            path="/auth/login"
            component={LoginForm}
          />
          <Redirect
            exact
            from="/auth/create-account"
            to="/create-account"
          />
          <Route
            path="/auth/reset-password"
            component={PasswordReset}
          />
          <Route
            path="/auth/resend-activation"
            component={ResendActivationLink}
          />
          <Route
            path="/auth/stripe"
            component={StripeForm}
          />
          <Route
            path="/activate-account/:path/:token"
            component={AccountActivated}
          />
          <Route
            path="/activate-beta-account/:path/:token"
            component={AccountActivatedBeta}
          />
          <Route
            path="/confirm-password-reset/:path/:token"
            component={ConfirmPasswordReset}
          />
          <Route
            path="*"
            component={LoginForm}
          />
        </Switch>
      </div>
      <div>
        <FooterCOA />
      </div>

      {/** POPUPS */}
      {state.shortGrantList && (
        <PopupModal
          type="custom"
          backgroundClicked={() => actions.setShortGrantList(false)}
          title={'Hey!'}
          message={
            <>
              Your matched list is short, but that makes your job easier!
              <br /> Don't fret, you'll have more matches coming soon!
            </>
          }
          image={PocketedColoredLogo}
        />
      )}
      {state.authFailed && (
        <PopupModal
          type="custom"
          backgroundClicked={() => actions.setAuthFailedState(false)}
          image={warningIcon}
          title={'So Close!'}
          message={
            <>
              Please check that all the fields are complete and in the correct format.
              <br />
              <br />
              Still having trouble?
              <br />
              <br />
              <HoverableButton
                presetBtnStyle="white-blue"
                href="https://pocketed.zendesk.com/hc/en-us/requests/new"
                target="_blank"
              >
                Click Here
              </HoverableButton>
            </>
          }
        />
      )}
    </div>
  );

  if (width > DEVICE_WIDTH.mobile) {
    return (
      <BigLogoLayout>
        <Helmet>
          <title>Pocketed | Login</title>
        </Helmet>
        {innerAuthComp}
      </BigLogoLayout>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>Pocketed | Login</title>
        </Helmet>
        {innerAuthComp}
      </>
    );
  }
};

export default withRouter(Auth);
