import React from 'react';
import PropTypes from 'prop-types';
import classes from './BasePopUp.module.css';

/**
 * A base pop up that's used by other popups.
 * @param {} props
 * @returns
 */
const BasePopUp = ({ imgSrc, imgSize, children }) => {
  return (
    <div
      className={`${classes.container} pocketed-rounded-card`}
      onClick={e => e.stopPropagation()}
    >
      {imgSrc && (
        <img
          src={imgSrc}
          className={`${classes.image} ${imgSize === 'small' && classes.small}`}
          alt="pop up"
        />
      )}
      <div className={classes.otherContent}>{children}</div>
    </div>
  );
};

BasePopUp.propTypes = {
  imgSrc: PropTypes.string,
  /**
   * String value for image size, options: 'small'.
   */
  imgSize: PropTypes.string,
};

export default BasePopUp;
