// order matters => pocketed styles, then app global style, then component style.
import '@hellopocketed/react-ui.style.pocketed-styles';
import './global.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { StoreProvider } from './Store/StoreContext';
import { BrowserRouter } from 'react-router-dom';
import Maintanance from './Containers/Maintenance/Maintenance';
import { PocketedTooltip } from '@hellopocketed/react-ui.widgets.tooltip';
import { initMixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import { FeedbackUIProvider } from '@hellopocketed/react-ui.widgets.feedback_ui';

initMixpanel(process.env.REACT_APP_MIXPANEL, process.env.REACT_APP_NODE_ENV);

ReactDOM.render(
  <StoreProvider>
    <FeedbackUIProvider>
      <BrowserRouter>{process.env.REACT_APP_IS_MAINTENANCE === 'true' ? <Maintanance /> : <App />}</BrowserRouter>
      <PocketedTooltip />
    </FeedbackUIProvider>
  </StoreProvider>,
  document.getElementById('root')
);
