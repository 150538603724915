import React, { useContext } from 'react';
import { StoreContext } from '../../../../Store/StoreContext';
import classes from './AlphaUsPopup.module.css';
import { XCircle } from 'react-bootstrap-icons';
import AlphaPocketedLogo from '../../../../Assets/Images/alpha_pocketed_logo_blue.png';

const AlphaUsPopup = () => {
  const { state, actions } = useContext(StoreContext);
  return (
    <div
      className={classes.container}
      onClick={e => e.stopPropagation()}
    >
      <div className={classes.header}>
        <div
          className={classes.icon}
          onClick={() => {
            actions.setAlphaPopup({ flag: false });
          }}
        >
          <XCircle size={30} />
        </div>
      </div>
      <div className={classes.body}>
        <img
          src={AlphaPocketedLogo}
          alt="Pocketed - Alpha"
        />
        <h1>Thank you for using Pocketed!</h1>
        <p>We are currently in Alpha and will be continually updating the platform to add more great features!</p>
      </div>
    </div>
  );
};

export default AlphaUsPopup;
