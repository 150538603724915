import React, { useEffect, useState, useContext } from 'react';
import instance from '../../../Assets/Axios/AxiosInstance';
import PopupModal from '../../../Components/UI/PopUps/PopupModal';
import { StoreContext } from '../../../Store/StoreContext';
import MySubscription from './MySubscription/MySubscription';
import { Form } from 'react-bootstrap';
import Plans from './Plans/Plans';
import sharedStyles from '../sharedStyles.module.css';
import { Loading } from '@hellopocketed/react-ui.widgets.loading';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import { Helmet } from 'react-helmet';
import classes from './Subscriptions.module.css';

export const MY_SUBSCRIPTIONS_URL = 'account/subscription';

const Subscriptions = () => {
  const { state, actions } = useContext(StoreContext);

  const [curReferralCode, setCurReferralCode] = useState(null);
  const [discountInfo, setDiscountInfo] = useState({ discountPlan: null, discountAmount: null, isConcierge: null });
  const [urlPlan, setUrlPlan] = useState(null);
  const [stripeTier, setStripeTier] = useState(null);
  /**
   * Use this format of UI Config to render the ref code using InputArrayMapper.
   * See GrantProfileCreationFormElements/FormElementConfigs/UserAuthConfig.js.
   */
  const [newReferralCode, setNewReferralCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [redeemMessage, setRedeemMessage] = useState({
    text: '',
    type: 'success', // or "error"
  });

  // need a place to show their redeemed codes
  const redeemCode = e => {
    e.preventDefault();
    setIsLoading(true);
    setRedeemMessage({
      text: '',
      type: '',
    });

    instance
      .post('/redeem-referral-code/', { referral_code: newReferralCode })
      .then(res => {
        if (res.data.message === 'Redemption successful') {
          setRedeemMessage({
            text: 'Redemption successful',
            type: 'success',
          });
          setCurReferralCode(newReferralCode);
        } else {
          setRedeemMessage({
            text: res.data.message,
            type: 'error',
          });
        }
      })
      .catch(err => {
        console.error(err.response);
        setRedeemMessage({
          text: 'An unexpected error occured. Please contact Pocketed for assitance.',
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const sessionId = params.get('session_id');
    const tier = params.get('tier');
    // handle plan clicked from wp website
    const planFromWebsite = params.get('wp_plan');
    planFromWebsite && setUrlPlan(planFromWebsite);
    tier && setStripeTier(tier);
    sessionId && actions.setSubscribedPopup({ flag: true });
    //eslint-disable-next-line
  }, []);

  const clearPopup = () => {
    actions.setSubscribedPopup({ flag: false });
  };

  // Fetch grantprofiles to get referral code
  useEffect(() => {
    instance
      .get('/api/grantprofiles/')
      .then(res => {
        const { referral_code } = res.data.results[0];

        if (referral_code) {
          setCurReferralCode(referral_code);
          instance.get(`api/discount-code/?code=${referral_code}`).then(res => {
            const data = res.data;
            const { discount_plan, discount_amount, is_concierge } = data;
            setDiscountInfo({ discountPlan: discount_plan, discountAmount: discount_amount, isConcierge: is_concierge });
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div className={sharedStyles.container}>
      <Helmet>
        <title>My Subscription</title>
      </Helmet>
      <h2>My Subscription</h2>
      {state.subscribedPopup.flag && (
        <PopupModal
          backgroundClicked={() => clearPopup()}
          type="subscribed"
          concierge={stripeTier === 'concierge'}
          plus={stripeTier === 'plus'}
        />
      )}
      <MySubscription />

      <Plans
        referralCode={curReferralCode}
        discountInfo={discountInfo}
        urlPlan={urlPlan}
      />

      <Form
        onSubmit={redeemCode}
        className={classes.referralCodeSection}
      >
        <h2 className={classes.title}>Referral Code</h2>
        {curReferralCode && (
          <p className={classes.curCode}>
            Your current referral code is <b>{curReferralCode}</b>
          </p>
        )}
        <div className={`${classes.newRefCodeSection}`}>
          {/* <Form.Label
            for="newReferralCode"
            className={classes.newRefCodeLabel}
          >
            Referral code:
          </Form.Label> */}
          <Form.Control
            id="newReferralCode"
            value={newReferralCode}
            onChange={evt => setNewReferralCode(evt.target.value)}
            className={classes.newRefCodeInput}
            placeholder="Enter referral code"
            required
          />
          {isLoading ? (
            <Loading className={classes.loading}>Please wait...</Loading>
          ) : (
            <HoverableButton
              presetBtnStyle="dark-purple"
              type="submit" // use the form's built in input checking
            >
              Redeem
            </HoverableButton>
          )}
        </div>
        <p className={sharedStyles[redeemMessage.type]}>{redeemMessage.text}</p>
      </Form>
      {state.showLoadingPopup && <PopupModal type="loadingPopup" />}
    </div>
  );
};

export default Subscriptions;
