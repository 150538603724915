import React, { useContext, useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router';
import instance from '../../Assets/Axios/AxiosInstance';
import GrantDetailsView from '../../Components/AppicationComponents/GrantDetailsView/GrantDetailsView';
import { GRANT_PAGE_URL } from '../AvailableGrantsView/AvailableGrantsView';
import ProjectCategoriesDropdown from '../../Components/AppicationComponents/Filters/ProjectCategoriesDropdown/ProjectCategoriesDropdown';
import ShowMeDropdown from '../../Components/AppicationComponents/Filters/ShowMeDropdown/ShowMeDropdown';
import ToggleSearchButton from '../../Components/AppicationComponents/Filters/ToggleSearchButton/ToggleSearchButton';
import FilterDropdown from '../../Components/AppicationComponents/Filters/FilterDropdown/FilterDropdown';
import { useDropdownLinking } from '@hellopocketed/react-ui.dropdown';
import ClearFiltersButton from '../../Components/AppicationComponents/Filters/ClearFiltersButton/ClearFiltersButton';
import { StoreContext } from '../../Store/StoreContext';
import Loading from '../../Components/UI/Loading/Loading';
import { decrypt } from '../../Assets/Functions/Encrypter';
import PopupModal from '../../Components/UI/PopUps/PopupModal';
import SignInSignUpPrompt from './SignInSignUpPrompt/SignInSignUpPrompt';
import GrantView from '../../Components/UI/GrantView/GrantView';
import { useIsUser } from '../../Assets/Functions/UserPermissions';
import { Helmet } from 'react-helmet';

import toolsIcon from '../../Assets/Images/tools.svg';
import classes from '../AvailableGrantsView/AvailableGrantsView.module.css';
import fakeList from '../../Assets/Images/AvailableGrants/blurred_grant_list.png';
import classesAlt from './SingleGrantView.module.css';

const SingleGrantView = props => {
  const { state, actions } = useContext(StoreContext);
  const history = useHistory();
  /* Generate a random grant count to display if the user is not logged in.*/
  const [grantCount, setGrantCount] = useState(0);

  /**
   * Whether to show the sign in / sign up popup.
   */
  const [showSignInSignUp, setSignInSignUp] = useState(false);

  // connect the following dropdowns so opening one closes the others
  const dropdowns = [ProjectCategoriesDropdown, ShowMeDropdown, FilterDropdown];
  const linkedDropdownStates = useDropdownLinking(dropdowns.length);

  const [grant, setGrant] = useState({
    loading: true,
    grantFound: true,
  });

  /**
   * Get the grant using the id encrypted in the URL.
   */
  useEffect(() => {
    try {
      const params = new URLSearchParams(window.location.search);
      const grantID = params.get('id');
      const country = params.get('country');

      let id = decrypt(decodeURIComponent(grantID));

      if (id.length < 1) return toggleGrantFound();

      let url = `/api/${country === 'US' ? 'usa-' : ''}grants/${id}/`;
      instance
        .get(url)
        .then(result => {
          if (result) actions.setIndividualGrant({ grant: result.data, country });
          instance.get('/user/').then(res => {
            // if user is not signed in
            if ('isAuthenticated' in res.data && !res.data.isAuthenticated) {
              actions.setCurrentUser({ loggedIn: false });
            }
            // if user is signed in
            else {
              actions.setBasicUserInfo(res.data);
            }
            setGrant(old => {
              return {
                ...old,
                loading: false,
              };
            });
          });
        })
        .catch(err => {
          console.error(err);
          toggleGrantFound();
        });
    } catch {
      toggleGrantFound();
    }
  }, []);

  const isUserTypeLoading = useIsUser.TypeLoading();
  const isFreeUser = useIsUser.Free();
  /**
   * Check whether the user is logged in. If they are, we will show them the available grant view instead.
   * This means that if the user sees the content of this page, it means that they are not logged in.
   */
  useEffect(() => {
    if (state.currentUser.loggedIn) {
      console.log('User logged in, redirecting');
      // check if we have user type
      if (isUserTypeLoading) return;
      // only redirect if they can see the page
      if (!isFreeUser) history.push(GRANT_PAGE_URL);
    }
  }, [state.currentUser.loggedIn, history, isFreeUser, isUserTypeLoading]);

  /**
   * Generate a random grant count only once when the page is loaded.
   */
  useEffect(() => {
    setGrantCount(Math.floor(Math.random() * 100) + 20);
  }, []);

  const toggleShowSignInPopUp = () => {
    actions.setCurrentUser({ showSignInPopUp: !state.currentUser.showSignInPopUp });
  };

  const toggleGrantFound = () => {
    setGrant({ ...grant, grantFound: !grant.grantFound });
  };

  return (
    <>
      <Helmet>
        <title>Shared Grant</title>
      </Helmet>
      <GrantView
        headerLeft={
          <div className={classes.titleArea}>
            <h2 className={classes.grantListTitle}>Your Matches ({grantCount})</h2>
            <ToggleSearchButton />
          </div>
        }
        headerRight={
          <div className={classes.filters}>
            {dropdowns.map((Dropdown, idx) => {
              const [isShowing, setIsShowing] = linkedDropdownStates[idx];
              return (
                <Dropdown
                  isShowing={isShowing}
                  setIsShowing={setIsShowing}
                  key={idx}
                  signInCallback={() => setSignInSignUp(true)}
                />
              );
            })}
            <ClearFiltersButton />
          </div>
        }
        bodyLeft={
          <>
            <img
              className={classesAlt.img}
              src={fakeList}
              alt="placeholder list"
            />
            <img
              className={classesAlt.img}
              src={fakeList}
              alt="placeholder list"
            />
            <div className={classesAlt.overlay}>
              <SignInSignUpPrompt
                toggleShowSignInPopUp={toggleShowSignInPopUp}
                isFreeUser={isFreeUser}
              />
            </div>
          </>
        }
        bodyRight={grant.loading ? <Loading /> : <GrantDetailsView signInCallback={() => setSignInSignUp(true)} />}
        footer={
          <>
            {showSignInSignUp && (
              <PopupModal
                backgroundClicked={() => setSignInSignUp(false)}
                type="signInSignUp"
              />
            )}
            {state.currentUser.showSignInPopUp && (
              <PopupModal
                type="signInForm"
                backgroundClicked={toggleShowSignInPopUp}
              />
            )}

            {/* Let user know grant is not found. */}
            {!grant.grantFound && (
              <PopupModal
                type="custom"
                backgroundClicked={() => {}}
                image={toolsIcon}
                title={'Whoops'}
                message={
                  <>
                    Whoops! It looks like this link is no longer active! No worries - visit your <a href="/home">Pocketed account</a> to search all grant programs
                  </>
                }
              />
            )}
          </>
        }
      />
    </>
  );
};

export default withRouter(SingleGrantView);
