import React from 'react';
import PropTypes from 'prop-types';
import FAQItem from '../FAQItem/FAQItem';

import classes from '../FAQSection/FAQSection.module.css';

const FAQSection = ({ data }) => {
  return (
    <div className={classes.FAQSection__container}>
      <h3>FAQ</h3>
      <div className={classes.FAQSection__item}>
        {data.map(({ title, content }) => (
          <FAQItem
            title={title}
            key={title}
            content={content}
          />
        ))}
      </div>
    </div>
  );
};

FAQSection.propTypes = {
  data: PropTypes.array.isRequired,
};

export default FAQSection;
