import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import CategoryCard from './CategoryCard/CategoryCard';
import Loading from '../../../Components/UI/Loading/Loading';
import { DEFAULT_CATEGORY_NAME } from '../../../Assets/Functions/CategoryUtil';
import { shuffleArray } from '../../../Assets/Functions/ArrayShuffler';
import useFilterTypes from '../../../Assets/Functions/StateSingletonHelper/useFilterTypesGetter';

import 'swiper/css/bundle';
import classes from './CategoriesCarousel.module.css';

const SPACE_BETWEEN_IN_PX = 30;

/**
 * A wrapper around a SwiperJS object that display categories.
 * Note: the swiper object requires its parents to have a set `width`
 * and occasionally `max-width`. For flexboxes, `flex-basis` is usually
 * an acceptable solution. See https://github.com/nolimits4web/swiper/issues/2620
 * for more details.
 *
 * Note: Can also pass in standard Swiper props (https://swiperjs.com/swiper-api#parameters)
 * through this carousel besides the specified ones. `autoPlayDelay` is short for `autoplay={{delay='value'}}`.
 */
const CategoriesCarousel = ({ slidesPerView = 2, autoPlayDelay = 7000, extraClasses, extraCardClasses, cardOnClick, ...rest }) => {
  const [categories, setCategories] = useState([]);
  const filterTypes = useFilterTypes();
  let { display_subcategories } = filterTypes;

  /**
   * Populate the categories by checking which one we can show to
   * the user.
   * Note: the dependency array is a stringified version of an array.
   * This ensures the comparision is value-wise and not reference-wise.
   */
  useEffect(() => {
    if (display_subcategories) {
      let processedSubCat = [DEFAULT_CATEGORY_NAME];
      if (display_subcategories.length > 0) {
        processedSubCat = shuffleArray(display_subcategories);
      }
      setCategories(
        processedSubCat.map(subcat => {
          return {
            title: subcat,
          };
        })
      );
    }
    // dependency array solution taken from https://stackoverflow.com/a/59468261/11683637
  }, [JSON.stringify(display_subcategories)]); // eslint-disable-line

  let style = {};
  // special styling if we have more than 1 page
  if (categories.length > slidesPerView) {
    style = {
      paddingLeft: SPACE_BETWEEN_IN_PX + 10,
      paddingRight: SPACE_BETWEEN_IN_PX + 10,
    };
  }
  let elem = <Loading />;
  if (categories) {
    elem = (
      <Swiper
        slidesPerView={slidesPerView}
        speed={1200}
        modules={[Navigation, Pagination, Autoplay]}
        className={`${classes.container} ${extraClasses}`}
        style={style}
        spaceBetween={SPACE_BETWEEN_IN_PX}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: autoPlayDelay, disableOnInteraction: false, pauseOnMouseEnter: true }}
        {...rest}
      >
        {categories.map(category => (
          <SwiperSlide key={category.title}>
            <CategoryCard
              {...category}
              isEmpty={category.title === DEFAULT_CATEGORY_NAME}
              onClick={cardOnClick}
              extraClasses={extraCardClasses}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }
  return elem;
};

CategoriesCarousel.propTypes = {
  /**
   * Amount of slides we want per view. Default is 5.
   */
  slidesPerView: PropTypes.number,

  /**
   * Amount of time (in miliseconds) before the carousel
   * move on to the next set of slides. Default is 7000.
   */
  autoPlayDelay: PropTypes.number,

  /**
   * Extra styling classes if needed.
   */
  extraClasses: PropTypes.string,

  /**
   * Extra styling classes for the CategoryCard within the
   * carousel.
   */
  extraCardClasses: PropTypes.string,

  /**
   * An onclick listener for the CategoryCard within the carousel.
   * Function signature will be (title, isEmpty) => {}.
   * See CategoryCard's onClick prop for more details.
   */
  cardOnClick: PropTypes.func,
};

export default CategoriesCarousel;
