class BaseSlice {
  /**
   * Construct a base slice representing a section of the Store.
   * Based on the Redux Slice.
   * @param {*} initialState
   * @param {{string: function}} reducerObj - an object with
   * the action type as the key and a function as the value.
   * The function is a standard reducer function with the signature
   * (state, action) => {}. NOTE: the action type MUST BE UNIQUE
   * across different slices.
   */
  constructor(initialState, reducerObj) {
    /**
     * The initial states of this slice.
     */
    this.initialState = initialState;

    /**
     * The action that this slice can take.
     */
    this.actions = this.createActionList(reducerObj);
    this.reducers = this.createReducerFunction(reducerObj);
  }

  createActionList(reducerObj) {
    let actions = Object.keys(reducerObj);
    if (actions.includes(BaseSlice.RESET_STATE_TO_INITIAL_ACTION)) {
      throw new Error('An instance of BaseSlice has an action matching a reserved `BaseSlice.RESET_STATE_TO_INITIAL_ACTION`.');
    }
    return actions;
  }

  createReducerFunction(reducerObj) {
    return (state, action) => {
      for (const [actionType, callback] of Object.entries(reducerObj)) {
        if (action.type === actionType) {
          return callback(state, action);
        }
      }
      return BaseSlice.NO_MATCHING_REDUCER; // empty obj aka break
    };
  }
}

/**
 * Special return value for when there's no matching action type.
 */
BaseSlice.NO_MATCHING_REDUCER = 'no matching reducer';

/**
 * Reserved action type for resetting state that future instances cannot use.
 */
BaseSlice.RESET_STATE_TO_INITIAL_ACTION = 'resetAllStateToInitialState';

export default BaseSlice;
