/**
 * Contains functions to modify the element configs quickly.
 * Only works for configs declared in `FormElementConfigs`.
 */

/**
 * Make element configs non-editable.
 */
export function makeElemNonEditable(elemConfig) {
  return {
    ...elemConfig,
    edit: false,
    entryValue: '',
  };
}

/**
 * Add a suffix to the label of the element config.
 * @param {*} elemConfig
 * @param {*} suffix, a string.
 * @returns
 */
export function suffixElemLabel(elemConfig, suffix) {
  return {
    ...elemConfig,
    label: `${elemConfig.label}${suffix}`,
  };
}

/**
 * Update the reliant inputs of a config object by checking its conditions.
 * @param {*} configs, an object containing the GrantProfile input config objects.
 * @param {*} key, the name of the config we are checking.
 * @param {*} value, the current value of the input.
 */
export function updateReliantInputs(configs, key, value) {
  let inputConfig = configs[key];
  // check if this element should affect the display of other input elements
  inputConfig.reliantInputs?.forEach(reliantConfig => {
    // if changed value matches the reliantInputs match value, set show value of the element that matches the display value of the reliantInput to true
    if (reliantConfig.match.toString() === value.toString()) {
      configs[reliantConfig.display].show = true;
    }
    // else set show value of the element that matches the display value of the reliantInput to false, and reset value of the input
    else {
      configs[reliantConfig.display].show = false;
      configs[reliantConfig.display].value = configs[reliantConfig.display].initialValue || '';
    }
  });
}
