import classes from './FormElementConfigs.module.css';

export const MiscInputElemConfigs = {
  alphaUserCommit: {
    type: 'checkbox',
    label: 'As a Pocketed User, I commit to:',
    elementConfig: {
      required: true,
    },
    edit: true,
    value: '',
    additionalClass: classes.emailCheckbox,
    belowInputContent: (
      <>
        <ul className={classes.alphaUserCommit}>
          <li>Sharing my feedback regularly with the Pocketed team </li>
          <li>Featuring my experience with Pocketed in a case study</li>
        </ul>
      </>
    ),
  },
  emailCheckbox: {
    type: 'checkbox',
    label: 'I agree to receive emails from Pocketed',
    elementConfig: {
      required: true,
    },
    edit: true,
    value: '',
    additionalClass: classes.emailCheckbox,
  },
};
