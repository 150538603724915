import React from 'react';
import LoginForm from '../../../../Containers/Auth/LoginForm/LoginForm';
import classes from './SignInPopUp.module.css';

const SignInPopUp = () => {
  return (
    <div
      className={classes.container}
      onClick={e => e.stopPropagation()}
    >
      <LoginForm noRedirect />
    </div>
  );
};

export default SignInPopUp;
