import React, { useEffect, useState, useContext } from 'react';
import { StoreContext } from '../../../Store/StoreContext';
import { useHistory } from 'react-router-dom';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import { deformatDate } from '@hellopocketed/react-ui.js_utils.core';
import instance from '../../../Assets/Axios/AxiosInstance';
import InputArrayMapper from '../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper';
import { GrantProfileInputElemConfigs, configAddOns } from '../../../Assets/Functions/GrantProfileCreationFormElements/FormElementConfigs';
import Loading from '../../../Components/UI/Loading/Loading';
import StopInteractionBackground from '../../../HOC/StopInteractionBackground/StopInteractionBackground';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import PopupModal from '../../../Components/UI/PopUps/PopupModal';
import moment from 'moment';
import getBackendDropdownOptions from '../../../Assets/Functions/GrantProfileOptionsGetter';
import { formatGrantProfileValue } from '../../Auth/GrantProfileCreation-v2/GrantProfileCreationForms/GrantProfileUtils';
import { Helmet } from 'react-helmet';
import classes from './GrantProfile.module.css';
import sharedStyles from '../sharedStyles.module.css';

const GrantProfile = () => {
  const history = useHistory();
  const { state, actions } = useContext(StoreContext);
  const [profileState, setProfileState] = useState({
    form: {
      // explicitly list which field we want => prevent new fields from showing up by accident
      businessName: { ...GrantProfileInputElemConfigs.businessName },
      website: { ...GrantProfileInputElemConfigs.website },
      country: { ...GrantProfileInputElemConfigs.country },
      region_ca: { ...GrantProfileInputElemConfigs.region_ca },
      region_us: { ...GrantProfileInputElemConfigs.region_us },
      phone: { ...GrantProfileInputElemConfigs.phone },
      companyStatus: { ...GrantProfileInputElemConfigs.companyStatus },
      companySize: { ...GrantProfileInputElemConfigs.companySize },
      annualRevenue: { ...GrantProfileInputElemConfigs.annualRevenue },
      monthlySpending: { ...GrantProfileInputElemConfigs.monthlySpending },
      registrationDate: { ...GrantProfileInputElemConfigs.registrationDate },
      founderType: { ...GrantProfileInputElemConfigs.founderType },
      industries: { ...GrantProfileInputElemConfigs.industries },
      businessType: { ...GrantProfileInputElemConfigs.businessType },
      language: {
        ...GrantProfileInputElemConfigs.preferredLang,
        value: state.translate.curLang,
      },
      description: { ...GrantProfileInputElemConfigs.description },
    },
    loading: true,
    editingGrantProfile: false,
    message: 'Please wait...',
    editPopUp: false,
    canUpdate: true,
    editCountRemaining: 2,
  });

  useEffect(() => {
    instance
      .get('/api/grantprofiles/')
      .then(res => {
        if (res.data.results.length !== 0) {
          let data = res.data.results[0];

          setProfileState(old => {
            let newState = { ...old };
            let { form } = newState; // js is pass by copy-ref => updating form will update newState.form
            Object.keys(form).forEach(field => (form[field].edit = false));

            form.businessName.value = data.company_name;
            form.country.value = data.country.abbreviation;
            let country_region = 'region_ca';
            if (data.country.abbreviation === 'US') {
              country_region = 'region_us';
              form.region_us.show = true;
              form.region_ca.show = false;
            }
            form[country_region].value = data.region
              .map(region => region.name)
              .sort()
              .join(', ');
            form[country_region].initialValue = form[country_region].value; // set initial value so when dropdown is reset, it gives us the old values.
            form.phone.value = data.phone_number;
            form.website.value = data.company_site;
            form.companySize.value = data.company_size;
            form.founderType.value = data.founder_types;
            form.industries.value = data.industries;
            form.businessType.value = data.business_type.type;
            form.annualRevenue.value = data.annual_revenue.name;
            form.monthlySpending.value = data.monthly_spending;
            form.registrationDate.value = data.registration_date ? deformatDate(data.registration_date) : '';
            form.description.value = data.description;
            form.companyStatus.value = data.incorporated;
            form.language.value = data.preference.language;
            let backupform = deconstructObject(form);

            newState.backupForm = { ...backupform };
            newState.loading = false;
            newState.grantProfileID = data.id;

            let edit_count = data.edit_count;
            let remaining_edits = 2 - (edit_count % 2);
            // get time profile was last updated and check if it is within threshold (7 days)
            let updated_time = new Date(data.updated_at);
            let updated_moment = moment(updated_time);

            // check if it's the 2nd edit
            if (edit_count % 2 !== 0 || edit_count === 0) {
              newState.canUpdate = true;
              newState.editCountRemaining = remaining_edits;
            } else {
              // check if it's been longer than 7 days since last edit
              newState.canUpdate = updated_moment < moment().subtract(7, 'days');
            }

            return newState;
          });
        } else {
          // no profile => gotta make a new profile before allowing user to access this page
          // this shouldn't happen but check just in case
          history.push('/grant-profile-creation');
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const deconstructObject = obj => {
    let newObj = {};
    Object.keys(obj).forEach(key => {
      newObj[key] = { ...obj[key] };
    });
    return newObj;
  };

  const formChange = (key, value) => {
    setProfileState(old => {
      let temp = { ...old };
      temp.form[key].value = value;
      configAddOns.updateReliantInputs(temp.form, key, value);
      return temp;
    });
  };

  const editForm = () => {
    actions.setEditProfilePopup({ flag: true });
    setProfileState(old => {
      return { ...old, loading: true };
    });

    getBackendDropdownOptions().then(dropdowns => {
      setProfileState(old => {
        let newState = { ...old };
        newState.form.industries.options = dropdowns.industries;
        newState.form.country.options = dropdowns.country;
        newState.form.region_ca.options = dropdowns.region_ca;
        newState.form.region_us.options = dropdowns.region_us;
        newState.form.founderType.options = dropdowns.founderType;
        newState.form.businessType.options = dropdowns.businessType;
        newState.form.annualRevenue.options = dropdowns.annualRevenue;

        Object.keys(newState.form).forEach(i => {
          newState.form[i].edit = true;
        });
        newState.editForm = true;
        newState.loading = false;
        return newState;
      });
    });
  };

  const formSavedSuccessfully = () => {
    setProfileState(old => {
      let temp = {
        ...old,
        loading: false,
        editForm: false,
        editingGrantProfile: false,
        message: 'please wait...',
      };
      Object.keys(temp.form).forEach(i => {
        temp.form[i].edit = false;
      });
      return temp;
    });
  };

  const cancelForm = () => {
    setProfileState(old => {
      let temp = {
        ...old,
        editForm: false,
      };
      Object.keys(temp.form).forEach(i => {
        temp.form[i].edit = false;
      });
      temp.form = temp.backupForm;
      return temp;
    });
  };

  const submitForm = e => {
    e.preventDefault();
    setProfileState(old => {
      let newState = {
        ...old,
        loading: true,
        editingGrantProfile: true,
        message: 'Your wish is granted. Sit tight, new grants are on their way!',
      };

      //decrement edit count
      newState.editCountRemaining -= 1;
      //check edit count after recalculating
      if (newState.editCountRemaining === 0) {
        newState.canUpdate = false;
      }
      return newState;
    });

    let data = {};
    Object.keys(profileState.form).forEach(key => {
      let configObj = profileState.form[key];
      if (configObj.show !== undefined && !configObj.show) return;

      data[configObj.entryValue] = formatGrantProfileValue(configObj.entryValue, configObj.value);
    });

    instance
      .patch('/api/grantprofiles/', data)
      .then(res => {
        Mixpanel.track('Grant Profile Updated', data);
        formSavedSuccessfully();
        actions.setCurLang(data.language); // sync lang in back and front end
      })
      .catch(err => {
        // temporary addition until backend is fixed
        Mixpanel.track('Grant Profile Update Failed', data);
        formSavedSuccessfully(); // show to the user that it's successful but it's not => just to prevent hangup
        console.error(err.response);
      });
  };

  return (
    <form
      className={sharedStyles.container}
      onSubmit={submitForm}
    >
      <Helmet>
        <title>Grant Profile</title>
      </Helmet>
      {profileState.loading ? (
        <Loading className={sharedStyles.loading}>Please Wait...</Loading>
      ) : (
        <>
          <h2>Grant Profile</h2>
          <InputArrayMapper
            customInputProps={profileState.form}
            formChange={formChange}
            containerClassName={sharedStyles.inputArrayContainer}
            inputElementsClassName={classes.inputElementsContainer}
            horizontal
          />
          <div className={`${sharedStyles.formButtons} `}>
            {profileState.editForm ? (
              <>
                <HoverableButton
                  presetBtnStyle="dark-purple"
                  type="submit"
                >
                  Save
                </HoverableButton>
                <p
                  className="link m-0"
                  onClick={cancelForm}
                >
                  Cancel
                </p>
              </>
            ) : (
              // If user has not updated their profile within the last 7 days, show "Edit" button, otherwise show office hours button
              <>
                {profileState.canUpdate ? (
                  <>
                    <HoverableButton
                      presetBtnStyle="dark-purple"
                      onClick={editForm}
                    >
                      Edit
                    </HoverableButton>
                  </>
                ) : (
                  <div className={classes.needHelpSection}>
                    <p>Need help refining your profile?</p>
                    <div className={sharedStyles.formButtons}>
                      <HoverableButton
                        href="https://us06web.zoom.us/meeting/register/tZMrc--uqzopGdIds6hvidCbWyeiXDCparBU"
                        target="_blank"
                        presetBtnStyle="dark-purple"
                      >
                        Join our free virtual office hours!
                      </HoverableButton>
                      <HoverableButton
                        presetBtnStyle="grey"
                        onClick={editForm}
                        disabled
                      >
                        Edit
                      </HoverableButton>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {profileState.canUpdate && (
            <p className={classes.editRemaining}>
              Edits remaining before locking for 7 days: <b>{profileState.editCountRemaining}</b>{' '}
            </p>
          )}
        </>
      )}
      {state.editProfilePopup.flag && (
        <PopupModal
          type="editGrantPopUp"
          backgroundClicked={() => actions.setEditProfilePopup({ flag: false })}
        />
      )}
      {profileState.editingGrantProfile ? <StopInteractionBackground /> : null}
    </form>
  );
};

export default GrantProfile;
