import { encrypt as encryptCore, decrypt as decryptCore } from '@hellopocketed/react-ui.js_utils.core';

/**
 * Encryption key.
 */
const KEY = process.env.REACT_APP_URL_SECRET;

/**
 * Encrypt the given value. Essentially a wrapper around
 * @hellopocketed/react-ui.js_utils.core's encrypt.
 * @param {string} value - value to be encrypted.
 * @returns a string.
 */
export function encrypt(value) {
  return encryptCore(value, KEY);
}

/**
 * Decrypt the given value. Essentially a wrapper around
 * @hellopocketed/react-ui.js_utils.core's decrypt.
 * @param {string} value
 * @returns a string.
 */
export function decrypt(value) {
  return decryptCore(value, KEY);
}
