import React from 'react';
import { Route, Switch } from 'react-router';
import BusinessInformation from './GrantProfileCreationForms/BusinessInformation/BusinessInformation';
import GrantInformation from './GrantProfileCreationForms/GrantInformation/GrantInformation';

import GrantProfileCreated from '../GrantProfileCreated/GrantProfileCreated';
import RegisterLayout from '../../../HOC/Layout/RegisterLayout/RegisterLayout';
import classes from './GrantProfileCreation-v2.module.css';

/**
 * This is the current grant profile creation form as of Oct 2022.
 * @returns
 */
const GrantProfileCreationV2 = () => {
  return (
    <RegisterLayout>
      <div className={classes.container}>
        <Switch>
          <Route
            exact
            path="/grant-profile-creation"
            component={BusinessInformation}
          />
          <Route
            path="/*/grant-information"
            component={GrantInformation}
          />
          <Route
            path="/*/account-created"
            component={GrantProfileCreated}
          />
        </Switch>
      </div>
    </RegisterLayout>
  );
};

export default GrantProfileCreationV2;
