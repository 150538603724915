import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import withTooltip from '../../../HOC/Tooltip/Tooltip';
import classes from './DiscountBanner.module.css';

const DiscountBanner = () => {
  const [activeDiscount, setActiveDiscount] = useState(false);
  const [tooltipCopied, setTooltipCopied] = useState(false);
  const history = useHistory();

  // expDate format: YYYY-MM-DD
  const discounts = [
    {
      text: 'Enjoy 25% off a Pocketed+ membership with code ',
      code: 'PPLUS25WINTER',
      link: '/pocketed-plus',
      expDate: '2023-03-20',
    },
  ];

  useEffect(() => {
    // check if discount codes have expired
    const validDiscounts = discounts.filter(discount => Date.parse(discount.expDate) > Date.now());

    // Choose a random discount code from the list above
    const numValidDiscounts = validDiscounts.length;
    const discountNum = Math.floor(Math.random() * numValidDiscounts);
    setActiveDiscount(numValidDiscounts > 0 ? validDiscounts[discountNum] : false);
  }, []);

  // On copyCode() set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 1.5 redirect user to discount page
      // setTooltipCopied(false);
      history.push(activeDiscount.link);
    }, 1500);

    return () => {
      clearTimeout(timeId);
    };
  }, [tooltipCopied]);

  const copyCode = () => {
    navigator.clipboard.writeText(activeDiscount.code);
    setTooltipCopied(true);

    // Uncomment the following two lines to show alert before redirecting instead
    // alert('Code ' + activeDiscount.code + ' copied to clipboard!');
    // history.push(activeDiscount.link);
  };

  const Tooltip = withTooltip('div', tooltipCopied ? '' : 'Click to copy discount');

  return (
    <>
      {activeDiscount && (
        <div className={classes.container}>
          <Tooltip>
            <p
              onClick={() => {
                copyCode();
              }}
              className={classes.text}
            >
              {tooltipCopied ? (
                <>
                  <span className={classes.code}>{activeDiscount.code}</span> copied to clipboard!
                </>
              ) : (
                <a className={classes.link}>
                  {activeDiscount.text}
                  <span className={classes.code}>{activeDiscount.code}</span>
                </a>
              )}
            </p>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default DiscountBanner;
