import React, { useContext, useEffect } from 'react';
import Layout from '../../HOC/Layout/Layout';
import GrantTrackingCard from '../../Components/GrantTrackingCard/GrantTrackingCard';
import Loading from '../../Components/UI/Loading/Loading';
import useUserCan from '../../Assets/Functions/UserPermissions';
import { ReactComponent as HeartOutline } from '../../Assets/Images/UI/heart.svg';
import { Helmet } from 'react-helmet';

import { withRouter } from 'react-router-dom';
import { StoreContext } from '../../Store/StoreContext';
import axios from 'axios';
import instance from '../../Assets/Axios/AxiosInstance';

import classes from './SavedGrants.module.css';

/**
 * Display all user's saved grants, along with any filters that they might set.
 * @param {*} props
 * @returns
 */

export const SAVED_PAGE_URL = '/saved-grants';

const SavedGrants = props => {
  const { state, actions } = useContext(StoreContext);
  const canGetAlerts = useUserCan.DoAll();

  let listBody = (
    <div className={classes.loading}>
      <Loading />
      <p>Loading...</p>
    </div>
  );

  const callback = (loading = true) => {
    loading && actions.setGrantListLoading(true);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    instance
      .get(`/api/saved-matches/`, { cancelToken: source.token })
      .then(res => {
        actions.setGrantList(res.data);
        loading && actions.setGrantListLoading(false);
      })
      .catch(err => {
        console.log(err);
      });

    return () => {
      source.cancel('axios saved grants request cancelled');
    };
  };

  useEffect(() => {
    actions.resetFilters();

    return callback(); // callback with loading
  }, []);

  if (!state.grantListLoading) {
    if (state.grantList.length) {
      listBody = (
        <div className={classes.cardGrid}>
          {state.grantList
            .filter(grantData => grantData.grant !== undefined)
            .map((grantData, index) => {
              return (
                <GrantTrackingCard
                  grantData={grantData}
                  callback={callback}
                  key={grantData.grant.id}
                />
              );
            })}
        </div>
      );
    } else {
      listBody = (
        <div className={classes.emptyList}>
          <div>
            <h3 className={classes.emptyInfo}>
              Save a grant using the <HeartOutline className={classes.heart} /> icon and track your progress here.
            </h3>

            {canGetAlerts ? (
              <p>When you save a grant, it triggers automatic email alerts so you'll be notified every time that program opens or closes.</p>
            ) : (
              <>
                <p>Want an easy way to stay in the loop for these grants?</p>
                <p>
                  <i>
                    Upgrade to{' '}
                    <a
                      href="/account/subscription"
                      target="blank"
                    >
                      Pocketed+
                    </a>{' '}
                    to receive automatic email alerts every time your saved grants open or close.
                  </i>
                </p>
              </>
            )}
          </div>
        </div>
      );
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>Saved Grants</title>
      </Helmet>
      <div className={classes.container}>
        <h2 className={classes.title}>Saved Grants</h2>
        {listBody}
      </div>
    </Layout>
  );
};

SavedGrants.propTypes = {
  // savedGrants: PropType.bool,
};

export default withRouter(SavedGrants);
