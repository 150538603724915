/**
 * Helper hook to forward user to desired link within/outside platform
 *
 * @param {*} history - a History object from react-router-dom.
 * @param {String} link Link to forward the user to.
 */
export default function goToLink(history, link) {
  if (link.startsWith('https://') || link.startsWith('mailto:')) {
    window.open(link);
  } else {
    history.push(link);
  }
}
