import React, { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../../../Store/StoreContext';
import classes from './PaginationBar.module.css';

/**
 * Number of list item per page. Note: this value is set in the backend for the
 * API result, not the frontend.
 */
const LIST_ITEM_PER_PAGE = 10;

const PaginationBar = () => {
  const { state, actions } = useContext(StoreContext);
  const [pagiBtns, setPagiBtns] = useState([]);

  useEffect(() => {
    let count = Math.ceil(state.totalGrantCount / LIST_ITEM_PER_PAGE);
    let items = [];
    let flag = true,
      flag2 = true;
    // If there 5 or less paginations, just show all 5 pagination
    if (count <= 5) {
      for (let x = 1; x <= count; x++) {
        if (x === state.filterSelection.page) {
          items.push(
            <button
              key={x}
              onClick={() => changePagination(x)}
              className={`${classes.button} ${classes.active}`}
            >
              {x}
            </button>
          );
        } else {
          items.push(
            <button
              key={x}
              onClick={() => changePagination(x)}
              className={classes.button}
            >
              {x}
            </button>
          );
        }
      }
    } else {
      for (let x = 1; x <= count; x++) {
        // If x equals the current page, display it with the active class
        if (x === state.filterSelection.page) {
          items.push(
            <button
              key={x}
              onClick={() => changePagination(x)}
              className={`${classes.button} ${classes.active}`}
            >
              {x}
            </button>
          );
        }
        // If the pagination is the first page, last page, 1 less than the current page, or one more than the current page, display it as a regular pagination number
        else if (x === 1 || x === count || (x >= state.filterSelection.page - 1 && x <= state.filterSelection.page + 1)) {
          items.push(
            <button
              key={x}
              onClick={() => changePagination(x)}
              className={classes.button}
            >
              {x}
            </button>
          );
        }
        // If the pagination meets none of the above criteria, the flag has not been triggered, and is less than the current page, display the goTo button and change flag to false
        else if (flag && x > state.filterSelection.page) {
          items.push(
            <button
              key={x}
              onClick={() => goToPage()}
              className={classes.button}
            >
              ...
            </button>
          );
          flag = false;
        }
        // If the pagination meets none of the above criteria, the flag2 has not been triggered, and is more than the current page, display the goTo button and change flag2 to false
        else if (flag2 && x < state.filterSelection.page) {
          items.push(
            <button
              key={x}
              onClick={() => goToPage()}
              className={classes.button}
            >
              ...
            </button>
          );
          flag2 = false;
        }
      }
    }

    setPagiBtns(items);
  }, [state.grantList, state.filterSelection.page]);

  const changePagination = x => {
    if (x !== state.filterSelection.page) {
      actions.setFilterSelection({ page: x });
    }
  };

  const goToPage = () => {
    let result = prompt('Go to page ' + 1 + '-' + Math.ceil(state.totalGrantCount / LIST_ITEM_PER_PAGE));
    if (result !== null && result !== '') {
      let num = parseInt(result);
      if (num !== state.filterSelection.page) {
        actions.setFilterSelection({ page: num });
      }
    }
  };

  return <div className={classes.container}>{pagiBtns}</div>;
};

export default PaginationBar;
