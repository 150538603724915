import React from 'react';
import classes from './FooterCOA.module.css';
import LangSwitch from '../../LangSwitch/LangSwitch';

const FooterCOA = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className={classes.copyContainer}>
      <div className={classes.copyLinks}>
        <a href="https://hellopocketed.io/privacy-policy/">Privacy Policy</a>
        <a href="https://hellopocketed.io/terms-of-service/">Terms of Use</a>
        <LangSwitch blockAutoTranslate={true} />
      </div>
      <div className={classes.copywrite}>
        <p>&#169;{currentYear} DeepND Inc</p>
      </div>
    </div>
  );
};

export default FooterCOA;
