import React from 'react';
import img from '../../../../Assets/Images/Popups/ViolatedTermsOfService.png';
import SimpleModal from '../BasePopUp/SimpleModal/SimpleModal';

const ViolatedTermsOfServiceModal = ({ show }) => {
  return (
    <SimpleModal show={show}>
      <img
        src={img}
        alt="Naughty Naughty. Your account has been suspended for violating our terms of service. Contact our CEO at briann@hellopocketed.io for more information."
        onClick={() => window.open('mailto:brianna@hellpocketed.io')}
      />
    </SimpleModal>
  );
};

export default ViolatedTermsOfServiceModal;
