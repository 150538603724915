import React, { useContext } from 'react';
import { PocketedColoredLogoWhiteText } from '@hellopocketed/react-ui.images.pocketed_logos';
import classes from './MMpopup.module.css';
import { useHistory } from 'react-router-dom';
import { StoreContext } from '../../../../Store/StoreContext';
import { XCircle } from 'react-bootstrap-icons';
const MMpopup = () => {
  const { actions } = useContext(StoreContext);
  let history = useHistory();
  const goTo = location => {
    actions.setMMPopup({ flag: false });
    history.push(location);
  };
  return (
    <div
      className={classes.container}
      onClick={e => e.stopPropagation()}
    >
      <div className={classes.heading}>
        <img
          src={PocketedColoredLogoWhiteText}
          alt="plus"
        />
        <div
          className={classes.icon}
          onClick={() => {
            actions.setMMPopup({ flag: false });
          }}
        >
          <XCircle
            size={30}
            color={'white'}
          />
        </div>
      </div>

      <div className={classes.content}>
        <h2 className={classes.title}> Money for Hiring</h2>
        <p className={classes.message}>Let us secure you up to $7,500 per student hire. It's risk free - you only pay if you use the cash.</p>
        <p className={classes.info}>Hiring Requirements: Student in tech focused role</p>
        <p className={classes.info}>Deadline: April 30th, 2022</p>
        <a
          href="https://hsmgsurveys.typeform.com/to/spunlIKj"
          target="_blank"
          className={classes.btn}
          onClick={() => actions.setMMPopup({ flag: false })}
        >
          Apply for Me
        </a>
      </div>
    </div>
  );
};

export default MMpopup;
