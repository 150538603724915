import React from 'react';
import PropTypes from 'prop-types';
import BasePocketedIntelSection from './BasePocketedIntelSection/BasePocketedIntelSection';
import { v4 as uuid } from 'uuid';
import { defaultInsightGenerator } from './defaultInsights';
import classes from './PocketedIntelSection.module.css';

/**
 * Height of the text part of intel card by default (not-extended).
 * Make sure this value is compatible with `lockedInsights` and `lockedKeywords`.
 */
export const intelCardTextHeight = '10em';

/**
 * Show the intel that Pocketed has on a grant. So far, this includes
 * Insights, keywords, and resources.
 * @param {} props
 * @returns
 */
const PocketedIntelSection = ({ grant }) => {
  const insights = grant.insights.length > 0 ? grant.insights : defaultInsightGenerator(classes, grant);

  const insightCardContent = insights.map(insight => (
    <p
      key={uuid()}
      className={classes.intelCardText}
    >
      {insight.detail}
    </p>
  ));

  const keywordCardContent = (
    <ul>
      {grant.keywords.length > 0 ? (
        grant.keywords.map(keyword => (
          <li
            key={keyword.name}
            className={classes.intelCardText}
          >
            {keyword.name}
          </li>
        ))
      ) : (
        <li>Coming Soon! We are adding more content everyday. Check back again!</li>
      )}
    </ul>
  );

  return (
    <BasePocketedIntelSection
      insightCardContent={insightCardContent}
      keywordCardContent={keywordCardContent}
    />
  );
};

PocketedIntelSection.propTypes = {
  /**
   * The actual grant data (name, amount, etc.) that are saved in the backend.
   */
  grant: PropTypes.object.isRequired,
};

export default PocketedIntelSection;
