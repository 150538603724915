import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import classes from './ExpandableText.module.css';

/**
 * A textbox that's set to an initial height but can
 * be expand later. Contents that overflow are hidden and has
 * a faded screen hiding parts of it. When
 * shown, will has a smooth transition. The faded screen's height
 * is fixed at 1.2em (rough height of 1 line) and will appear at the bottom of the visible area.
 * @param {} props
 * @returns
 */
const ExpandableText = ({ children, initialHeight = '1em', show = false, isOverflowCallback, withFadedScreen = false }) => {
  /**
   * Whether the inner content is longer than the parent container.
   */
  const [isOverflow, setIsOverflow] = useState(false);

  /**
   * Max height of the container. This is the height when the container is in the
   * `show` = true state.
   */
  const [maxHeight, setMaxHeight] = useState(initialHeight);
  const containerRef = useRef();
  const contentBodyRef = useRef();

  const checkForOverflow = () => {
    const limit = containerRef.current.clientHeight;
    const bodyOverLimit = contentBodyRef.current.clientHeight > limit;
    setIsOverflow(bodyOverLimit);
    if (isOverflowCallback) isOverflowCallback(bodyOverLimit);
    setMaxHeight(contentBodyRef.current.clientHeight);
  };

  useEffect(() => {
    let elem = containerRef.current;
    elem.addEventListener('transitionend', checkForOverflow);

    return () => {
      elem.removeEventListener('transitionend', checkForOverflow);
    };
  });

  useEffect(checkForOverflow, [children, contentBodyRef, containerRef, isOverflowCallback]);

  return (
    <div
      className={classes.container}
      ref={containerRef}
      style={{ height: show ? `${maxHeight}px` : initialHeight }}
    >
      <div ref={contentBodyRef}>{children}</div>

      {/* Only show the faded screen if the user wanted to, the content overflows and the state is not showing */}
      {withFadedScreen && isOverflow && !show && (
        <div className={classes.fadedScreenWrapper}>
          <div
            className={classes.fadedScreen}
            style={{ top: initialHeight }}
          ></div>
        </div>
      )}
    </div>
  );
};

ExpandableText.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * Initial height of the text (before expansion) as a CSS value. Default is `1em`.
   * Using `em` is recommended since it roughly equates to how many lines are shown.
   */
  initialHeight: PropTypes.string,

  /**
   * Whether to show the whole text. Default is `false`.
   */
  show: PropTypes.bool,

  /**
   * A callback that will accept a value of whether the content/children passed is
   * overflowing in this component. `Overflowing` means that the content/children is
   *  bigger than the height of this component.
   */
  isOverflowCallback: PropTypes.func,

  /**
   * Whether to display the faded screen that covers the bottom of the expandable text.
   */
  withFadedScreen: PropTypes.bool,
};

export default ExpandableText;
