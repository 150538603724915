import { freToEngStore, engToFreStore } from './translateGlobal';

/**
 * Get all the nodes with text that are children of the rootNode.
 * @param {Node} rootNode - the root node to start translating. Since this is a React app -> translate the root.
 * @param {boolean} toFrench - whether we are translating from eng to french. If false, it means we are translating from
 * french to eng.
 * @param {boolean} checkIFrame - whether to check the iframes as well. Default false.
 * @returns an array of two arrays: first is all the nodes that has text in them; second is all the nodes that are inputs
 * with values in them.
 */
export function getTranslateableNodes(rootNode, toFrench, checkIFrame = false) {
  let textNodes = [];
  let inputNodes = [];
  let title = '';
  let searchNodes = [[document, rootNode]];

  if (checkIFrame) {
    searchNodes = searchNodes.concat(getIframes());
  }

  if (toFrench) {
    for (let [documentGlobal, searchNode] of searchNodes) {
      textNodes = textNodes.concat(checkTextNodesForEngToFre(documentGlobal, searchNode, documentGlobal));
      inputNodes = inputNodes.concat(checkInputsForEngToFre(documentGlobal, searchNode));
    }
    title = checkTitleForEngToFre();
  } else {
    for (let [documentGlobal, searchNode] of searchNodes) {
      textNodes = textNodes.concat(checkTextNodesForFreToEng(documentGlobal, searchNode, documentGlobal));
      inputNodes = inputNodes.concat(checkInputsForFreToEng(documentGlobal, searchNode));
    }
    title = checkTitleForFreToEng();
  }

  return [textNodes, inputNodes, title];
}

/**
 * Get all the iframes in the documents. Only get those that are visible to the user.
 * @return an Array of arrays that represents each iframe found.
 * Each inner array contains 2 elements: the iframe's `contentDocument`
 * and the `body`.
 */
export function getIframes() {
  let iframes = [];
  let iframeNodes = document.querySelectorAll('iframe');
  // check if we have any iframes in the document
  if (iframeNodes) {
    for (let i = 0; i < iframeNodes.length; i++) {
      let iframe = iframeNodes[i];
      if (iframe.style.display !== 'none' && iframe.style.visibility !== 'hidden' && iframe.contentDocument && iframe.contentDocument.body) {
        iframes.push([iframe.contentDocument, iframe.contentDocument.body]);
      }
    }
  }
  return iframes;
}

/**
 * Check all the text nodes of the document (starting from the searchNode)
 * for translations. This is done to get nodes for eng-to-fre translations.
 * @param {Document} doc
 * @param {Node} searchNode
 * @param {boolean} toFrench
 * @param {Document} documentGlobal - the document object that the
 * element belongs in.
 */
function checkTextNodesForEngToFre(doc, searchNode, documentGlobal) {
  let nodes = [];
  let walker = doc.createTreeWalker(searchNode, NodeFilter.SHOW_ELEMENT);
  let element = walker.nextNode();
  while (element) {
    if (!hasValidTextChild(element)) {
      element = walker.nextNode();
      continue;
    }

    if (element.getAttribute('translate') === 'no') {
      element = walker.nextNode();
      continue;
    }

    if (element.childNodes.length === 1) {
      // skip if it's a child of a complex text node
      if (isInValidComplexNode(element)) {
        element = walker.nextNode();
        continue;
      }

      // already in french, don't translate
      if (!freToEngStore[element.textContent] && !parseInt(element.textContent) && element.textContent.trim() !== '') {
        nodes.push(element);
      }

      element = walker.nextNode();
      continue;
    }

    // handle complex nodes
    // 2. <div> Hello <span> world </span> => element contains other elements containing texts
    let string = compressSVGStr(element.innerHTML);

    // already in french, don't translate
    if (!freToEngStore[string] && string !== '') {
      // create a custom node that mimics a normal node so we can re-use the translate code
      nodes.push({
        textContent: string,
        complexNode: element, // mark the node to replace text
        documentGlobal,
      });
    }

    element = walker.nextNode();
  }
  return nodes;
}

/**
 * Compress the svg part of a content string before it gets translated. This is
 * usually used to deal with contents with other tags in them. Helps with reducing
 * character count and reduces costs.
 * @param {string} string
 */
function compressSVGStr(string) {
  return string.replace(/<svg.*<\/svg>/, '<svg></svg>');
}

/**
 * Check all the text nodes of the document (starting from the searchNode)
 * for translations. This is done to get nodes for fre-to-eng translations.
 * @param {Document} doc
 * @param {Node} searchNode
 * @param {Document} documentGlobal - the document object that the
 * element belongs in.
 */
function checkTextNodesForFreToEng(doc, searchNode, documentGlobal) {
  let nodes = [];
  let walker = doc.createTreeWalker(searchNode, NodeFilter.SHOW_ELEMENT);
  let element = walker.nextNode();
  while (element) {
    if (!hasValidTextChild(element)) {
      element = walker.nextNode();
      continue;
    }

    if (element.childNodes.length === 1) {
      if (!isInValidComplexNode(element) && freToEngStore[element.textContent]) {
        nodes.push(element);
      }

      element = walker.nextNode();
      continue;
    }

    // handle case 2
    // 2. <div> Hello <span> world </span> => element contains other elements containing texts
    let string = compressSVGStr(element.innerHTML);
    // only translate if it's in french
    if (freToEngStore[string] && string !== '') {
      nodes.push({
        textContent: string,
        complexNode: element, // mark the node to replace text
        documentGlobal,
      });
    }

    element = walker.nextNode();
  }
  return nodes;
}

/**
 * Check all the inputs of the document (starting from the searchNode)
 * for translations. This is done to get nodes for eng-to-fre translations.
 * @param {Document} doc
 * @param {Node} searchNode
 */
function checkInputsForEngToFre(doc, searchNode) {
  let nodes = [];
  let walker = doc.createTreeWalker(searchNode, NodeFilter.SHOW_ELEMENT);

  let node = walker.nextNode();
  while (node) {
    // only care about the input nodes
    if (node.nodeName !== 'INPUT' || !['submit', 'file', 'button', 'reset'].includes(node.type)) {
      node = walker.nextNode();
      continue;
    }

    // skip if see manual marking to not translate
    if (node.getAttribute('translate') === 'no') {
      node = walker.nextNode();
      continue;
    }

    // skip if the text is already in french
    if (freToEngStore[node.value]) {
      node = walker.nextNode();
      continue;
    }

    nodes.push(node);
    node = walker.nextNode();
  }
  return nodes;
}

/**
 * Check all the inputs of the document (starting from the searchNode)
 * for translations. This is done to get nodes for fre-to-eng translations.
 * @param {Document} doc
 * @param {Node} searchNode
 */
function checkInputsForFreToEng(doc, searchNode) {
  let nodes = [];
  let walker = doc.createTreeWalker(searchNode, NodeFilter.SHOW_ELEMENT);

  let node = walker.nextNode();
  let acceptableTypes = ['submit', 'file', 'button', 'reset'];
  while (node) {
    // only care about the input nodes
    if (node.nodeName === 'INPUT' && acceptableTypes.includes(node.type)) {
      nodes.push(node);
    }
    node = walker.nextNode();
  }
  return nodes;
}

/**
 * Check the page's title and for eng-to-fre translation.
 * @returns the document title if it needs translations
 */
function checkTitleForEngToFre() {
  // check whether it's already in french
  return freToEngStore[document.title] ? '' : document.title;
}

/**
 * Check the page's title and for fre-to-eng translation.
 * @returns the document title if it needs translations
 */
function checkTitleForFreToEng() {
  return document.title;
}

/**
 * Check whether an element has text nodes that's a direct child
 * of it. Also, that text node(s) must NOT be "" aka empty string to be valid.
 * Why check for empty str is necessary: conditional rendering from React.
 * e.g. isShow && <Component />, formatting from js formatter.
 * @param {Element} elem
 * @returns
 */
export function hasValidTextChild(elem) {
  let hasTxt = false;
  let hasNoneEmpty = false;
  for (let node of elem.childNodes) {
    if (node.nodeName === '#text') {
      hasTxt = true;
      if (node.textContent.trim() !== '') {
        hasNoneEmpty = true;
        break;
      }
    }
  }

  if (!hasTxt) return false;

  // empty txt => it's like having no text at all
  return hasNoneEmpty;
}

/**
 * Check whether the node is in a complex node aka a node that has
 * other text nodes surround this element.
 * @param {Element} elem
 */
export function isInValidComplexNode(elem) {
  return hasValidTextChild(elem.parentElement);
}

/**
 * Check whether the element is a complex node where it has one non-empty
 * text node and the other children are non-text nodes (including elements that
 * can contain other text nodes).
 * 1. <div> Hello <a><svg></svg></a></div> => element contains text and non-text elements
 * This will turn the complex case into a non-complex case.
 * @param {*} elem
 * @returns
 */
function isComplexWithOnlyOneText(elem) {
  let textNode = undefined;
  for (let node of elem.childNodes) {
    // is a non-empty text node
    if (node.nodeName === '#text' && node.textContent.trim() !== '') {
      if (textNode) return; // short circuit, we only need 1
      textNode = node;
    }
    // is an element containing a text node aka case 2
    else if (hasValidTextChild(node)) {
      return;
    }
  }
  return textNode;
}
