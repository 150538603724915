import { commonPasswords } from './CommonPasswords';

export const checkPassword = (p1, p2) => {
  let res = { flag: false, msg: '' };
  if (p1 !== p2) {
    res = { flag: true, msg: 'Error: this password does not match the password below' };
  } else if (p1.length < 8) {
    res = { flag: true, msg: 'Error: Your password must be at least 8 characters long' };
  } else if (!isNaN(p1)) {
    res = { flag: true, msg: 'Error: Your password can not be entirely numeric' };
  } else if (commonPasswords.includes(p1)) {
    res = { flag: true, msg: 'Error: Your password must be at not be a commonly used password' };
  }
  // console.log(p1, p2, res)
  return res;
};
