import React, { useState, useContext } from 'react';
import { StoreContext } from '../../../../Store/StoreContext';
import classes from './MySubscription.module.css';
import { Dropdown } from '@hellopocketed/react-ui.dropdown';
import infoVectorPurple from '../../../../Assets/Images/info_vector_purple.svg';
import PopupModal from '../../../../Components/UI/PopUps/PopupModal';
import { portalSubmit } from '../../../../Assets/Functions/Stripe/Stripe';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';

const MySubscription = () => {
  const { state, actions } = useContext(StoreContext);
  const { currentUser } = state;
  const { customer, user_type } = currentUser;
  const [isShowing, setIsShowing] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showCancellationPopup, setShowCancellationPopup] = useState(false);

  if (!customer) return <></>;

  const subPrice = '$' + customer.plan_price + '/' + customer.plan_interval;
  const canCancel = customer.plan_interval === 'year' || customer.status === 'Trialing';

  const createPortalSession = async e => {
    Mixpanel.track('Clicked on Manage Subscription');
    e.preventDefault();
    actions.setShowLoadingPopUp(true);
    // stripe portal //
    return portalSubmit()
      .then(res => {
        actions.setShowLoadingPopUp(false);
      })
      .catch(err => {
        console.log(err);
        actions.setShowLoadingPopUp(false);
      });
  };

  const cancelClick = () => {
    setShowCancellationPopup(true);
  };

  const closeCancellationPopup = () => {
    setShowCancellationPopup(false);
  };

  const infoClicked = () => {
    const msg = '';
    setShowInfo(!showInfo);
  };

  return (
    <>
      <div className={classes.subsContainer}>
        <div className={classes.subsTable}>
          <div className={classes.tableCol}>
            <h3 className={classes.colHeader}>Current Plan</h3>
            <p className={classes.colValue}>{user_type}</p>
          </div>
          <div className={classes.tableCol}>
            <h3 className={classes.colHeader}>Price</h3>
            <p className={classes.colValue}>{subPrice}</p>
          </div>
          <div className={classes.tableCol}>
            <h3 className={classes.colHeader}>Start Date</h3>
            <p className={classes.colValue}>{customer.subscription_start_date}</p>
          </div>
          <div className={classes.tableCol}>
            <h3 className={classes.colHeader}>Next Due date</h3>
            <p className={classes.colValue}>{customer.current_period_end}</p>
          </div>
          <div className={classes.tableCol}>
            <h3 className={classes.colHeader}>Status</h3>
            <p className={classes.colValue}>{customer.status}</p>
          </div>

          <div className={classes.viewDetailsCol}>
            <div className={classes.viewDetails}>
              <Dropdown
                title={'View Details'}
                isShowing={isShowing}
                extraDropdownWrapperClasses={classes.dropdownWrapper}
                extraDropdownClasses={classes.dropdown}
                extraTitleClasses={classes.dropdownTitle}
                setIsShowing={setIsShowing}
              >
                <p
                  onClick={e => createPortalSession(e)}
                  className={classes.dropdownOption}
                >
                  Change Plan
                </p>
                {canCancel && (
                  <p
                    onClick={cancelClick}
                    className={classes.dropdownOption}
                  >
                    Request Cancellation
                  </p>
                )}
              </Dropdown>
              {showInfo && <div className={classes.monthlyInfoSmall}>You signed up for a monthly plan which has a 12-month commitment. If you need to cancel, you'll be able to access cancellation options in month 12 of your subscription.</div>}
            </div>
          </div>
          {!canCancel && (
            <img
              src={infoVectorPurple}
              className={classes.infoIcon}
              alt="info"
              onClick={() => infoClicked()}
            />
          )}
        </div>
        {showCancellationPopup && (
          <PopupModal
            type="cancellationPopup"
            portalSubmit={portalSubmit}
            closePopup={closeCancellationPopup}
            backgroundClicked={closeCancellationPopup}
          />
        )}
      </div>
      <h2 className={classes.headerTitlealignment}>What's Next?</h2>
    </>
  );
};

export default MySubscription;
