import classes from './GrantBasedFinancing.module.css';
import React from 'react';
import Layout from '../../../../HOC/Layout/Layout';
import { HubSpotForm } from '@hellopocketed/react-ui.hubspot_form';
import { Helmet } from 'react-helmet';

const GrantBasedFinancing = () => {
  return (
    <Layout>
      <Helmet>
        <title>Grant-Based Financing</title>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.content}>
          <h2 className={classes.title}>Grant-Based Financing Request Form</h2>
          <p className={classes.formMsg}>
            Looking to finance your grant? You came to the right place. <br />
            Fill out this form and a Grant Financial Advisor will reach out to you shortly.
          </p>
          <HubSpotForm formId="f3b7abb3-f5d0-4c99-aa66-0af5bf4ea3ec" />
        </div>
      </div>
    </Layout>
  );
};

export default GrantBasedFinancing;
