/**
 * Default insights that are shown on every grant.
 */
export const defaultInsightGenerator = (classes, grant) => {
  const grantTypes = grant.grant_type;
  const processTime = grant.process_time;

  // if all else fails, use these
  const defaultInsights = [
    { detail: 'Never miss a deadline. Save this grant in order to receive alerts on deadlines right in your inbox!' },
    {
      detail:
        'Use language the granting agency uses. For example, do they call a new hire "youth"? Use that term! Don\'t replace it with young people or junior employees. Do they refer to "new comers to Canada"? - then use the term new comers in your application. Do they talk about social development? Use social development not social impact. When writing a grant application, it\'s all about making the granting agencies\' job easier. If they can see keywords and terms while reviewing, they know you understand the program.',
    },
    {
      detail: (
        <span>
          Show impact! How will this money help your company? How will your company help Canada? Will this provide jobs? A needed service? Does it help advance innovation as it relates to
          <a
            className={classes.insightLink}
            href="https://www.ic.gc.ca/eic/site/125.nsf/eng/h_00000.html"
            target="_blank"
            rel="noreferrer"
          >
            Canada's current goals or priority areas?
          </a>
        </span>
      ),
    },
    { detail: 'Keep checking back. We are adding new insights every day!' },
  ];
  // short process time
  const shortInsights = [
    { detail: 'This application is generally quick – and can be made quicker by using Pocketed+’s Pocketed Application Wizard, which saves your info and allows you to quickly enter data into your application.' },
    { detail: 'Never miss a deadline. Save this grant to receive alerts on deadlines right in your inbox!' },
    {
      detail:
        'Use language and keywords that the granting agency uses to describe the funding program. For example, do they call a new hire “youth”? Use that term! Don’t replace it with young people or junior employees. Do they refer to “newcomers to Canada”? Then use the term “newcomers” in your application. Do they talk about social development? Use social development, not social impact. When writing a grant application, it’s all about making the granting agency’s job easier. If they can see familiar keywords and terms while reviewing, they know you understand the program and its requirements.',
    },
    {
      detail: (
        <span>
          Show impact! How will this money help your company? How will your company help Canada? Will this provide jobs? A needed service? Does help advance innovation as it relates to Canada’s current goals or priority areas? Here is one example of
          goals and priorities:{' '}
          <a
            className={classes.insightLink}
            href="https://www.ic.gc.ca/eic/site/125.nsf/eng/h_00000.html"
            target="_blank"
            rel="noreferrer"
          >
            https://www.ic.gc.ca/eic/site/125.nsf/eng/h_00000.html
          </a>
        </span>
      ),
    },
  ];
  // moderate process time
  const mediumInsights = [
    { detail: 'At Pocketed, we say that grants fund projects, not businesses. Be sure to focus on a specific project with clear deliverables! ' },
    {
      detail:
        'Tell a story.  Storytelling is loved by almost every human on the planet.  Think about how you can frame the problem and solution to lead the reader through your project, position, or idea such that even someone who knows very little about your business will be interested. A captive reader is a happy reader! ',
    },
    {
      detail:
        'Focus on the positive impact, changes, or outcomes that will come from having your grant application funded! Be sure to highlight how your clients, or the community you serve, will benefit and thrive upon the success of your project. This includes economic and social impacts.',
    },
    {
      detail: (
        <span>
          Here is one example of goals and priorities:{' '}
          <a
            className={classes.insightLink}
            href="https://www.ic.gc.ca/eic/site/125.nsf/eng/h_00000.html"
            target="_blank"
            rel="noreferrer"
          >
            https://www.ic.gc.ca/eic/site/125.nsf/eng/h_00000.html
          </a>
        </span>
      ),
    },
    {
      detail: 'Edit, edit, edit and then proofread! Nothing kills a great idea like small mistakes. Looking for help? Book a consultation with a Pocketed grant specialist.',
    },
  ];
  // long process time
  const longInsights = [
    { detail: 'Grants of this value typically require signficant time, management, and expertise to be successful. While it is possible to successfully secure these on your own, hiring a professional is highly recommended.' },
    {
      detail:
        'Talk to the granting agency. No list of tips and tricks will help as much as going straight to the source. For big programs that write big checks, it is worth talking to a real person. Ask them what they are looking for in a project? What has been successful in the past? Are there pitfalls to avoid? Try and get a general sense of the process and make a connection.',
    },
    {
      detail:
        'Give yourself a lot of time. You will likely need letters, quotes, third party support letters, and much more. Granting agencies often look to see if there is broader industry support for big money. But even when there is, it takes time to navigate large companies and review processes to get the right letters.',
    },
    {
      detail:
        'Ask for discounts on quotes from vendors supporting the project. Vendors may say no, but many are quite accommodating. It never hurts to ask, and discounts often signal related industries see the long-term potential for what you’re trying to do.',
    },
    {
      detail:
        'Ask for discounts on quotes from vendors supporting the project. Vendors may say no, but many are quite accommodating. It never hurts to ask, and discounts often signal related industries see the long-term potential for what you’re trying to do.',
    },
    {
      detail: (
        <span>
          Think hard about the budget. Ask for what you truly need. Grants are not designed for companies to use as cash profits and over asking may very well get you rejected. On the flip side, asking for too little may suggest you don’t know what
          it takes to get the work done – and ultimately set yourself up for failure. Invest energy in developing your forecasts to submit a realistic budget. Need support building your model? Pocketed can help! Get in touch:{' '}
          <a
            className={classes.insightLink}
            href="mailto:grantapplications@hellopocketed.io"
            target="_blank"
            rel="noreferrer"
          >
            grantapplications@hellopocketed.io
          </a>
        </span>
      ),
    },
  ];
  const hiringInsights = [
    {
      detail: 'Hiring grants are a beautiful dovetail between the government’s goal of job creation and developing the in-demand skills needed ensure a competitive Canadian workforce, and a company’s need to build their teams and complete projects.',
    },
    { detail: 'It can be harder to secure unrestricted funding for a general marketing or software development project, but hiring grants for marketers or developers abound!' },
    {
      detail:
        'Hiring grants have three elements that must be approved: the employer, the role (including tasks and requirements), and the hire (or employee). This three-sided eligibility means the applications typically have multiple approval stages, and often require information to be submitted from both the company and the candidate.',
    },
    {
      detail: 'Hiring grants are generally awarded on a first come-first serve basis, so are sometimes considered non-competitive. There can still be high demand. This means you are competing against the clock, not against the other applicants.',
    },
    {
      detail:
        'Beginning in summer 2022, hiring funds became increasingly competitive. While you used to be able to apply successfully on your own, it’s now recommended to work with a grant writer – and begin hiring well ahead of the anticipated date the funding agency will begin accepting applications – to increase your chances of success.',
    },
    {
      detail:
        'There are dozens of delivery organizations (or funding agencies) for the federal pool of hiring funding. Each funding agency has nuanced criteria that may include location, industry, role, and more. They may also have unique application and reporting requirements. We recommend reviewing all of your options before determining the right program.',
    },
  ];

  let hiringGrant = false;
  grantTypes.forEach(grantType => {
    if (grantType.type === 'Hiring') {
      hiringGrant = true;
    }
  });

  if (hiringGrant === true) {
    // If hiring grant, show hiring insights
    return hiringInsights;
  } else {
    // Otherwise, show defaults based off application length
    switch (processTime) {
      case 'Quick':
        return shortInsights;
      case 'Moderate':
        return mediumInsights;
      case 'Long':
        return longInsights;
      default:
        return defaultInsights;
    }
  }
};
