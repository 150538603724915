import React, { useContext, useState } from 'react';
import { StoreContext } from '../../../Store/StoreContext';
import classes from './ResendActivationLink.module.css';
import instance from '../../../Assets/Axios/AxiosInstance';
import InputArrayMapper from '../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper';
import { UserAuthInputElemConfigs } from '../../../Assets/Functions/GrantProfileCreationFormElements/FormElementConfigs';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import PocketedLogo from '../../../Components/UI/PocketedLogo/PocketedLogo';

const ResendActivationLink = () => {
  const { actions } = useContext(StoreContext);
  const [profileState, setProfileState] = useState({
    form: {
      email: { ...UserAuthInputElemConfigs.email },
    },
    loading: true,
    editForm: false,
    formComplete: false,
  });

  const formChange = (key, value) => {
    setProfileState(old => {
      let temp = { ...old };
      temp.form[key].value = value;
      return temp;
    });
  };

  const formSubmit = e => {
    e.preventDefault();
    let formdata = new FormData();
    let email = document.getElementById('email').value;
    formdata.append('email', email);

    instance
      .post('/re-activate/', formdata)
      .then(res => {
        setProfileState(old => {
          let res = { ...old, formComplete: true };
          return res;
        });
      })
      .catch(err => {
        // console.log(err.response.status, err.response.data.error)
        if (err.response.status == 422) {
          actions.setPopup({ flag: true, message: 'error' });
        } else {
          setProfileState(old => {
            let res = { ...old };
            res.form.email.belowInputContent = <p className={classes.error}>Error: User email not registered to an account.</p>;
            return res;
          });
        }
      });
  };

  return (
    <form
      className={classes.form}
      onSubmit={e => formSubmit(e)}
    >
      <PocketedLogo extraClasses={classes.img} />
      <div className={classes.header}>
        <h2>Re-send Email Activation Link</h2>
      </div>
      {profileState.formComplete ? (
        <div className={classes.formCompleteMsg}>
          <div className={classes.success}>
            <p>Activation link sent, please check your email.</p>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.content}>
            <InputArrayMapper
              customInputProps={profileState.form}
              containerClassName={classes.inputContainer}
              inputElementsClassName={classes.inputElements}
              classes={classes}
              formChange={formChange}
            />
          </div>
          <div className={classes.formButtons}>
            <HoverableButton
              presetBtnStyle="dark-purple"
              type="submit"
              id="reset-password-submit-btn"
            >
              Reset Password
            </HoverableButton>
          </div>
        </>
      )}
    </form>
  );
};

export default ResendActivationLink;
