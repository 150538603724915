import { GrantProfileInputElemConfigs, configAddOns } from '../../../../../Assets/Functions/GrantProfileCreationFormElements/FormElementConfigs';

/**
 * Formatting for the grant profile is a bit different. Thus, we need to edit the
 * base config with something more applicable to us.
 */
export const formContent = {
  businessName: configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.businessName, '*'),
  website: configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.website, ' (Optional)'),
  phone: configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.phone, '*'),
  country: {
    ...configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.country, '*'),
    type: 'radio',
  },
  region_ca: {
    ...configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.region_ca, '*'),
    belowInputContent: <p>Many provincial grants are restricted to local businesses.</p>,
  },
  region_us: configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.region_us, '*'),
  businessType: {
    ...configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.businessType, '*'),
  },
  businessGoals: { ...GrantProfileInputElemConfigs.businessGoals },
  companyStatus: {
    ...configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.companyStatus, '*'),
    type: 'radio',
    belowInputContent: (
      <p>
        Note: If you're a registered <b>sole proprietorship</b> or <b>partnership</b>, you're considered to be unincorporated.
      </p>
    ),
  },
  registrationDate: {
    ...configAddOns.suffixElemLabel(GrantProfileInputElemConfigs.registrationDate, '*'),
  },
};
