import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExpandableText from '../../../../UI/ExpandableText/ExpandableText';
import { UpDownArrow } from '@hellopocketed/react-ui.widgets.up_down_arrow';
import { GrantTextInfo } from '@hellopocketed/react-ui.texts.grant_text_info';

import classes from './DropdownGrantTextSection.module.css';
import grantDetailsClasses from '../../GrantDetailsView.module.css';

const DropdownGrantTextSection = ({ title, content, overrideCallback, blurText = false }) => {
  const [isShowing, setIsShowing] = useState(false);

  const onClickHandler = () => {
    if (overrideCallback) overrideCallback();
    else setIsShowing(!isShowing);
  };

  return (
    <div>
      <h3
        className={`${grantDetailsClasses.sectionHeader} ${classes.title}`}
        onClick={onClickHandler}
      >
        {title}
        <UpDownArrow
          flipped={!isShowing}
          extraClasses={classes.toggleShowBtn}
        />
      </h3>
      <ExpandableText
        show={isShowing}
        withFadedScreen
      >
        <GrantTextInfo
          content={content}
          className={blurText ? 'blurred-text' : ''}
          renderAsHTML
        />
      </ExpandableText>
    </div>
  );
};

DropdownGrantTextSection.propTypes = {
  /**
   * Title of the section.
   */
  title: PropTypes.string,

  /**
   * The content we want to display. Note that any `+` will
   * be treated as a list delimiter. This is passed down to
   * GrantTextInfo component.
   */
  content: PropTypes.string.isRequired,

  /**
   * A callback that overrides the default callback.
   * By default, clicking the arrow or the text will reveal the description.
   */
  overrideCallback: PropTypes.func,

  /**
   * Whether to blur the text.
   */
  blurText: PropTypes.bool,
};

export default DropdownGrantTextSection;
