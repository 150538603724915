/**
 * Shuffle an array's contents and return it as a copy.
 * This is a pure function (does not modify the original array).
 * Taken from https://stackoverflow.com/a/12646864/11683637.
 * @param {[]} array
 * @return none.
 */
export function shuffleArray(array) {
  let copy = [...array];
  for (let i = copy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [copy[i], copy[j]] = [copy[j], copy[i]];
  }
  return copy;
}
