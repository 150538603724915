import React, { useContext, useEffect, useRef } from 'react';
import instance from '../../../../Assets/Axios/AxiosInstance';
import PropTypes from 'prop-types';
import isGrantHidden from '../../../../Assets/Functions/IsGrantHidden';
import SaveGrantIcon from '../../../UI/SaveGrantIcon/SaveGrantIcon';
import { StoreContext } from '../../../../Store/StoreContext';
import GrantTitle from '../../../UI/GrantDetails/GrantTitle/GrantTitle';
import GrantAmount from '../../../UI/GrantDetails/GrantAmount/GrantAmount';
import GrantClosingDate from '../../../UI/GrantDetails/GrantClosingDate/GrantClosingDate';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import classes from './GrantListItem.module.css';

/**
 * A grant list item in the grants view.
 * @param {Object} props
 * @returns
 */
const GrantListItem = props => {
  const { actions, state } = useContext(StoreContext);

  // store this to check whether the grant list item changes from not hidden to hidden.
  const oldIsHidden = useRef(isGrantHidden(props.liked));

  /**
   * Display the grant's information on the right hand side.
   */
  const itemPicked = () => {
    if (!props.selected) {
      const grantItem = state.grantList.find(data => data.id === props.id);
      actions.setCurrentMatch(grantItem);
      if (props.new) {
        // update the new state. We don't have to update our
        instance
          .patch(`/api/matches/${props.id}/`, { new: false })
          .then(res => actions.replaceCurrentMatch(res.data))
          .catch(err => {
            console.log('Caught error, failing gracefully. Error message below.');
            console.error(err);
          });
      }
      Mixpanel.track('Grant Viewed', {
        ...grantItem,
        grantName: props.title,
      });
    } else {
      // clear out the grant picked
      actions.clearCurrentMatch();
    }

    if (props.onClick) props.onClick();
  };

  useEffect(() => {
    const newGrantHidden = isGrantHidden(props.liked);
    oldIsHidden.current = newGrantHidden;
  }, [props.liked]);

  return (
    <div className={classes.itemWrapper}>
      {isGrantHidden(props.liked) && !oldIsHidden.current && (
        <div className={classes.hiddenOverlay}>
          <p className="m-0">This grant will be available at the end of your grant list.</p>
        </div>
      )}
      <div
        className={`${classes.item} pocketed-rounded-card ${props.selected && classes.selected} ${isGrantHidden(props.liked) && classes.hidden} ${props.className}`}
        onClick={itemPicked}
      >
        <GrantTitle title={props.title} />
        <SaveGrantIcon
          className={classes.grantSaveIcon}
          id={props.id}
          saved={props.saved}
        />
        <p className={classes.grantType}>{props.type}</p>
        <GrantAmount
          amount={props.amount}
          extraClasses={classes.grantAmount}
        />
        <GrantClosingDate expiroryDate={props.expiroryDate} />
      </div>
    </div>
  );
};

GrantListItem.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  expiroryDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  profileImage: PropTypes.string,
  new: PropTypes.bool,
  clicked: PropTypes.func,
  selected: PropTypes.bool,
  liked: PropTypes.number,
  /**
   * Extra onclick handler for when the item is clicked.
   */
  onClick: PropTypes.func,
};

export default GrantListItem;
