import React from 'react';
import PropTypes from 'prop-types';
import withTooltip from '../../../../HOC/Tooltip/Tooltip';

import classes from './BaseOverlay.module.css';

/**
 * A base overlay that will wrap around passed in elements
 * and display an overlay encompassing it. Has two components:
 * a `wrapper` that wraps around the elements we want to lock and a
 * `cover` that covers the elements.
 *
 * By default:
 * - the overlay's wrapper's width be 100%.
 * - the overlay's wrapper's height will be enough to contain the children passed.
 * - The default overlay color is semi-transparent white.
 * @param {} props
 * @returns
 */
const BaseOverlay = ({ children, coverChildren, wrapperClasses = '', coverClasses = '', wrapperStyles = {}, coverOnClick, coverTooltip = '' }) => {
  // simple pass through HOC to a div so it's easier to pass props to both versions.
  let Cover = props => <div {...props}></div>;
  if (coverTooltip) {
    Cover = withTooltip('div', coverTooltip);
  }
  return (
    <div
      className={`${classes.wrapper} ${wrapperClasses}`}
      style={wrapperStyles}
    >
      {children}

      <Cover
        className={`${classes.cover} ${coverClasses}`}
        onClick={coverOnClick}
      >
        {coverChildren}
      </Cover>
    </div>
  );
};

BaseOverlay.propTypes = {
  /**
   * The nodes that we want to put an overlay on top.
   */
  children: PropTypes.node,

  /**
   * The children of the cover. These will be displayed
   * on top of the `children` that we are covering. Can be
   * nodes or text.
   */
  coverChildren: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),

  /**
   * The extra classes of the wrapper. Useful for setting the height/width
   * or `display` of the wrapper.
   */
  wrapperClasses: PropTypes.string,

  /**
   * The extra classes of the cover. Useful for changing colors of the overlay.
   */
  coverClasses: PropTypes.string,

  /**
   * The extra styles of the wrapper. Useful for when using `wrapperClasses` is not
   * applicable.
   */
  wrapperStyles: PropTypes.object,

  /**
   * Onclick handler for when the user clicks on the overlay.
   */
  coverOnClick: PropTypes.func,

  /**
   * Tooltip value for when the cover is hovered over. Default is "" for no tooltip.
   */
  coverTooltip: PropTypes.string,
};

export default BaseOverlay;
