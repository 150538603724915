import React from 'react';
import PropTypes from 'prop-types';

import classes from './BaseResourcesSection.module.css';
import grantDetailsClasses from '../../GrantDetailsView.module.css';

const BaseResourcesSection = ({ children }) => {
  return (
    <div className={`${grantDetailsClasses.section} ${classes.resourcesSection} pocketed-rounded-card`}>
      <h3 className={grantDetailsClasses.sectionHeader}>Resources</h3>
      {children}
    </div>
  );
};

BaseResourcesSection.propTypes = {
  children: PropTypes.node,
};

export default BaseResourcesSection;
