/**
 * All the groups from the backend that the front end should be aware of.
 */
export const GROUPS = {
  termsOfServiceViolators: 'terms_of_service_violators',
};

/**
 * Check whether the `groupName` is in `groups`.
 * @param {string} groupName - name of the group. Should be from the `GROUPS`
 * constant.
 * @param {{name: string}[]} groups - an array of groups as returned by the backend.
 * @returns
 */
export function isInGroup(groupName, groups) {
  return groups.some(group => group.name === groupName);
}
