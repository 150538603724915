import JobDesc from '../../../Assets/Images/ProductTools/JobDesc.png';
import StudentRec from '../../../Assets/Images/ProductTools/StudentRec.png';
import JobAd from '../../../Assets/Images/ProductTools/JobAd.png';
import EmployeeDev from '../../../Assets/Images/ProductTools/EmployeeDev.png';
import InterviewProcess from '../../../Assets/Images/ProductTools/InterviewProcess.png';
import GrantPrep from '../../../Assets/Images/ProductTools/GrantPrep.png';
import { PocketedColoredLogo } from '@hellopocketed/react-ui.images.pocketed_logos';

export const productToolsData = [
  {
    title: 'Job Description Tool',
    body: (
      <>
        Define the role you're hiring for and communicate essential accountabilities with our <b>Job Description Tool!</b>
      </>
    ),
    stripeLink: 'https://hellopocketed.io/job-description-tool',
    downloadLink: 'https://welcome.hellopocketed.io/job-description',
    img: props => (
      <img
        src={JobDesc}
        {...props}
        alt="Product Tool"
      />
    ),
  },
  {
    title: 'Student Recruitment Guide',
    body: (
      <>
        Learn how to build a powerful internship program using our <b>Student Recruitment Guide!</b>
      </>
    ),
    stripeLink: 'https://hellopocketed.io/student-recruitment-tool',
    downloadLink: 'https://welcome.hellopocketed.io/student-recruitment',
    img: props => (
      <img
        src={StudentRec}
        {...props}
        alt="Product Tool"
      />
    ),
  },
  {
    title: 'Job Advertisement Tool',
    body: (
      <>
        Want to attract great talent? Get our winning <b>Job Advertisement Tool!</b>
      </>
    ),
    stripeLink: 'https://hellopocketed.io/job-advertisement-tool',
    downloadLink: 'https://welcome.hellopocketed.io/job-advertisement',
    img: props => (
      <img
        src={JobAd}
        {...props}
        alt="Product Tool"
      />
    ),
  },
  {
    title: 'Employee Development Tool',
    body: (
      <>
        Manage your team more effectively with actionable tips in our <b>Employee Development Tool!</b>
      </>
    ),
    stripeLink: 'https://hellopocketed.io/employee-development-tool',
    downloadLink: 'https://welcome.hellopocketed.io/employee-development',
    img: props => (
      <img
        src={EmployeeDev}
        {...props}
        alt="Product Tool"
      />
    ),
  },
  {
    title: 'Interview Process Tool',
    body: (
      <>
        Identify the right candidates and move your business forward with our <b>Interview Process Tool!</b>
      </>
    ),
    stripeLink: 'https://hellopocketed.io/interview-process-tool',
    downloadLink: 'https://welcome.hellopocketed.io/interview-process',
    img: props => (
      <img
        src={InterviewProcess}
        {...props}
        alt="Product Tool"
      />
    ),
  },
  {
    title: 'Grants Prep Checklist',
    body: (
      <>
        Get organized before you start applying with our <b>Grants Prep Checklist!</b>
      </>
    ),
    stripeLink: 'https://hellopocketed.io/grant-checklist-tool',
    downloadLink: 'https://welcome.hellopocketed.io/grant-checklist',
    img: props => (
      <img
        src={GrantPrep}
        {...props}
        alt="Grant Checklist"
      />
    ),
  },
  {
    title: 'Coming Soon',
    body: 'More tools coming soon!',
    isLogo: true,
    img: props => (
      <img
        src={PocketedColoredLogo}
        {...props}
        alt="Pocketed Logo"
      />
    ),
  },
];
