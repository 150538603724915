import axios from 'axios';
import CSRF_TOKEN_NAME from '../CSRF_TOKEN_NAME';

const instance = axios.create({
  baseURL: process.env.REACT_APP_HOST,
  withCredentials: true,
});
console.log(process.env.REACT_APP_HOST);
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
instance.defaults.xsrfCookieName = CSRF_TOKEN_NAME;
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
// instance.setHeaders({"X-CSRFTOKEN": 'cookie.load("csrftoken")'});

export default instance;
