/**
 * NOTE: It is up to the page that calls this function to set the state.currentUser.
 * See the `useCurrentUserState()` for more info.
 */
import { useContext } from 'react';
import { StoreContext } from '../../Store/StoreContext';
import useGrantProfile from './StateSingletonHelper/useGrantProfileGetter';

/**
 * Helper function to get current user state.
 * NOTE: this function DOES NOT query the backend API
 * for the user state. The cases where this function
 * are used assumed that the user is already logged in (aka
 * they are in a PrivateRoute as seen in App.js).
 * @returns
 */
function useCurrentUserState() {
  const { state } = useContext(StoreContext);
  return state.currentUser;
}

/**
 * Helper function to check users' awarded_amount value from grantprofile
 * Value is stored as a string for a range e.g. '$25K - $50K' so a switch statement is needed
 * returns true/false depending on whether value equates to being greater than 50k
 */
const awarded_amount_condition_check = awarded_amount => {
  switch (awarded_amount) {
    case 'Up to $25K':
    case '$25K - $50K':
      return false;
    case '$50K - $100K':
    case '$100K - $250K':
    case '$250K - $500K':
    case 'Over $500K':
      return true;
    default:
      return false; // Handle unknown values or ranges
  }
};

/**
 * Helper hooks to determine the quality of a user.
 */
export const useIsUser = {
  /**
   * Whether user is paying money for their subscription.
   * NOTE: !Paying() is not the same as Free()
   * @returns
   */
  Paying: () => {
    const { loggedIn, user_type, user_subtype, is_trialing } = useCurrentUserState();
    return loggedIn && user_type !== 'Free' && !(user_type === 'Basic' && user_subtype === 'Legacy') && !is_trialing && !(user_subtype === 'Referral');
  },

  PPlusOrConcierge: () => {
    const { loggedIn, user_type } = useCurrentUserState();
    return loggedIn && (user_type === 'Plus' || user_type === 'Concierge');
  },

  BasicLegacy: () => {
    const { loggedIn, user_type, user_subtype } = useCurrentUserState();
    return loggedIn && user_type === 'Basic' && user_subtype === 'Legacy';
  },
  BasicReferral: () => {
    const { loggedIn, user_type, user_subtype } = useCurrentUserState();
    return loggedIn && user_type === 'Basic' && user_subtype === 'Referral';
  },
  PlusReferral: () => {
    const { loggedIn, user_type, user_subtype } = useCurrentUserState();
    return loggedIn && user_type === 'Plus' && user_subtype === 'Referral';
  },
  BasicTrial: () => {
    const { loggedIn, user_type, is_trialing } = useCurrentUserState();
    return loggedIn && user_type === 'Basic' && is_trialing === true;
  },

  /**
   * Can be used to check whether the user type information is still loading.
   * @returns
   */
  TypeLoading: () => {
    const { user_type } = useCurrentUserState();
    return user_type === '';
  },

  /**
   * Whether user is a free user.
   */
  Free: () => {
    const { loggedIn, user_type } = useCurrentUserState();
    return loggedIn && user_type === 'Free';
  },

  /**
   * Whether user is a Concierge user.
   */
  Concierge: () => {
    const { loggedIn, user_type } = useCurrentUserState();
    return loggedIn && user_type === 'Concierge';
  },

  GrantProfileLoading: () => {
    const { company_size } = useGrantProfile();
    return company_size === '';
  },

  /**
   * Whether user is ICP and are logged in.
   */
  ICPCandidate: () => {
    const { loggedIn } = useCurrentUserState();
    const grantProfileLoading = useIsUser.GrantProfileLoading();
    const isICP = useIsICPCandidate();
    return loggedIn && !grantProfileLoading && isICP;
  },

  /**
   * Whether user is a non-ICP and are logged in.
   * @returns
   */
  NonICPCandidate: () => {
    const { loggedIn } = useCurrentUserState();
    const isICP = useIsICPCandidate();
    const grantProfileLoading = useIsUser.GrantProfileLoading();
    return loggedIn && !grantProfileLoading && !isICP;
  },

  CanExportCandidate: () => {
    const { loggedIn, user_type, referral_code } = useCurrentUserState();
    const grantProfile = useGrantProfile();
    const grantProfileLoading = useIsUser.GrantProfileLoading();
    const { company_size, annual_revenue, incorporated, business_type, country } = grantProfile;
    if (company_size === '') return false; // default company_size is empty while the API call is fetching, so return false until it evaluates
    return (
      loggedIn &&
      !grantProfileLoading &&
      user_type !== 'Concierge' &&
      annual_revenue.min_revenue >= 100000 &&
      incorporated &&
      referral_code !== 'READY4CONCIERGE' &&
      business_type.type === 'For-profit' &&
      country.name === 'Canada'
    );
  },

  /**
   * Whether user is signing up to subscribe to concierge.
   */
  ConciergeCandidate: () => {
    const { loggedIn, user_referral_code, is_trialing, user_type } = useCurrentUserState();
    return loggedIn && user_referral_code === 'READY4CONCIERGE' && is_trialing && user_type !== 'Concierge';
  },

  /**
   * Whether user is signing up to subscribe to concierge.
   */
  NonConciergeCandidate: () => {
    const { loggedIn, user_referral_code, is_trialing } = useCurrentUserState();
    return loggedIn && user_referral_code !== 'READY4CONCIERGE' && is_trialing;
  },

  /**
   * Whether user has signed up using referral code IPFunding.
   */
  IPFundingCandidate: () => {
    const { loggedIn, user_referral_code } = useCurrentUserState();
    return loggedIn && user_referral_code?.toUpperCase() === 'IPFUNDING';
  },
};

/**
 * Determines whether a user is an ICP candidate based on their profile and other details.
 *
 * Difference compared to `useIsUser.ICPCandidate()` is that this doesn't check that the user is logged in.
 *
 * NOTE: this uses `useGrantProfile` and `useCurrentUserState` underneath so it's up to you to check that the user is
 * 1. loggedIn and 2. the grant profile is not loading.
 * @returns
 */
function useIsICPCandidate() {
  const { user_type, referral_code } = useCurrentUserState();
  const { company_size, annual_revenue, awarded_amount, incorporated, industries, country, engage_rd } = useGrantProfile();
  const awarded_amount_condition = awarded_amount_condition_check(awarded_amount); // evaluates to true if amount >= 50k
  return (
    company_size > 0 &&
    user_type !== 'Concierge' &&
    (awarded_amount_condition || annual_revenue.min_revenue >= 100000) &&
    !(industries.includes('Sports') || industries.includes('Real Estate')) &&
    incorporated &&
    engage_rd &&
    referral_code !== 'READY4CONCIERGE' &&
    country.name === 'Canada'
  );
}

/**
 * Helper hooks to check whether the user has permission
 * to do something. Naming convention is `TitleCase` so
 * it looks like `useUserCan.SeeAll()`. Note: this
 */
const useUserCan = {
  /**
   * Catch all checks to see whether the user can do EVERYTHING.
   */
  DoAll: useIsUser.PPlusOrConcierge,

  /**
   * Whether user can see the explore funding page.
   */
  SeeExploreFunding: () => {
    const { loggedIn, user_type } = useCurrentUserState();
    return loggedIn && user_type !== 'Free';
  },
};

export default useUserCan;
