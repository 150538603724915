import { useContext } from 'react';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import StaticPage from '../../../HOC/StaticPage/StaticPage';
import useUserCan from '../../../Assets/Functions/UserPermissions';
import { StoreContext } from '../../../Store/StoreContext';
import Layout from '../../../HOC/Layout/Layout';
import { getConsultationLink } from '../../../Assets/Functions/ConsultationLink';
import image from '../../../Assets/Images/Book a consultation.jpg';
import DiscountCode from '../../../Components/UI/DiscountCode/DiscountCode';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import { Helmet } from 'react-helmet';

const BookAConsultation = () => {
  const { state } = useContext(StoreContext);
  const consultationLink = getConsultationLink(state.currentUser.paidTier);
  const getDiscount = useUserCan.DoAll();
  const is_concierge = state.currentUser.customer?.is_concierge;
  const discountCodes = {
    plus: {
      code: 'PLUSPERKS',
      discount: 25,
    },
    concierge: {
      code: 'CONCIERGEPERKS',
      discount: 25,
    },
  };

  let buttonClick = () => {
    Mixpanel.track('Book a Consultation button clicked');
  };

  const FAQData = [
    {
      title: 'Why book a consultation?',
      content: (
        <>
          We offer 1:1 consultations with our grant specialists to dive into your business details and help you devise a grant strategy. We can help you find grants that are right for your business projects and give you recommendations on how best to
          apply. Book in right{' '}
          <a
            href={consultationLink}
            target="blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    {
      title: 'How to prepare for your consultation?',
      content:
        "We recommend bringing any ideas or questions you have to help focus your consultation and get the most out of your time together. Consider your business goals, any upcoming projects, or grant questions. We'll help you identify which programs are the best fit, what you need to apply, and how to plan for grant reporting once it's approved.",
    },
    {
      title: 'Next steps to book your appointment',
      content: (
        <>
          Simply{' '}
          <a
            href={consultationLink}
            target="blank"
          >
            click this link
          </a>{' '}
          to book your appointment. Once payment is completed, our customer success team will be in touch to connect you with our grant specialists to book you in.
        </>
      ),
    },
  ];

  return (
    <Layout isConsultation={true}>
      <Helmet>
        <title>Book a Consultation</title>
      </Helmet>
      <StaticPage
        title="Book a Pocketed Grant Consultation"
        imgSrc={image}
        FAQ={FAQData}
        shiftXValue={25}
      >
        <p>With our team of specialists and an intuitive database of grants, tax credits, and business incentives, finding the right funding for your business has never been easier!</p>
        <p>Book a 1:1 consultation call with a Pocketed Grant Specialist today to dive into your funding needs.</p>

        {/* TODO: Make button hoverable with "Get 25% off with Pocketed+" */}
        <HoverableButton
          href={consultationLink}
          target="blank"
          presetBtnStyle="pink"
          style={{ margin: '0 0 30px 0' }}
          onClick={() => buttonClick()}
        >
          Book a Consultation
        </HoverableButton>
        {getDiscount && (
          <DiscountCode
            discountCode={is_concierge ? discountCodes.concierge.code : discountCodes.plus.code}
            discountInfo={is_concierge ? { discountPlan: 'Pocketed Concierge', discountAmount: discountCodes.concierge.discount } : { discountPlan: 'Pocketed Plus', discountAmount: discountCodes.plus.discount }}
            isReferral={false}
          />
        )}
      </StaticPage>
    </Layout>
  );
};

export default BookAConsultation;
