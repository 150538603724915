import React from 'react';
import PropTypes from 'prop-types';
import BasePocketedIntelSection, { intelCardTextHeight } from '../BasePocketedIntelSection/BasePocketedIntelSection';
import LockedOverlay, { signInMessage, upgradePocketedPlusMessage } from '../../../../UI/Overlays/LockedOverlay/LockedOverlay';
import { v4 as uuid } from 'uuid';

import grantDetailsClasses from '../../GrantDetailsView.module.css';

/**
 * The keywords that we show to the user when they don't have access to the keywords.
 */
const lockedKeywords = [{ name: 'funding' }, { name: 'support' }, { name: 'green energy' }, { name: 'market expansion' }, { name: 'youth' }, { name: 'technology' }];

/**
 * Default insights that are shown on every grant.
 */
const lockedInsights = [
  { detail: 'Never miss a deadline. Save this grant in order to receive alerts on deadlines right in your inbox!' },
  {
    detail:
      'Tell a story.  Storytelling is loved by almost every human on the planet.  Think about how you can frame the problem and solution to lead the reader through your project, position or idea such that even someone who knows very little about your business will be interested. A captive reader is a happy reader!',
  },
  { detail: 'Think about how you can frame the problem and solution to lead the reader through your project, position or idea such that even someone who knows very little about your business will be interested.' },
];

/**
 * Show the restricted version of the PocketedIntelSection. This means we lock the page
 * if the user is not sign in or not a Pocketed+/above user.
 * @param {} props
 * @returns
 */
const LockedPocketedIntelSection = ({ signInCallback, upgradeCallback }) => {
  // check which callback to use. `signInCallback` takes priority.
  let callback = upgradeCallback;
  let tooltipMessage = upgradePocketedPlusMessage;
  if (signInCallback) {
    callback = signInCallback;
    tooltipMessage = signInMessage;
  }

  let insightCardContent, keywordCardContent;

  insightCardContent = (
    <LockedOverlay
      tooltipMessage={tooltipMessage}
      height={intelCardTextHeight}
      onClick={callback}
    >
      {lockedInsights.map(insight => (
        <p
          key={uuid()}
          className="blurred-text"
        >
          {insight.detail}
        </p>
      ))}
    </LockedOverlay>
  );

  keywordCardContent = (
    <LockedOverlay
      tooltipMessage={tooltipMessage}
      height={intelCardTextHeight}
      onClick={callback}
    >
      <ul>
        {lockedKeywords.map(keyword => (
          <li
            key={keyword.name}
            className="blurred-text"
          >
            {keyword.name}
          </li>
        ))}
      </ul>
    </LockedOverlay>
  );

  return (
    <BasePocketedIntelSection
      insightCardContent={insightCardContent}
      keywordCardContent={keywordCardContent}
    />
  );
};

LockedPocketedIntelSection.propTypes = {
  /**
   * On click handler for asking user to sign in. Also functions
   * as a switch to let the component knows the user is NOT signed in yet.
   */
  signInCallback: PropTypes.func,

  /**
   * On click handler for asking user to upgrade. Also functions
   * as a switch to let the component knows the user is NOT signed in yet.
   */
  upgradeCallback: PropTypes.func,
};

export default LockedPocketedIntelSection;
