import React, { useEffect, useState, useContext } from 'react';
import { formContent } from './FormContent';
import getBackendDropdownOptions from '../../../../../Assets/Functions/GrantProfileOptionsGetter';
import GrantProfileCreationBuilder from '../GrantProfileCreationBuilder';
import OnboardingProgress from '../../../../../Components/UI/ProgressBar/ProgressBar';
import { withRouter } from 'react-router';
import { StoreContext } from '../../../../../Store/StoreContext';
import Loading from '../../../../../Components/UI/Loading/Loading';
import classes from '../FormStyles.module.css'; // general classes for onboarding/registration
import pageClasses from './BusinessInformation.module.css'; // specific to this page
import { OutlineHoverableButton } from '@hellopocketed/react-ui.widgets.buttons.outline_hoverable_button';

const percentage = 50;

const BusinessInformation = ({ history }) => {
  const { state } = useContext(StoreContext);
  const [formContents, setFormContents] = useState({
    ...formContent,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [missingFieldMsg, setMissingFieldMsg] = useState('');

  useEffect(() => {
    getBackendDropdownOptions().then(dropdowns => {
      setFormContents(oldState => {
        let newState = {
          ...oldState,
        };

        newState.country.options = dropdowns.country;
        newState.region_ca.options = dropdowns.region_ca;
        newState.region_us.options = dropdowns.region_us;
        newState.businessGoals.options = dropdowns.businessGoals;
        newState.businessType.options = dropdowns.businessType;
        return newState;
      });
      setIsLoading(false);
    });
  }, []);

  const formSubmit = e => {
    e.preventDefault();
    if (!state.grantProfile.bus_goals || state.grantProfile.bus_goals.length === 0) {
      setMissingFieldMsg('Please select a business goal.');
    } else if (!state.grantProfile.regions || state.grantProfile.regions.length === 0) {
      setMissingFieldMsg('Please select a region.');
    } else {
      history.push('/grant-profile-creation/grant-information');
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h2>
          Welcome to Pocketed! <br /> Complete your profile to get started.
        </h2>
        <p>We use your profile to match your business with eligible funding programs.</p>
        {!state.currentUser.loggedIn && (
          <p className={pageClasses.checkEmail}>
            Make sure to check <b>{state.currentUser.user}</b> for an activation link!
          </p>
        )}
      </div>
      <div className={classes.outerFormContainer}>
        <div className={classes.progressContainer}>
          <OnboardingProgress percentage={percentage} />
        </div>
        <form
          className={classes.formContainer}
          onSubmit={e => formSubmit(e)}
        >
          <h2>Company Information</h2>
          <hr />
          {isLoading ? (
            <div className="min-vh-50 d-flex flex-column justify-content-center align-items-center py-5">
              <Loading />
              <p className="pt-2">Loading...</p>
            </div>
          ) : (
            Object.keys(formContents).map(key => {
              return (
                <GrantProfileCreationBuilder
                  customInputProps={formContents[key]}
                  inputName={key}
                  setFormContents={setFormContents}
                  key={key}
                />
              );
            })
          )}
          {/** The input elements for certain elements (countries, business goals, etc.) don't have 'required' attribute => need to display them manually */}
          {missingFieldMsg ? <p className={classes.errorMessage}>{missingFieldMsg}</p> : <></>}
          <div className={pageClasses.btnContainer}>
            <OutlineHoverableButton
              presetBtnStyle="pink"
              type="submit"
            >
              Next
            </OutlineHoverableButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(BusinessInformation);
