import React, { useContext } from 'react';
import * as Icon from 'react-bootstrap-icons';
import PropTypes from 'prop-types';
import { StoreContext } from '../../../../Store/StoreContext';

import classes from './ToggleSearchButton.module.css';

/**
 * A button that shows/hide the search bar.
 * @param {Object} props
 * @returns
 */
const ToggleSearchButton = ({ onClick }) => {
  const { state, actions } = useContext(StoreContext);
  const masterOnClick = () => {
    actions.showSearchBar(!state.showSearchBar);
    if (onClick) onClick();
  };

  return (
    <Icon.Search
      className={classes.searchIcon}
      onClick={masterOnClick}
    />
  );
};

ToggleSearchButton.propTypes = {
  /**
   * Extra onclick handler for when the button is clicked.
   */
  onClick: PropTypes.func,
};

export default ToggleSearchButton;
