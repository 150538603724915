import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StoreContext } from '../../../../../../Store/StoreContext';
import { Mixpanel } from '@hellopocketed/react-ui.js_utils.mixpanel';
import { CheckboxInput } from '@hellopocketed/react-ui.inputs.checkbox_input';

/**
 * A checkbox input section intended for Filters.
 * @param {*} props
 * @returns
 */
const FilterCheckboxInput = ({ option, filter, checked }) => {
  const { actions } = useContext(StoreContext);

  /**
   * Default onclick for inputs.
   * @param {} option
   */
  const defaultUpdateFilterOnClick = () => {
    checked ? actions.removeSelectedFilter({ filter, value: option }) : actions.addSelectedFilter({ filter, value: option });
    Mixpanel.track('Clicked Filter(Traditional)', { filter: filter });
  };

  return (
    <CheckboxInput
      label={option}
      onChange={defaultUpdateFilterOnClick}
      checked={checked}
    />
  );
};

FilterCheckboxInput.propTypes = {
  /**
   * The option the user can select as the inputs.
   */
  option: PropTypes.string,

  /**
   * The filter that we want to set when the input is clicked on.
   * Must be a valid value to be sent to `/matches`.
   */
  filter: PropTypes.string,

  /**
   * Whether the checkbox should be checked.
   */
  checked: PropTypes.bool,
};

export default FilterCheckboxInput;
