import React from 'react';
import PropTypes from 'prop-types';
import { displayDeadline } from '@hellopocketed/react-ui.js_utils.core';

import classes from './GrantClosingDate.module.css';

/**
 * A grant list item in the grants view.
 * @param {Object} props
 * @returns
 */
const GrantClosingDate = ({ expiroryDate }) => {
  return <p className={classes.grantDeadline}>{displayDeadline(expiroryDate)}</p>;
};

GrantClosingDate.propTypes = {
  expiroryDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default GrantClosingDate;
