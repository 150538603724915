import instance from '../../Axios/AxiosInstance';

/**
 * Creates a HubSpot deal via POST request to our API.
 *
 * The deal adds an owner in HubSpot to send out an introduction email. An association is made to the contact (customer) in HubSpot for follow up.
 *
 * @param {{userEmail: String, partnerEmail: String, title: String, details: String}} data
 */
export const createDeal = data => {
  instance
    .post('/hubspot-api/perks', {
      user_email: data.userEmail,
      partner_email: data.partnerEmail,
      title: data.title,
      // details: data.details,
    })
    .then(res => {
      console.log(res);
    });
};

/**
 * Keep in case we need to create tasks in the future.
 *
 * @param {String} title Task title
 * @param {String} priority low, medium, high
 * @param {String} notes Message for task owner, (user, partner, discount info)
 */
// export const createTask = (title, priority, notes) => {
//   const payload = {
//     properties: {
//       hs_timestamp: '2019-10-30T03:30:17.883Z',
//       hs_task_body: 'Send Proposal',
//       hubspot_owner_id: '64492917',
//       hs_task_subject: 'Follow-up for Brian Buyer',
//       hs_task_status: 'WAITING',
//       hs_task_priority: 'HIGH',
//       hs_task_type: 'CALL',
//     },
//   };
// };
