import React, { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../../Store/StoreContext';
import { useIsUser } from '../../../Assets/Functions/UserPermissions';
import HeroSection from './HeroSection/HeroSection';
import CategoriesCarousel from '../CategoriesCarousel/CategoriesCarousel';
import GrantUsage from './GrantUsage/GrantUsage';
import { useScreenWidth, DEVICE_WIDTH } from '@hellopocketed/react-ui.js_utils.core';
import { UpgradeCallToAction, BookConsultationCallToAction } from '../../UI/CallToAction/CallToAction';
import instance from '../../../Assets/Axios/AxiosInstance';
import classes from './ExploreGrantView.module.css';
import useGrantProfile from '../../../Assets/Functions/StateSingletonHelper/useGrantProfileGetter';
import { Loading } from '@hellopocketed/react-ui.widgets.loading';

// helper function to check if given date is within 1 week of today
const withinOneWeek = updated_at => {
  const profile_updated_recently = new Date(updated_at);

  // Get the current date
  const current_date = new Date();

  // Calculate the difference in milliseconds
  const time_difference = current_date - profile_updated_recently;

  // Convert the time difference to hours
  const hours_difference = time_difference / (1000 * 60 * 60);

  // Check if the profile was updated in the last week
  const updatedWithinLastWeek = hours_difference <= 168;

  return updatedWithinLastWeek === true ? 'Yes' : 'No';
};

const ExploreGrantView = () => {
  const { actions } = useContext(StoreContext);
  const isPaying = useIsUser.Paying();
  const grantProfile = useGrantProfile();
  const isUserICP = useIsUser.ICPCandidate();
  const { incorporated, country, company_name, annual_revenue, industries, region, updated_at, company_size, business_goals } = grantProfile;

  // state to state whether to load the typebot or not
  const [loadTypebot, setLoadTypebot] = useState(true);
  // state to store whether data is ready or not
  const [dataReady, setDataReady] = useState(false);
  // store first name
  const [firstName, setFirstName] = useState(null);
  // store email
  const [email, setEmail] = useState(null);
  // store prefilled variables
  const [prefilled, setPrefilled] = useState({});
  // all conditions to check before rendering the Typebot
  const typebotReady = loadTypebot && dataReady && firstName && email && Object.keys(prefilled).length !== 0;

  // some links will include typebot=false to prevent multiple windows opening and starting a new typebot
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const typebotParam = params.get('typebot');
    if (typebotParam === 'false') {
      setLoadTypebot(false);
    }
  }, []);

  // useEffect to fetch required data, and set boolean flags when data is ready for use
  useEffect(() => {
    const fetchData = () => {
      instance
        .get('/api/users/')
        .then(response => {
          if (response.data.results.length !== 0) {
            setFirstName(response.data.results[0]['first_name']);
            setEmail(response.data.results[0]['email']);
            instance.get('/typebot-history/').then(typebotHistoryResponse => {
              const { grantprofile_correct_date } = typebotHistoryResponse.data;
              let profile_updated_recently;
              if (updated_at === undefined) {
                profile_updated_recently = withinOneWeek(grantprofile_correct_date);
              } else {
                // check which date is more recent, then check that date to see if it's within the last week
                profile_updated_recently = grantprofile_correct_date > updated_at ? withinOneWeek(grantprofile_correct_date) : withinOneWeek(updated_at);
              }

              const regions = region?.map(obj => obj.name).join(', ');
              const industriesList = industries?.map(obj => obj).join(', ');
              const numEmployees = company_size.toString();
              const businessGoal = business_goals?.[0]?.type;
              const isUserIcpString = isUserICP ? 'Yes' : 'No';
              const prefilledVars = {
                APP_HOST: process.env.REACT_APP_HOST,
                FRONTEND_URL: process.env.REACT_APP_FRONTEND,
                FIRST_TIME: typebotHistoryResponse.data.error ? 'Yes' : 'No',
                FIRST_NAME: response.data.results[0]['first_name'],
                EMAIL: response.data.results[0]['email'],
                COMPANY_NAME: company_name,
                INCORP: incorporated ? 'Yes' : 'No',
                COUNTRY: country?.name,
                NUM_EMPLOYEES: numEmployees,
                ANNUAL_REV: annual_revenue?.name,
                INDUSTRIES: industriesList,
                PROVINCES: regions,
                BUSINESS_GOAL: businessGoal,
                IS_USER_ICP: isUserIcpString,
                PROFILE_UPDATED_RECENTLY: profile_updated_recently,
              };
              // only set prefilled and dataReady IF all fields are populated
              let allFieldsPopulated = Object.values(prefilledVars).every(value => value !== undefined && value !== null && value !== '');
              if (allFieldsPopulated) {
                setPrefilled(prefilledVars);
                setDataReady(true);
              }
            });
          } else {
            console.log('No results found');
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    };
    if (loadTypebot) {
      fetchData();
    }
  }, [loadTypebot, grantProfile, isUserICP, firstName, email]);

  useEffect(() => {
    if (window.Typebot && typebotReady) {
      initTypebot(prefilled, setLoadTypebot);
    } else if (typebotReady) {
      const script = document.createElement('script');
      script.type = 'module';
      script.src = 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2/dist/web.js';
      script.async = true;
      script.onload = () => initTypebot(prefilled, setLoadTypebot);

      document.body.appendChild(script);

      return () => {
        // Clean up the script when the component unmounts
        document.body.removeChild(script);
      };
    }
  }, [typebotReady, prefilled]);
  // Existing code for regular Explore Grant View
  const screenWidth = useScreenWidth();
  let slidesPerGroup = 5;

  // go in order from smallest to biggest since it's more restrictice
  if (screenWidth <= DEVICE_WIDTH.xLarge) {
    slidesPerGroup = 3;
  } else if (screenWidth <= DEVICE_WIDTH.xxLarge) {
    slidesPerGroup = 4;
  }

  return (
    <div className={classes.container}>
      {loadTypebot ? (
        typebotReady ? (
          <typebot-standard></typebot-standard>
        ) : (
          <Loading />
        )
      ) : (
        <div className={classes.container}>
          <HeroSection />
          <div className={classes.section}>
            <h2>EXPLORE POPULAR FUNDING CATEGORIES</h2>
            <CategoriesCarousel
              slidesPerView={slidesPerGroup}
              slidesPerGroup={slidesPerGroup}
              cardOnClick={cardTitle => actions.setSelectedFilter({ subcategory: cardTitle })}
              extraClasses={classes.carousel}
            />
          </div>
          <div className={classes.section}>
            <h2>THREE WAYS TO USE GRANTS</h2>
            <GrantUsage />
          </div>
          <div className={classes.section}>
            {isPaying ? <BookConsultationCallToAction extraClasses={classes.callToAction} /> : <UpgradeCallToAction extraClasses={classes.callToAction} />}
          </div>
        </div>
      )}
    </div>
  );
};

function initTypebot(prefilled, setLoadTypebot) {
  window.Typebot.initStandard({
    typebot: 'in-platform-chatbot-zpi9m59',
    prefilledVariables: prefilled,
    apiHost: 'https://typebot.io',
    onInit: () => {
      let typebotElem = document.querySelector('typebot-standard');
      let interval = null;
      let counter = 0;
      interval = setInterval(() => {
        // after 10 secs and still not load -> server might be down
        // treat like an error
        if (counter === 20) {
          clearInterval(interval);
          setLoadTypebot(false);
        }
        // check for success or failure based on the existence of error/success element
        let successTypebot = typebotElem.shadowRoot.querySelector('div.typebot-container > div');
        let failedTypebot = typebotElem.shadowRoot.querySelector('div > p');
        if (successTypebot || failedTypebot) {
          clearInterval(interval);

          if (failedTypebot) setLoadTypebot(false);
        }
        counter++;
      }, 500);
    },
  });
}

export default ExploreGrantView;
