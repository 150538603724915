import sharedStyles from '../sharedStyles.module.css';
import React, { useState } from 'react';
import instance from '../../../Assets/Axios/AxiosInstance';
import InputArrayMapper from '../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper';
import { UserAuthInputElemConfigs } from '../../../Assets/Functions/GrantProfileCreationFormElements/FormElementConfigs';
import { Link } from 'react-router-dom';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import { checkPassword } from '../../../Assets/Functions/ProfileCreation';
import { Loading } from '@hellopocketed/react-ui.widgets.loading';
import { Helmet } from 'react-helmet';

const ChangePassword = () => {
  const [profileState, setProfileState] = useState({
    form: {
      // these are custom made inputs just for this page => just borrow the base password config
      current_password: {
        ...UserAuthInputElemConfigs.password,
        label: 'Current Password',
        id: 'cpassword',
        entryValue: 'old_password',
      },
      new_password: {
        ...UserAuthInputElemConfigs.password,
        label: 'New Password',
        id: 'npassword',
        entryValue: 'new_password1',
      },
      confirm_new_password: {
        ...UserAuthInputElemConfigs.password,
        label: 'Confirm New Password',
        id: 'cnpassword',
        entryValue: 'new_password2',
      },
    },
    editForm: false,
    formComplete: false,
  });
  const [message, setMessage] = useState({
    text: '',
    type: 'success', // or "error"
  });
  const [isLoading, setIsLoading] = useState(false);

  const formChange = (key, value) => {
    setProfileState(old => {
      let temp = { ...old };
      temp.form[key].value = value;
      return temp;
    });
  };

  const formSavedSuccessfully = () => {
    setProfileState(old => {
      let result = { ...old };
      result.form.new_password.belowInputContent = null;
      return result;
    });
    setMessage({
      text: 'Password Successfully Changed!',
      type: 'success',
    });
  };

  const saveForm = e => {
    e.preventDefault();
    setMessage({
      text: '',
      type: '',
    });
    let temp = profileState.form;
    let passwordCheck = checkPassword(temp.new_password.value, temp.confirm_new_password.value);
    if (passwordCheck.flag) {
      setMessage({
        text: passwordCheck.msg,
        type: 'error',
      });
    } else {
      let formdata = new FormData();
      formdata.append(temp['current_password'].entryValue, temp['current_password'].value);
      formdata.append(temp['new_password'].entryValue, temp['new_password'].value);
      formdata.append(temp['confirm_new_password'].entryValue, temp['confirm_new_password'].value);
      setIsLoading(true);
      instance
        .post('/accounts/password_change/', formdata)
        .then(res => {
          formSavedSuccessfully();
        })
        .catch(err => {
          console.log(err.response);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <form className={`${sharedStyles.container} ${sharedStyles.textInputContainer}`}>
      <Helmet>
        <title>Change Password</title>
      </Helmet>
      <h2>Change Password</h2>
      <InputArrayMapper
        customInputProps={profileState.form}
        containerClassName={sharedStyles.inputArrayContainer}
        formChange={formChange}
        horizontal
      />
      <p className={sharedStyles[message.type]}>{message.text}</p>
      <div className={sharedStyles.formButtons}>
        {isLoading ? (
          <Loading>Please wait...</Loading>
        ) : (
          <>
            <HoverableButton
              presetBtnStyle="dark-purple"
              presetSize="small"
              onClick={e => saveForm(e)}
            >
              Update
            </HoverableButton>
            <Link to="/account/">Cancel</Link>
          </>
        )}
      </div>
    </form>
  );
};

export default ChangePassword;
