import React, { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { formatAmount } from '@hellopocketed/react-ui.js_utils.core';
import { shuffleArray } from '../../../Assets/Functions/ArrayShuffler';
// must be json files. ProDis data originally comes from CSV => convert to json using services like https://csvjson.com/csv2json
import proDisData from '../../../Assets/Data/proactive_disclosure.json';

import classes from './ProactiveDisclosureBanner.module.css';

/**
 * Delay before swapping to another disclosure.
 */
const SWIPER_DELAY = 5000;

/**
 * Delay before swapping to another disclosure.
 */
const SWIPER_SPEED = 1500;

/**
 * Show the proactive disclosures to the user.
 * @returns
 */
const ProactiveDisclosureBanner = () => {
  const dataToUse = useMemo(() => {
    return shuffleArray(proDisData).slice(0, 100); // only need the first 100
  }, [proDisData]);

  return (
    <div className={'d-sm-flex d-none ' + classes.banner}>
      <p className={`m-0 ${classes.titleText}`}>Recently Awarded Funding | </p>
      <Swiper
        className={classes.swiperContainer}
        modules={[Autoplay]}
        loop
        speed={SWIPER_SPEED}
        allowTouchMove={false}
        autoplay={{ delay: SWIPER_DELAY, disableOnInteraction: false, pauseOnMouseEnter: true }}
      >
        {dataToUse.map(winnerData => (
          <SwiperSlide
            key={winnerData['id']}
            className={classes.slide}
          >
            <p className="m-0">
              {winnerData['recipient_legal_name']} won <b>{formatAmount(winnerData['agreement_value'])}</b> for <i>{winnerData['agreement_title_eng']}</i>.
            </p>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProactiveDisclosureBanner;
