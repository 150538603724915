import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import getCategoryIcon from '../../../../Assets/categoryIcons';
import { Loading } from '@hellopocketed/react-ui.widgets.loading';

import classes from './DropdownOption.module.css';

const CategoryDropdownOption = ({ name }) => {
  return <p className={classes.category}>{name}</p>;
};

CategoryDropdownOption.propTypes = {
  /**
   * The name shown in the dropdown option
   */
  name: PropTypes.string,
};

/**
 * Placeholder for when the dropdowns are loading
 * @returns
 */
const LoadingDropdownOption = () => {
  return (
    <div className={classes.loading}>
      <p className={`${classes.category}`}>Loading</p>
      <Loading />
    </div>
  );
};

const SubcategoryDropdownOption = ({ name, onClick, selected }) => {
  const optionRef = useRef();

  let CategoryIcon = getCategoryIcon(name);

  useEffect(() => {
    if (selected) {
      optionRef.current.scrollIntoView(false);
    }
  }, [selected]);
  return (
    <div
      className={`${classes.subcategory} ${selected ? classes.selected : ''}`}
      onClick={() => onClick(selected)}
      ref={optionRef}
    >
      <CategoryIcon className={classes.subcategoryIcon} />
      <p className={classes.subcategoryText}>{name}</p>
    </div>
  );
};

SubcategoryDropdownOption.propTypes = {
  /**
   * The name shown in the dropdown option.
   * Must be a valid subcategory name so that the appropriate subcategory
   * image can be created.
   */
  name: PropTypes.string,

  /**
   * An onclick handler for the option. Signature is
   * (selected) => null where `selected` is the same
   * as the `selected` props.
   */
  onClick: PropTypes.func,

  /**
   * Whether this option is being selected.
   */
  selected: PropTypes.bool,
};

export { CategoryDropdownOption, SubcategoryDropdownOption, LoadingDropdownOption };
