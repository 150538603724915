import React, { useContext } from 'react';
import classes from './SignInSignUpPopUp.module.css';
import BasePopUp from '../BasePopUp/BasePopUp';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import { OutlineHoverableButton } from '@hellopocketed/react-ui.widgets.buttons.outline_hoverable_button';
import { StoreContext } from '../../../../Store/StoreContext';

/**
 * Prompt the user to sign in or sign up for an account.
 * @param {*} props
 * @returns
 */
const SignInSignUpPopUp = props => {
  const { actions } = useContext(StoreContext);

  const signInCicked = () => {
    actions.setCurrentUser({ showSignInPopUp: true });
    props.closePopUp();
  };

  return (
    <BasePopUp>
      <div className={classes.message}>
        <h2>You need an account to gain access to all of Pocketed's features!</h2>
        <div className={classes.linkContainer}>
          <HoverableButton
            presetBtnStyle="pink"
            onClick={() => signInCicked()}
          >
            Sign in
          </HoverableButton>
          <p>or</p>
          <OutlineHoverableButton
            presetBtnStyle="dark-purple"
            href="/create-account"
          >
            Create an account
          </OutlineHoverableButton>
        </div>
      </div>
    </BasePopUp>
  );
};

export default SignInSignUpPopUp;
