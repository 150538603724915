import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StoreContext } from '../../../Store/StoreContext';
import CustomPopUp from './CustomPopUp/CustomPopUp';
import ErrorPopUp from './ErrorPopUp/ErrorPopUp';
import classes from './PopupModal.module.css';
import ShareGrantPopUp from './SharePopUp/SharePopUp';
import SubscribedPopup from './SubscribedPopup/SubscribedPopup';
import SignInPopUp from './SignInPopUp/SignInPopUp';
import SignInSignUpPopUp from './SignInSignUpPopUp/SignInSignUpPopUp';
import ThumbsDownPopUp from './ThumbsDownPopUp/ThumbsDownPopUp';
import GrantHelpPopUp from './GrantHelpPopUp/GrantHelpPopUp';
import AlphaUsPopup from './AlphaUsPopup/AlphaUsPopup';
import MMpopup from './MMPopup/MMpopup';
import OfficeHoursPopup from './OfficeHoursPopup/OfficeHoursPopup';
import EditProfilePopup from './EditProfilePopup/EditProfilePopup';
import UpgradeToBasicPopUp from './UpgradeToBasicPopUp/UpgradeToBasicPopUp';
import LoadingPopup from './LoadingPopup/LoadingPopup';
import CancellationPopup from './CancellationPopup/CancellationPopup';
import TalktomePopup from './TalktomePopup/TalktomePopup';
// import HolidaysPopup from './HolidaysPopup/HolidaysPopup';

/**
 * Represents a pop up along with a faded background.
 * @param {} props
 * @returns
 */
const PopupModal = props => {
  const { state } = useContext(StoreContext);
  let popup;

  switch (props.type) {
    case 'error':
      popup = <ErrorPopUp message={state.popup.message} />;
      break;
    case 'custom':
      popup = (
        <CustomPopUp
          message={props.message}
          title={props.title}
          image={props.image}
        />
      );
      break;
    case 'thumbsDown':
      popup = <ThumbsDownPopUp thumbsDownFormSubmitted={props.thumbsDownFormSubmitted} />;
      break;
    case 'share':
      popup = <ShareGrantPopUp grantLink={props.grantLink} />;
      break;
    case 'subscribed':
      popup = (
        <SubscribedPopup
          plus={props.plus}
          concierge={props.concierge}
        />
      );
      break;
    case 'signInSignUp':
      popup = (
        <SignInSignUpPopUp
          closePopUp={props.backgroundClicked}
          signInClicked={props.signInClicked}
        />
      );
      break;
    case 'signInForm':
      popup = <SignInPopUp />;
      break;
    case 'grantHelp':
      popup = <GrantHelpPopUp />;
      break;
    case 'alphaUsPopup':
      popup = <AlphaUsPopup />;
      break;
    case 'upgradeToBasic':
      popup = <UpgradeToBasicPopUp />;
      break;
    case 'loadingPopup':
      popup = <LoadingPopup />;
      break;
    case 'MMpopup':
      popup = <MMpopup />;
      break;
    case 'officeHours':
      popup = <OfficeHoursPopup />;
      break;
    case 'editGrantPopUp':
      popup = <EditProfilePopup />;
      break;
    case 'talktomePopup':
      popup = <TalktomePopup />;
      break;
    // case 'holidaysPopup':
    //   popup = <HolidaysPopup/>
    //   break;
    case 'cancellationPopup':
      // allow user to close in case they change their mind and don't want to cancel
      popup = (
        <CancellationPopup
          closePopup={props.closePopup}
          portalSubmit={props.portalSubmit}
        />
      );
      break;
    default:
      popup = null;
      break;
  }

  return (
    <div
      className={classes.popupContainer}
      onClick={() => {
        if (props.backgroundClicked) props.backgroundClicked();
      }}
    >
      {popup}
    </div>
  );
};

PopupModal.propTypes = {
  type: PropTypes.string,
  closePopUp: PropTypes.func,
  signInClicked: PropTypes.func,
};

export default PopupModal;
