import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StoreContext } from '../../../Store/StoreContext';
import GrantDetailsHeader from './GrantDetailsHeader/GrantDetailsHeader';
import PocketedIntelSection from './PocketedIntelSection/PocketedIntelSection';
import LockedPocketedIntelSection from './PocketedIntelSection/LockedPocketedIntelSection/LockedPocketedIntelSection';
import GrantRequirements from './GrantRequirements/GrantRequirements';
import ResourcesSection from './ResourcesSection/ResourcesSection';
import LockedResourcesSection from './ResourcesSection/LockedResourcesSection/LockedResourcesSection';

import classes from './GrantDetailsView.module.css';

/**
 * Show details about a grant (requirements, intels, description, etc.).
 * @param {} props
 * @returns
 */
const GrantDetailsView = ({ signInCallback, upgradeCallback }) => {
  const { state } = useContext(StoreContext);

  /**
   * The grant data including various metadata (selected, saved, etc.).
   * This is whichever grant is saved in the state so it works well even for
   * the transition from single grant view to available grants
   */
  const currentMatch = state.currentMatch;

  let intelSection, resourcesSection;
  if (signInCallback || upgradeCallback) {
    intelSection = (
      <LockedPocketedIntelSection
        signInCallback={signInCallback}
        upgradeCallback={upgradeCallback}
      />
    );

    resourcesSection = (
      <LockedResourcesSection
        signInCallback={signInCallback}
        upgradeCallback={upgradeCallback}
      />
    );
  } else {
    intelSection = <PocketedIntelSection grant={currentMatch.grant} />;
    resourcesSection = <ResourcesSection grant={currentMatch.grant} />;
  }

  return (
    <div className={classes.container}>
      <GrantDetailsHeader
        currentMatch={currentMatch}
        isLockedCallback={signInCallback}
      />
      {intelSection}
      <GrantRequirements
        grant={currentMatch.grant}
        isLockedCallback={signInCallback}
      />
      {resourcesSection}
    </div>
  );
};

GrantDetailsView.propTypes = {
  /**
   * A callback used when the `GrantDetailsView` is in locked mode and require
   * the user to SIGN IN. Default is `undefined` for not in locked mode.
   * Supplying this prop means we want to the component and its children
   * to prompt user to login. This takes priority over `upgradeCallback`.
   */
  signInCallback: PropTypes.func,

  /**
   * A callback used when the `GrantDetailsView` is in locked mode and require
   * the user to UPGRADE. Default is `undefined` for not in locked mode.
   * Supplying this prop means we want to the component and its children
   * to prompt user to login. This has lower priority over `signInCallback`.
   */
  upgradeCallback: PropTypes.func,
};

export default GrantDetailsView;
