import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { HoverableButton } from '@hellopocketed/react-ui.widgets.buttons.hoverable_button';
import classes from './GrantProfileCreated.module.css';
import Upgradegif from '../../../Assets/Images/Upgradetobasic.gif';
import { StoreContext } from '../../../Store/StoreContext';

const GrantProfileCreated = () => {
  const { state } = useContext(StoreContext);
  const isBasicOrPlusReferral = state.currentUser.user_subtype === 'Referral' && (state.currentUser.user_type === 'Basic' || state.currentUser.user_type === 'Plus');

  return (
    <div
      className={classes.container}
      onClick={e => e.stopPropagation()}
    >
      <div className={classes.row}>
        <h2 className={classes.title}> Welcome to Pocketed! </h2>
      </div>
      <div className={classes.row}>
        <p className={classes.message}>Save hundreds of hours Googling for grants and get all the funding programs in one place.</p>
        <p className={classes.message}>Don’t miss a dollar! Grants have never been this easy.</p>
      </div>
      <div className={classes.row}>
        <HoverableButton
          presetBtnStyle="pink"
          href="/auth/signin"
          fontSize={'20px'}
        >
          {isBasicOrPlusReferral ? 'Login now!' : 'Start Your Free 14-Day Trial'}
        </HoverableButton>
      </div>
      <div className={classes.row}>
        <img
          className={classes.gif}
          src={Upgradegif}
          alt="Pocketed+ gif"
        />
      </div>
    </div>
  );
};
export default withRouter(GrantProfileCreated);
