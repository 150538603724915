import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import classes from './PocketedLogo.module.css';
import { PocketedColoredLogoColoredText } from '@hellopocketed/react-ui.images.pocketed_logos';

const PocketedLogo = ({ extraClasses, logoImg = PocketedColoredLogoColoredText }) => {
  const history = useHistory();

  const logoClick = () => {
    history.push('/');
  };

  return (
    <img
      src={logoImg}
      onClick={logoClick}
      className={`${classes} ${extraClasses}`}
      alt="Pocketed Logo"
    />
  );
};

PocketedLogo.propTypes = {
  /**
   * Styling class name.
   */
  extraClasses: PropTypes.string,

  /**
   * The image object that we want to use. Default to Assets/Images/logo.png
   */
  logoImg: PropTypes.any,
};
export default PocketedLogo;
