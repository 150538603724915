import Layout from '../../../HOC/Layout/Layout';
import image from '../../../Assets/Images/Finance a grant.jpg';
import StaticPage from '../../../HOC/StaticPage/StaticPage';
import { HubSpotForm } from '@hellopocketed/react-ui.hubspot_form';
import { Helmet } from 'react-helmet';

const FinanceAGrant = () => {
  const FAQData = [
    {
      title: 'What is grant-based financing?',
      content:
        'Grants work on a reimbursement model, which means that you will still need to cover the cost of your project until you get reimbursed. Grant-based financing is a short-term financing solution to assist with this cash flow until you get reimbursed. Pocketed has partnered with a grant-based financing lender to help you access this financing. Fill out the form on this page to get started.',
    },
    {
      title: 'What are the rates?',
      content: (
        <p>
          Rates vary based on current market conditions.{' '}
          <a
            href="https://www.youtube.com/watch?v=WsNKoAQfOSw&t=1252s"
            target="blank"
          >
            Click here
          </a>{' '}
          to watch Pocketed’s webinar with our partner OKR Financial for more information about grant-based financing.
        </p>
      ),
    },
    {
      title: 'How much of my project costs will a grant cover?',
      content: 'Grants usually cover 50-70% of a project’s costs, which leaves you more cash in the bank to invest in your business.',
    },
  ];

  return (
    <Layout>
      <Helmet>
        <title>Finance a Grant</title>
      </Helmet>
      <StaticPage
        title={'Finance a Grant'}
        shiftXValue={10}
        imgSrc={image}
        FAQ={FAQData}
        form={<HubSpotForm formId="f3b7abb3-f5d0-4c99-aa66-0af5bf4ea3ec" />}
      >
        <p>
          It's important to consider your cash flow during the application process. Most grants in Canada require your company to pay up front and then reimburse eligible costs. For many companies, this means they don't have the cash flow necessary
          to use the grants they win.
        </p>

        <p>
          Pocketed’s grant financing solution helps you get the cash you need! If you’re looking for short-term financing to bridge the gap so that you can use your approved funds, we can help. We have partnered with several lending organizations to
          facilitate short-term loans at founder-friendly rates.
        </p>
      </StaticPage>
    </Layout>
  );
};

export default FinanceAGrant;
