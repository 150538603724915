import React from 'react';
import PropTypes from 'prop-types';
import { GrantTextInfo } from '@hellopocketed/react-ui.texts.grant_text_info';

import grantDetailsClasses from '../../GrantDetailsView.module.css';
import classes from './GrantTextSection.module.css';

/**
 * A grant text section displaying a header and a text content. The text
 * content might contain a paragraph and a list content
 * that are strings separated by `+` in the backend.
 * @returns a React component
 */
const GrantTextSection = ({ title, content, link }) => {
  return (
    <div>
      <h3 className={grantDetailsClasses.sectionHeader}>
        {link ? (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            {title}
          </a>
        ) : (
          title
        )}
      </h3>
      <GrantTextInfo
        content={content}
        renderAsHTML
      />
    </div>
  );
};

GrantTextSection.propTypes = {
  /**
   * Title of the section.
   */
  title: PropTypes.string,

  /**
   * The content we want to display. Note that any `+` will
   * be treated as a list delimiter. This is passed down to
   * GrantTextInfo component.
   */
  content: PropTypes.string.isRequired,
};

export default GrantTextSection;
