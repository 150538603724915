import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StoreContext } from '../../../../Store/StoreContext';
import CustomInput from '../../../../Components/UI/CustomInputs/CustomInput';
import { configAddOns } from '../../../../Assets/Functions/GrantProfileCreationFormElements/FormElementConfigs';

/**
 * Create input elements for the grant profile creation pages.
 * Slightly different from InputArrayMapper in that this component also save data to the grantProfile store context.
 * @param {*} param0
 * @returns
 */
const GrantProfileCreationBuilder = ({ customInputProps, inputName, setFormContents }) => {
  const { actions } = useContext(StoreContext);
  if (customInputProps.show === false) return <div key={inputName}></div>; // only if show is explicitly false do we return a dummy object

  const change = (key, value) => {
    actions.createGrantProfile({ value: value, target: customInputProps.entryValue });
    setFormContents(old => {
      let temp = { ...old };
      temp[inputName].value = value;
      configAddOns.updateReliantInputs(temp, key, value);
      return temp;
    });
    if (customInputProps.onChange) customInputProps.onChange(key, value);
  };

  let value = customInputProps.value;

  return (
    <CustomInput
      {...customInputProps}
      inputName={inputName}
      isGrantProfileCreationInput
      edit
      onChange={change}
      value={value}
    />
  );
};

GrantProfileCreationBuilder.propTypes = {
  /**
   * A config object for the custom input to create.
   */
  customInputProps: PropTypes.object,

  /**
   * Name of the input config.
   */
  inputName: PropTypes.any,

  /**
   * A function to edit the form state
   */
  setFormContents: PropTypes.func,
};

export default GrantProfileCreationBuilder;
