import React, { useContext } from 'react';
import instance from '../../../Assets/Axios/AxiosInstance';
import classes from './Preferences.module.css';
import { StoreContext } from '../../../Store/StoreContext';
import { OutlineHoverableButton } from '@hellopocketed/react-ui.widgets.buttons.outline_hoverable_button';
import { useFeedbackUI } from '@hellopocketed/react-ui.widgets.feedback_ui';

import { Helmet } from 'react-helmet';

const Preferences = () => {
  const { state, actions } = useContext(StoreContext);
  const { showFailedAlert, showSuccessAlert } = useFeedbackUI();
  const email_preference = state.currentUser.preference.email_preference;

  const handleChange = e => {
    console.log(e.target.value);
    actions.setCurrentUser({ preference: { email_preference: e.target.value } });
  };

  const handleSubmit = e => {
    e.preventDefault();
    instance
      .patch(`/api/user-preference/`, { email_preference })
      .then(res => {
        console.log(res.data);
        actions.setCurrentUser({ preference: res.data });
        showSuccessAlert('Preferences saved successfully!');
      })
      .catch(err => {
        showFailedAlert('Error saving preferences. Please try again.');
        console.log(err);
      });
  };

  return (
    <form
      className={classes.container}
      onSubmit={handleSubmit}
    >
      <Helmet>
        <title>Preferences</title>
      </Helmet>
      <div className={classes.header}>
        <h2>Preferences</h2>
      </div>

      <div className={classes.field}>
        <label
          htmlFor="email_preference"
          className={classes.label}
        >
          Email Preference:
        </label>
        <div className={classes.dropdownContainer}>
          <select
            id="email_preference"
            name="email_preference"
            value={email_preference}
            onChange={handleChange}
            className={classes.dropdown}
          >
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="">I don't want to receive exciting updates about my funding opportunities</option>
          </select>
        </div>
        <br />
        <p>Note: Emails will be sent every Tuesday, or monthly on the first Tuesday of each month - depending on your selection.</p>
      </div>
      <OutlineHoverableButton
        presetBtnStyle="pink"
        presetSize="skinny"
        type="submit"
      >
        Save Preferences
      </OutlineHoverableButton>
    </form>
  );
};

export default Preferences;
