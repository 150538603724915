import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classes from './DiscountCode.module.css';

/**
 * A component for displaying Discount Codes that takes percentage & discountCode params for displaying.
 * @param {} props
 * @returns
 */
const DiscountCode = ({ discountCode, discountInfo, isReferral, extraClasses = '' }) => {
  const [copied, setCopied] = useState(false);
  const copyCode = () => {
    setCopied(true);
    navigator.clipboard.writeText(discountCode);
  };
  return (
    <div className={`${classes.container} pocketed-rounded-card ${extraClasses}`}>
      <div className={classes.body}>
        <p className={classes.bodyText}>
          Use the code{' '}
          <span
            className={classes.code}
            onClick={() => {
              copyCode();
            }}
          >
            {discountCode}
          </span>{' '}
          {isReferral ? (
            <>
              at checkout for {discountInfo.discountAmount} off <b>{discountInfo.discountPlan}</b>.
              {!discountInfo.isConcierge && (
                <>
                  {' '}
                  Ready to go all the way to <b>Pocketed Concierge</b>? Contact us and we'll provide a custom discount!
                </>
              )}
            </>
          ) : (
            <>at checkout to access your {discountInfo && 'discountAmount' in discountInfo && `${discountInfo.discountAmount}%`} discount!</>
          )}
        </p>
        {copied && <p className={classes.copiedMsg}>Copied to clipboard!</p>}
      </div>
    </div>
  );
};

DiscountCode.propTypes = {
  discountCode: PropTypes.string,
  discountInfo: PropTypes.object,
  isReferral: PropTypes.bool,
  /**
   * Extra styling classes if needed.
   */
  extraClasses: PropTypes.string,
};

export default DiscountCode;
