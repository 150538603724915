import React, { useState } from 'react';
import { OutlineHoverableButton } from '@hellopocketed/react-ui.widgets.buttons.outline_hoverable_button';

import classes from './PartnerPerksCard.module.css';
// import withTooltip from '../../../HOC/Tooltip/Tooltip';

const PartnerPerksCard = props => {
  let { image, title, details, email, perkClaimHandler } = props;

  const [detailsShowing, setDetailsShowing] = useState(false);
  const [renderText, setRenderText] = useState(false);
  const [claimed, setClaimed] = useState(false);
  const [hasAnimation, setHasAnimation] = useState(false);

  const showDetailsHandler = (e, show) => {
    if (e.target.tagName === 'B') return;

    if (show) {
      setDetailsShowing(show);
      setTimeout(() => {
        setRenderText(show);
        setHasAnimation(true);
      }, 250);
    } else {
      setRenderText(show);
      setTimeout(() => {
        setDetailsShowing(show);
      }, 250);
    }
  };

  return (
    <div
      onClick={event => showDetailsHandler(event, !detailsShowing)}
      className={`${detailsShowing ? classes.activeCard : classes.card} pocketed-rounded-card`}
    >
      {renderText ? (
        <div className={classes.gridActive}>
          <div className={classes.textContainer}>
            <h3
              className={classes.cardTitle}
              translate="no"
            >
              {title}
            </h3>
            <div className={classes.cardDetails}>{details}</div>
          </div>

          <div className={classes.imageContainer}>
            <img
              className={classes.image}
              src={image}
              alt={`${title} logo`}
            />
          </div>

          <div className={classes.buttonContainer}>
            <OutlineHoverableButton
              presetBtnStyle="whitePink"
              onClick={() => perkClaimHandler(props, setClaimed)}
            >
              CLAIM PERK
            </OutlineHoverableButton>
          </div>
        </div>
      ) : (
        <>
          {claimed ? (
            <div className={classes.claimedContainer}>
              <img
                className={classes.claimedImage}
                src={image}
                alt={`${title} logo`}
              />
              {email ? (
                <p>
                  Perk Claimed! We’re pumped to get you started with <b>{title}</b>! An intro is coming your way shortly.
                </p>
              ) : (
                <p>Please sign up through our partner’s website to automatically apply your perk!</p>
              )}
            </div>
          ) : (
            <div className={classes.basicContainer}>
              <img
                className={`${classes.basicImage} ${hasAnimation && classes.hasAnimation}`}
                src={image}
                alt={`${title} logo`}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PartnerPerksCard;
